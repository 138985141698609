import React from 'react';
import Typography from '@mui/material/Typography';

function ApproveTemplateRequestForm({ actionRequest }) {
  return actionRequest ? (
      <Typography variant="body1">
        Approve {actionRequest.permissionTemplateName}?
      </Typography>
  ) : (
    ''
  );
}

export default ApproveTemplateRequestForm;

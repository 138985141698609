import ActionDialog from 'components/Dialogs/ActionDialog';
import ApproveAgencyRequestForm from '../forms/ApproveAgencyRequestForm';
import React from 'react';
import LotusButton from 'components/widgets/Forms/LotusButton';

export default ({ actionRequest, open, handleClose, handleSubmit }) => {
  return (
    <ActionDialog
      title={`Approve Agency Request`}
      open={open}
      handleClose={handleClose}
      content={<ApproveAgencyRequestForm actionRequest={actionRequest} />}
      actions={
        <>
          <LotusButton onClick={handleClose}>
            Cancel
          </LotusButton>
          <LotusButton
            onClick={() => handleSubmit(actionRequest)}
          >
            Approve Request
          </LotusButton>
        </>
      }
    />
  );
};

import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import { usePermissionTemplateSearch } from 'contexts/PermissionTemplateSearchContext';
import { usePermissionTemplateManagement } from 'contexts/PermissionTemplateManagementContext';

export default function StartingTemplatePanel({ handleChange }) {
  const {
    values: { startingTemplateId },
    setFieldValue,
  } = useFormikContext();
  const { permissionTemplates, loadPermissionTemplates } = usePermissionTemplateSearch();
  const { startingPermissionTemplate, loadStartingPermissionTemplate } = usePermissionTemplateManagement();

  useEffect(() => {
    if (!permissionTemplates) {
      loadPermissionTemplates();
    }
  }, [permissionTemplates]);

  useEffect(() => {
    if (startingTemplateId && (!startingPermissionTemplate || startingPermissionTemplate.id !== startingTemplateId)) {
      loadStartingPermissionTemplate(startingTemplateId);
    }
  }, [startingTemplateId]);

  useEffect(() => {
    if (startingTemplateId && startingPermissionTemplate && startingPermissionTemplate.id === startingTemplateId) {
      setFieldValue('startingTemplate', startingPermissionTemplate);
    }
  }, [startingTemplateId, startingPermissionTemplate]);

  useEffect(() => {
    return () => {
      setFieldValue('startingTemplate', undefined);
    };
  }, []);
  return (
    permissionTemplates && (
        <LotusSelect
          name="startingTemplateId"
          label="Starting Template"
          items={permissionTemplates
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((template) => {
              return { label: template.name, value: template.id };
            })}
          value={startingTemplateId}
          onChange={handleChange}
        />
      )
  );
}

import React from 'react';
import { FieldArray } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { FormGroup, Stack } from '@mui/material';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';

export default function ProviderTypeLinker({
  values,
  handleChange,
  setFieldValue,
}) {
  const addProviderType = () => {
    if (values.addedProviderTypeName) {
      values.providerTypes.push({name: values.addedProviderTypeName, selected: true, subtypes: []});
      setFieldValue('providerTypes', values.providerTypes);
      setFieldValue('addedProviderTypeName', '');
    }
  };

  return (
    <>
        <FormGroup>
          {values.providerTypes.map((providerType, index) => {
            const handleTypeSelectedChange = (e, idx) => {
              if (!e.target.checked) {
                values.providerTypes[idx].subtypes.forEach(sub => {
                  sub.selected = false;
                });
                setFieldValue('providerTypes', values.providerTypes);
              }
              handleChange(e);
            }
            return (
              <div key={index}>
                <LotusCheckbox
                  name={`providerTypes.${index}.selected`}
                  label={providerType.name}
                  value={providerType.name}
                  checked={providerType.selected}
                  onChange={(e) => handleTypeSelectedChange(e, index)}
                />
                <FieldArray name={`providerTypes.${index}.subtypes`}>
                {({ push }) => {
                  const addSubtype = (idx) => {
                    push({name: values.providerTypes[idx].addedProviderSubtypeName, selected: true});
                    setFieldValue(`providerTypes.${idx}.addedProviderSubtypeName`, '');
                  }
                  return (
                    <>
                    <LotusFormItem indent={1}>
                    {providerType.subtypes.length > 0 && providerType.subtypes.map((subtype, sindex) => (
                      <LotusCheckbox
                        key={sindex}
                        name={`providerTypes.${index}.subtypes.${sindex}.selected`}
                        label={subtype.name}
                        value={subtype.name}
                        checked={subtype.selected}
                        onChange={handleChange}
                      />
                    ))}
                    </LotusFormItem>
                    <LotusFormItem indent={1}>
                      <Stack direction="row">
                        <LotusTextInput
                          name={`providerTypes.${index}.addedProviderSubtypeName`}
                        />
                        <LotusButton onClick={() => addSubtype(index)} style={{ marginBottom: 4, marginLeft: 10 }}>
                          Add Provider Subtype
                        </LotusButton>
                      </Stack>
                    </LotusFormItem>
                    </>
                  );
                }}
                </FieldArray>
              </div>
            );
          })}
        </FormGroup>
        <LotusFormItem>
          <Stack direction="row">
            <LotusTextInput
              name="addedProviderTypeName"
            />
            <LotusButton onClick={addProviderType} style={{ marginBottom: 4, marginLeft: 10 }}>
              Add Provider Type
            </LotusButton>
          </Stack>
        </LotusFormItem>
    </>
  );
}

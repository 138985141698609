import React from 'react';
import AgencyList from './AgencyList';
import AddAgencyPanel from './AddAgencyPanel';
import EditAgencyPanel from './EditAgencyPanel';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { Stack } from '@mui/material';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function AgenciesPage() {
  const navigate = useNavigate();
  const { page } = useParams();
  const { verifyPermission } = useAuthState();
  const hasRequestAgencyPermission = verifyPermission(PERMISSIONS.REQUEST_SUB_AGENCY);

  const addAgencyClick = () => {
    navigate('/agencies/create');
  };

  const showAgencyList = () => {
    navigate('/agencies/list');
  };

  return (
    <div>
      {page === 'list' && (
        <>
          <LotusSpacedBlock>
            <Stack justifyContent="flex-end" direction='row'>
              <LotusButton
                disabled={!hasRequestAgencyPermission}
                onClick={addAgencyClick}
              >
                Request New Agency
              </LotusButton>
            </Stack>
          </LotusSpacedBlock>
          <AgencyList />
        </>
      )}

      {page === 'create' && (
        <AddAgencyPanel handleBackClicked={showAgencyList} />
      )}

      {page === 'edit' && (
        <EditAgencyPanel handleBackClicked={showAgencyList} />
      )}
    </div>
  );
}

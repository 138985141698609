import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import LanguageLinker from '../linkers/LanguageLinker';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function AgencyLanguagesForm({ handleSubmit }) {
  const { agency, languages, loadAgencyLanguages } = useAgencyManagement();

  useEffect(() => {
    if (agency && !languages) {
      loadAgencyLanguages(agency);
    }
  }, [agency]);

  const initialValues =
    agency && languages
      ? {
          selectedLanguages: languages.map((al) => al.languageId),
        }
      : {
          selectedLanguages: [],
        };

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, handleChange, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Languages for Agency'>
                <LotusForm>
                  <LotusFormItem>
                    <LanguageLinker
                      values={values}
                      handleChange={handleChange}
                    />
                  </LotusFormItem>
                  <LotusButton type="submit">
                    Submit
                  </LotusButton>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import ProviderTypeLinker from '../linkers/ProviderTypeLinker';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function AgencyProviderTypesForm({ handleSubmit }) {
  const { agency, providerTypes, loadAgencyProviderTypes } = useAgencyManagement();

  useEffect(() => {
    if (agency && !providerTypes) {
      loadAgencyProviderTypes();
    }
  }, [agency, providerTypes]);

  const initialValues =
    agency && providerTypes
      ? {
          providerTypes: providerTypes.map(
            (apt) => {
              return {
                name: apt.name, 
                selected: true, 
                addedProviderSubtypeName: '',
                subtypes: apt.subtypes.map(s => {
                  return {name: s.name, selected: true} })}}
          ),
          addedProviderTypeName: ''
        }
      : {
          providerTypes: [],
          addedProviderTypeName: ''
        };

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          const toSubmit = values.providerTypes.filter(typ => typ.selected).map(typ => {
            return {
              name: typ.name,
              subtypeNames: typ.subtypes.filter(sub => sub.selected).map(sub => sub.name)
            }
          });
          await handleSubmit(toSubmit);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Provider Types for Agency'>
                <LotusForm>
                  <LotusFormItem>
                    <ProviderTypeLinker
                      values={values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </LotusFormItem>
                  <LotusButton type="submit">
                    Submit
                  </LotusButton>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '@lotus/components';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { usePermissionTemplateSearch } from 'contexts/PermissionTemplateSearchContext';

export default function TemplatesList(permissions) {
  const { hasTemplateEditPermission } = permissions;
  const { permissionTemplates, loadPermissionTemplates } = usePermissionTemplateSearch();
  const { userAgency } = useUserAgency();
  const { agenciesForGrantee, loadAgenciesForGrantee } = useAgencySearch();

  useEffect(() => {
    if (!permissionTemplates) {
      loadPermissionTemplates();
    }
  }, [permissionTemplates]);

  useEffect(() => {
    if (userAgency && !agenciesForGrantee) {
      loadAgenciesForGrantee(userAgency.granteeAgencyId);
    }
  }, [userAgency, agenciesForGrantee]);

  const shapeTemplate = (template) => {
    // need to explicitly construct column values here instead of in table markup
    // as filtering doesnt work on subobjects
    return {
      ...template,
      subagenciesStr: template.agencies
        .filter((a) => a.id !== userAgency.granteeAgencyId)
        .map((a) => a.name)
        .sort((a, b) => (a > b ? 1 : -1))
        .join(', '),
    };
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'Template Name',
      name: 'name',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          const statusKey = tableMeta.rowData[3];
          return hasTemplateEditPermission && statusKey === 'active' ? (
            <Link to={`/templates/edit/${id}`}>
              {value}
            </Link>
          ) : (
            <div>{value}</div>
          );
        },
      },
    },
    {
      label: 'Subagencies',
      name: 'subagenciesStr',
      options: {
        filterOptions: {
          names: agenciesForGrantee
            ? agenciesForGrantee
                .filter((a) => a.id !== userAgency.granteeAgencyId)
                .map((a) => a.name)
            : [],
          logic: (name, filters) => {
            if (filters.length) {
              return filters.every((f) => !name.includes(f));
            }
            return false;
          },
        },
      },
    },
    {
      name: 'statusKey',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      label: 'Template Status',
      name: 'statusName',
    },
    {
      label: 'User Count',
      name: 'userCount',
      options: {
        filterOptions: {
          names: ['> 0', '0'],
          logic: (count, filters) => {
            if (filters.length) {
              return filters.every((f) => {
                if (f === '> 0') {
                  return count === 0;
                } else if (f === '0') {
                  return count !== 0;
                }
              });
            }
            return false;
          },
        },
      },
    },
  ];

  return (
    permissionTemplates && (
      <Table
        columns={columns}
        data={permissionTemplates}
        dataShaper={shapeTemplate}
        totalRows={permissionTemplates.length}
        options={{
          selectableRows: 'none',
          selectableRowsHideCheckboxes: false,
          selectableRowsHeader: false,
        }}
      />
    )
  );
}

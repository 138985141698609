import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import ApproveIcon from '@mui/icons-material/CheckCircleOutline';
import ApprovedIcon from '@mui/icons-material/Check';
import DenyIcon from '@mui/icons-material/HighlightOff';
import DeniedIcon from '@mui/icons-material/HighlightOff';
import { Table } from '@lotus/components';
import { useAuthState } from 'contexts/AuthProvider';
import TemplateActionRequestApprovalDialog from './dialogs/TemplateActionRequestApprovalDialog';
import TemplateActionRequestDenyDialog from './dialogs/TemplateActionRequestDenyDialog';
import { PERMISSIONS } from 'lib/permissionEnums'
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { usePermissionTemplateRequests } from 'contexts/PermissionTemplateRequestsContext';
import { usePermissionTemplateSearch } from 'contexts/PermissionTemplateSearchContext';

export default function RequestsList({ requests }) {
  const [showApproveRequest, setShowApproveRequest] = useState(false);
  const [showDenyRequest, setShowDenyRequest] = useState(false);
  const [selectedRequestType, setSelectedRequestType] = useState('template_request');
  const [requestToProcess, setRequestToProcess] = useState();

  const { user: loggedInUser, verifyPermission } = useAuthState();

  const {
    recentApprovals,
    loadRecentPermissionTemplateApprovals,
    approveRequestToCreatePermissionTemplate,
    approveRequestToUpdatePermissionTemplate,
    denyRequestToCreatePermissionTemplate,
    denyRequestToUpdatePermissionTemplate,
  } = usePermissionTemplateRequests();

  const { loadPermissionTemplates } = usePermissionTemplateSearch();

  useEffect(() => {
    if (!recentApprovals) {
      loadRecentPermissionTemplateApprovals();
    }
  }, []);

  const approveClicked = async (actionRequest) => {
    setShowApproveRequest(true);
    setRequestToProcess(actionRequest);
  };

  const denyClicked = async (actionRequest) => {
    setShowDenyRequest(true);
    setRequestToProcess(actionRequest);
  };

  const denyRequest = async (actionRequest, denyReason) => {
    setShowDenyRequest(false);
    if ('new_template' === actionRequest.actionRequestTypeKey) {
      await denyRequestToCreatePermissionTemplate(actionRequest.id, denyReason);
    } else {
      await denyRequestToUpdatePermissionTemplate(actionRequest.id, denyReason);
    }
  };

  const approveRequest = async (actionRequest) => {
    setShowApproveRequest(false);
    if ('new_template' === actionRequest.actionRequestTypeKey) {
      await approveRequestToCreatePermissionTemplate(actionRequest.id);
    } else {
      await approveRequestToUpdatePermissionTemplate(actionRequest.id);
    }
    // Need to refresh list so new one shows up
    loadPermissionTemplates();
  };

  const shapeRequest = (request) => {
    return {
      ...request,
      actionRequest: request,
    };
  };

  const getApprovalCellView = (row) => {
    const request = row[1];

    if (selectedRequestType === 'recent') {
      const approvalDate = row[2];

      if (approvalDate) {
        return (
          <div>
            <ApprovedIcon style={{ color: 'green' }} />
            <span style={{ marginLeft: 10, verticalAlign: 'top' }}>
              Approved
            </span>
          </div>
        );
      } else {
        const deniedReason = row[3];
        return (
          <div>
            <div>
              <DeniedIcon style={{ color: 'red' }} />
              <span style={{ marginLeft: 10, verticalAlign: 'top' }}>
                Denied
              </span>
            </div>
            <div>
              <div style={{ marginTop: 10 }}>
                    <b>Reason:</b>
                    {deniedReason}
              </div>
            </div>
          </div>
        );
      }
    } else {
      let hasApproveTemplatePermission = false;
      hasApproveTemplatePermission = verifyPermission(PERMISSIONS.APPROVE_TEMPLATE);
      return (
        <>
          {request.requestedById === loggedInUser.id ? (
            <div>
              <ApproveIcon color="disabled" />
              <DenyIcon color="disabled" />
            </div>
          ) : (
            <div>
              <ApproveIcon
                color={hasApproveTemplatePermission ? "primary" : "disabled"}
                style={{ cursor: hasApproveTemplatePermission ? 'pointer' : 'default' }}
                onClick={() => hasApproveTemplatePermission && approveClicked(request)}
              />
              <DenyIcon
                color={hasApproveTemplatePermission ? "primary" : "disabled"}
                style={{ cursor: hasApproveTemplatePermission ? 'pointer' : 'default' }}
                onClick={() => hasApproveTemplatePermission && denyClicked(request)}
              />
            </div>
          )}
        </>
      );
    }
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'actionRequest',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'approvalDate',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      name: 'deniedReason',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      label: 'Approve / Deny',
      name: 'approveDeny',
      options: {
        customBodyRender: (value, tableMeta) => {
          return getApprovalCellView(tableMeta.rowData);
        },
        filter: false,
        download: false,
      },
    },
    {
      label: 'Request',
      name: 'actionRequestTypeName',
    },
    {
      label: 'Template Name',
      name: 'permissionTemplateName',
    },
    {
      label: 'Template Status',
      name: 'permissionTemplateStatusName',
    },
    {
      label: 'Requested By',
      name: 'requestedByEmail',
    },
  ];

  return (
    <>
      <LotusSpacedBlock>
        <Stack justifyContent="flex-start" direction="row" spacing={1}>
          <LotusButton onClick={() => setSelectedRequestType('template_request')}>
            {selectedRequestType === 'template_request' ? (
              <b>Template Requests</b>
            ) : (
              'Template Requests'
            )}
          </LotusButton>
          <LotusButton onClick={() => setSelectedRequestType('recent')} style={{marginLeft: 10}}>
            {selectedRequestType === 'recent' ? (
              <b>Review Recent</b>
            ) : (
              'Review Recent'
            )}
          </LotusButton>
        </Stack>
      </LotusSpacedBlock>
      {((selectedRequestType === 'template_request' && requests) ||
        (selectedRequestType === 'recent' && recentApprovals)) && (
            <Table
              columns={columns}
              data={
                selectedRequestType === 'template_request'
                  ? requests
                  : recentApprovals
              }
              dataShaper={shapeRequest}
              options={{
                selectableRows: 'none',
                selectableRowsHideCheckboxes: false,
                selectableRowsHeader: false,
              }}
            />
      )}

      {requestToProcess && (
        <TemplateActionRequestApprovalDialog
          open={showApproveRequest}
          handleClose={() => setShowApproveRequest(false)}
          actionRequest={requestToProcess}
          handleSubmit={approveRequest}
        />
      )}
      {requestToProcess && (
        <TemplateActionRequestDenyDialog
          open={showDenyRequest}
          handleClose={() => setShowDenyRequest(false)}
          actionRequest={requestToProcess}
          handleSubmit={denyRequest}
        />
      )}
    </>
  );
}

import React, { useEffect } from 'react';
import { useLists } from 'contexts/ListsContext';
import { useState } from 'react';
import { FormGroup } from '@mui/material';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';

export default function LanguageLinker({ values, handleChange }) {
  const { languages, loadLanguages } = useLists();
  const [allLanguages, setAllLanguages] = useState();

  useEffect(() => {
    if (!languages) {
      loadLanguages();
    }
  }, []);

  useEffect(() => {
    if (languages) {
      let lst = [...languages];
      lst = lst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setAllLanguages(lst);
    }
  }, [languages]);

  return (
        <FormGroup>
          {allLanguages &&
            allLanguages.map((node) => {
              return (
                <LotusCheckbox
                  key={`language-${node.id}`}
                  name={'selectedLanguages'}
                  label={node.name}
                  value={node.id}
                  checked={
                    values.selectedLanguages.find((aId) => aId === node.id) !==
                    undefined
                  }
                  onChange={handleChange}
                />
              );
            })}
        </FormGroup>
  );
}

import React, { useState, useEffect } from 'react';
import AgencyProgramsForm from './forms/AgencyProgramsForm';
import AgencyAssessmentsList from './AgencyAssessmentsList';
import { useParams } from 'react-router-dom';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums'
import AgencyDocumentTypesForm from './forms/AgencyDocumentTypesForm';
import AgencyLanguagesForm from './forms/AgencyLanguagesForm';
import AgencyConsentTypesPanel from './AgencyConsentTypesPanel';
import AgencyIntegrationList from './AgencyIntegrationList';
import AgencyProviderTypesForm from './forms/AgencyProviderTypesForm';
import AgencyOptionalFeaturesForm from './forms/AgencyOptionalFeaturesForm';
import AgencyClientInfoForm from './forms/AgencyClientInfoForm';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusTabPanel from 'components/widgets/Layout/LotusTabPanel';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import AgencyEligibilityForm from './forms/AgencyEligibilityForm';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { useUserAgency } from 'contexts/UserAgencyContext';
import TestResultConfigureForm from './forms/TestResultConfigureForm';
import PremiumsPaidConfigureForm from './forms/PremiumsPaidConfigureForm';
import MedicationDispensesConfigureForm from './forms/MedicationDispensesConfigureForm';
import AgencyDashboardsForm from './forms/AgencyDashboardsForm';
import AgencyPermissionsForm from './forms/AgencyPermissionsForm';

export default function EditAgencyPanel() {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabs, setTabs] = useState([]);
  const {
    agency,
    updateAgencyDocumentTypes,
    loadAgency,
    updateAgencyLanguages,
    updateAgencyProviderTypes,
    updateAgencyOptionalFeatures,
    updateAgencyEligibilityConfig,
    updateAgencyPrograms,
    updateAgencyTestResultConfig,
    updateAgencyPremiumsPaidConfig,
    updateAgencyMedicationDispensesConfig,
    updateAgencyPermissions
  } = useAgencyManagement();
  const { loadUserAgency } = useUserAgency();

  const { id: agencyId } = useParams();
  const { verifyPermission } = useAuthState();
  const hasEditIntegrations = verifyPermission(PERMISSIONS.AGENCY_EDIT_INTEGRATIONS);

  useEffect(() => {
    if (agencyId && (!agency || agency.id !== agencyId)) {
      loadAgency(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    const tabsForAgency = [];
    if (agency && agency.id === agencyId) {
      if (agency.isGranteeAgency) {
        tabsForAgency.push(
          { label: 'Assessments', link: '' },
          { label: 'Client Info', link: '' },
          { label: 'Consents', link: '' },
          { label: 'Dashboards', link: '' },
          { label: 'Document Types', link: '' },
          { label: 'Eligibility', link: '' },
          { label: 'Languages', link: '' },
          { label: 'Provider Types', link: '' },
        );
      }
      tabsForAgency.push({ label: 'Optional Features', link: '' });
      if (!agency.isGranteeAgency) {
        // Cant delete programs for grantee or they disappear altogether
        tabsForAgency.push({ label: 'Programs', link: '' });
        tabsForAgency.push({ label: 'Permissions', link: '' });
      }
      if (agency.isGranteeAgency && hasEditIntegrations) {
        tabsForAgency.push({ label: 'Integrations', link: '' });
      }
      if (agency.isGranteeAgency) {
        tabsForAgency.push({ label: 'Test Result', link: '' });
        tabsForAgency.push({ label: 'Premiums Paid', link: '' });
        tabsForAgency.push({ label: 'Medication Dispenses', link: '' });
      }
    }
    setTabs(tabsForAgency);
  }, [agency]);

  const handleTabChange = async (event, newValue) => {
    setTabIndex(newValue);
  };

  const saveAgencyPrograms = async (values) => {
    await updateAgencyPrograms(values.selectedPrograms);
    await loadUserAgency();
  };

  const saveAgencyLanguages = async (values) => {
    await updateAgencyLanguages(values.selectedLanguages);
    await loadUserAgency();
  };

  const saveAgencyProviderTypes = async (providerTypes) => {
    await updateAgencyProviderTypes(providerTypes);
    await loadUserAgency();
  };

  const saveOptionalFeatures = async (values) => {
    await updateAgencyOptionalFeatures(values);
    await loadUserAgency();
  };

  const afterSaveClientInfoConfig = async () => {
    await loadUserAgency();
  };

  const afterSaveDashboardConfig = async () => {
    await loadUserAgency();
  };

  const saveEligibilityConfig = async (values) => {
    await updateAgencyEligibilityConfig(values);
    await loadUserAgency();
  };

  const saveAgencyDocumentTypes = async (values) => {
    await updateAgencyDocumentTypes([
      ...values.agencyDocumentTypes,
      ...values.newDocumentTypes,
    ]);
    await loadUserAgency();
  };

  const saveIntegration = async (values) => {
    await updateAgencyOptionalFeatures(values);
    await loadUserAgency();
  };

  const saveTestResultConfig = async (values) => {
    await updateAgencyTestResultConfig(values);
    await loadUserAgency();
  };

  const savePremiumsPaidConfig = async (values) => {
    await updateAgencyPremiumsPaidConfig(values);
    await loadUserAgency();
  };
  
  const saveMedicationDispensesConfig = async (values) => {
    await updateAgencyMedicationDispensesConfig(values);
    await loadUserAgency();
  };

  const saveAgencyPermissions = async (values) => {
    await updateAgencyPermissions(values.selectedPermissionTemplateIds);
    await loadUserAgency();
  };

  return (
    <LotusPaper>
      {agency && tabs.length > 0 && (
        <>
          <LotusPageTitle title={'Edit Agency - ' + agency.name} />
          <LotusSpacedBlock>
            <LotusTabPanel
              useColorForSelectedItem={true}
              value={tabIndex}
              handleTabChanged={handleTabChange}
              tabs={tabs}
              variant="scrollable"
              scrollButtons="auto"
            />
          </LotusSpacedBlock>
          <LotusSpacedBlock>
            {tabs[tabIndex].label === 'Assessments' && (
              <AgencyAssessmentsList agencyId={agencyId} />
            )}
            {tabs[tabIndex].label === 'Consents' && (
              <AgencyConsentTypesPanel agencyId={agencyId} />
            )}
            {tabs[tabIndex].label === 'Languages' && (
              <AgencyLanguagesForm
                agencyId={agencyId}
                handleSubmit={saveAgencyLanguages}
              />
            )}
            {tabs[tabIndex].label === 'Programs' && (
              <AgencyProgramsForm
                agencyId={agencyId}
                handleSubmit={saveAgencyPrograms}
              />
            )}
            {tabs[tabIndex].label === 'Document Types' && (
              <AgencyDocumentTypesForm
                agencyId={agencyId}
                handleSubmit={saveAgencyDocumentTypes}
              />
            )}
            {tabs[tabIndex].label === 'Provider Types' && (
              <AgencyProviderTypesForm
                agencyId={agencyId}
                handleSubmit={saveAgencyProviderTypes}
              />
            )}
            {tabs[tabIndex].label === 'Optional Features' && (
              <AgencyOptionalFeaturesForm
                agencyId={agencyId}
                handleSubmit={saveOptionalFeatures}
              />
            )}
            {tabs[tabIndex].label === 'Client Info' && (
              <AgencyClientInfoForm
                agency={agency}
                afterSubmit={afterSaveClientInfoConfig}
              />
            )}
            {tabs[tabIndex].label === 'Dashboards' && (
              <AgencyDashboardsForm
                agency={agency}
                afterSubmit={afterSaveDashboardConfig}
              />
            )}
            {tabs[tabIndex].label === 'Eligibility' && (
              <AgencyEligibilityForm
                agency={agency}
                handleSubmit={saveEligibilityConfig}
              />
            )}
            {tabs[tabIndex].label === 'Integrations' && (
              <AgencyIntegrationList
                agencyId={agencyId}
                handleSubmit={saveIntegration}
              />
            )}
            {tabs[tabIndex].label === 'Test Result' && (
              <TestResultConfigureForm
                agency={agency}
                handleSubmit={saveTestResultConfig}
              />
            )}
            {tabs[tabIndex].label === 'Premiums Paid' && (
              <PremiumsPaidConfigureForm
                agency={agency}
                handleSubmit={savePremiumsPaidConfig}
              />
            )}
            {tabs[tabIndex].label === 'Medication Dispenses' && (
              <MedicationDispensesConfigureForm
                agency={agency}
                handleSubmit={saveMedicationDispensesConfig}
              />
            )}
            {tabs[tabIndex].label === 'Permissions' && (
              <AgencyPermissionsForm
                agencyId={agencyId}
                handleSubmit={saveAgencyPermissions}
              />
            )}         
          </LotusSpacedBlock>
        </>
      )}
    </LotusPaper>
  );
}

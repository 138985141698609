import React, { useEffect, useState } from 'react';
import axios from '../../../../axiosInterceptor';
import { Formik, Form } from 'formik';
import { debounce } from 'lodash';
import SelectAgenciesPanel from './SelectAgenciesPanel';
import StartingTemplatePanel from './StartingTemplatePanel';
import CategoriesPanel from './CategoriesPanel';
import { parseApiResult } from 'lib/utils';
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { usePermissionTemplateRequests } from 'contexts/PermissionTemplateRequestsContext';
import { usePermissionTemplateManagement } from 'contexts/PermissionTemplateManagementContext';

export default function TemplateForm({ afterSave, template }) {

  const { categories, loadAllPermissionCategories } = usePermissionTemplateManagement();
  const { createRequestToCreatePermissionTemplate, createRequestToUpdatePermissionTemplate } = usePermissionTemplateRequests();

  const [startingValues, setStartingValues] = useState();
  const { userAgency } = useUserAgency();

  useEffect(() => {
    if (!categories) {
      loadAllPermissionCategories();
    }
  }, []);

  useEffect(() => {
    if (categories) {
      const newValues = template
        ? {
            name: template.name,
            categories: [],
            selectedSubagencies: template.agencyCounts.map(ag => {return ag.agencyId}),
            userCounts: template.agencyCounts,
            errorIds: []
          }
        : {
            name: '',
            categories: [],
            selectedSubagencies: [],
            startingTemplateId: '',
            userCounts: []
          };
      newValues.categories = categories.map((cat) => {
        return {
          ...cat,
          permissions: cat.permissions.map((perm) => {
            return {
              ...perm,
              enabled: template?.data.find(p => perm.key === p.key)?.enabled || false
            };
          }),
        };
      });
      if (template) {
        newValues.startingTemplate = template;
      }
      setStartingValues(newValues);
    }
  }, [categories, template]);

  const handleSubmitTemplate = async (templateData) => {
    const categoryPermissions = templateData.categories
      .map((category) => {
        return category.permissions.map((perm) => {
          return {
            key: perm.key,
            enabled: perm.enabled,
          };
        });
      })
      .flat();

    const agenciesIncludingGrantee = [
      ...templateData.selectedSubagencies,
      userAgency.granteeAgencyId,
    ];
    if (template) {
      await createRequestToUpdatePermissionTemplate(
        {
          templateId: template.id,
          templateName: templateData.name,
          agencies: agenciesIncludingGrantee,
          permissions: categoryPermissions,
        }
      );
    } else {
      await createRequestToCreatePermissionTemplate(
        {
          templateName: templateData.name,
          agencies: agenciesIncludingGrantee,
          permissions: categoryPermissions,
        },
      );
    }

    if (afterSave) {
      await afterSave(templateData);
    }
  };

  const nameChecker = debounce(async (value) => {
    if (value && (!template || value !== template.name)) {
      try {
        const { data } = await axios.post('/api/permissionTemplates', {operationName: 'checkTemplateName', templateName: value});
        return parseApiResult(data).body.error === undefined;
      } catch (error) {
        console.log({ error });
        return false;
      }
    } else {
      return true;
    }
  }, 500);

  return startingValues && (
      <Formik
        initialValues={startingValues}
        validate={async (values) => {
          const errors = {};
          if (values.name.trim()) {
            const ok = await nameChecker(values.name.trim());
            if (ok === false) {
              // this seems to be undefined the first time
              errors.name = 'Template with given name already exists';
            }
          }

          values?.categories.forEach((category, index) => {
            if (category.validationType === 'only-one') {
              const checked = category.permissions.filter(
                (permission) => permission.enabled
              );

              if (checked.length > 1) {
                errors.categories = {
                  [index]:
                    'Only one permission may be selected for this category',
                };
              }
            }
          });

          if (values.errorIds && values.errorIds.length > 0) {
            errors.selectedSubagencies = 'Agency users attached to the template';
          }

          return errors;
        }}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmitTemplate(values);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, handleChange, touched, errors, values }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <LotusForm>
                <LotusFormItem>
                  <LotusTextInput
                    name="name"
                    label="Permission Template Name"
                    required
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <SelectAgenciesPanel
                    values={values}
                    errors={errors}
                    touched={touched}
                    hasTemplate={template}
                    handleChange={handleChange}
                  />
                </LotusFormItem>
                {!template && (
                    <LotusFormItem>
                      <StartingTemplatePanel
                        handleChange={handleChange}
                        values={values}
                      />
                    </LotusFormItem>
                )}
                <LotusFormItem>
                  <CategoriesPanel
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusButton type="submit">
                    Submit Request
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </Form>
          );
        }}
      </Formik>
  );
}

import React from 'react';
import { Formik, Form, yupToFormErrors } from 'formik';
import LotusButton from 'components/widgets/Forms/LotusButton';
import * as yup from 'yup';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import Stack from '@mui/material/Stack';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  data: yup.object({
    unsubmittedDays: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('unsubmitted_apps')) {
        return schema.integer()
          .typeError('Applications Not Submitted Days must be an integer')
          .min(1, 'Applications Not Submitted Days must be greater than zero')
          .max(100, 'Applications Not Submitted Days must be less than or equal to 100')
          .required('Applications Not Submitted Days is required');
      }
      return schema;
    }),
    appsExpiringDays: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('apps_expiring_soon')) {
        return schema.integer()
          .typeError('Applications Expiring Days must be an integer')
          .min(1, 'Applications Expiring Days must be greater than zero')
          .max(100, 'Applications Expiring Days must be less than or equal to 100')
          .required('Applications Expiring Days is required');
      }
      return schema;
    }),
    determinationDays: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('undetermined_apps')) {
        return schema.integer()
          .typeError('Applications Without Determination Days must be an integer')
          .min(1, 'Applications Without Determination Days must be greater than zero')
          .max(100, 'Applications Without Determination Days must be less than or equal to 100')
          .required('Applications Without Determination Days is required');
      }
      return schema;
    }),
    pendingAppDays: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('pending_apps_not_resub')) {
        return schema.integer()
          .typeError('Pending Application Days must be an integer')
          .min(1, 'Pending Application Days must be greater than zero')
          .max(100, 'Pending Application Days must be less than or equal to 100')
          .required('Pending Application Days is required');
      }
      return schema;
    }),
    reviewNotStartedDays: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('unreviewed_apps')) {
        return schema.integer()
          .typeError('Review Not Started Days must be an integer')
          .min(1, 'Review Not Started Days must be greater than zero')
          .max(100, 'Review Not Started Days must be less than or equal to 100')
          .required('Reviews Not Started Days is required');
      }
      return schema;
    }),
    recertificationWindowDays: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('apps_in_recert_window')) {
        return schema.integer()
          .typeError('Number of Days must be an integer')
          .min(1, 'Number of Days must be greater than zero')
          .max(100, 'Number of Days must be less than or equal to 100')
          .required('Number of Days is required');
      }
      return schema;
    }),
    missingTestMonths: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('retention')) {
        return schema.integer()
          .typeError('Number of Months must be an integer')
          .min(1, 'Number of Months must be greater than zero')
          .max(100, 'Number of Months must be less than or equal to 100')
          .required('Number of Months is required');
      }
      return schema;
    }),
    valueExceeded: yup.number().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('suppression')) {
        return schema.integer()
          .typeError('Value Exceeded must be an integer')
          .min(1, 'Value Exceeded must be greater than zero')
          .max(10000, 'Value Exceeded must be less than or equal to 10000')
          .required('Value Exceeded is required');
      }
      return schema;
    }),
    testType: yup.string().when('$metricKey', (metricKey, schema) => {
      if (metricKey.includes('suppression')) {
        return schema.required('Test Type is required');
      }
      return schema;
    }),
  }),
});




const RecertificationWindowFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.recertificationWindowDays"
          value={values.data.recertificationWindowDays}
          label="Unstarted Applications that are x days into the recertification window"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.recertificationWindowDays && Boolean(errors.data?.recertificationWindowDays)
          }
        />
      </LotusFormItem>
    </>
  );
};

const ReviewsNotStartedFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.reviewNotStartedDays"
          value={values.data.reviewNotStartedDays}
          label="Submitted Applications where review has not been started within x days"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.reviewNotStartedDays && Boolean(errors.data?.reviewNotStartedDays)
          }
        />
      </LotusFormItem>
    </>
  );
};

const PendingAppFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.pendingAppDays"
          value={values.data.pendingAppDays}
          label="Pending Applications without being resubmitted within x days"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.pendingAppDays && Boolean(errors.data?.pendingAppDays)
          }
        />
      </LotusFormItem>
    </>
  );
};

const DeterminationFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.determinationDays"
          value={values.data.determinationDays}
          label="Applications submitted without a determination within x days"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.determinationDays && Boolean(errors.data?.determinationDays)
          }
        />
      </LotusFormItem>
    </>
  );
};


const ExpiringAppsFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.appsExpiringDays"
          value={values.data.appsExpiringDays}
          label="Applications that will expire in x days"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.appsExpiringDays && Boolean(errors.data?.appsExpiringDays)
          }
        />
      </LotusFormItem>
    </>
  );
};

const UnsubmittedAppsFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.unsubmittedDays"
          value={values.data.unsubmittedDays}
          label="Applications not submitted within x days"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.unsubmittedDays && Boolean(errors.data?.unsubmittedDays)
          }
        />
      </LotusFormItem>
    </>
  );
};

const RetentionFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.missingTestMonths"
          value={values.data.missingTestMonths}
          label="No test within x months"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.missingTestMonths && Boolean(errors.data?.missingTestMonths)
          }
        />
      </LotusFormItem>

    </>
  );

};

const SuppressionFormFields = ({
  values,
  touched,
  errors,
  readOnly,
  handleChange
}) => {

  return (
    <>
      <LotusFormItem>
        <LotusTextInput
          name="data.testType"
          value={values.data.testType}
          label="Test Type"
          onChange={handleChange}
          maxLength={100}
          disabled={readOnly}
          error={
            touched.data?.testType && Boolean(errors.data?.testType)
          }
        />
      </LotusFormItem>

      <LotusFormItem>
        <LotusTextInput
          name="data.valueExceeded"
          value={values.data.valueExceeded}
          label="Value Exceeded"
          onChange={handleChange}
          disabled={readOnly}
          error={
            touched.data?.valueExceeded && Boolean(errors.data?.valueExceeded)
          }
        />
      </LotusFormItem>
    </>
  );
};


export default function AgencyMetricForm({
  agencyMetric,
  metric,
  readOnly,
  handleSave,
  handleCancel,
}) {

  const initialValues = agencyMetric
    ? {
      ...agencyMetric
    }
    : {
      id: '',
      title: metric.name,
      data: {},
    };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={(values) => {
        try {
          validationSchema.validateSync(values, {
            abortEarly: false,
            context: { metricKey: metric.key },
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={async (values, actions) => {
        await handleSave(values);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <LotusForm>
              {!readOnly && (
                <LotusFormItem>
                  <LotusTextInput
                    name="title"
                    value={values.title}
                    label="Title"
                    onChange={handleChange}
                    maxLength={100}
                    error={touched.title && Boolean(errors.title)}
                  />
                </LotusFormItem>
              )}

              {metric.key === 'apps_expiring_soon' && (
                <ExpiringAppsFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}
              {metric.key === 'unsubmitted_apps' && (
                <UnsubmittedAppsFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}
              {metric.key === 'undetermined_apps' && (
                <DeterminationFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}
              {metric.key === 'pending_apps_not_resub' && (
                <PendingAppFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}
              {metric.key === 'unreviewed_apps' && (
                <ReviewsNotStartedFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}
              {metric.key === 'apps_in_recert_window' && (
                <RecertificationWindowFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}

              {metric.key === 'retention' && (
                <RetentionFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}
              {metric.key === 'suppression' && (
                <SuppressionFormFields
                  values={values}
                  touched={touched}
                  errors={errors}
                  readOnly={readOnly}
                  handleChange={handleChange}
                />
              )}

              {!readOnly && (
                <LotusFormItem>
                  <Stack justifyContent="flex-end" spacing={1} direction="row">
                    <LotusButton
                      color="primary"
                      variant="contained"
                      disabled={readOnly}
                      onClick={handleSubmit}
                    >
                      SAVE CHANGES
                    </LotusButton>
                    <LotusButton
                      onClick={handleCancel}
                    >
                      CANCEL
                    </LotusButton>
                  </Stack>
                </LotusFormItem>
              )}
            </LotusForm>
          </Form>
        );
      }}
    </Formik>
  );
}

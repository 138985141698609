import React, { useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import RequestsList from './components/RequestsList';
import UsersPage from './components/UsersPage';
import { useNavigate, useParams } from 'react-router-dom';
import LotusPage from 'components/widgets/Layout/LotusPage';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { useUserRequests } from 'contexts/UserRequestsContext';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';

export default function UserManagement() {
  const [tabIndex, setTabIndex] = useState(0);
  const { page } = useParams();
  const navigate = useNavigate();

  const { pendingRequests, loadPendingUserRequests } = useUserRequests();
  const { user: loggedInUser, verifyPermission } = useAuthState();
  
  let isValid = loggedInUser.isRoot ||
    verifyPermission(PERMISSIONS.REQUEST_NEW_ACCOUNT) ||
    verifyPermission(PERMISSIONS.EDIT_ACCOUNT) ||
    verifyPermission(PERMISSIONS.APPROVE_NEW_ACCOUNT) || 
    verifyPermission(PERMISSIONS.APPROVE_ACCOUNT_CHANGE);

  useEffect(() => {
    if (page) {
      switch (page) {
        case 'userList': {
          setTabIndex(0);
          break;
        }
        case 'requests': {
          setTabIndex(1);
          break;
        }
        default: {
          setTabIndex(0);
          break;
        }
      }
    }
  }, [page]);

  const handleTabChange = async (event, newValue) => {
    switch (newValue) {
      case 0:
        await navigate('/users/list');
        return;
      case 1:
        await navigate('/users/requests');
        return;
      default:
    }
  };

  // sigh, always need to get requests right away to display the badge
  useEffect(() => {
    if (!pendingRequests && isValid) {
      loadPendingUserRequests();
    }
  }, [pendingRequests, isValid]);

  return isValid === true ? (
    <LotusPage>
      <LotusSpacedBlock>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab
            label={
              <Typography variant="body2" color="textPrimary">
                User List
              </Typography>
            }
          />
          <Tab
            label={
              <Typography variant="body2" color="textPrimary">
                Approval Requests
                <Badge
                  style={{
                    top: -10,
                    right: -10,
                    marginRight: 20
                  }}
                  badgeContent={
                    pendingRequests && pendingRequests.length
                  }
                  overlap="circular"
                  color="primary"
                />
              </Typography>
            }
          />
        </Tabs>
      </LotusSpacedBlock>
      {tabIndex === 0 && (
        <UsersPage />
      )}
      {tabIndex === 1 && (
        <RequestsList requests={pendingRequests} />
      )}
    </LotusPage>
  ) : (isValid === false ? <LotusPage>Access Denied</LotusPage> : <LotusPage></LotusPage>);
}

import React, { useState } from 'react';
import { Table } from '@lotus/components';
import ActionDialog from 'components/Dialogs/ActionDialog';
import AgencyConsentTypeForm from './forms/AgencyConsentTypeForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFileViewer  from 'components/widgets/LotusFileViewer';

export default function AgencyConsentTypesList({ consentTypesToShow, agencyId }) {
  const [consentTypeToEdit, setConsentTypeToEdit] = useState(false);
  const [documentToView, setDocumentToView] = useState();

  const editConsentType = (id) => {
    setConsentTypeToEdit(consentTypesToShow.find((s) => s.id === id));
  };

  const afterSave = () => {
    setConsentTypeToEdit(null);
  };

  const afterCancelSave = () => {
    setConsentTypeToEdit(null);
    setDocumentToView(null);
  };

  const renderDiscontinuedStyle = (value, tableMeta) => {
    const style =
      tableMeta.rowData[3] === 'Discontinued' ? { color: '#c3c3c3' } : {};
    return <div style={style}>{value}</div>;
  };

  const viewConsentForRow = async (rowData, rowMeta) => {
     const id = rowData[0];
     
    const consentToView = consentTypesToShow.find((s) => s.id === id);
    let file = {
      fileName: consentToView.fileName, uniqueFileName: consentToView.defaultConsentDocumentUniqueFileName,
      createdDate: consentToView.createdDate, fileType: consentToView.defaultConsentDocumentFileType, filePath:'consents',
      description:consentToView.name
    }
    setDocumentToView(file);
  };
 
  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
      },
    },
    {
      label: 'Consent Name',
      name: 'name',
      options: {
        customBodyRender: renderDiscontinuedStyle,
      },
    },

    {
      label: 'Preview',
      name: 'thumbnail',
      options: {
        customBodyRender: (value) => {
          return <img src={value} />;
        },
        filter: false,
        searchable: false,
        download: false,
      },
    },
    {
      label: 'Status',
      name: 'statusStr',
      options: {
        customBodyRender: renderDiscontinuedStyle,
      },
    },
    {
      label: 'Verbal',
      name: 'verbalStr',
      options: {
        customBodyRender: renderDiscontinuedStyle,
      },
    },
    {
      label: 'Written',
      name: 'writtenStr',
      options: {
        customBodyRender: renderDiscontinuedStyle,
      },
    },
    {
      label: 'Multilanguage',
      name: 'multilanguageStr',
      options: {
        customBodyRender: renderDiscontinuedStyle,
      },
    },
    {
      label: 'File name',
      name: 'fileName',
      options: {
        customBodyRender: renderDiscontinuedStyle,
      },
    },
    {
      label: 'Date last modified',
      name: 'lastModified',
      options: {
        customBodyRender: renderDiscontinuedStyle,
      },
    },
    {
      name: '',
      label: '',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <LotusButton
              variant="text"
              pencilIcon
              onClick={(e) => {
                e.stopPropagation();
                editConsentType(id);
              }}
            >
              Edit
            </LotusButton>
          );
        },
        download: false,
        searchable: false,
        viewColumns: false,
        sort: false,
        print: false,
      },
    },
  ];

  return (
      consentTypesToShow && (
        <>
          <Table
            columns={columns}
            data={consentTypesToShow}
            options={{
              pagination: false,
              filter: false,
            }}
            handleRowClicked={viewConsentForRow}
          />
          <ActionDialog
            fullWidth={true}
            maxWidth="lg"
            open={Boolean(consentTypeToEdit)}
            handleClose={afterCancelSave}
            content={
              <AgencyConsentTypeForm
                consentType={consentTypeToEdit}
                afterSave={afterSave}
                handleCancel={afterCancelSave}
              />
            }
          />
          <ActionDialog
            fullWidth={true}
            maxWidth="lg"
            open={Boolean(documentToView)}
            content={<LotusFileViewer agencyId={agencyId} subfolderName="consents" title='Agency Consent' file={[documentToView]} handleClose={afterCancelSave} />}
          />
        </>
      )
  );
}

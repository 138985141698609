import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function TestResultConfigureForm({ agency, handleSubmit }) { 

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (agency) {
      const newInitialValues = {
          enabled: agency.testResultConfig.enabled || false,
          // Test Result Component - Basic Config
          basicConfig: {
            testTech: agency.testResultConfig.basicConfig.testTech || false,
            testCompletedTime: agency.testResultConfig.basicConfig.testCompletedTime || false,
            testStatus: agency.testResultConfig.basicConfig.testStatus || false,
            testResultType: agency.testResultConfig.basicConfig.testResultType || false,
            testResultText: agency.testResultConfig.basicConfig.testResultText || false,
            testResultNumeric: agency.testResultConfig.basicConfig.testResultNumeric || false,
            testResultKeyword: agency.testResultConfig.basicConfig.testResultKeyword || false,
            testReferenceId: agency.testResultConfig.basicConfig.testReferenceId || false,
            testSource: agency.testResultConfig.basicConfig.testSource || false,
            testFacility: agency.testResultConfig.basicConfig.testFacility || false,
            testCompletedBy: agency.testResultConfig.basicConfig.testCompletedBy || false,
            normalcyStatus: agency.testResultConfig.basicConfig.normalcyStatus || false,
            comments: agency.testResultConfig.basicConfig.comments || false
          },
          // Test Result Component - HL7 Config
          hl7Config : {
            labIdentifiedDob: agency.testResultConfig.hl7Config.labIdentifiedDob || false,
            labIdentifiedGender: agency.testResultConfig.hl7Config.labIdentifiedGender || false,
            labIdentifiedLoinc: agency.testResultConfig.hl7Config.labIdentifiedLoinc || false,
            labIdentifiedLoincDesc: agency.testResultConfig.hl7Config.labIdentifiedLoincDesc || false,
            testCptCode: agency.testResultConfig.hl7Config.testCptCode || false,
            referenceRange: agency.testResultConfig.hl7Config.referenceRange || false,
            labPanelId: agency.testResultConfig.hl7Config.labPanelId || false
          }
      };
      setInitialValues(newInitialValues);
    }
  }, [agency]);

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Test Result Configuration'>
                <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="enabled"
                    value={values.enabled}
                    label="Test Result Configuration Enabled"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                {values.enabled && (
                  <>
                    <LotusFormSubSection name='Basic Configuration Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="basicConfig.testTech"
                          value={values.basicConfig.testTech}
                          label="Test Technology Enabled"
                          labelPlacement="start"
                        />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testCompletedTime"
                        value={values.basicConfig.testCompletedTime}
                        label="Test Completed Time Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testStatus"
                        value={values.basicConfig.testStatus}
                        label="Test Status Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testResultType"
                        value={values.basicConfig.testResultType}
                        label="Test Result Type Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testResultText"
                        value={values.basicConfig.testResultText}
                        label="Test Result Text Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testResultNumeric"
                        value={values.basicConfig.testResultNumeric}
                        label="Test Result Numeric Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testResultKeyword"
                        value={values.basicConfig.testResultKeyword}
                        label="Test Result Keyword Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testReferenceId"
                        value={values.basicConfig.testReferenceId}
                        label="Test Reference ID Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testSource"
                        value={values.basicConfig.testSource}
                        label="Test Source Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testFacility"
                        value={values.basicConfig.testFacility}
                        label="Test Facility Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.testCompletedBy"
                        value={values.basicConfig.testCompletedBy}
                        label="Test Completed By Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.normalcyStatus"
                        value={values.basicConfig.normalcyStatus}
                        label="Normalcy Status Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="basicConfig.comments"
                        value={values.basicConfig.comments}
                        label="Comments Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='HL7 Configuration Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="hl7Config.labIdentifiedDob"
                          value={values.hl7Config.labIdentifiedDob}
                          label="Lab Identified Date Of Birth Enabled"
                          labelPlacement="start"
                        />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="hl7Config.labIdentifiedGender"
                        value={values.hl7Config.labIdentifiedGender}
                        label="Lab Identified Gender Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="hl7Config.labIdentifiedLoinc"
                        value={values.hl7Config.labIdentifiedLoinc}
                        label="Lab Identified LOINC Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="hl7Config.labIdentifiedLoincDesc"
                        value={values.hl7Config.labIdentifiedLoincDesc}
                        label="Lab Identified LOINC Description Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="hl7Config.testCptCode"
                        value={values.hl7Config.testCptCode}
                        label="Test CPT Code Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="hl7Config.referenceRange"
                        value={values.hl7Config.referenceRange}
                        label="Reference Range"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="hl7Config.labPanelId"
                        value={values.hl7Config.labPanelId}
                        label="Lab Panel ID Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    </LotusFormSubSection>
                  </>
                )}
                <LotusFormItem lastOne>
                  <LotusButton type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

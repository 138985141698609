import React, { useEffect } from 'react';
import { FormGroup } from '@mui/material';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function ProgramLinker({ values, handleChange }) {
  const { userAgency, userGranteeAgencyPrograms, loadUserGranteeAgencyPrograms } = useUserAgency();

  useEffect(() => {
    if (userAgency && !userGranteeAgencyPrograms) {
      loadUserGranteeAgencyPrograms();
    }
  }, [userAgency]);

  return (
    <FormGroup>
      {userGranteeAgencyPrograms && userGranteeAgencyPrograms.map((node) => {
          return (
            <LotusCheckbox
              key={`program-${node.id}`}
              name={'selectedPrograms'}
              label={node.name}
              value={node.id}
              checked={
                values.selectedPrograms.find((aId) => aId === node.id) !=
                undefined
              }
              onChange={handleChange}
            />
          );
        })}
    </FormGroup>
  );
}

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusTrueFalseSelect from 'components/widgets/Forms/LotusTrueFalseSelect';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';
import { useClient } from 'contexts/ClientContext';
import HybridForm from 'components/Forms/MultiTab/MultiForm/HybridForm';
import { Formik, useFormikContext } from 'formik';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { Grid, Stack, Typography } from '@mui/material';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { Button } from '@lotus/components';
import { v4 as uuidv4 } from 'uuid';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from 'components/widgets/Forms/LotusProgressiveDisclosure';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import ClientPremiumPaymentsList from './ClientServices/ClientPremiumPaymentsList';
import HealthPlanPanel from 'components/widgets/Composites/HealthPlanPanel';

const validationSchema = Yup.object().shape({});

const buildSectionNames = (existingClientConfig) => {
  const lst = [];
  lst.push("Private Insurance");
  if (existingClientConfig.showDentalInsurance) {
    lst.push("Dental Insurance");
  }
  if (existingClientConfig.showVisionInsurance) {
    lst.push("Vision Insurance");
  }
  lst.push("Medicare Part A, B, C or D");
  lst.push("Medicaid");
  if (existingClientConfig.showBenefitSubsidies) {
    lst.push("Subsidies");
  }
  lst.push("Premium Payments");
  lst.push("Documentation");
  return lst;
}

const ClientInsuranceStaticForm = ({clientId, existingClientConfig, disabled, updateRestrictions, hasEligibilityApp, premiumPaymentConfig}) => {

  const { medicareStatuses, medicarePartDStatuses, medicareLowIncomeSubsidyStatuses, medicareLowIncomeSubsidyTypes, medicaidStatuses,
    fullLowIncomeSubsidyStatuses, vaHealthBenefitsStatuses, ihsHealthBenefitsStatuses, medicaidCategories,
    privateInsuranceStatuses, privateInsuranceSources, dentalInsuranceStatuses, visionInsuranceStatuses, premiumPaymentFrequencies, 
    medicareCoverageTypes } = useLists();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [privateInsuranceStatusList, setPrivateInsuranceStatusList] = useState();
  const [privateInsuranceSourceList, setPrivateInsuranceSourceList] = useState();
  const [dentalInsuranceStatusList, setDentalInsuranceStatusList] = useState();
  const [visionInsuranceStatusList, setVisionInsuranceStatusList] = useState();
  const [premiumPaymentFrequencyList, setPremiumPaymentFrequencyList] = useState();
  const [medicareStatusList, setMedicareStatusList] = useState();
  const [medicarePartDStatusList, setMedicarePartDStatusList] = useState();
  const [medicaidStatusList, setMedicaidStatusList] = useState();
  const [medicaidCategoryList, setMedicaidCategoryList] = useState();
  const [medicareLowIncomeSubsidyStatusList, setMedicareLowIncomeSubsidyStatusList] = useState();
  const [medicareLowIncomeSubsidyTypeList, setMedicareLowIncomeSubsidyTypeList] = useState();
  const [fullLowIncomeSubsidyStatusList, setFullLowIncomeSubsidyStatusList] = useState();
  const [vaHealthBenefitsStatusList, setVaHealthBenefitsStatusList] = useState();
  const [ihsHealthBenefitsStatusList, setIhsHealthBenefitsStatusList] = useState();

  const [isChangingInsurance, setIsChangingInsurance] = useState();

  const { changeClientInsurance, deleteClientInsurance } = useClient();
  const { adapProgramEnrollments, loadAdapEnrollmentsForClient } = useEnrollments();

  const [currentAdapEnrollment, setCurrentAdapEnrollment] = useState();
  const [showAdapDialog, setShowAdapDialog] = useState(false);
  const [showNoAdapDialog, setShowNoAdapDialog] = useState(false);

  const [medicareCoverageTypesList, setMedicareCoverageTypesList] = useState();
  const { values, setFieldValue, resetForm, errors } = useFormikContext();

  useEffect(() => {
    if (clientId) {
      // need to check whether client is currently enrolled in adap to allow user to select subprograms
      loadAdapEnrollmentsForClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    if (privateInsuranceStatuses) {
      setPrivateInsuranceStatusList(privateInsuranceStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (privateInsuranceSources) {
      setPrivateInsuranceSourceList(privateInsuranceSources.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}}));
    }
    if (dentalInsuranceStatuses) {
      setDentalInsuranceStatusList(dentalInsuranceStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (visionInsuranceStatuses) {
      setVisionInsuranceStatusList(visionInsuranceStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (premiumPaymentFrequencies) {
      setPremiumPaymentFrequencyList(premiumPaymentFrequencies.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicareStatuses) {
      setMedicareStatusList(medicareStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicarePartDStatuses) {
      setMedicarePartDStatusList(medicarePartDStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicaidStatuses) {
      setMedicaidStatusList(medicaidStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicaidCategories) {
      setMedicaidCategoryList(medicaidCategories.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicareLowIncomeSubsidyStatuses) {
      setMedicareLowIncomeSubsidyStatusList(medicareLowIncomeSubsidyStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicareLowIncomeSubsidyTypes) {
      setMedicareLowIncomeSubsidyTypeList(medicareLowIncomeSubsidyTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (fullLowIncomeSubsidyStatuses) {
      setFullLowIncomeSubsidyStatusList(fullLowIncomeSubsidyStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (vaHealthBenefitsStatuses) {
      setVaHealthBenefitsStatusList(vaHealthBenefitsStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (ihsHealthBenefitsStatuses) {
      setIhsHealthBenefitsStatusList(ihsHealthBenefitsStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (premiumPaymentFrequencies) {
      setPremiumPaymentFrequencyList(premiumPaymentFrequencies.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (medicareCoverageTypes) {
      setMedicareCoverageTypesList(medicareCoverageTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [medicareStatuses, medicarePartDStatuses, medicareLowIncomeSubsidyStatuses, medicareLowIncomeSubsidyTypes,
      fullLowIncomeSubsidyStatuses, vaHealthBenefitsStatuses, ihsHealthBenefitsStatuses, premiumPaymentFrequencies,
      privateInsuranceStatuses, privateInsuranceSources, dentalInsuranceStatuses, visionInsuranceStatuses, medicareCoverageTypes]);

  useEffect(() => {
    if (adapProgramEnrollments) {
      let now = moment().startOf('day');
      let adap = adapProgramEnrollments?.find(e => moment(e.startDate,'MM/DD/YYYY') <= now && moment(e.endDate,'MM/DD/YYYY') >= now);
      setCurrentAdapEnrollment(adap);
    }
  }, [adapProgramEnrollments]);

  const onSaveInsuranceChangeClick = async () => {
    if (Object.keys(errors).length === 0) {
      if (currentAdapEnrollment) {
        setShowAdapDialog(true);
      } else {
        setShowNoAdapDialog(true);
      }
    }
  }

  const onMedicaidCheckChanged = async (isChecked) => {
    if (!isChecked) {
      setFieldValue(`coverageList.0.medicaidLastVerificationCompletedDate`, null);
      setFieldValue(`coverageList.0.medicaidStatusId`, '');
      setFieldValue(`coverageList.0.medicaidPlanName`, '');
      setFieldValue(`coverageList.0.medicaidIdNumber`, '');
      setFieldValue(`coverageList.0.medicaidQualifierCode`, '');
      setFieldValue(`coverageList.0.medicaidComments`, '');
      setFieldValue(`coverageList.0.medicaidRxBin`, '');
      setFieldValue(`coverageList.0.medicaidRxPcn`, '');
      setFieldValue(`coverageList.0.medicaidRxGroup`, '');
      setFieldValue(`coverageList.0.medicaidCategory`, '');
      setFieldValue(`coverageList.0.medicaidCarrierName`, '');
      setFieldValue(`coverageList.0.medicaidHealthPlanId`, '');
      setFieldValue(`coverageList.0.medicaidMCOorHMOPlan`, '');
      setFieldValue(`coverageList.0.medicaidEffectiveDate`, null);
    }
  };

  const saveInsuranceChange = async (adapSubprograms) => {
    await changeClientInsurance(clientId, {...values.coverageList[0], id: undefined, insuranceDocumentation: values.insuranceDocumentation}, adapSubprograms);
    setIsChangingInsurance(false);
    setShowAdapDialog(false);
    setShowNoAdapDialog(false);
  }

  const deleteInsurance = async (index) => {
    await deleteClientInsurance(clientId, values.coverageList[index].id);
  }

  const isPolicySourceAca = (id) => {
    return privateInsuranceSources && privateInsuranceSources.filter(sources => sources.id === id && sources.itemKey === 'aca_exchange')?.length > 0 ? true : false;
  }
  
  const isMedicareCoverageTypeC = (medicareCoverageType) => {
    return medicareCoverageTypes && 
           medicareCoverageTypes.filter(types => types.id === medicareCoverageType && types.itemKey === 'part_c')?.length > 0 ? true : false;
  }

  const buildInsuranceEffectiveCoverageDisplay = (coverage, index, coverageDisabled) => {
    return (
      <>
      {isChangingInsurance &&
        <>
        <LotusFormItem>
          <div style={{fontSize: 24, fontWeight: 500}}>Insurance Update</div>
        </LotusFormItem>
        <LotusFormItem>
          <LotusDatePicker
            name={`coverageList.${index}.insuranceUpdateEffectiveDate`}
            label="Insurance Update Effective Date"
            disabled={coverageDisabled}
            minDate={moment().format('MM/DD/YYYY')}
            required
          />
        </LotusFormItem>
        </>
      }
      <LotusFormSection name="Private Insurance" disabled={coverageDisabled}>
        <LotusFormItem extraWide>
          {privateInsuranceStatusList &&
            <LotusSelect
              name={`coverageList.${index}.privateInsuranceStatusId`}
              label="Private Insurance Status"
              items={privateInsuranceStatusList}
              disabled={coverageDisabled}
            />
          }
        </LotusFormItem>
        {coverage.privateInsuranceStatusId && privateInsuranceStatuses &&  coverage.privateInsuranceStatusId !== privateInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id &&
          <>
            <HealthPlanPanel
              effectiveDateFieldName={`coverageList.${index}.privateInsuranceEffectiveDate`}
              policySourceIdFieldName={`coverageList.${index}.privateInsurancePolicySourceId`}
              policySources={privateInsuranceSourceList}
              carrierFieldName={`coverageList.${index}.privateInsuranceCarrierName`}
              planIdFieldName={`coverageList.${index}.privateInsuranceHealthPlanId`}
              planNameFieldName={`coverageList.${index}.privateInsurancePlanName`}
              mentalHealthCoverageFieldName={`coverageList.${index}.privateInsuranceMentalHealthCoverage`}
              pharmacyCoverageFieldName={`coverageList.${index}.privateInsurancePharmacyCoverage`}
              substanceAbuseCoverageFieldName={`coverageList.${index}.privateInsuranceSubstanceAbuseCoverage`}
              medicalCoverageFieldName={`coverageList.${index}.privateInsuranceMedicalCoverage`}
              existingClientConfig={existingClientConfig}
              disabled={coverageDisabled}
              effectiveDateRequired={privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.privateInsuranceStatusId)}
              carrierAndPlanRequired={privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.privateInsuranceStatusId)}
            />
            {coverage.privateInsuranceStatusId && privateInsuranceStatuses && coverage.privateInsuranceStatusId === privateInsuranceStatuses.find(s => s.itemKey === 'cobra').id &&
              <>
                <LotusFormItem>
                  <LotusDatePicker
                    name={`coverageList.${index}.privateInsuranceCobraStartDate`}
                    label="COBRA Start Date"
                    disabled={coverageDisabled}
                  />
                </LotusFormItem>
                <LotusFormItem>
                    <LotusDatePicker
                      name={`coverageList.${index}.privateInsuranceCobraEndDate`}
                      label="COBRA End Date"
                      disabled={coverageDisabled}
                    />
                </LotusFormItem>
              </>
            }
            <LotusFormItems>
              <LotusTextInput
                name={`coverageList.${index}.privateInsuranceMemberId`}
                label="Private Member ID"
                disabled={coverageDisabled}
                maxLength={30}
              />
              {coverage.privateInsuranceStatusId && privateInsuranceStatuses && ['active','applied'].includes(privateInsuranceStatuses.find(s => s.id === coverage.privateInsuranceStatusId)?.itemKey) &&
                  <LotusTextInput
                    name={`coverageList.${index}.privateInsurancePremiumAmount`}
                    label="Premium Amount"
                    disabled={coverageDisabled}
                    maxLength={16}
                    floatingPoint
                    adornment="$"
                    required
                  />
              }
              {coverage.privateInsuranceStatusId && privateInsuranceStatuses && ['active','applied'].includes(privateInsuranceStatuses.find(s => s.id === coverage.privateInsuranceStatusId)?.itemKey) &&
                <>
                  {premiumPaymentFrequencyList &&
                    <LotusSelect
                      name={`coverageList.${index}.privateInsurancePremiumPaymentFrequencyId`}
                      label="Premium Payment Frequency"
                      items={premiumPaymentFrequencyList}
                      disabled={coverageDisabled}
                      required
                    />
                  }
                </>
              }
              {isPolicySourceAca(coverage.privateInsurancePolicySourceId) &&
                <>
                  <LotusTrueFalseRadioGroup
                    name={`coverageList.${index}.privateInsuranceHasACATaxCredit`}
                    label="Is the client receiving ACA tax credit?"
                    disabled={coverageDisabled}
                    required
                    dontCheckRequiredFields
                  />
                  {coverage.privateInsuranceHasACATaxCredit === true && 
                    <LotusFormItem style={{marginTop: 20}} lastOne>
                      <LotusTextInput
                        name={`coverageList.${index}.privateInsuranceACATaxCreditAmount`}
                        label="Tax Credit Amount"
                        disabled={coverageDisabled}
                        maxLength={16}
                        floatingPoint
                        adornment="$"
                        required
                      />
                    </LotusFormItem>
                  }
                  {coverage.privateInsuranceHasACATaxCredit === false && 
                    <LotusFormItem style={{marginTop: 20}} lastOne>
                      <LotusTextInput
                        name={`coverageList.${index}.privateInsuranceNoACATaxCreditReason`}
                        label="Reason"
                        disabled={coverageDisabled}
                        maxLength={500}
                        multiline
                        rows={5}
                        required
                      />
                    </LotusFormItem>
                  }
                </>
              }
              <LotusTextInput
                name={`coverageList.${index}.privateInsuranceMaxOutOfPocketAmount`}
                label="Insurance Max out of pocket amount"
                disabled={coverageDisabled}
                maxLength={16}
                floatingPoint
                adornment="$"
              />
              <LotusTextInput
                name={`coverageList.${index}.privateInsurancePolicyHolderName`}
                label="Policy Holder First and Last Name"
                disabled={coverageDisabled}
                maxLength={75}
                required={privateInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.privateInsuranceStatusId)}
              />
              <LotusTrueFalseSelect
                name={`coverageList.${index}.privateInsuranceFamilyPlan`}
                label="Family Plan?"
                disabled={coverageDisabled}
              />
              {coverage.privateInsuranceFamilyPlan && 
                <LotusTrueFalseSelect
                  name={`coverageList.${index}.privateInsuranceAllFamilyMembers`}
                  label="Are all family members on the plan?"
                  disabled={coverageDisabled}
                />
              }
            </LotusFormItems>
            {coverage.privateInsurancePharmacyCoverage === true &&
              <LotusFormItems>
                <LotusTrueFalseSelect
                  name={`coverageList.${index}.privateInsuranceScripDiscountCardOnly`}
                  label="Does the plan offer a prescription discount card only?"
                  disabled={coverageDisabled}
                />
                <LotusTrueFalseSelect
                  name={`coverageList.${index}.privateInsuranceHivMeds`}
                  label="Does the insurance plan cover HIV medications?"
                  disabled={coverageDisabled}
                />
                <LotusTextInput
                  name={`coverageList.${index}.privateInsuranceRxBin`}
                  label="RX BIN"
                  disabled={coverageDisabled}
                  maxLength={30}
                />
                <LotusTextInput
                  name={`coverageList.${index}.privateInsuranceRxPcn`}
                  label="RX PCN"
                  disabled={coverageDisabled}
                  maxLength={30}
                />
                <LotusTextInput
                  name={`coverageList.${index}.privateInsuranceRxGroup`}
                  label="RX Group"
                  disabled={coverageDisabled}
                  maxLength={30}
                />
              </LotusFormItems>
            }
            <LotusFormItems>
              <LotusTrueFalseSelect
                name={`coverageList.${index}.privateInsuranceHasCap`}
                label="Does the insurance plan have a cap?"
                disabled={coverageDisabled}
              />
              {coverage.privateInsuranceHasCap && 
                <LotusTextInput
                  name={`coverageList.${index}.privateInsuranceCapAmount`}
                  label="Insurance cap amount"
                  disabled={coverageDisabled}
                  maxLength={30}
                  floatingPoint
                  adornment="$"
                />
              }
              <LotusTextInput
                name={`coverageList.${index}.privateInsuranceComments`}
                label="Private Insurance Comments"
                disabled={coverageDisabled}
                maxLength={500}
                multiline
                rows={5}
              />
            </LotusFormItems>
          </>
        }
        {coverage.privateInsuranceStatusId && privateInsuranceStatuses && ['active','applied'].includes(privateInsuranceStatuses.find(s => s.id === coverage.privateInsuranceStatusId)?.itemKey) &&
          <LotusFormItems>
            <LotusTextInput
              name={`coverageList.${index}.privateInsurancePremiumOverdueAmount`}
              label="Premium Overdue Amount"
              disabled={coverageDisabled}
              maxLength={16}
              floatingPoint
              adornment="$"
            />
            <LotusTextInput
              name={`coverageList.${index}.privateInsurancePremiumOverdueMonths`}
              label="Premium Overdue Months"
              disabled={coverageDisabled}
              maxLength={50}
              integer
            />
          </LotusFormItems>
        }
      </LotusFormSection>
      {existingClientConfig.showDentalInsurance &&
        <LotusFormSection name="Dental Insurance" disabled={coverageDisabled}>
          {dentalInsuranceStatusList &&
            <LotusFormItem>
              <LotusSelect
                name={`coverageList.${index}.dentalInsuranceStatusId`}
                label="Dental Insurance Status"
                items={dentalInsuranceStatusList}
                disabled={coverageDisabled}
              />
            </LotusFormItem>
          }
          {coverage.dentalInsuranceStatusId && dentalInsuranceStatuses && coverage.dentalInsuranceStatusId !== dentalInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id &&
            <>
              <HealthPlanPanel
                effectiveDateFieldName={`coverageList.${index}.dentalInsuranceEffectiveDate`}
                carrierFieldName={`coverageList.${index}.dentalInsuranceCarrierName`}
                planIdFieldName={`coverageList.${index}.dentalInsuranceHealthPlanId`}
                planNameFieldName={`coverageList.${index}.dentalInsurancePlanName`}
                disabled={coverageDisabled}
                planType='DEN'
                useHealthPlanDb={existingClientConfig.dentalUsesHealthPlanDatabase}
                effectiveDateRequired={dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.dentalInsuranceStatusId)}
                carrierAndPlanRequired={dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.dentalInsuranceStatusId)}
              />
              {coverage.dentalInsuranceStatusId && dentalInsuranceStatuses && coverage.dentalInsuranceStatusId === dentalInsuranceStatuses.find(s => s.itemKey === 'cobra').id &&
                <>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`coverageList.${index}.dentalInsuranceCobraStartDate`}
                      label="Dental COBRA Start Date"
                      disabled={coverageDisabled}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`coverageList.${index}.dentalInsuranceCobraEndDate`}
                      label="Dental COBRA End Date"
                      disabled={coverageDisabled}
                    />
                  </LotusFormItem>
                </>
              }
              <LotusFormItems>
                <LotusTextInput
                  name={`coverageList.${index}.dentalInsuranceMemberId`}
                  label="Member ID"
                  disabled={coverageDisabled}
                  maxLength={30}
                  required={dentalInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.dentalInsuranceStatusId)}
                />
                <LotusTextInput
                  name={`coverageList.${index}.dentalInsuranceBillingId`}
                  label="Billing ID"
                  disabled={coverageDisabled}
                  maxLength={30}
                />
                <LotusTextInput
                  name={`coverageList.${index}.dentalInsurancePremiumAmount`}
                  label="Dental Premium Amount"
                  disabled={coverageDisabled}
                  maxLength={30}
                  floatingPoint
                  adornment="$"
                />
                {premiumPaymentFrequencyList &&
                  <LotusSelect
                    name={`coverageList.${index}.dentalInsurancePremiumPaymentFrequencyId`}
                    label="Premium Payment Frequency"
                    items={premiumPaymentFrequencyList}
                    disabled={coverageDisabled}
                  />
                }
                <LotusTextInput
                  name={`coverageList.${index}.dentalInsuranceComments`}
                  label="Dental Insurance Comments"
                  disabled={coverageDisabled}
                  maxLength={500}
                  multiline
                  rows={5}
                />
              </LotusFormItems>
            </>
          }
        </LotusFormSection>
      }
      {existingClientConfig.showVisionInsurance &&
        <LotusFormSection name="Vision Insurance" disabled={coverageDisabled}>
          <LotusFormItem>
            {visionInsuranceStatusList &&
              <LotusSelect
                name={`coverageList.${index}.visionInsuranceStatusId`}
                label="Vision Insurance Status"
                items={visionInsuranceStatusList}
                disabled={coverageDisabled}
              />
            }
          </LotusFormItem>
          {coverage.visionInsuranceStatusId && visionInsuranceStatuses && coverage.visionInsuranceStatusId !== visionInsuranceStatuses.find(s => s.itemKey === 'no_benefits').id &&
            <>
              <HealthPlanPanel
                effectiveDateFieldName={`coverageList.${index}.visionInsuranceEffectiveDate`}
                carrierFieldName={`coverageList.${index}.visionInsuranceCarrierName`}
                planIdFieldName={`coverageList.${index}.visionInsuranceHealthPlanId`}
                planNameFieldName={`coverageList.${index}.visionInsurancePlanName`}
                disabled={coverageDisabled}
                useHealthPlanDb={existingClientConfig.visionUsesHealthPlanDatabase}
                planType='VIS'
                effectiveDateRequired={visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.visionInsuranceStatusId)}
                carrierAndPlanRequired={visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.visionInsuranceStatusId)}
             />
              {coverage.visionInsuranceStatusId && visionInsuranceStatuses && coverage.visionInsuranceStatusId === visionInsuranceStatuses.find(s => s.itemKey === 'cobra').id &&
                <>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`coverageList.${index}.visionInsuranceCobraStartDate`}
                      label="Vision COBRA Start Date"
                      disabled={coverageDisabled}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`coverageList.${index}.visionInsuranceCobraEndDate`}
                      label="Vision COBRA End Date"
                      disabled={coverageDisabled}
                    />
                  </LotusFormItem>
                </>
              }
              <LotusFormItems>
                <LotusTextInput
                  name={`coverageList.${index}.visionInsuranceMemberId`}
                  label="Member ID"
                  disabled={coverageDisabled}
                  maxLength={30}
                  required={visionInsuranceStatuses.filter(s => s.itemKey === 'active' || s.itemKey === 'applied').map(s => s.id).includes(coverage.visionInsuranceStatusId)}
                />
                <LotusTextInput
                  name={`coverageList.${index}.visionInsuranceBillingId`}
                  label="Billing ID"
                  disabled={coverageDisabled}
                  maxLength={30}
                />
                <LotusTextInput
                  name={`coverageList.${index}.visionInsurancePremiumAmount`}
                  label="Vision Premium Amount"
                  disabled={coverageDisabled}
                  maxLength={30}
                  floatingPoint
                  adornment="$"
                />
                {premiumPaymentFrequencyList &&
                  <LotusSelect
                    name={`coverageList.${index}.visionInsurancePremiumPaymentFrequencyId`}
                    label="Premium Payment Frequency"
                    items={premiumPaymentFrequencyList}
                    disabled={coverageDisabled}
                  />
                }
              </LotusFormItems>
            </>
          }
        </LotusFormSection>
      }
      <LotusFormSection name="Medicare Part A, B, C or D" disabled={coverageDisabled}>
        <LotusFormItem>
          {medicareStatusList &&
            <LotusSelect
              name={`coverageList.${index}.medicareStatusId`}
              label="Medicare Status"
              items={medicareStatusList}
              disabled={coverageDisabled}
            />
          }
        </LotusFormItem>
        {coverage.medicareStatusId && medicareStatuses && coverage.medicareStatusId !== medicareStatuses.find(s => s.itemKey === 'no_benefits').id &&
          <>
            <LotusFormItems>
              {medicareCoverageTypesList &&
                <LotusSelect
                  name={`coverageList.${index}.medicareCoverageType`}
                  label="Medicare Coverage Type"
                  items={medicareCoverageTypesList}
                  disabled={coverageDisabled}
                  required
                />
              }
            </LotusFormItems>
            {coverage.medicareCoverageType &&
              <>
                <HealthPlanPanel
                  effectiveDateFieldName={`coverageList.${index}.medicareEffectiveDate`}
                  carrierFieldName={`coverageList.${index}.medicareCarrierName`}
                  planIdFieldName={`coverageList.${index}.medicareHealthPlanId`}
                  planNameFieldName={`coverageList.${index}.medicarePlanName`}
                  medicareCompanyPlanFieldStatus={isMedicareCoverageTypeC(coverage.medicareCoverageType)}
                  disabled={coverageDisabled}
                  useHealthPlanDb={existingClientConfig.medicareUsesHealthPlanDatabase}
                  planType='MEDS'
                  effectiveDateRequired={true}
                  carrierAndPlanRequired={true}
                />
                <LotusFormItems>
                  <LotusTextInput
                    name={`coverageList.${index}.medicareMemberId`}
                    label="Medicare Member ID"
                    disabled={coverageDisabled}
                    maxLength={30}
                   />
                  {isMedicareCoverageTypeC(coverage.medicareCoverageType) &&
                    <>
                      <LotusFormItem>
                        <LotusTextInput
                          name={`coverageList.${index}.medicarePremiumAmount`}
                          label="Premium Amount"
                          disabled={coverageDisabled}
                          maxLength={16}
                          floatingPoint
                          adornment="$"
                        />
                      </LotusFormItem>
                      {premiumPaymentFrequencyList &&
                        <LotusFormItem>
                          <LotusSelect
                            name={`coverageList.${index}.medicarePremiumPaymentFrequencyId`}
                            label="Premium Payment Frequency"
                            items={premiumPaymentFrequencyList}
                            disabled={coverageDisabled}
                          />
                        </LotusFormItem>
                      }
                      <LotusTrueFalseSelect
                        name={`coverageList.${index}.medicareIncludesPharmacyBenefit`}
                        label="Includes Pharmacy Benefit?"
                        disabled={coverageDisabled}
                      />
                    </>
                  }
                  {existingClientConfig.showMedicarePolicyHolder &&
                    <LotusTextInput
                      name={`coverageList.${index}.medicarePrimaryPolicyHolderName`}
                      label="Primary Policy Holder First and Last Name"
                      disabled={coverageDisabled}
                      maxLength={75}
                    />
                  }
                </LotusFormItems>
                {isMedicareCoverageTypeC(coverage.medicareCoverageType) && existingClientConfig.showMedicareRx &&
                  <LotusFormItems>
                    <LotusTextInput
                      name={`coverageList.${index}.medicareRxBin`}
                      label="RX BIN"
                      disabled={coverageDisabled}
                      maxLength={30}
                    />
                    <LotusTextInput
                      name={`coverageList.${index}.medicareRxPcn`}
                      label="RX PCN"
                      disabled={coverageDisabled}
                      maxLength={30}
                    />
                    <LotusTextInput
                      name={`coverageList.${index}.medicareRxGroup`}
                      label="RX Group"
                      disabled={coverageDisabled}
                      maxLength={30}
                    />
                  </LotusFormItems>
                }
                <LotusFormItems>
                  {isMedicareCoverageTypeC(coverage.medicareCoverageType) &&
                    <>
                      <LotusFormItem>
                        <LotusTextInput
                          name={`coverageList.${index}.medicarePremiumOverdueAmount`}
                          label="Premium Overdue Amount"
                          disabled={coverageDisabled}
                          maxLength={16}
                          floatingPoint
                          adornment="$"
                        />
                      </LotusFormItem>
                      <LotusTextInput
                        name={`coverageList.${index}.medicarePremiumOverdueMonths`}
                        label="Premium Overdue Months"
                        disabled={coverageDisabled}
                        maxLength={50}
                        integer
                      />
                    </>
                  }
                  <LotusTextInput
                    name={`coverageList.${index}.medicareComments`}
                    label="Medicare Comments"
                    disabled={coverageDisabled}
                    maxLength={500}
                    multiline
                    rows={5}
                  />
                </LotusFormItems>
                <LotusFormItem>
                  {medicarePartDStatusList &&
                    <LotusSelect
                      name={`coverageList.${index}.medicarePartDStatusId`}
                      label="Medicare Part D Status"
                      items={medicarePartDStatusList}
                      disabled={coverageDisabled}
                    />
                  }
                </LotusFormItem>
                {coverage.medicarePartDStatusId && medicarePartDStatuses && coverage.medicarePartDStatusId !== medicarePartDStatuses.find(s => s.itemKey === 'no_benefits').id &&
                  <>
                    <HealthPlanPanel
                      effectiveDateFieldName={`coverageList.${index}.medicarePartDEffectiveDate`}
                      carrierFieldName={`coverageList.${index}.medicarePartDCarrierName`}
                      planIdFieldName={`coverageList.${index}.medicarePartDHealthPlanId`}
                      planNameFieldName={`coverageList.${index}.medicarePartDPlanName`}
                      disabled={coverageDisabled}
                      useHealthPlanDb={existingClientConfig.medicarePartDUsesHealthPlanDatabase}
                      planType='MEDD'
                      effectiveDateRequired={true}
                      carrierAndPlanRequired={false}
                    />
                    <LotusFormItems>
                      <LotusTextInput
                        name={`coverageList.${index}.medicarePartDMemberId`}
                        label="Medicare Member ID"
                        disabled={coverageDisabled}
                        maxLength={11}
                      />
                      <LotusTextInput
                        name={`coverageList.${index}.medicarePartDRxBin`}
                        label="RX BIN"
                        disabled={coverageDisabled}
                        maxLength={30}
                      />
                      <LotusTextInput
                        name={`coverageList.${index}.medicarePartDRxPcn`}
                        label="RX PCN"
                        disabled={coverageDisabled}
                        maxLength={30}
                      />
                      <LotusTextInput
                        name={`coverageList.${index}.medicarePartDRxGroup`}
                        label="RX Group"
                        disabled={coverageDisabled}
                        maxLength={30}
                      />
                      <LotusTextInput
                        name={`coverageList.${index}.medicarePartDPremiumAmount`}
                        label="Medicare Part D Premium Amount"
                        disabled={coverageDisabled}
                        maxLength={16}
                        floatingPoint
                        adornment="$"
                      />
                      {premiumPaymentFrequencyList &&
                        <LotusSelect
                          name={`coverageList.${index}.medicarePartDPremiumPaymentFrequencyId`}
                          label="Medicare Part D Premium Payment Frequency"
                          items={premiumPaymentFrequencyList}
                          disabled={coverageDisabled}
                        />
                      }
                      <LotusTextInput
                        name={`coverageList.${index}.medicarePartDComments`}
                        label="Medicare Part D Comments"
                        disabled={coverageDisabled}
                        maxLength={500}
                        multiline
                        rows={5}
                      />
                    </LotusFormItems>
                  </>
                }
              </>
            }
          </>
        }
      </LotusFormSection>
      <LotusFormSection name="Medicaid" disabled={coverageDisabled}>
        <LotusFormItem>
          <LotusTrueFalseRadioGroup
            name={`coverageList.${index}.medicaidCheck`}
            label="Medicaid Checked?"
            disabled={coverageDisabled}
            required
            dontCheckRequiredFields
            onValueChange={onMedicaidCheckChanged}
          />
        </LotusFormItem>
        {coverage.medicaidCheck &&
          <>
            <LotusFormItems>
              <LotusDatePicker
                name={`coverageList.${index}.medicaidLastVerificationCompletedDate`}
                label="Date Last Verification Completed"
                disabled={coverageDisabled}
                required
              />
              {medicaidStatusList &&
                <LotusSelect
                  name={`coverageList.${index}.medicaidStatusId`}
                  label="Medicaid Status"
                  items={medicaidStatusList}
                  disabled={coverageDisabled}
                />
              }
            </LotusFormItems>
            {coverage.medicaidStatusId && medicaidStatuses && coverage.medicaidStatusId !== medicaidStatuses.find(s => s.itemKey === 'no_benefits').id &&
              <>
                <HealthPlanPanel
                  effectiveDateFieldName={`coverageList.${index}.medicaidEffectiveDate`}
                  carrierFieldName={`coverageList.${index}.medicaidCarrierName`}
                  planIdFieldName={`coverageList.${index}.medicaidHealthPlanId`}
                  planNameFieldName={`coverageList.${index}.medicaidPlanName`}
                  medicaidPlanFieldName={`coverageList.${index}.medicaidMCOorHMOPlan`}
                  medicaidPanel={true}
                  disabled={coverageDisabled}
                  useHealthPlanDb={existingClientConfig.medicaidUsesHealthPlanDatabase}
                  planType='MEDI'
                />
                <LotusFormItems>
                  <LotusTextInput
                    name={`coverageList.${index}.medicaidIdNumber`}
                    label="Medicaid ID number"
                    disabled={coverageDisabled}
                    maxLength={13}
                  />
                  {medicaidCategoryList &&
                    <LotusSelect
                      name={`coverageList.${index}.medicaidCategory`}
                      label="Medicaid Category"
                      items={medicaidCategoryList}
                      disabled={coverageDisabled}
                      autoSort={false}
                    />
                  }
                  <LotusTextInput
                    name={`coverageList.${index}.medicaidQualifierCode`}
                    label="Medicaid Qualifier Code"
                    disabled={coverageDisabled}
                    maxLength={30}
                  />
                </LotusFormItems>
                {coverage.medicaidMCOorHMOPlan && existingClientConfig.showMedicaidRx &&
                  <LotusFormItems>
                    <LotusTextInput
                      name={`coverageList.${index}.medicaidRxBin`}
                      label="RX BIN"
                      disabled={coverageDisabled}
                      maxLength={30}
                    />
                    <LotusTextInput
                      name={`coverageList.${index}.medicaidRxPcn`}
                      label="RX PCN"
                      disabled={coverageDisabled}
                      maxLength={30}
                    />
                    <LotusTextInput
                      name={`coverageList.${index}.medicaidRxGroup`}
                      label="RX Group"
                      disabled={coverageDisabled}
                      maxLength={30}
                    />
                  </LotusFormItems>
                }
                <LotusFormItems>
                  <LotusTextInput
                    name={`coverageList.${index}.medicaidComments`}
                    label="Medicaid Comments"
                    disabled={coverageDisabled}
                    maxLength={500}
                    multiline
                    rows={5}
                  />
                </LotusFormItems>
              </>
            }
        </>
      }
      </LotusFormSection>
      {existingClientConfig.showBenefitSubsidies &&
        <LotusFormSection name="Subsidies" disabled={coverageDisabled}>
          <LotusFormItems>
            {medicareLowIncomeSubsidyStatusList &&
              <LotusSelect
                name={`coverageList.${index}.medicareLowIncomeSubsidyStatusId`}
                label="Medicare Low Income Subsidy Status"
                items={medicareLowIncomeSubsidyStatusList}
                disabled={coverageDisabled}
                autoSort={false}
              />
            }
            {medicareLowIncomeSubsidyTypeList &&
              <LotusSelect
                name={`coverageList.${index}.medicareLowIncomeSubsidyTypeId`}
                label="Medicare Low Income Subsidy Type"
                items={medicareLowIncomeSubsidyTypeList}
                disabled={coverageDisabled}
                autoSort={false}
              />
            }
            {fullLowIncomeSubsidyStatusList &&
              <LotusSelect
                name={`coverageList.${index}.fullLowIncomeSubsidyStatusId`}
                label="Full Low Income Subsidy Status"
                items={fullLowIncomeSubsidyStatusList}
                disabled={coverageDisabled}
                autoSort={false}
              />
            }
            {vaHealthBenefitsStatusList &&
              <LotusSelect
                name={`coverageList.${index}.vaHealthBenefitsStatusId`}
                label="VA Health Benefits Status"
                items={vaHealthBenefitsStatusList}
                disabled={coverageDisabled}
                autoSort={false}
              />
            }
            {ihsHealthBenefitsStatusList &&
              <LotusSelect
                name={`coverageList.${index}.ihsHealthBenefitsStatusId`}
                label="IHS Health Benefits Status"
                items={ihsHealthBenefitsStatusList}
                disabled={coverageDisabled}
                autoSort={false}
              />
            }
          </LotusFormItems>
        </LotusFormSection>
      }
      {index !== 0 && !isChangingInsurance && !disabled &&
        <Stack direction="row" justifyContent="flex-end">
          <LotusButton garbageIcon variant="text" onClick={() => {deleteInsurance(index);}}>
            Remove
          </LotusButton>
        </Stack>
      }
      </>
    );
  }

  return values && existingClientConfig && (
    <Stack direction="row" spacing={1}>
      <LotusForm>
        {updateRestrictions && (updateRestrictions.all || updateRestrictions.insurance) &&
        <LotusFormSection>
          <LotusFormItem>
            <Alert variant="filled" severity="warning">Update in progress, fields are locked.</Alert>
          </LotusFormItem>
        </LotusFormSection>
        }
        {values.coverageList.map((cov, idx) => {
          return (
            <div key={cov.id} style={{backgroundColor: idx !== 0 || isChangingInsurance ? '#E0F1F3' : 'inherit', padding: 10, marginBottom: 30}}>
              {idx !== 0 &&
                <LotusSpacedBlock>
                  <LotusFormItem>
                    <div style={{fontSize: 24, fontWeight: 500}}>Insurance Update</div>
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusDatePicker
                      name={`coverageList.${idx}.effectiveDate`}
                      label="Insurance Update Effective Date"
                      disabled={true}
                    />
                  </LotusFormItem>
                </LotusSpacedBlock>
              }
              {buildInsuranceEffectiveCoverageDisplay(cov, idx, idx !== 0 || disabled || (!isChangingInsurance && hasEligibilityApp))}
              {idx === 0 && 
                <>
                {!isChangingInsurance &&
                  <LotusFormSection name="Premium Payments" disabled={disabled}>
                    <LotusFormItem>
                      <ClientPremiumPaymentsList
                        premiumPaymentConfig={premiumPaymentConfig}
                        premiumPayments="premiumPayments"
                        clientId={clientId}
                      />
                    </LotusFormItem>
                  </LotusFormSection>
                }
                <LotusFormSection name="Documentation">
                  <LotusFormItem extraWide>
                    <ClientInfoDocumentation
                      documentationListFieldName="insuranceDocumentation"
                      allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoInsuranceDocumentationTypes || []}
                      clientId={clientId}
                      disabled={disabled || (!isChangingInsurance && hasEligibilityApp)}
                    />
                  </LotusFormItem>
                </LotusFormSection>
                </>
              }
            </div>
          );
        })}
        <ActionDialog
          maxWidth="lg"
          open={showAdapDialog}
          content={
            <AdapSubprogramSelector
              currentAdapEnrollment={currentAdapEnrollment}
              handleCancel={() => setShowAdapDialog(false)}
              handleConfirm={(adapSubprograms) => {saveInsuranceChange(adapSubprograms);}}
            />
          }
        />
        <ActionDialog
          maxWidth="lg"
          open={showNoAdapDialog}
          content={
            <NoAdapConfirmationDialog
              handleCancel={() => setShowNoAdapDialog(false)}
              handleContinue={() => {saveInsuranceChange({subprogramList: []});}}
            />
          }
        />
        
      </LotusForm>
      <div style={{padding: 10}}>
        {hasEligibilityApp && values.coverageList.length === 1 && !disabled && !isChangingInsurance &&
          <LotusButton plusIcon variant="outlined" onClick={() => {
            setIsChangingInsurance(true); 
            setFieldValue('insuranceDocumentation', []);
            setFieldValue('coverageList.0.insuranceUpdateEffectiveDate', moment().format('MM/DD/YYYY'));
          }}>
            Change Insurance
          </LotusButton>
        }
        {isChangingInsurance &&
          <Stack justifyContent='flex-end' direction="row" spacing={1}>
            <Button variant="text" onClick={() => {resetForm(); setIsChangingInsurance(false);}}>
              <Typography variant="h6">Cancel</Typography>
            </Button>
            <Button onClick={() => {onSaveInsuranceChangeClick();}}>
              <Typography variant="h6">Save</Typography>
            </Button>
          </Stack>
        }
      </div>
  </Stack>
  );
};

export function buildStaticForm({...props}) {
  return (
      <ClientInsuranceStaticForm {...props}/>
  );
}

function AdapSubprogramSelector ({
  currentAdapEnrollment,
  handleCancel,
  handleConfirm,
}) {
  
  const { userAgency, userAgencyEligibilityConfig, userAgencyAdapSubprograms, loadUserAgencyAdapSubprograms } = useUserAgency();

  const [ initialValues, setInitialValues ] = useState();
  const [eligibilityAppConfig, setEligibilityAppConfig] = useState();
  const [agencyAdapSubprogramList, setAgencyAdapSubprogramList] = useState();

  useEffect(() => {
    if (userAgencyEligibilityConfig ) {
      setEligibilityAppConfig(userAgencyEligibilityConfig.application || {});
    }
  }, [userAgencyEligibilityConfig]);

  useEffect(() => {
    if (!userAgencyAdapSubprograms) {
      loadUserAgencyAdapSubprograms();
    } else if (initialValues && initialValues.subprogramIds) {
      setAgencyAdapSubprogramList(userAgencyAdapSubprograms.filter(s => s.active || initialValues.subprogramIds.includes(s.id)).map(s => { return {label: s.name, value: s.id}}));
    }
  }, [userAgency, userAgencyAdapSubprograms, initialValues]);

  useEffect(() => {
    if (currentAdapEnrollment) {
      const lst = currentAdapEnrollment.subprograms.map(s => { 
        return s.id
      });

      setInitialValues({
        wantsToApplyForAssistance: lst.length > 0,
        subprogramIds: lst,
        subprogramId: lst && lst.length > 0 ? lst[0].toString() : ''
      });
    }
  }, [currentAdapEnrollment]);

  return eligibilityAppConfig && agencyAdapSubprogramList && (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, actions) => {
        let subprogramList = [];
        if(values.wantsToApplyForAssistance && eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments) {
          subprogramList = values.subprogramIds.map(id => {return { agencyAdapSubprogramId: id }});
        } else if (values.wantsToApplyForAssistance && !eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments) {
          subprogramList = [{ agencyAdapSubprogramId: values.subprogramId }];
        }

        await handleConfirm({subprogramList});
        actions.setSubmitting(false);
      }}
    >
      {({ values, handleSubmit }) => {
        return values && (
          <LotusForm>
            <LotusFormItem>
              <Grid item xs={12} alignItems="center">
                <Typography variant="h2">Sub-program Selection</Typography>
              </Grid>
            </LotusFormItem>
            <LotusFormItem>
              <Grid item xs={12} alignItems="center">
                <Typography variant="body">
                  It looks like you're updating the client's insurance. Please update the client's sub-program selection if needed.
                </Typography>
              </Grid>
            </LotusFormItem>
            <LotusFormItem>
              <LotusProgressiveDisclosure
                name="wantsToApplyForAssistance"
                label={`Does applicant wish to apply for ${eligibilityAppConfig.selectedAssistanceProgramName || "ADAP"}?`}
                required
              >
                <LotusProgressiveDisclosureItem
                  itemValue="true"
                  label="Yes"
                >
                  {eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments && 
                  <LotusCheckboxList
                    name="subprogramIds" 
                    label="Choose Subprogram(s)"
                    items={agencyAdapSubprogramList}
                    required
                  />
                  }
                  {!eligibilityAppConfig.allowMultipleAdapSubprogramEnrollments && 
                  <LotusRadioGroup
                    name="subprogramId" 
                    label="Choose Subprogram"
                    items={agencyAdapSubprogramList}
                    required
                  />
                  }   
                </LotusProgressiveDisclosureItem>
                <LotusProgressiveDisclosureItem
                  itemValue='false'
                  label="No" 
                />
              </LotusProgressiveDisclosure>
            </LotusFormItem>
            <LotusFormItem>
              <Stack direction="row" justifyContent='flex-end' spacing={1}>
                <Button variant="outlined" onClick={handleCancel}>
                  Back
                </Button>
                <Button variant="contained" onClick={() => {handleSubmit(values);}}>
                  Confirm
                </Button>
              </Stack>
            </LotusFormItem>
          </LotusForm>
        );
      }}
    </Formik>
  );
}

function NoAdapConfirmationDialog ({
  handleCancel,
  handleContinue,
}) {
  
  return (
    <LotusForm>
      <LotusFormItem>
        <Stack direction="row" justifyContent="center">
          <Typography variant="h2">Insurance update</Typography>
        </Stack>
      </LotusFormItem>
      <LotusFormItem>
        <Grid item xs={12} alignItems="center">
          <Typography variant="body">
            It looks like you're updating the client's insurance. This update will not enroll the client in ADAP services. Please request ADAP services through Eligibility.
          </Typography>
        </Grid>
      </LotusFormItem>
      <LotusFormItem>
        <Stack direction="row" justifyContent='flex-end' spacing={1}>
          <Button variant="outlined" onClick={handleCancel}>
            Back
          </Button>
          <Button variant="contained" onClick={handleContinue}>
            Continue
          </Button>
        </Stack>
      </LotusFormItem>
    </LotusForm>
  );
}

export default function ClientInsuranceForm ({client, existingClientConfig, disabled, dynamicFormExtensionDefinition, dynamicFormExtensionValues, updateRestrictions, premiumPaymentConfig}) {

  const { clientInsurance, loadClientInsurance, upsertClientInsurance, clientHasEligibilityApplication, loadClientHasEligibilityApplication } = useClient();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (client) {
      loadClientInsurance(client.id);
      loadClientHasEligibilityApplication(client.id);
    }
  }, [client]);

  useEffect(() => {
    if (clientInsurance) {
      let lst = [];
      if (clientInsurance.effectiveCoverages.length > 0) {
         // Dont show old records
        const todayMoment = moment().startOf('day');
        let currentIns = clientInsurance.effectiveCoverages.findLast(c => moment(c.effectiveDate,'MM/DD/YYYY') <= todayMoment);
        let futureInsList = clientInsurance.effectiveCoverages.filter(c => moment(c.effectiveDate,'MM/DD/YYYY') > todayMoment);
        if (currentIns) {
          lst.push(currentIns);
        }
        lst = [...lst, ...futureInsList];
        lst = lst.map((currentInsurance, idx) => { 
          return {
            id: currentInsurance.id,
            effectiveDate: currentInsurance.effectiveDate || null,
            privateInsuranceStatusId: currentInsurance.privateInsuranceStatusId || '', 
            privateInsuranceEffectiveDate: currentInsurance.privateInsuranceEffectiveDate || null, 
            privateInsurancePolicySourceId: currentInsurance.privateInsurancePolicySourceId || '', 
            privateInsuranceCarrierName: currentInsurance.privateInsuranceCarrierName || '', 
            privateInsurancePlanName: currentInsurance.privateInsurancePlanName || '', 
            privateInsuranceMemberId: currentInsurance.privateInsuranceMemberId || '', 
            privateInsuranceFamilyPlan: currentInsurance.privateInsuranceFamilyPlan !== null ? currentInsurance.privateInsuranceFamilyPlan : '', 
            privateInsuranceAllFamilyMembers: currentInsurance.privateInsuranceAllFamilyMembers !== null ? currentInsurance.privateInsuranceAllFamilyMembers : '', 
            privateInsurancePolicyHolderName: currentInsurance.privateInsurancePolicyHolderName || '', 
            privateInsuranceMedicalCoverage: currentInsurance.privateInsuranceMedicalCoverage !== null ? currentInsurance.privateInsuranceMedicalCoverage : '', 
            privateInsuranceMentalHealthCoverage: currentInsurance.privateInsuranceMentalHealthCoverage !== null ? currentInsurance.privateInsuranceMentalHealthCoverage : '', 
            privateInsuranceHasCap: currentInsurance.privateInsuranceHasCap !== null ? currentInsurance.privateInsuranceHasCap : '', 
            privateInsuranceCapAmount: currentInsurance.privateInsuranceCapAmount !== null ? currentInsurance.privateInsuranceCapAmount : '', 
            privateInsuranceCobraStartDate: currentInsurance.privateInsuranceCobraStartDate || null, 
            privateInsuranceCobraEndDate: currentInsurance.privateInsuranceCobraEndDate || null, 
            privateInsurancePremiumAmount: currentInsurance.privateInsurancePremiumAmount !== null ? currentInsurance.privateInsurancePremiumAmount : '', 
            privateInsurancePremiumPaymentFrequencyId: currentInsurance.privateInsurancePremiumPaymentFrequencyId || '', 
            privateInsurancePremiumOverdueAmount: currentInsurance.privateInsurancePremiumOverdueAmount !== null ? currentInsurance.privateInsurancePremiumOverdueAmount : '', 
            privateInsurancePremiumOverdueMonths: currentInsurance.privateInsurancePremiumOverdueMonths !== null ? currentInsurance.privateInsurancePremiumOverdueMonths : '', 
            privateInsurancePharmacyCoverage: currentInsurance.privateInsurancePharmacyCoverage !== null ? currentInsurance.privateInsurancePharmacyCoverage : false, 
            privateInsuranceScripDiscountCardOnly: currentInsurance.privateInsuranceScripDiscountCardOnly !== null ? currentInsurance.privateInsuranceScripDiscountCardOnly : '', 
            privateInsuranceHivMeds: currentInsurance.privateInsuranceHivMeds !== null ? currentInsurance.privateInsuranceHivMeds : '', 
            privateInsuranceRxBin: currentInsurance.privateInsuranceRxBin || '', 
            privateInsuranceRxPcn: currentInsurance.privateInsuranceRxPcn || '', 
            privateInsuranceRxGroup: currentInsurance.privateInsuranceRxGroup || '', 
            dentalInsuranceStatusId: currentInsurance.dentalInsuranceStatusId || '', 
            dentalInsuranceEffectiveDate: currentInsurance.dentalInsuranceEffectiveDate || null, 
            dentalInsurancePlanName: currentInsurance.dentalInsurancePlanName || '', 
            dentalInsuranceMemberId: currentInsurance.dentalInsuranceMemberId || '', 
            dentalInsuranceBillingId: currentInsurance.dentalInsuranceBillingId || '', 
            dentalInsurancePremiumAmount: currentInsurance.dentalInsurancePremiumAmount !== null ? currentInsurance.dentalInsurancePremiumAmount : '', 
            dentalInsurancePremiumPaymentFrequencyId: currentInsurance.dentalInsurancePremiumPaymentFrequencyId || '', 
            dentalInsuranceCobraStartDate: currentInsurance.dentalInsuranceCobraStartDate || null, 
            dentalInsuranceCobraEndDate: currentInsurance.dentalInsuranceCobraEndDate || null, 
            dentalInsuranceComments: currentInsurance.dentalInsuranceComments || '', 
            visionInsuranceStatusId: currentInsurance.visionInsuranceStatusId || '', 
            visionInsuranceEffectiveDate: currentInsurance.visionInsuranceEffectiveDate || null, 
            visionInsurancePlanName: currentInsurance.visionInsurancePlanName || '', 
            visionInsuranceMemberId: currentInsurance.visionInsuranceMemberId || '', 
            visionInsuranceBillingId: currentInsurance.visionInsuranceBillingId || '', 
            visionInsurancePremiumAmount: currentInsurance.visionInsurancePremiumAmount !== null ? currentInsurance.visionInsurancePremiumAmount : '', 
            visionInsurancePremiumPaymentFrequencyId: currentInsurance.visionInsurancePremiumPaymentFrequencyId || '', 
            visionInsuranceCobraStartDate: currentInsurance.visionInsuranceCobraStartDate || null, 
            visionInsuranceCobraEndDate: currentInsurance.visionInsuranceCobraEndDate || null,
            medicareEffectiveDate: currentInsurance.medicareEffectiveDate || null,
            medicareStatusId: currentInsurance.medicareStatusId || '', 
            medicareCarrierName: currentInsurance.medicareCarrierName || '', 
            medicarePlanName: currentInsurance.medicarePlanName || '', 
            medicareMemberId: currentInsurance.medicareMemberId || '',
            medicareIncludesPharmacyBenefit: currentInsurance.medicareIncludesPharmacyBenefit !== null ? currentInsurance.medicareIncludesPharmacyBenefit : '', 
            medicareComments: currentInsurance.medicareComments || '', 
            medicarePartDStatusId: currentInsurance.medicarePartDStatusId || '', 
            medicarePartDEffectiveDate: currentInsurance.medicarePartDEffectiveDate || null, 
            medicarePartDMemberId: currentInsurance.medicarePartDMemberId || '',
            medicarePartDPlanName: currentInsurance.medicarePartDPlanName || '', 
            medicarePartDComments: currentInsurance.medicarePartDComments || '', 
            medicaidStatusId: currentInsurance.medicaidStatusId || '', 
            medicaidCheck: currentInsurance.medicaidCheck !== null ? currentInsurance.medicaidCheck : false, 
            medicaidLastVerificationCompletedDate: currentInsurance.medicaidLastVerificationCompletedDate || null,
            medicaidPlanName: currentInsurance.medicaidPlanName || '', 
            medicaidIdNumber: currentInsurance.medicaidIdNumber || '', 
            medicaidQualifierCode: currentInsurance.medicaidQualifierCode || '',
            medicaidComments: currentInsurance.medicaidComments || '', 
            medicaidRxBin: currentInsurance.medicaidRxBin || '', 
            medicaidRxPcn: currentInsurance.medicaidRxPcn || '',
            medicaidRxGroup: currentInsurance.medicaidRxGroup || '', 
            medicarePrimaryPolicyHolderName: currentInsurance.medicarePrimaryPolicyHolderName || '', 
            medicareRxBin: currentInsurance.medicareRxBin || '', 
            medicareRxPcn: currentInsurance.medicareRxPcn || '',
            medicareRxGroup: currentInsurance.medicareRxGroup || '', 
            medicarePremiumAmount: currentInsurance.medicarePremiumAmount !== null ? currentInsurance.medicarePremiumAmount : '', 
            medicarePremiumPaymentFrequencyId: currentInsurance.medicarePremiumPaymentFrequencyId || '', 
            medicarePremiumOverdueAmount: currentInsurance.medicarePremiumOverdueAmount !== null ? currentInsurance.medicarePremiumOverdueAmount : '', 
            medicarePremiumOverdueMonths: currentInsurance.medicarePremiumOverdueMonths !== null ? currentInsurance.medicarePremiumOverdueMonths : '', 
            medicareLowIncomeSubsidyStatusId: currentInsurance.medicareLowIncomeSubsidyStatusId || '', 
            medicareLowIncomeSubsidyTypeId: currentInsurance.medicareLowIncomeSubsidyTypeId || '',
            fullLowIncomeSubsidyStatusId: currentInsurance.fullLowIncomeSubsidyStatusId || '', 
            vaHealthBenefitsStatusId: currentInsurance.vaHealthBenefitsStatusId || '', 
            ihsHealthBenefitsStatusId: currentInsurance.ihsHealthBenefitsStatusId || '', 
            benefitDocumentation: currentInsurance.benefitDocumentation || [],
            medicarePartDRxBin: currentInsurance.medicarePartDRxBin || '',
            medicarePartDRxPcn: currentInsurance.medicarePartDRxPcn || '',
            medicarePartDRxGroup: currentInsurance.medicarePartDRxGroup || '',
            medicarePartDPremiumAmount: currentInsurance.medicarePartDPremiumAmount !== null ? currentInsurance.medicarePartDPremiumAmount : '', 
            medicarePartDPremiumPaymentFrequencyId: currentInsurance.medicarePartDPremiumPaymentFrequencyId || '',
            privateInsuranceHealthPlanId: currentInsurance.privateInsuranceHealthPlanId || '', 
            dentalInsuranceHealthPlanId: currentInsurance.dentalInsuranceHealthPlanId || '',
            visionInsuranceHealthPlanId: currentInsurance.visionInsuranceHealthPlanId || '', 
            medicareHealthPlanId: currentInsurance.medicareHealthPlanId || '',
            medicarePartDHealthPlanId: currentInsurance.medicarePartDHealthPlanId || '', 
            medicaidHealthPlanId: currentInsurance.medicaidHealthPlanId || '',
            dentalInsuranceCarrierName: currentInsurance.dentalInsuranceCarrierName || '', 
            visionInsuranceCarrierName: currentInsurance.visionInsuranceCarrierName || '',
            medicarePartDCarrierName: currentInsurance.medicarePartDCarrierName || '', 
            medicaidCarrierName: currentInsurance.medicaidCarrierName || '',
            medicaidEffectiveDate: currentInsurance.medicaidEffectiveDate || null,
            privateInsuranceHasACATaxCredit: currentInsurance.privateInsuranceHasACATaxCredit !== null && currentInsurance.privateInsuranceHasACATaxCredit !== '' ?  currentInsurance.privateInsuranceHasACATaxCredit : false,
            privateInsuranceACATaxCreditAmount: currentInsurance.privateInsuranceACATaxCreditAmount || '',
            privateInsuranceNoACATaxCreditReason: currentInsurance.privateInsuranceNoACATaxCreditReason || '',
            privateInsuranceMaxOutOfPocketAmount: currentInsurance.privateInsuranceMaxOutOfPocketAmount || '',
            privateInsuranceSubstanceAbuseCoverage: currentInsurance.privateInsuranceSubstanceAbuseCoverage !== null ? currentInsurance.privateInsuranceSubstanceAbuseCoverage : '',
            medicareCoverageType: currentInsurance.medicareCoverageType || '',
            medicaidMCOorHMOPlan: currentInsurance.medicaidMCOorHMOPlan !== null ? currentInsurance.medicaidMCOorHMOPlan : false,
            medicaidCategory: currentInsurance.medicaidCategory || '',
            privateInsuranceComments: currentInsurance.privateInsuranceComments || ''
          };
        });
      }
      else {
        lst.push({
          id: uuidv4(),
          effectiveDate: moment().format('MM/DD/YYYY'),
          privateInsuranceStatusId: '', privateInsuranceEffectiveDate: null, 
          privateInsurancePolicySourceId: '', privateInsuranceCarrierName: '', 
          privateInsurancePlanName: '', privateInsuranceMemberId: '', 
          privateInsuranceFamilyPlan: '', privateInsuranceAllFamilyMembers: '', 
          privateInsuranceMedicalCoverage: '', privateInsuranceMentalHealthCoverage: '', 
          privateInsuranceHasCap: '', privateInsuranceCapAmount: '', 
          privateInsuranceCobraStartDate: null, privateInsuranceCobraEndDate: null, 
          privateInsurancePremiumAmount: '', privateInsurancePremiumPaymentFrequencyId: '', 
          privateInsurancePremiumOverdueAmount: '', 
          privateInsurancePremiumOverdueMonths: '', privateInsurancePharmacyCoverage: '', 
          privateInsuranceScripDiscountCardOnly: '', privateInsuranceHivMeds: '', 
          privateInsuranceRxBin: '', privateInsuranceRxPcn: '', 
          privateInsuranceRxGroup: '', privateInsurancePolicyHolderName: '',
          dentalInsuranceStatusId: '', dentalInsurancePlanName: '', 
          dentalInsuranceMemberId: '', dentalInsuranceBillingId: '', 
          dentalInsuranceCobraStartDate: null, dentalInsuranceCobraEndDate: null, 
          dentalInsuranceComments: '', dentalInsuranceEffectiveDate: null,
          dentalInsurancePremiumAmount: '', dentalInsurancePremiumPaymentFrequencyId: '',
          visionInsuranceStatusId: '', visionInsurancePlanName: '', 
          visionInsuranceMemberId: '', visionInsuranceBillingId: '', 
          visionInsuranceCobraStartDate: null, visionInsuranceCobraEndDate: null,
          visionInsurancePremiumAmount: '', visionInsurancePremiumPaymentFrequencyId: '',
          visionInsuranceEffectiveDate: null,
          medicareEffectiveDate: null, medicareMemberId: '',
          medicareStatusId: '', medicareCarrierName: '', medicarePlanName: '', medicareIncludesPharmacyBenefit: '',
          medicareComments: '', medicarePartDStatusId: '', medicarePartDEffectiveDate: null, medicarePartDMemberId: '',
          medicarePartDPlanName: '', medicarePartDComments: '', medicaidCheck: '', medicaidLastVerificationCompletedDate: null,
          medicaidStatusId: '', medicaidPlanName: '', medicaidIdNumber: '',
          medicaidQualifierCode: '',
          medicaidComments: '', medicaidRxBin: '', medicaidRxPcn: '',
          medicaidRxGroup: '', medicarePrimaryPolicyHolderName: '', medicareRxBin: '', medicareRxPcn: '',
          medicareRxGroup: '', medicarePremiumAmount: '', medicarePremiumPaymentFrequencyId: '',
          medicarePremiumOverdueAmount: '', medicarePremiumOverdueMonths: '', medicareLowIncomeSubsidyStatusId: '', medicareLowIncomeSubsidyTypeId: '',
          fullLowIncomeSubsidyStatusId: '', vaHealthBenefitsStatusId: '', ihsHealthBenefitsStatusId: '',
          medicarePartDRxBin: '', medicarePartDRxPcn: '', medicarePartDRxGroup: '',
          medicarePartDPremiumAmount: '', medicarePartDPremiumPaymentFrequencyId: '',
          privateInsuranceHealthPlanId: '', dentalInsuranceHealthPlanId: '',
          visionInsuranceHealthPlanId: '', medicareHealthPlanId: '',
          medicarePartDHealthPlanId: '', medicaidHealthPlanId: '',
          dentalInsuranceCarrierName: '', visionInsuranceCarrierName: '',
          medicarePartDCarrierName: '', medicaidCarrierName: '',
          medicaidEffectiveDate: null,
          privateInsuranceHasACATaxCredit: false,
          privateInsuranceACATaxCreditAmount: '',
          privateInsuranceNoACATaxCreditReason: '',
          privateInsuranceMaxOutOfPocketAmount: '',
          privateInsuranceSubstanceAbuseCoverage: '',
          medicareCoverageType: '',
          medicaidMCOorHMOPlan: false,
          medicaidCategory: '',
          privateInsuranceComments: ''
        });
      }
      setInitialValues( {
        coverageList: lst,
        insuranceDocumentation: clientInsurance.insuranceDocumentation,
        premiumPayments: clientInsurance.premiumPayments || []
      });
    }
  }, [clientInsurance]);

  const splitFormData = (formData) => {
      
    // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
    const {
      coverageList, insuranceDocumentation,
      ...dynamicFormData} = formData;

    const clientInsurance = {
      coverageList,
      insuranceDocumentation
    };
    return [clientInsurance, dynamicFormData];
  }

  const saveForm = async (values) => {
    await upsertClientInsurance(client.id, {...values.coverageList[0], insuranceDocumentation: values.insuranceDocumentation});
  }

  return initialValues && clientHasEligibilityApplication !== undefined && (
    <HybridForm 
      title="Insurance"
      staticFormDefinitionBuilder={() => buildStaticForm({disabled: disabled || updateRestrictions?.all || updateRestrictions?.insurance, existingClientConfig: existingClientConfig, clientId: client.id, updateRestrictions: updateRestrictions, hasEligibilityApp: clientHasEligibilityApplication, premiumPaymentConfig: premiumPaymentConfig})}
      staticFormSectionNameBuilder={() => buildSectionNames(existingClientConfig)}
      staticFormValues={initialValues}
      staticFormValidationSchema={validationSchema}
      dynamicFormDefinition={dynamicFormExtensionDefinition} 
      dynamicFormValues={dynamicFormExtensionValues} 
      clientId={client.id}
      disabled={disabled || updateRestrictions?.all || updateRestrictions?.insurance || clientHasEligibilityApplication}
      splitStaticAndDynamicFormValues={splitFormData}
      onSaveStaticFormValues={saveForm}
    />
  );
}
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function MedicationDispensesConfigureForm({ agency, handleSubmit }) { 

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (agency) {
      const newInitialValues = {
          enabled: agency.medicationDispensesConfig.enabled || false,
          // Medication Dispenses Component - System Category
          systemCategory: {
            pbmClientId: agency.medicationDispensesConfig.systemCategory.pbmClientId || false
          },
          // Medication Dispenses Component - Type Category
          typeCategory: {
            reversal: agency.medicationDispensesConfig.typeCategory.reversal || false,
            reversalType: agency.medicationDispensesConfig.typeCategory.reversalType || false
          },
          // Medication Dispenses Component - Prescriber Category
          prescriberCategory: {
            prescriberPhoneNumber: agency.medicationDispensesConfig.prescriberCategory.prescriberPhoneNumber || false
          },
          // Medication Dispenses Component - Pharmacy Category
          pharmacyCategory: {
            pharmacyName: agency.medicationDispensesConfig.pharmacyCategory.pharmacyName || false,
            pharmacyNo: agency.medicationDispensesConfig.pharmacyCategory.pharmacyNo || false,
            pharmacyAddress1: agency.medicationDispensesConfig.pharmacyCategory.pharmacyAddress1 || false,
            pharmacyAddress2: agency.medicationDispensesConfig.pharmacyCategory.pharmacyAddress2 || false,
            pharmacyCity: agency.medicationDispensesConfig.pharmacyCategory.pharmacyCity || false,
            pharmacyState: agency.medicationDispensesConfig.pharmacyCategory.pharmacyState || false,
            pharmacyZipCode: agency.medicationDispensesConfig.pharmacyCategory.pharmacyZipCode || false,
            pharmacyPhone: agency.medicationDispensesConfig.pharmacyCategory.pharmacyPhone || false
          },
          // Medication Dispenses Component - Prescription Category
          prescriptionCategory: {
            ajudicationDate: agency.medicationDispensesConfig.prescriptionCategory.ajudicationDate || false,
            cycleEndDate: agency.medicationDispensesConfig.prescriptionCategory.cycleEndDate || false,
            rxNumber: agency.medicationDispensesConfig.prescriptionCategory.rxNumber || false,
            rxNumberQual: agency.medicationDispensesConfig.prescriptionCategory.rxNumberQual || false,
            fillNumber: agency.medicationDispensesConfig.prescriptionCategory.fillNumber || false,
            fillDate: agency.medicationDispensesConfig.prescriptionCategory.fillDate || false,
            datePrescriptionWritten: agency.medicationDispensesConfig.prescriptionCategory.datePrescriptionWritten || false,
            dispenseAsWrittenCode: agency.medicationDispensesConfig.prescriptionCategory.dispenseAsWrittenCode || false,
            numberRefillsAuthorized: agency.medicationDispensesConfig.prescriptionCategory.numberRefillsAuthorized || false,
            unitOfMeasure: agency.medicationDispensesConfig.prescriptionCategory.unitOfMeasure || false,
            originalQuantity: agency.medicationDispensesConfig.prescriptionCategory.originalQuantity || false,
            originalDaySupply: agency.medicationDispensesConfig.prescriptionCategory.originalDaySupply || false,
            dawCode: agency.medicationDispensesConfig.prescriptionCategory.dawCode || false,
            productStrength: agency.medicationDispensesConfig.prescriptionCategory.productStrength || false,
            dosageFormCode: agency.medicationDispensesConfig.prescriptionCategory.dosageFormCode || false,
            drugType: agency.medicationDispensesConfig.prescriptionCategory.drugType || false,
            drugCategoryCode: agency.medicationDispensesConfig.prescriptionCategory.drugCategoryCode || false,
            drugTier: agency.medicationDispensesConfig.prescriptionCategory.drugTier || false
          },
          // Medication Dispenses Component - Payment Category
          paymentCategory: {
            claimId: agency.medicationDispensesConfig.paymentCategory.claimId || false,
            groupId: agency.medicationDispensesConfig.paymentCategory.groupId || false,
            carrierNumber: agency.medicationDispensesConfig.paymentCategory.carrierNumber || false,
            amountSalesTax: agency.medicationDispensesConfig.paymentCategory.amountSalesTax || false,
            patientPayAmount: agency.medicationDispensesConfig.paymentCategory.patientPayAmount || false
          }
      };
      setInitialValues(newInitialValues);
    }
  }, [agency]);

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Medication Dispenses Configuration'>
                <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="enabled"
                    value={values.enabled}
                    label="Medication Dispenses Configuration Enabled"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                {values.enabled && (
                  <>
                    <LotusFormSubSection name='System Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="systemCategory.pbmClientId"
                          value={values.systemCategory.pbmClientId}
                          label="PBM Client ID Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Type Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="typeCategory.reversal"
                          value={values.typeCategory.reversal}
                          label="Reversal Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="typeCategory.reversalType"
                          value={values.typeCategory.reversalType}
                          label="Reversal Type Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      </LotusFormSubSection>
                      <LotusFormSubSection name='Prescriber Category Fields'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="prescriberCategory.prescriberPhoneNumber"
                            value={values.prescriberCategory.prescriberPhoneNumber}
                            label="Prescriber Phone Number Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Pharmacy Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="pharmacyCategory.pharmacyName"
                          value={values.pharmacyCategory.pharmacyName}
                          label="Pharmacy Name Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="pharmacyCategory.pharmacyNo"
                            value={values.pharmacyCategory.pharmacyNo}
                            label="Pharmacy No Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="pharmacyCategory.pharmacyAddress1"
                            value={values.pharmacyCategory.pharmacyAddress1}
                            label="Pharmacy Address1 Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="pharmacyCategory.pharmacyAddress2"
                            value={values.pharmacyCategory.pharmacyAddress2}
                            label="Pharmacy Address2 Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="pharmacyCategory.pharmacyCity"
                            value={values.pharmacyCategory.pharmacyCity}
                            label="Pharmacy City Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="pharmacyCategory.pharmacyState"
                            value={values.pharmacyCategory.pharmacyState}
                            label="Pharmacy State Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="pharmacyCategory.pharmacyZipCode"
                            value={values.pharmacyCategory.pharmacyZipCode}
                            label="Pharmacy Zip Code Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="pharmacyCategory.pharmacyPhone"
                            value={values.pharmacyCategory.pharmacyPhone}
                            label="Pharmacy Phone Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Prescription Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.ajudicationDate"
                          value={values.prescriptionCategory.ajudicationDate}
                          label="Ajudication Date Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="prescriptionCategory.cycleEndDate"
                            value={values.prescriptionCategory.cycleEndDate}
                            label="Cycle End Date Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="prescriptionCategory.rxNumber"
                            value={values.prescriptionCategory.rxNumber}
                            label="Rx Number Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="prescriptionCategory.rxNumberQual"
                            value={values.prescriptionCategory.rxNumberQual}
                            label="Rx Number Qualifier Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="prescriptionCategory.fillNumber"
                            value={values.prescriptionCategory.fillNumber}
                            label="Fill Number Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="prescriptionCategory.fillDate"
                            value={values.prescriptionCategory.fillDate}
                            label="Fill Date Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="prescriptionCategory.datePrescriptionWritten"
                            value={values.prescriptionCategory.datePrescriptionWritten}
                            label="Date Prescription Written Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                          <LotusSwitch
                            name="prescriptionCategory.dispenseAsWrittenCode"
                            value={values.prescriptionCategory.dispenseAsWrittenCode}
                            label="Dispense As Written Code Enabled"
                            labelPlacement="start"
                          />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.numberRefillsAuthorized"
                          value={values.prescriptionCategory.numberRefillsAuthorized}
                          label="Number Refills Authorized Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.unitOfMeasure"
                          value={values.prescriptionCategory.unitOfMeasure}
                          label="Unit Of Measure Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.originalQuantity"
                          value={values.prescriptionCategory.originalQuantity}
                          label="Original Quantity Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.originalDaySupply"
                          value={values.prescriptionCategory.originalDaySupply}
                          label="Original Day Supply Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.dawCode"
                          value={values.prescriptionCategory.dawCode}
                          label="DAW Code Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.productStrength"
                          value={values.prescriptionCategory.productStrength}
                          label="Product Strength Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.dosageFormCode"
                          value={values.prescriptionCategory.dosageFormCode}
                          label="Dosage Form Code Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.drugType"
                          value={values.prescriptionCategory.drugType}
                          label="Drug Type Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.drugCategoryCode"
                          value={values.prescriptionCategory.drugCategoryCode}
                          label="Drug Category Code Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="prescriptionCategory.drugTier"
                          value={values.prescriptionCategory.drugTier}
                          label="Drug Tier Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Payment Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="paymentCategory.claimId"
                          value={values.paymentCategory.claimId}
                          label="Claim ID Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="paymentCategory.groupId"
                          value={values.paymentCategory.groupId}
                          label="Group ID Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="paymentCategory.carrierNumber"
                          value={values.paymentCategory.carrierNumber}
                          label="Carrier Number Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="paymentCategory.amountSalesTax"
                          value={values.paymentCategory.amountSalesTax}
                          label="Amount Sales Tax Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="paymentCategory.patientPayAmount"
                          value={values.paymentCategory.patientPayAmount}
                          label="Patient Pay Amount Enabled"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                  </>
                )}
                <LotusFormItem lastOne>
                  <LotusButton type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

import React, { useEffect, useState } from 'react';
import LotusPage from 'components/widgets/Layout/LotusPage';
import { useAuthState } from 'contexts/AuthProvider';
import { METRIC_CATEGORIES } from 'lib/metricCategoryEnum';
import SingleMetricDashboard from './singleMetricDashboard';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useParams } from 'react-router-dom';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import MetricMatrixDashboard from './metricMatrixDashboard';

export default function Dashboard() {

  const { type: dashboardType } = useParams();
  const { verifyPermission } = useAuthState();
  const {userAgency} = useUserAgency();

  const [dashboardHeader, setDashboardHeader] = useState();

  useEffect(() => {
    if (dashboardType) {
      switch (dashboardType) {
        case METRIC_CATEGORIES.CYCLE_TIME:
          setDashboardHeader(userAgency.dashboardConfig?.cycleTimeDashboardName || 'Cycle Time Dashboard');
          break;
        case METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY:
          setDashboardHeader(userAgency.dashboardConfig?.processorProductivityDashboardName || 'Processor Productivity Dashboard');
          break;
        default:
          setDashboardHeader('Dashboard');
      }
    }
  }, [dashboardType]);

  return (
    <LotusPage>
      <LotusPageTitle title={dashboardHeader}/>
      {dashboardType === METRIC_CATEGORIES.CYCLE_TIME &&
        <SingleMetricDashboard/>
      }
      {dashboardType === METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY && verifyPermission(PERMISSIONS.VIEW_PROCESSOR_PRODUCTIVITY_DASHBOARD) &&
        <MetricMatrixDashboard/>
      }
    </LotusPage>
  );
}


import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function PremiumsPaidConfigureForm({ agency, handleSubmit }) { 

  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (agency) {
      const newInitialValues = {
          enabled: agency.premiumsPaidConfig.enabled || false,
          // Premiums Paid Component - Type Category
          typeCategory: {
            adjustmentFlag: agency.premiumsPaidConfig.typeCategory.adjustmentFlag || false,
            refunded: agency.premiumsPaidConfig.typeCategory.refunded || false
          },
          // Premiums Paid Component - Payment Category
          paymentCategory: {
            voucherDate: agency.premiumsPaidConfig.paymentCategory.voucherDate || false,
            voucherId: agency.premiumsPaidConfig.paymentCategory.voucherId || false,
            authNum: agency.premiumsPaidConfig.paymentCategory.authNum || false,
            paymentMethod: agency.premiumsPaidConfig.paymentCategory.paymentMethod || false,
            paymentId: agency.premiumsPaidConfig.paymentCategory.paymentId || false,
            paymentRefundUniqueId: agency.premiumsPaidConfig.paymentCategory.paymentRefundUniqueId || false
          },
          // Premiums Paid Component - Premium Coverage Category
          premiumCoverageCategory: {
            planName: agency.premiumsPaidConfig.premiumCoverageCategory.planName || false,
            planMemberId: agency.premiumsPaidConfig.premiumCoverageCategory.planMemberId || false,
            planType: agency.premiumsPaidConfig.premiumCoverageCategory.planType || false
          }
      };
      setInitialValues(newInitialValues);
    }
  }, [agency]);

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {({ handleSubmit, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Premiums Paid Configuration'>
                <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="enabled"
                    value={values.enabled}
                    label="Premiums Paid Configuration Enabled"
                    labelPlacement="start"
                  />
                </LotusFormItem>
                {values.enabled && (
                  <>
                    <LotusFormSubSection name='Type Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="typeCategory.adjustmentFlag"
                          value={values.typeCategory.adjustmentFlag}
                          label="Adjustment Flag Enabled"
                          labelPlacement="start"
                        />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="typeCategory.refunded"
                        value={values.typeCategory.refunded}
                        label="Refunded Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Payment Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="paymentCategory.voucherDate"
                          value={values.paymentCategory.voucherDate}
                          label="Voucher Date Enabled"
                          labelPlacement="start"
                        />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="paymentCategory.voucherId"
                        value={values.paymentCategory.voucherId}
                        label="Voucher ID Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="paymentCategory.authNum"
                        value={values.paymentCategory.authNum}
                        label="Authorization Number Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="paymentCategory.paymentMethod"
                        value={values.paymentCategory.paymentMethod}
                        label="Payment Method Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="paymentCategory.paymentId"
                        value={values.paymentCategory.paymentId}
                        label="Payment ID Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="paymentCategory.paymentRefundUniqueId"
                        value={values.paymentCategory.paymentRefundUniqueId}
                        label="Payment Refund Unique ID Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Premium Coverage Category Fields'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="premiumCoverageCategory.planName"
                          value={values.premiumCoverageCategory.planName}
                          label="Plan Name Enabled"
                          labelPlacement="start"
                        />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="premiumCoverageCategory.planMemberId"
                        value={values.premiumCoverageCategory.planMemberId}
                        label="Plan Member ID Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="premiumCoverageCategory.planType"
                        value={values.premiumCoverageCategory.planType}
                        label="Plan Type Enabled"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    </LotusFormSubSection>
                  </>
                )}
                <LotusFormItem lastOne>
                  <LotusButton type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

import React from 'react';
import Typography from '@mui/material/Typography';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function AddTemplateConfirmPanel({ name }) {
  return (
    <LotusSpacedBlock>
      <Typography variant="h6">Agency Request Sent</Typography>
        <p>
          <Typography variant="body">
            New Agency Requested: <b>{name}</b>{' '}
          </Typography>
        </p>
        <br />
        <p>
          <Typography variant="body">
            <b>Next Steps</b>{' '}
          </Typography>
        </p>
        <p>
          <Typography variant="body">
            You will receive a message when the agency request is approved or
            denied.
          </Typography>
        </p>
    </LotusSpacedBlock>
  );
}

import React, { useState } from 'react';
import AgencyForm from './forms/AgencyForm';
import AddAgencyConfirmPanel from './AddAgencyConfirmPanel';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function AddAgencyPanel({ handleBackClicked }) {
  const [savedAgency, setSavedAgency] = useState();

  const showConfirmPanel = (agency) => {
    setSavedAgency(agency);
  };

  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton onClick={handleBackClicked}>Back</LotusButton>
      </LotusSpacedBlock>
      {!savedAgency && (
        <LotusPageSection header='Request New Agency'>
          <AgencyForm afterSave={showConfirmPanel} />
        </LotusPageSection>
      )}
      {savedAgency && (
        <AddAgencyConfirmPanel name={savedAgency.name} />
      )}
    </LotusPaper>
  );
}

import React, { useState } from 'react';
import { Grid, Stack, Accordion } from '@mui/material';
import LotusButton from 'components/widgets/Forms/LotusButton';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AgencyMetricForm from './forms/AgencyMetricForm';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ActionDialog from 'components/Dialogs/ActionDialog';


const PREFIX = 'AgencyMetricPanel';

const classes = {
  container: `${PREFIX}-container`,
  expandIcon: `${PREFIX}-expandIcon`,
  accordion: `${PREFIX}-accordion`,
  metricContainer: `${PREFIX}-metricContainer`,
  borderNoTop: `${PREFIX}-borderNoTop`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.container}`]: {
    marginLeft: '30px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  [`& .${classes.expandIcon}`]: {
    fontSize: '35px',
  },
  [`& .${classes.accordion}`]: {
    border: '1px solid #7A7A7A66',
    borderRadius: '5px',
    borderBottom: 'none',
  },
  [`& .${classes.metricContainer}`]: {
    marginTop: '30px',
  },
  [`& .${classes.borderNoTop}`]: {
    border: '1px solid #7A7A7A66',
    borderRadius: '5px',
    borderTop: 'none',
  },
}));

export default function AgencyMetricPanel({
  agencyId,
  agencyMetric,
  metric,
  handleSave,
  handleDelete
}) {
  const [agencyMetricExpanded, setAgencyMetricExpanded] = useState(false);
  const [readOnly, setReadOnly] = useState(Boolean(agencyMetric));
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleSaveAgencyMetricEvent = async (values) => {
    await handleSave(values);
    setAgencyMetricExpanded(false);
    setReadOnly(true);
  };

  const handleConfirmDeleteCancel = () => {
    setShowConfirmDelete(false);
  };

  const handleConfirmDeleteSubmit = (agencyId, agencyMetric) => {
    const id = agencyMetric.id;
    const metricId = agencyMetric.metricId;
    handleDelete(agencyId, id);
    setShowConfirmDelete(false);
  };

  const EditButtonWrapper = ({ children }) => (
    <div onClick={(e) => e.stopPropagation()}>{children}</div>
  );

  return (
    <StyledGrid item xs={12}>
      <Accordion style={{ width: '100%', marginBottom: 20 }}
        onChange={(e, expanded) => {
          if (expanded !== agencyMetricExpanded) {
            setAgencyMetricExpanded(expanded);
          }
          if (!expanded) {
            setReadOnly(true);
          }
        }}
        expanded={agencyMetricExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          className={classes.accordion}
        >
          <Grid container>
            {readOnly && (
              <Grid item xs={6}>
                <Typography color="primary" variant="body">{agencyMetric.title}</Typography>
              </Grid>
            )}
            {readOnly && (
              <Grid item xs={6}>
                <Stack direction="row" justifyContent='flex-end'>
                  <EditButtonWrapper>
                    <LotusButton
                      pencilIcon
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => {
                        if (!agencyMetricExpanded) {
                          setAgencyMetricExpanded(true);
                        }
                        setReadOnly(false);
                      }}
                    >
                      Edit
                    </LotusButton>


                    <LotusButton
                      garbageIcon
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => {
                        setShowConfirmDelete(true);
                      }}
                    >
                      Delete
                    </LotusButton>

                    <ActionDialog
                      title={`Delete Metric`}
                      open={showConfirmDelete}
                      handleClose={handleConfirmDeleteCancel}
                      content={
                        <>
                          <Typography variant='body1'>Are you sure you want to delete this metric?</Typography>
                        </>
                      }
                      actions={
                        <div>
                          <LotusButton onClick={handleConfirmDeleteCancel}>
                            Cancel
                          </LotusButton>

                          <LotusButton
                            onClick={() => handleConfirmDeleteSubmit(agencyId, agencyMetric)}>
                            Continue
                          </LotusButton>
                        </div>
                      }
                    />
                  </EditButtonWrapper>

                </Stack>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.borderNoTop}>
          {agencyMetricExpanded && (
            <AgencyMetricForm
              agencyMetric={agencyMetric}
              metric={metric}
              readOnly={readOnly}
              handleSave={handleSaveAgencyMetricEvent}
              handleCancel={() => {
                setAgencyMetricExpanded(false);
                setReadOnly(true);
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </StyledGrid>


  );
}

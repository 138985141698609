
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LotusMainTabs from 'components/widgets/Layout/LotusMainTabs';
import LotusMainTabSpacer from 'components/widgets/Layout/LotusMainTabSpacer';
import DataDictionaryTable from './DataDictionaryTable';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';

export default function DataDictionaryList() {
  
  const [tabs, setTabs] = useState();
  const {subview} = useParams();
  const navigate = useNavigate();
  const [tableName, setTableName] = useState();
  const { verifyPermission } = useAuthState();
  const hasImportClientPermission = verifyPermission(PERMISSIONS.IMPORT_CLIENT_DATA);
  const hasImportServicesPermission = verifyPermission(PERMISSIONS.IMPORT_SERVICES_DATA);
  const hasImportAgencyConfigurationPermission = verifyPermission(PERMISSIONS.IMPORT_AGENCY_CONFIGURATION_DATA);

  let permittedTabs = [];
  if (hasImportServicesPermission) {
    permittedTabs.push({
      label: 'Test Results',
      name: 'TestResultFields',
      link: '/imports/list/dataDictionary/TestResultFields'
    });
    permittedTabs.push({
      label: 'Paid Premiums',
      name: 'PaidPremiumFields',
      link: '/imports/list/dataDictionary/PaidPremiumFields'
    });
    permittedTabs.push({
      label: 'Dispenses',
      name: 'DispenseFields',
      link: '/imports/list/dataDictionary/DispenseFields'
    });
  }
  if (hasImportClientPermission) {
    permittedTabs.push({
      label: 'Agencies',
      name: 'AgencyFields',
      link: '/imports/list/dataDictionary/AgencyFields'
    });
    permittedTabs.push({
      label: 'User Accounts',
      name: 'UserAccountFields',
      link: '/imports/list/dataDictionary/UserAccountFields'
    });
    permittedTabs.push({
      label: 'Client Details',
      name: 'ClientDetailFields',
      link: '/imports/list/dataDictionary/ClientDetailFields'
    });
    permittedTabs.push({
      label: 'Client Addresses',
      name: 'ClientAddressFields',
      link: '/imports/list/dataDictionary/ClientAddressFields'
    });
    permittedTabs.push({
      label: 'Client Contacts',
      name: 'ClientContactFields',
      link: '/imports/list/dataDictionary/ClientContactFields'
    });
    permittedTabs.push({
      label: 'Client Income',
      name: 'ClientIncomeFields',
      link: '/imports/list/dataDictionary/ClientIncomeFields'
    });
    permittedTabs.push({
      label: 'Client Health',
      name: 'ClientHealthFields',
      link: '/imports/list/dataDictionary/ClientHealthFields'
    });
    permittedTabs.push({
      label: 'Client Insurance',
      name: 'ClientInsuranceFields',
      link: '/imports/list/dataDictionary/ClientInsuranceFields'
    });
    permittedTabs.push({
      label: 'Client Eligibility',
      name: 'ClientEligibilityFields',
      link: '/imports/list/dataDictionary/ClientEligibilityFields'
    });
    permittedTabs.push({
      label: 'Client Enrollment',
      name: 'ClientEnrollmentFields',
      link: '/imports/list/dataDictionary/ClientEnrollmentFields'
    });
  }
  if (hasImportAgencyConfigurationPermission) {
    permittedTabs.push({
      label: 'Health Plan Db',
      name: 'HealthPlanFields',
      link: '/imports/list/dataDictionary/HealthPlanFields'
    });
  }
  
  useEffect(() => {
    if (!subview) {
      if (hasImportServicesPermission) {
        navigate('/imports/list/dataDictionary/TestResultFields');
      } else if (hasImportClientPermission) {
        navigate('/imports/list/dataDictionary/AgencyFields');
      } else {
        navigate('/imports/list/dataDictionary/HealthPlanFields');
      }
    }
    setTabs(permittedTabs);
  }, []);

  useEffect(() => {
    if (subview) {
      if (subview.toLowerCase() === 'testresultfields') {
        setTableName('testResultsTable');
      }
      else if (subview.toLowerCase() === 'paidpremiumfields') {
        setTableName('paidPremiumsTable');
      }
      else if (subview.toLowerCase() === 'dispensefields') {
        setTableName('dispensesTable');
      }
      else if (subview.toLowerCase() === 'agencyfields') {
        setTableName('agenciesTable');
      }
      else if (subview.toLowerCase() === 'useraccountfields') {
        setTableName('userAccountsTable');
      }
      else if (subview.toLowerCase() === 'clientdetailfields') {
        setTableName('clientDetailsTable');
      }
      else if (subview.toLowerCase() === 'clientaddressfields') {
        setTableName('clientAddressesTable');
      }
      else if (subview.toLowerCase() === 'clientcontactfields') {
        setTableName('clientContactsTable');
      }
      else if (subview.toLowerCase() === 'clientincomefields') {
        setTableName('clientIncomeTable');
      }
      else if (subview.toLowerCase() === 'clienthealthfields') {
        setTableName('clientHealthTable');
      }
      else if (subview.toLowerCase() === 'clientinsurancefields') {
        setTableName('clientInsuranceTable');
      }
      else if (subview.toLowerCase() === 'clienteligibilityfields') {
        setTableName('clientEligibilityTable');
      }
      else if (subview.toLowerCase() === 'clientenrollmentfields') {
        setTableName('clientEnrollmentTable');
      }
      else if (subview.toLowerCase() === 'healthplanfields') {
        setTableName('healthPlanTable');
      }
    }
  }, [subview]);

  return tabs && subview && (
    <div>
      <LotusMainTabs
        selectedTabName={subview}
        tabs={tabs}
        tabPropertyToMatch={'name'}
        isScrollable={true}
      />
      <LotusMainTabSpacer/>
      {tableName === 'testResultsTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'paidPremiumsTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'dispensesTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'agenciesTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'userAccountsTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientDetailsTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientAddressesTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientContactsTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientIncomeTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientHealthTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientInsuranceTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientEligibilityTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'clientEnrollmentTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
      {tableName === 'healthPlanTable' &&
        <DataDictionaryTable tableName={tableName}/>
      }
    </div>
  );
}

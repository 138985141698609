import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import { useEffect, useState } from 'react';
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { generateThumbnailsForUrl } from 'lib/documents';
import { v4 as uuidv4 } from 'uuid';
import { useAppStatus } from 'contexts/AppStatusContext';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';

export default function AgencyConsentTypeForm({
  consentType,
  afterSave,
  handleCancel,
}) {
  const { agency, upsertAgencyConsentType, languages, loadAgencyLanguages } = useAgencyManagement();
  const { addBusyBee, removeBusyBee } = useAppStatus();

  const [initialValues, setInitialValues] = useState();
  const [loadedAgencyLanguages, setLoadedAgencyLanguages] = useState();
  
  useEffect(() => {
    if (agency && !languages) {
      loadAgencyLanguages();
    }
  }, [agency, languages]);

  useEffect(() => {
    if (agency && languages) {
      setLoadedAgencyLanguages(languages);
    }
  }, [agency, languages]);

  useEffect(() => {
    let initialValue = consentType
      ? {
          id: consentType.id,
          name: consentType.name,
          isAgencyWide: consentType.isAgencyWide,
          supportsWritten: consentType.supportsWritten,
          supportsVerbal: consentType.supportsVerbal,
          expirationMonths: consentType.expirationMonths,
          expirationSet: consentType.expirationMonths ? 'yes' : 'no',
          discontinued: !consentType.isActive,
          defaultConsentDocument:
            consentType.defaultConsentDocumentUniqueFileName
              ? {
                  displayName: consentType.defaultConsentDocumentFileName,
                  uniqueName: consentType.defaultConsentDocumentUniqueFileName,
                  type: consentType.defaultConsentDocumentFileType,
                  thumbnail: consentType.defaultConsentDocumentThumbnail,
                  preview: consentType.defaultConsentDocumentPreview,
                  file: {
                    name: consentType.defaultConsentDocumentUniqueFileName,
                  },
                }
              : {},
          defaultConsentDocumentError: '',
          languageDocuments: consentType.languageDocuments.map((ld) => {
            return {
              id: ld.id,
              languageId: ld.languageId,
              languageName: ld.languageName,
              document: {
                displayName: ld.consentDocumentFileName,
                uniqueName: ld.consentDocumentUniqueFileName,
                type: ld.consentDocumentFileType,
                thumbnail: ld.consentDocumentThumbnail,
                file: {
                  name: ld.consentDocumentUniqueFileName,
                },
              },
              documentError: '',
            };
          }),
          supportMultipleLanguages: consentType.languageDocuments.length > 0,
        }
      : {
          name: '',
          isAgencyWide: false,
          supportsWritten: false,
          supportsVerbal: false,
          expirationMonths: '',
          expirationSet: 'no',
          discontinued: false,
          defaultConsentDocument: {},
          languageDocuments: [],
          defaultConsentDocumentError: '',
          supportMultipleLanguages: false,
        };
    setInitialValues(initialValue);
  }, [consentType]);

  const handleUpsertConsentType = async (newValues) => {
    if (!newValues.supportMultipleLanguages) {
      newValues.languageDocuments = [];
    } else {
      newValues.languageDocuments.map((ld) => {
        ld.consentDocumentFileName = ld.document.displayName;
        ld.consentDocumentUniqueFileName = ld.document.uniqueName;
        ld.consentDocumentFileType = ld.document.type;
        ld.consentDocumentThumbnail = ld.document.thumbnail;
      });
    }
    if (newValues.expirationSet === 'no') {
      newValues.expirationMonths = '';
    }
    newValues.defaultConsentDocumentFileName = newValues.defaultConsentDocument.displayName;
    newValues.defaultConsentDocumentUniqueFileName = newValues.defaultConsentDocument.uniqueName;
    newValues.defaultConsentDocumentFileType = newValues.defaultConsentDocument.type;
    newValues.defaultConsentDocumentThumbnail = newValues.defaultConsentDocument.thumbnail;
    newValues.defaultConsentDocumentPreview = newValues.defaultConsentDocument.preview;
    newValues.isActive = !newValues.discontinued;
    upsertAgencyConsentType(newValues);
  };

  return (
    <LotusPageSection header={consentType ? 'Edit Consent' : 'New Consent'}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={async (values) => {
          let result = {};
          if (!values.name) {
            result.name = 'Name is required';
          }
          if (values.expirationSet === 'yes' && !values.expirationMonths) {
            result.expirationMonths = 'Expiration is required';
          }

          // Doing all the document validation here as the upload component
          // doesnt natively support it
          if (Object.keys(values.defaultConsentDocument).length === 0) {
            result.defaultConsentDocument =
              'Default consent document is required';
          }
          if (values.defaultConsentDocumentError) {
            result.defaultConsentDocumentError =
              values.defaultConsentDocumentError;
          }
          if (values.supportMultipleLanguages && values.languageDocuments) {
            const languageIds = new Set();
            values.languageDocuments.map((ld, idx) => {
              let err = {};
              if (
                !values.languageDocuments[idx].document ||
                Object.keys(values.languageDocuments[idx].document).length === 0
              ) {
                err.document = 'Consent document is required';
              }
              if (ld.documentError) {
                err.documentError = values.languageDocuments[idx].documentError;
              }

              if (!ld.languageId) {
                err.languageId = 'Language is required';
              } else if (languageIds.has(ld.languageId)) {
                err.languageId = 'This language has already been used';
              } else {
                languageIds.add(ld.languageId);
              }

              if (Object.keys(err).length > 0 || result.languageDocuments) {
                if (!result.languageDocuments) {
                  // need to signify no errors in previous language docs
                  result.languageDocuments = [];
                  for (let dIdx = 0; dIdx < idx; dIdx++) {
                    result.languageDocuments.push({});
                  }
                }
                result.languageDocuments.push(err);
              }
            });
          }
          return result;
        }}
        onSubmit={async (newValues, actions) => {
          await handleUpsertConsentType(newValues);

          if (afterSave) {
            await afterSave(newValues);
          }
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          touched,
          errors,
          setFieldTouched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          //console.log(values, touched, errors);
          const handleExpirationToggleChange = (event) => {
            handleChange(event);
            // This seems to be causing weirdness with validation
            // if (event.target.value === "no") {
            //   setFieldValue('expirationMonths', '');
            // }
          };
          return values ? (
            <LotusForm>
              {consentType && (
                <LotusFormItem>
                  <LotusSwitch
                    name="discontinued"
                    value={values.discontinued}
                    label="Discontinue"
                    onChange={handleChange}
                  />
                </LotusFormItem>
              )}
              <LotusFormItem>
                <LotusTextInput
                  required
                  name="name"
                  label="Name of consent"
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                />
              </LotusFormItem>
              <LotusFormItem>
                <LotusSwitch
                  name="isAgencyWide"
                  value={values.isAgencyWide}
                  label="Mandatory agency-wide"
                  onChange={handleChange}
                />
              </LotusFormItem>
              <LotusFormSection name="Written signature and/or verbal consent collected">
                <LotusFormItem>
                  <LotusSwitch
                    name="supportsWritten"
                    value={values.supportsWritten}
                    label="Written signature"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="supportsVerbal"
                    value={values.supportsVerbal}
                    label="Verbal consent"
                    onChange={handleChange}
                  />
                </LotusFormItem>
              </LotusFormSection>
              <LotusFormSection name="Consent expiration">
                <LotusFormItem>
                  <RadioGroup
                    name="expirationSet"
                    value={values.expirationSet}
                    onChange={handleExpirationToggleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={
                        <LotusSelect
                          name="expirationMonths"
                          label=""
                          disabled={values.expirationSet === 'no'}
                          items={[
                            { label: '6 months', value: 6 },
                            { label: '12 months', value: 12 },
                            { label: '18 months', value: 18 },
                            { label: '24 months', value: 24 },
                            { label: '36 months', value: 36 }
                          ]}
                          onChange={handleChange}
                        />
                      }
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="Does not expire"
                    />
                  </RadioGroup>
                </LotusFormItem>
              </LotusFormSection>
              <LotusFormSection name="Upload PDF of default consent document">
                <LotusFormItem>
                  <LotusFileUploader
                    maxFiles={1}
                    name="defaultConsentUploader"
                    agencyId={agency.id}
                    subfolderName="consents"
                    existingFiles={
                      Object.keys(values.defaultConsentDocument).length > 0
                        ? [{source:values.defaultConsentDocument.uniqueName, 
                          options:{type:'local', 
                          file:{name: values.defaultConsentDocument.displayName, type:values.defaultConsentDocument.type}}}]
                        : []
                    }
                    onFileAdded={async (newFile, base64Str) => {
                      console.log('Added', newFile);
                      try {
                        addBusyBee('generatingThumbnail');
                        const thumbnails = await generateThumbnailsForUrl(
                          base64Str,
                          newFile.type,
                          [64, 300]
                        );
                        newFile.thumbnail = thumbnails[0];
                        newFile.preview = thumbnails[1];
                        setFieldTouched('defaultConsentDocument');
                        setFieldValue('defaultConsentDocument', newFile);

                      }
                      catch (err) {
                        console.log(err);
                        setFieldTouched('defaultConsentDocumentError');
                        setFieldValue(
                          'defaultConsentDocumentError',
                          'An error occurred while generating thumbnail images for file'
                        );
                      } finally {
                        removeBusyBee('generatingThumbnail');
                      }
                    }}
                    onFileDeleted={(newFile) => {
                      console.log('Deleted', newFile);
                      setFieldValue('defaultConsentDocument', {});
                      setFieldTouched('defaultConsentDocument');
                    }}
                    onError={(err, action, msg) => {
                      console.log(err, msg);
                      setFieldTouched('defaultConsentDocument');
                      setFieldTouched('defaultConsentDocumentError');
                      const errMsg =
                        msg ||
                        'An error occurred while processing the default consent document';
                      if (action !== 'preview') {
                        setFieldValue('defaultConsentDocument', {});
                      }
                      setFieldValue('defaultConsentDocumentError', errMsg);
                    }}
                  />
                  <div style={{ color: '#b53f3f', marginTop: 10 }}>
                    <ErrorMessage name="defaultConsentDocument" />
                  </div>
                  <div style={{ color: '#b53f3f', marginTop: 10 }}>
                    <ErrorMessage name="defaultConsentDocumentError" />
                  </div>
                </LotusFormItem>
                <LotusFormItem>
                  <FieldArray name="languageDocuments">
                  {({ remove, push }) => {
                    const onSupportMultipleLanguagesChanged = (event) => {
                      handleChange(event);
                      if (event.target.checked && values.languageDocuments.length === 0) {
                        addElement();
                      }
                    }
                    const addElement = () => {
                      const newItem = {id: uuidv4(), languageId: '', document: {}, documentError: ''};
                      push(newItem);
                    }
                    const removeElement = (index) => {
                      if (values.languageDocuments.length === 1) {
                        setFieldValue("supportMultipleLanguages", false);
                      }
                      remove(index);
                    }
                    return (
                      <>
                      <LotusFormItem>
                        <LotusSwitch
                          name="supportMultipleLanguages"
                          value={values.supportMultipleLanguages}
                          label='Support Multiple Languages'
                          onChange={onSupportMultipleLanguagesChanged}
                        />
                      </LotusFormItem>
                      {values.supportMultipleLanguages &&
                        <LotusPaper>
                          <LotusFormSubSection name="Upload Additional Language Versions">
                            {values.languageDocuments.map((row, index) => (        
                              <>
                              <LotusFormItem key={index}>
                                {loadedAgencyLanguages &&
                                <LotusFormItem>
                                  <LotusSelect
                                    required
                                    name={`languageDocuments.${index}.languageId`}
                                    label="Select Language Version"
                                    value={row.languageId}
                                    items={loadedAgencyLanguages.map(al => {return {label: al.name, value: al.languageId}})}
                                    onChange={handleChange}
                                  />
                                </LotusFormItem>
                                }
                                <LotusFormItem>
                                  <LotusFileUploader
                                    maxFiles={1}
                                    name={`languageConsentUploader-${index}`}
                                    agencyId={agency.id}
                                    subfolderName="consents"
                                    existingFiles={
                                      Object.keys(row.document).length > 0
                                        ? [{
                                          source: row.document.uniqueName,
                                          options: {
                                            type: 'local',
                                            file: { name: row.document.displayName, type: row.document.type }
                                          }
                                        }]
                                        : []
                                    }
                                    onFileAdded={async (newFile, base64Str) => {
                                      console.log('Added', newFile);
                                      
                                      try {
                                        addBusyBee('generatingThumbnail');
                                        const thumbnails = await generateThumbnailsForUrl(
                                          base64Str,
                                          newFile.type,
                                          [64]
                                        );
                                        newFile.thumbnail = thumbnails[0];
                                        setFieldTouched(`languageDocuments.${index}.document`);
                                        setFieldValue(`languageDocuments.${index}.document`, newFile);
                                      }
                                      catch (err) {
                                        console.log(err);
                                        setFieldTouched(`languageDocuments.${index}.document`);
                                        setFieldValue(`languageDocuments.${index}.document`, {});
                                        setFieldTouched(`languageDocuments.${index}.documentError`);
                                        setFieldValue(`languageDocuments.${index}.documentError`, 'An error occurred while generating thumbnail images for file');
                                      } finally {
                                        removeBusyBee('generatingThumbnail');
                                      }
                                    }}
                                    onFileDeleted={(newFile) => {
                                      console.log('Deleted', newFile);
                                      setFieldTouched(`languageDocuments.${index}.document`);
                                      setFieldValue(`languageDocuments.${index}.document`, {});
                                    }}
                                  />
                                  <div style={{color:'#b53f3f', marginTop: 10}}>
                                    <ErrorMessage name={`languageDocuments.${index}.document`} />
                                  </div>
                                  <div style={{color:'#b53f3f', marginTop: 10}}>
                                    <ErrorMessage name={`languageDocuments.${index}.documentError`} />
                                  </div>
                                </LotusFormItem> 
                                <LotusFormItem>
                                  <LotusButton variant='text' onClick={() => removeElement(index)} garbageIcon>Delete</LotusButton>
                                </LotusFormItem>
                              </LotusFormItem>
                            </>
                            ))}
                            <LotusFormItem>
                              <LotusButton variant='outlined' onClick={addElement} plusIcon>Add Another Language</LotusButton>
                            </LotusFormItem>
                          </LotusFormSubSection>
                        </LotusPaper>
                      }
                      </>
                    )}}
                  </FieldArray>
                </LotusFormItem>
              </LotusFormSection>
              <LotusFormItem>
                <Stack justifyContent="flex-end" direction="row" spacing={1}>
                  {handleCancel && (
                    <LotusButton
                      variant="text"
                      style={{ marginRight: 10 }}
                      onClick={handleCancel}
                    >
                      Cancel
                    </LotusButton>
                  )}
                  <LotusButton
                    onClick={handleSubmit}
                  >
                    Save
                  </LotusButton>
                </Stack>
              </LotusFormItem>
            </LotusForm>
          ) : (
            <div></div>
          );
        }}
      </Formik>
    </LotusPageSection>
  );
}

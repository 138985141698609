import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Chip, Grid, IconButton, Typography } from '@mui/material';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPage from 'components/widgets/Layout/LotusPage';
import LotusPageTitle from 'components/widgets/Layout/LotusPageTitle';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { useUserManagement } from 'contexts/UserManagementContext';
import { useAuthState } from "contexts/AuthProvider";
import { useFiles } from 'contexts/FilesContext';
import moment from "moment";
import LotusCard from 'components/widgets/Layout/LotusCard';
import LotusCardList from 'components/widgets/Layout/LotusCardList';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function Training() {
  const { user: loggedInUser } = useAuthState();
  const { userTrainingDocs, loadUserTrainingDocs } = useUserManagement();
  const { getSignedURLForDownload } = useFiles();

  const navigate = useNavigate();

  const sxStyles = {
    "&:hover": {
      boxShadow: 8
  }}

  useEffect(() => {
    if (loggedInUser) {
      loadUserTrainingDocs(loggedInUser.id);
    }
  }, [loggedInUser]);

  const handleClose = () => {
    navigate(-1);
  }

  const openInTab = async (doc) => {
    const fileType = doc.documentType === 'pdf' ? 'application/pdf' : (doc.documentType === 'video' ? 'video/mp4' : '');
    const fileName = doc.displayName + (doc.documentType === 'pdf' ? '.pdf' : '.mp4');
    const signedUrl = await getSignedURLForDownload(loggedInUser.granteeId, '', '', 'training', fileName, fileType);
    try {
      if (doc.documentType === 'pdf') {
        let pdfWindow = window.open("");
        pdfWindow.document.write(
          '<embed type="application/pdf" src=' + signedUrl + '#toolbar=0 width="100%" height="100%" />'
        )
      
      } else if(doc.documentType === 'video') {
        let videoWindow = window.open("");
        videoWindow.document.write(
          '<video width="100%" height="100%" controls autoplay><source src=' + signedUrl + ' type="video/mp4" /></video>'
        )
      }
    } catch(e) {
      console.log(e);
    }
  }

  return (
    <LotusPage>
      <LotusPageTitle title="Training">
        <LotusButton variant="outlined" closeIcon onClick={() => {handleClose();}}>
          <Typography variant="h6">Close</Typography>
        </LotusButton>
      </LotusPageTitle>
      <LotusCardList>
        {userTrainingDocs &&
            userTrainingDocs.map((doc, index) => {
              return (
                <LotusCard key={index} onClick={() => { openInTab(doc); }} sxStyles={sxStyles}>
                  <Typography variant='subtitle2' sx={{ color: '#294055', marginBottom: 1}}>
                    {doc.createdDate ? moment(doc.createdDate).format('D MMM YYYY') : ''}
                    <Chip label={doc.documentType === 'video' ? 'Video' : 'User Guide'} sx={{background: doc.documentType === 'video' ? '#E1F5FE' :'#F3E5F5', float: 'right'}}/>
                  </Typography>
                  <Typography variant="h4" sx={{marginBottom: 1}}>
                    {doc.displayName}
                  </Typography>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      <OpenInNewIcon color='action' sx={{marginTop: 1}}></OpenInNewIcon>
                    </Grid>
                    <Grid item>
                      <Typography variant="text" sx={{marginLeft: 1}}>
                        {doc.documentType === 'video' ? 'Watch Video' : 'View Guide'}
                      </Typography>
                    </Grid>
                  </Grid>
                </LotusCard>
              )
            })}
      </LotusCardList>
      <Grid item xs={12} sx={{marginTop: 5, marginBottom: 3}}>
          <Typography variant="h3">
            Couldn't find what you needed?
          </Typography>
          <Typography variant='subtitle2' sx={{ color: '#294055'}}>
            Here are some other ways we can help.
          </Typography>
      </Grid>
      <Grid item xs={3} sx={{marginBottom: 5}}>
        <LotusCard sxStyles={sxStyles} onClick={() => { window.open('https://support.provideenterprise.com/support/login', '_blank') }}>
          <IconButton
            size="large"
            aria-label="Support"
            sx={{background: '#2699A814'}}
          >
            <FindInPageIcon sx={{color: '#2699A8'}}/>
          </IconButton >
          <Typography variant="h4" sx={{marginTop: 1, marginBottom: 1}}>
            Contact Support
          </Typography>
          <Typography variant='subtitle2' sx={{ color: '#294055' }}>
            Need to report an issue? Visit our support portal to submit a ticket.
          </Typography>
        </LotusCard>
      </Grid>
    </LotusPage>
  );
}

import React, { useEffect, useState } from 'react';
import AgencyIntegrationPanel from './AgencyIntegrationPanel';
import AgencyIntegrationForm from './forms/AgencyIntegrationForm';
import { useAgencyIntegrations } from 'contexts/AgencyIntegrationsContext';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import Stack from '@mui/material/Stack';

export default function AgencyIntegrationsList({ agencyId }) {
  const {
    integrations,
    loadAgencyIntegrations,
    createAgencyIntegration,
    updateAgencyIntegration,
  } = useAgencyIntegrations();

  const [loadedAgencyIntegrations, setLoadedAgencyIntegrations] = useState([]);
  const [addingIntegration, setAddingIntegration] = useState(false);

  useEffect(() => {
    if (agencyId && !integrations) {
      loadAgencyIntegrations(agencyId);
    }
  }, [agencyId]);


  useEffect(() => {
    if (integrations) {
      const lst = [...integrations];
      lst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setLoadedAgencyIntegrations(lst);
    }
  }, [integrations]);

  const handleUpsertIntegration = async (values) => {
    if (values.arn) {
      await updateAgencyIntegration(agencyId, values);
    } else {
      await createAgencyIntegration(agencyId, values);
    }
    setAddingIntegration(false);
  };

  return (
    <LotusPageSection header="Integrations">
      {!addingIntegration && (
        <Stack justifyContent="flex-end" direction="row">
          <LotusButton
            plusIcon
            variant={'outlined'}
            color={'secondary'}
            onClick={() => setAddingIntegration(true)}
          >
            ADD
          </LotusButton>
        </Stack>
      )}
      {addingIntegration && (
        <AgencyIntegrationForm
          agencyId={agencyId}
          integrations={integrations}
          handleSubmit={handleUpsertIntegration}
          handleCancel={() => setAddingIntegration(false)}

        />
      )}
      {loadedAgencyIntegrations &&
        loadedAgencyIntegrations.map((integration) => (
          <AgencyIntegrationPanel
            key={integration.id}
            agencyId={agencyId}
            integration={integration}
            handleSave={handleUpsertIntegration}
          />
        ))}
    </LotusPageSection>
  );
}

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusTrueFalseSelect from 'components/widgets/Forms/LotusTrueFalseSelect';
import LotusTrueFalseRadioGroup from 'components/widgets/Forms/LotusTrueFalseRadioGroup';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from 'components/widgets/Forms/LotusProgressiveDisclosure';
import moment from 'moment';
import { Stack } from '@mui/system';
import SectionCommentsChain from './SectionCommentsChain';
import { determineRwEligibility } from 'lib/rwScorer';
import ActionDialog from 'components/Dialogs/ActionDialog';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import { Button } from '@mui/material';

export const getHealthStaticFormSchema = (eligibilityAppConfig, hivDiseaseStages, meta) => {
  return Yup.object().shape({
    pregnancyStatus: Yup.string().required("Is client pregnant? is required"),
    hivStatusId: Yup.string().required("Current Disease Stage is required"),
    aidsDiagnosisDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        if (ctx.parent.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id && !value) {
          return ctx.createError({ message: 'Estimated Date AIDS Diagnosed is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Estimated Date AIDS Diagnosed must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Estimated Date AIDS Diagnosed is too old' });
          }
        }
        return true;
      }
    }),
    hivDiagnosisDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        if ((ctx.parent.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id ||
             ctx.parent.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_unknown').id || 
             ctx.parent.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_pos_not_aids').id) && !value) {
          return ctx.createError({ message: 'Estimated Date HIV Diagnosed is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Estimated Date HIV Diagnosed must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Estimated Date HIV Diagnosed is too old' });
          }
        }
        return true;
      }
    }),
    hivRiskFactors: Yup.array().test({
      name: 'maybeRequired',
      skipAbsent: false,
      test(value, ctx) {
        if (
          (ctx.parent.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id ||
           ctx.parent.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_unknown').id || 
           ctx.parent.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_pos_not_aids').id) &&
          (!value || value.length < 1)
        ) {
          return ctx.createError({ message: 'Identified HIV Risk Factors is required' });
        }
        return true;
      }
    }),
    antiretroviralTherapyStatus: Yup.string().required("Are you currently on Antiretroviral Therapy? is required"),
    antiretroviralTherapyStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: false,
      test(value, ctx) {
        if (ctx.parent.antiretroviralTherapyStatus === 'true' && !value) {
          return ctx.createError({ message: 'Estimated Date Antiretroviral Therapy Started is required' });
        }
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Estimated Date Antiretroviral Therapy Started must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Estimated Date Antiretroviral Therapy Started is too old' });
          }
        }
        return true;
      }
    }),
    antiretroviralDenialReasons: Yup.array().test({
      name: 'validList',
      skipAbsent: false,
      test(value, ctx) {
        if (ctx.parent.antiretroviralTherapyStatus === 'false' && !ctx.parent.antiretroviralOtherReason && (!value || value.length === 0)) {
          return ctx.createError({ message: 'Reason not on ARV is required' });
        }
        return true;
      }
    }),
    hivCurrentArvCount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Current ARV Count must be a number').integer("Current ARV Count must be an integer").min(0,'Current ARV Count must be non-negative').test({
      name: 'validVal',
      skipAbsent: false,
      test(value, ctx) {
        if (eligibilityAppConfig.showCurrentArvCount && value === null) {
          return ctx.createError({ message: 'Current ARV Count is required' });
        }
        return true;
      }
    }),
    hivCd4: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('CD4 must be a number').integer("CD4 must be an integer").min(0, "CD4 must be non-negative").test({
      name: 'validVal',
      skipAbsent: false,
      test(value, ctx) {
        if (eligibilityAppConfig.showCd4AndViralLoads && value === null) {
          return ctx.createError({ message: 'CD4 is required' });
        }
        return true;
      }
    }),
    hivViralLoads: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Viral Loads must be a number').integer("Viral Loads must be an integer").min(0, "Viral Loads must be non-negative").test({
      name: 'validVal',
      skipAbsent: false,
      test(value, ctx) {
        if (eligibilityAppConfig.showCd4AndViralLoads && value === null) {
          return ctx.createError({ message: 'Viral Loads are required' });
        }
        return true;
      }
    }),
    hivCertificationSignature: Yup.string().required("Certification Signature is required"),
    healthCommentsChain: Yup.array().test({
      name: 'unacknowledgedComments',
      test(value, ctx) {
        if (value.length > 0) {
          let last = value[value.length - 1];
          let valType = ctx.options.context.validationType;
          if (valType === 'submit' || valType === 'pend' || valType === 'initial') {
            if (!last.resolverId && last.commenterId !== ctx.options.context.userId) {
              return ctx.createError({ message: 'Comment must be resolved or replied to'});
            }
          }
          if (valType === 'complete') {
            if (!last.resolverId) {
              return ctx.createError({ message: 'Comment must be resolved'});
            }
          }
        }
        return true;
      }
    }),
  });
}

export const getHealthStaticFormSchemaExcludingRequiredChecks = () => {
  return Yup.object().shape({
    aidsDiagnosisDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Estimated Date AIDS Diagnosed must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Estimated Date AIDS Diagnosed is too old' });
          }
        }
        return true;
      }
    }),
    hivDiagnosisDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Estimated Date HIV Diagnosed must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Estimated Date HIV Diagnosed is too old' });
          }
        }
        return true;
      }
    }),
    antiretroviralTherapyStartDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        if (value) {
          let toValidate = moment(value, 'MM/DD/YYYY');
          if (!toValidate.isValid()) {
            return ctx.createError({ message: 'Estimated Date Antiretroviral Therapy Started must be a valid date' });
          }
          if (toValidate.year() < 1900) {
            return ctx.createError({ message: 'Estimated Date Antiretroviral Therapy Started is too old' });
          }
        }
        return true;
      }
    }),
    hivCurrentArvCount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Current ARV Count must be a number').integer("Current ARV Count must be an integer").min(0,'Current ARV Count must be non-negative'),
    hivCd4: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('CD4 must be a number').integer("CD4 must be an integer").min(0, "CD4 must be non-negative"),
    hivViralLoads: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Viral Loads must be a number').integer("Viral Loads must be an integer").min(0, "Viral Loads must be non-negative")
  });
}

export const buildHealthStaticFormSectionNames = (eligibilityAppConfig, meta) => {
  const lst = [];
  if (eligibilityAppConfig.showGeneralHealthInfo) {
    lst.push("General");
  }
  lst.push("HIV Status");
  lst.push("Proof of HIV Status");
  return lst;
}

export const initializeHealthFormData = (formData) => {
  if (!formData) {
    return formData;
  }
  // React gets pissed about null values bound to controls
  return {
    hivStatusId: formData.hivStatusId || '', 
    hivDiagnosisDate: formData.hivDiagnosisDate || null,
    aidsDiagnosisDate: formData.aidsDiagnosisDate || null, 
    hivRiskFactors: formData.hivRiskFactors || [],  
    hepatitisCDiagnosis: formData.hepatitisCDiagnosis !== null ? formData.hepatitisCDiagnosis : '', 
    pregnancyStatus: formData.pregnancyStatus !== null ? formData.pregnancyStatus : false, 
    antiretroviralTherapyStatus: formData.antiretroviralTherapyStatus !== null ? formData.antiretroviralTherapyStatus : false, 
    antiretroviralTherapyStartDate: formData.antiretroviralTherapyStartDate || null,  
    antiretroviralDenialReasons: formData.antiretroviralDenialReasons || [],
    antiretroviralOtherReason: formData.antiretroviralOtherReason || '',  
    hasAllergies: formData.hasAllergies !== null ? formData.hasAllergies : '',  
    currentAllergies: formData.currentAllergies || '', 
    complicatingMedicalConditions: formData.complicatingMedicalConditions || '', 
    hivCurrentArvCount: formData.hivCurrentArvCount || '', 
    hivCd4: formData.hivCd4 || '',
    hivViralLoads: formData.hivViralLoads || '',
    hivCertificationDate: formData.hivCertificationDate || moment().format('MM/DD/YYYY'),
    hivCertificationSignature: formData.hivCertificationSignature || '',
    hivDocumentation: formData.hivDocumentation || [], 
    healthCommentsChain: formData.healthCommentsChain || []
  };
}

export const getHealthFormData = (formData) => {
    
  const {
    hivStatusId,
    hivDiagnosisDate, aidsDiagnosisDate, hivRiskFactors, hepatitisCDiagnosis,
    pregnancyStatus, antiretroviralTherapyStatus, antiretroviralTherapyStartDate, antiretroviralDenialReasons,
    antiretroviralOtherReason, hasAllergies, currentAllergies, complicatingMedicalConditions,
    hivCurrentArvCount, hivCd4, hivViralLoads, hivCertificationDate, hivCertificationSignature, hivDocumentation, healthCommentsChain
  } = formData;

  const health = {
    hivStatusId,
    hivDiagnosisDate, aidsDiagnosisDate, hivRiskFactors, hepatitisCDiagnosis,
    pregnancyStatus, antiretroviralTherapyStatus, antiretroviralTherapyStartDate, antiretroviralDenialReasons,
    antiretroviralOtherReason, hasAllergies, currentAllergies, complicatingMedicalConditions,
    hivCurrentArvCount, hivCd4, hivViralLoads, hivCertificationDate, hivCertificationSignature,hivDocumentation, healthCommentsChain
  };
  return health;
}

const EligibilityApplicationHealthStaticForm = ({existingClientConfig, eligibilityAppConfig, rwScoringConfig, disabled, meta}) => {

  const { hivDiseaseStages, hivRiskFactors, arvDenialReasons} = useLists();

  const [hivRiskFactorList, setHivRiskFactorList] = useState();
  const [arvDenialReasonList, setArvDenialReasonList] = useState();

  const { values, setFieldValue } = useFormikContext();

  const [showTermsAndConditionsPopup, setShowTermsAndConditionsPopup] = useState(false);

  useEffect(() => {
    if (hivRiskFactors) {
      setHivRiskFactorList(hivRiskFactors.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (arvDenialReasons) {
      setArvDenialReasonList(arvDenialReasons.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [hivRiskFactors, arvDenialReasons]);

  useEffect(() => {
    let result = determineRwEligibility(values, rwScoringConfig, existingClientConfig);
    setFieldValue("rwIncomeIneligible", !result.incomeEligible);
    setFieldValue("rwResidencyIneligible", !result.residencyEligible);
    setFieldValue("rwHivStatusIneligible", !result.hivStatusEligible);
    setFieldValue("rwEligible", result.rwEligible);
  }, [values.hivStatusId]);

  return values && existingClientConfig && eligibilityAppConfig && rwScoringConfig && meta && (
    <Stack direction="row">
      <LotusForm>
        {eligibilityAppConfig.showGeneralHealthInfo &&
        <LotusFormSection name="General" disabled={disabled}>
          <LotusFormItems>
            <LotusTrueFalseRadioGroup
              name="pregnancyStatus"
              label="Is client pregnant?"
              disabled={disabled}
              required
              dontCheckRequiredFields
            />
            <LotusTrueFalseSelect
              name="hasAllergies"
              label="Does Client have any current allergies?"
              disabled={disabled}
            />
            {values.hasAllergies === true &&
              <LotusTextInput
                name="currentAllergies"
                label="Current Allergies"
                disabled={disabled}
                maxLength={500}
                multiline
                rows={5}
              />
            }
            <LotusTextInput
              name="complicatingMedicalConditions"
              label="Does Client have any current complicating medical conditions?"
              disabled={disabled}
              maxLength={500}
              multiline
              rows={5}
            />
            <LotusTrueFalseSelect
              name="hepatitisCDiagnosis"
              label="Has the applicant received a current diagnosis for Hepatitis C?"
              disabled={disabled}
            />
          </LotusFormItems>
        </LotusFormSection>
        }
        <LotusFormSection name="HIV Status" disabled={disabled}>
          {hivDiseaseStages && 
            <>
              <LotusFormItem>
                <LotusRadioGroup
                  name="hivStatusId"
                  label="Current Disease Stage"
                  items={hivDiseaseStages.map(s => {return {value: s.id, label: s.itemName}})}
                  disabled={disabled}
                  required
                  dontCheckRequiredFields
                />
              </LotusFormItem>
            {values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id &&
              <LotusFormItem>
                <LotusDatePicker
                  name="aidsDiagnosisDate"
                  label="Estimated Date AIDS Diagnosed"
                  disabled={disabled}
                  required
                  dontCheckRequiredFields
                />
              </LotusFormItem>
            }
            {(values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id ||
                values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_unknown').id ||
                values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_pos_not_aids').id) &&
              <LotusFormItem>
                <LotusDatePicker
                  name="hivDiagnosisDate"
                  label="Estimated Date HIV Diagnosed"
                  disabled={disabled}
                  required
                  dontCheckRequiredFields
                />
              </LotusFormItem>
            }
            {hivRiskFactorList &&
                (values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'aids').id ||
                  values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_unknown').id ||
                  values.hivStatusId === hivDiseaseStages.find(s => s.itemKey === 'hiv_pos_not_aids').id) &&
              <LotusFormItem>
                <LotusMultiSelect
                  name="hivRiskFactors"
                  label="Identified HIV Risk Factor(s)"
                  items={hivRiskFactorList}
                  disabled={disabled}
                  readOnlyRows={5}
                  required
                  dontCheckRequiredFields
                />
              </LotusFormItem>
            }
            </>
          }
          <LotusFormItems>
            <LotusProgressiveDisclosure
              name="antiretroviralTherapyStatus"
              label="Are you currently on Antiretroviral Therapy?"
              disabled={disabled}
              required
              dontCheckRequiredFields
            >
              <LotusProgressiveDisclosureItem
                itemValue='true'
                label="Yes"
              >
                <LotusDatePicker
                  name="antiretroviralTherapyStartDate"
                  label="Estimated Date Antiretroviral Therapy Started"
                  disabled={disabled}
                  required
                  dontCheckRequiredFields
                />
              </LotusProgressiveDisclosureItem>
              <LotusProgressiveDisclosureItem
                itemValue='false'
                label="No" 
              >
                {arvDenialReasonList && 
                  <LotusCheckboxList
                    name="antiretroviralDenialReasons"
                    otherItemName="antiretroviralOtherReason"
                    label="Reason not on ARV"
                    items={arvDenialReasonList}
                    disabled={disabled}
                    required
                    dontCheckRequiredFields
                  />
                }
              </LotusProgressiveDisclosureItem>
            </LotusProgressiveDisclosure>
            {eligibilityAppConfig.showCurrentArvCount &&
              <LotusTextInput
                name="hivCurrentArvCount"
                label="Current ARV Count"
                disabled={disabled}
                maxLength={4}
                required
                dontCheckRequiredFields
              />
            }
            {eligibilityAppConfig.showCd4AndViralLoads &&
              <LotusTextInput
                name="hivCd4"
                label="CD4"
                disabled={disabled}
                maxLength={4}
                required
                dontCheckRequiredFields
              />
            }
            {eligibilityAppConfig.showCd4AndViralLoads &&
              <LotusTextInput
                name="hivViralLoads"
                label="Viral Loads"
                disabled={disabled}
                maxLength={4}
                required
                dontCheckRequiredFields
              />
            }
          </LotusFormItems>
        </LotusFormSection>
        <LotusFormSection name="Proof of HIV Status" disabled={disabled}>
          <LotusFormItem>
            <div>I certify that the above-named individual is HIV positive. I certify that I have explained (or read) the <span style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => {setShowTermsAndConditionsPopup(true); return false;}}>Terms and Conditions for Applicant and Interviewer</span> contained within to the client who is requesting Ryan White services.</div>
          </LotusFormItem>
          <LotusFormItem>
            <LotusTextInput
              name="hivCertificationSignature"
              label="Interviewer Signature"
              disabled={disabled}
              maxLength={100}
              required
              dontCheckRequiredFields
            />
          </LotusFormItem>
          <LotusFormItem>
            <LotusDatePicker
              name="hivCertificationDate"
              label="Date Signed"
              disabled={true}
            />
          </LotusFormItem>
        </LotusFormSection>
        {/* 
        Not using this for now in favor of the section above, but suspect it will return
        {meta.isInitialApplication && !meta.isInterimUpdate && !meta.isAdapOnlyApplication &&
          <LotusFormSection name="Documentation" disabled={disabled}>
            <LotusFormItem>
            <LotusSpacedBlock>
              <div>Upload Proof of HIV Documentation</div>
            </LotusSpacedBlock>
              <ClientInfoDocumentation 
                documentationListFieldName="hivDocumentation"
                allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoHivDocumentationTypes}
                clientId={clientId}
                disabled={disabled}
              />
            </LotusFormItem>
          </LotusFormSection>
        } */}
      </LotusForm>
      <div>
        <SectionCommentsChain chainFieldName="healthCommentsChain"/>
      </div>
      <ActionDialog
        maxWidth="lg"
        open={showTermsAndConditionsPopup}
        title="Terms and Conditions for Application and Interviewer"
        content={
          <>
            <div style={{maxHeight: 500, overflow: 'scroll', marginBottom: 20, paddingRight: 10}}>
              <div>
                <b>I agree to notify the interviewer within 30 days about any changes</b> in my address, financial resources, expenses, family situation, or health insurance coverage that might affect my eligibility for Department payment programs. I certify that the information I have provided is a true and complete statement of facts according to my best knowledge and belief. I understand that information provided may be checked by a state reviewer, and I agree to provide the financial records required to carry out this investigation. I also understand that my employer may be asked to verify information concerning my income.
              </div>
              <div>
                <b>I assign insurance benefits to the Department.</b> I agree to repay the Department any money I receive from insurance or liability settlements for services or appliances which the Department purchased for me. I understand that such payments should be made to the Department within 45 days of the date that I receive them and that the amount paid to the Department should not exceed the amount the Department paid the provider. I further agree that failure to repay assigned insurance benefits to the Department is a reason for denial of future service requests to the Department until such amounts have been repaid.
              </div>
              <div>
                <b>I understand that my eligibility for Medicaid will be checked.</b> I hereby authorize and agree to a free exchange of information between the Division of Medical Assistance and the Department of Health and Human Services relating to financial information and the amount of services provided by either program.
              </div>
              <div>
                <b>I hereby authorize the interviewer and service providers to release to the Department and its affiliate programs</b> the information provided on this form and also the medical records of the patient which pertain to medical services or appliances for which reimbursement is being sought from the Department.
              </div>
              <div> 
                <b>I also authorize release of this information to the county health department</b> where the patient resides and/or receives services. I also authorize release of the information on this form to all health departments, hospitals, and service providers in North Carolina. These disclosures shall be made for purposes of determining the patient’s eligibility for Department payment programs and for conducting program evaluation.
              </div>
              <div>
                <b>I also authorize release of enrollment, eligibility and utilization records</b> to my physicians, my case manager, other medical providers, the contracted pharmacy, Pharmacy Benefits Managers, third party administrators, health insurers or other service providers in North Carolina to facilitate program services.
              </div>
              <div>
                <b>I voluntarily give my consent to the terms of this release.</b> My consent shall be valid for a period of one year. I further understand that I may revoke my consent at any time. Such revocation does not affect the validity of my consent for information disclosed prior to the revocation.
              </div>
              <div>
                <b>I understand that I may appeal the denial of this financial eligibility application.</b> Information on how to appeal the denial can be obtained by writing to HIV Medication Assistance Services (HMAP), 1907 Mail Service Center, Raleigh NC 27699-1907. I understand that payment by the Department for health care provided to me is dependent upon me meeting all financial and medical requirements, timely submission of authorization requests and claims, and the availability of funds.
              </div>
            </div>
            <Stack alignItems='flex-end'>
              <Button variant="outlined" onClick={() => {setShowTermsAndConditionsPopup(false);}}>Close</Button>
            </Stack>
          </>
        }
      />
    </Stack>
  );
};

export function buildHealthStaticForm({...props}) {
  return (
      <EligibilityApplicationHealthStaticForm {...props}/>
  );
}
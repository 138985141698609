import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusDateOfBirth from 'components/widgets/Forms/LotusDateOfBirth';
import LotusPhoneInput from 'components/widgets/Forms/LotusPhoneInput';
import { useLists } from 'contexts/ListsContext';
import SocialSecurityNumberFieldPanel from 'components/widgets/Composites/SocialSecurityNumberFieldPanel';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import RaceFieldPanel from 'components/widgets/Composites/RaceFieldPanel';
import EthnicityFieldPanel from 'components/widgets/Composites/EthnicityFieldPanel';
import AgencyLanguageSelector from 'components/widgets/Composites/AgencyLanguageSelector';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusTrueFalseSelect from 'components/widgets/Forms/LotusTrueFalseSelect';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from 'components/widgets/Forms/LotusProgressiveDisclosure';
import moment from 'moment';
import { Stack } from '@mui/material';
import SectionCommentsChain from './SectionCommentsChain';
import { determineRwEligibility } from 'lib/rwScorer';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';

export const getApplicantStaticFormSchema = (eligibilityAppConfig) => {
  return Yup.object().shape({
    lastName: Yup.string().required("Legal Last Name is required"),
    firstName: Yup.string().required("Legal First Name is required"),
    birthDate: Yup.string().required("Date of Birth is required").test({
      name: 'validDate',
      test(value, ctx) {
        let toValidate = moment(value, 'MM/DD/YYYY');
        if (!toValidate.isValid()) {
          return ctx.createError({ message: 'Date of Birth must be a valid date' });
        }
        if (toValidate.year() < 1900) {
          return ctx.createError({ message: 'Date of Birth is too old' });
        }
        if (moment() < toValidate) {
          return ctx.createError({ message: 'Date of Birth cannot be in the future' });
        }
        return true;
      }
    }),
    hasSocialSecurityNumber: Yup.string().test('hasSsn', 'Does the client have a social security number? is required', function(value, ctx) {
      return value !== undefined && value !== '' && value !== null;
    }),
    socialSecurityNumber: Yup.string().test({
      name: 'socialSecurityNumber',
      test(value, ctx) {
        if (ctx.parent.hasSocialSecurityNumber === 'true') {
          if (!Boolean(value)) {
            return ctx.createError({ message: 'Social Security Number is required' });
          }
          let regex = new RegExp(`^\\d{3}-\\d{2}-\\d{4}`,"g");
          if (value && !regex.test(value)) {
            return ctx.createError({ message: 'Social Security Number must be in format 999-99-9999' });
          }
        }
        return true;
      }
    }),
    missingSsnReasonId: Yup.string().test('missingSsnReasonId', "Reason for No SSN is required", function(value, ctx) {
      return (ctx.parent.hasSocialSecurityNumber !== 'false') || (ctx.parent.hasSocialSecurityNumber === 'false' && Boolean(value));
    }),
    birthGenderId: Yup.string().required("Gender Assigned At Birth is required"),
    currentGenderIdentityId: Yup.string().required("Current Gender is required"),
    primaryPhoneTypeId: Yup.string().required("Primary Phone Type is required"),
    emailAddress: Yup.string().required("Email Address is required"),
    prefProgNotifMethodId: Yup.string().required("Preferred Method of Receiving Program Notification is required"),
    races: Yup.array().min(1, "Race is required"),
    isHispanic: Yup.bool().typeError('Is the Client ethnically Hispanic or Latino? is required'),
    lawfulResidentDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        let toValidate = moment(value, 'MM/DD/YYYY');
        if (!toValidate.isValid()) {
          return ctx.createError({ message: 'Date became Lawfully Present Resident must be a valid date' });
        }
        if (toValidate.year() < 1900) {
          return ctx.createError({ message: 'Date became Lawfully Present Resident is too old' });
        }
        return true;
      }
    }),
    cellPhone: Yup.string().nullable().test("phoneFormat", "Cell Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
    workPhone: Yup.string().nullable().test("phoneFormat", "Work Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
    homePhone: Yup.string().nullable().test("phoneFormat", "Home Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
    otherPhone: Yup.string().nullable().test("phoneFormat", "Other Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
    applicantCommentsChain: Yup.array().test({
      name: 'unacknowledgedComments',
      test(value, ctx) {
        if (value.length > 0) {
          let last = value[value.length - 1];
          let valType = ctx.options.context.validationType;
          if (valType === 'submit' || valType === 'pend' || valType === 'initial') {
            if (!last.resolverId && last.commenterId !== ctx.options.context.userId) {
              return ctx.createError({ message: 'Comment must be resolved or replied to'});
            }
          }
          if (valType === 'complete') {
            if (!last.resolverId) {
              return ctx.createError({ message: 'Comment must be resolved'});
            }
          }
        }
        return true;
      }
    }),
  });
}

export const getApplicantStaticFormSchemaExcludingRequiredChecks = (eligibilityAppConfig) => {
  return Yup.object().shape({
    birthDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        let toValidate = moment(value, 'MM/DD/YYYY');
        if (!toValidate.isValid()) {
          return ctx.createError({ message: 'Date of Birth must be a valid date' });
        }
        if (toValidate.year() < 1900) {
          return ctx.createError({ message: 'Date of Birth is too old' });
        }
        if (moment() < toValidate) {
          return ctx.createError({ message: 'Date of Birth cannot be in the future' });
        }
        return true;
      }
    }),
    socialSecurityNumber: Yup.string().test({
      name: 'ssn',
      test(value, ctx) {
        if (ctx.parent.hasSocialSecurityNumber === 'true') {
          let regex = new RegExp(`^\\d{3}-\\d{2}-\\d{4}`,"g");
          if (value && !regex.test(value)) {
            return ctx.createError({ message: 'Social Security Number must be in format 999-99-9999' });
          }
        }
        return true;
      }
    }),
    lawfulResidentDate: Yup.string().nullable().test({
      name: 'validDate',
      skipAbsent: true,
      test(value, ctx) {
        let toValidate = moment(value, 'MM/DD/YYYY');
        if (!toValidate.isValid()) {
          return ctx.createError({ message: 'Date became Lawfully Present Resident must be a valid date' });
        }
        if (toValidate.year() < 1900) {
          return ctx.createError({ message: 'Date became Lawfully Present Resident is too old' });
        }
        return true;
      }
    }),
    cellPhone: Yup.string().nullable().test("phoneFormat", "Cell Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
    workPhone: Yup.string().nullable().test("phoneFormat", "Work Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
    homePhone: Yup.string().nullable().test("phoneFormat", "Home Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
    otherPhone: Yup.string().nullable().test("phoneFormat", "Other Phone must be in format (###) ###-####", function(value) {
      let regex = new RegExp(`^[0-9]{10}$`,"g");
      return !value || regex.test(value);
    }),
  });
}

export const buildApplicantStaticFormSectionNames = (eligibilityAppConfig) => {
  const lst = [
    "Basic Information",
    "Gender",
    "Contact Phone & Email",
    "Race",
    "Ethnicity",
    "Language",
    "Other Demographics",
    "Additional Information",
  ];
  if (eligibilityAppConfig.showIsUrgentApplication) {
    lst.push("Urgent Application");
  }
  lst.push("Documentation");
  return lst;
}

export const initializeApplicantFormData = (formData) => {
  if (!formData) {
    return formData;
  }
  // React gets pissed about null values bound to controls
  return {
    lastName: formData.lastName || '', 
    firstName: formData.firstName || '', 
    middleName: formData.middleName || '', 
    nameSuffix: formData.nameSuffix || '', 
    preferredName: formData.preferredName || '', 
    birthDate: formData.birthDate || null, 
    birthGenderId: formData.birthGenderId || '', 
    currentGenderIdentityId: formData.currentGenderIdentityId || '', 
    genderPronouns: formData.genderPronouns || '', 
    socialSecurityNumber: formData.socialSecurityNumber || '',
    primaryPhoneTypeId: formData.primaryPhoneTypeId || '', 
    cellPhone: formData.cellPhone || '', 
    workPhone: formData.workPhone || '', 
    homePhone: formData.homePhone || '', 
    otherPhone: formData.otherPhone || '', 
    emailAddress: formData.emailAddress || '', 
    missingSsnReasonId: formData.missingSsnReasonId || '', 
    confidentialityRequested: formData.confidentialityRequested !== null ? formData.confidentialityRequested : '', 
    communicationByText: formData.communicationByText !== null ? formData.communicationByText : '',  
    phoneCallConsent: formData.phoneCallConsent !== null ? formData.phoneCallConsent : '',  
    voiceMailConsent: formData.voiceMailConsent !== null ? formData.voiceMailConsent : '',  
    additionalEmailAddresses: formData.additionalEmailAddresses || '', 
    emailConsent: formData.emailConsent !== null ? formData.emailConsent : '',  
    prefProgNotifMethodId: formData.prefProgNotifMethodId || '', 
    communicationNotes: formData.communicationNotes || '',
    races: formData.races || [], 
    asianRaces: formData.asianRaces || [], 
    nhpiRaces: formData.nhpiRaces || [], 
    isHispanic: formData.isHispanic !== null ? formData.isHispanic : '', 
    hispanicEthnicities: formData.hispanicEthnicities || [],
    primaryLanguageId: formData.primaryLanguageId || '', 
    secondaryLanguageId: formData.secondaryLanguageId || '', 
    preferredWrittenCommLanguageId: formData.preferredWrittenCommLanguageId || '', 
    maritalStatusId: formData.maritalStatusId || '', 
    isVeteran: formData.isVeteran !== null ? formData.isVeteran : '', 
    lawfulResidentDate: formData.lawfulResidentDate || null, 
    citizenshipStatusId: formData.citizenshipStatusId || '', 
    isTobaccoUser: formData.isTobaccoUser !== null ? formData.isTobaccoUser : '', 
    hasSocialSecurityNumber: formData.hasSocialSecurityNumber !== null ? formData.hasSocialSecurityNumber : '', 
    comments: formData.comments || '',
    isApplicationUrgent: formData.isApplicationUrgent !== null ? formData.isApplicationUrgent : '', 
    applicationUrgencyTypeId: formData.applicationUrgencyTypeId || '', 
    applicantCommentsChain: formData.applicantCommentsChain || [],
    additionalAssistanceInterests: formData.additionalAssistanceInterests || [],
    clientDetailsDocumentation: formData.clientDetailsDocumentation || [],
  }
}

export const getApplicantFormData = (formData) => {
    
  const {
    lastName, firstName, middleName, nameSuffix, preferredName, 
    birthDate, birthGenderId, currentGenderIdentityId, genderPronouns, socialSecurityNumber,
    primaryPhoneTypeId, cellPhone, workPhone, homePhone, otherPhone, 
    emailAddress, missingSsnReasonId, confidentialityRequested, communicationByText, phoneCallConsent, 
    voiceMailConsent, additionalEmailAddresses, emailConsent, prefProgNotifMethodId, communicationNotes,
    races, asianRaces, nhpiRaces, isHispanic, hispanicEthnicities,
    primaryLanguageId, secondaryLanguageId, preferredWrittenCommLanguageId, maritalStatusId, isVeteran,
    lawfulResidentDate, citizenshipStatusId, isTobaccoUser, hasSocialSecurityNumber, comments, additionalAssistanceInterests,
    isApplicationUrgent, applicationUrgencyTypeId, applicantCommentsChain, clientDetailsDocumentation} = formData;

  const applicant = {
    lastName, firstName, middleName, nameSuffix, preferredName, 
    birthDate, birthGenderId, currentGenderIdentityId, genderPronouns, socialSecurityNumber,
    primaryPhoneTypeId, cellPhone, workPhone, homePhone, otherPhone, 
    emailAddress, missingSsnReasonId, confidentialityRequested, communicationByText, phoneCallConsent, 
    voiceMailConsent, additionalEmailAddresses, emailConsent, prefProgNotifMethodId, communicationNotes,
    races, asianRaces, nhpiRaces, isHispanic, hispanicEthnicities,
    primaryLanguageId, secondaryLanguageId, preferredWrittenCommLanguageId, maritalStatusId, isVeteran,
    lawfulResidentDate, citizenshipStatusId, isTobaccoUser, hasSocialSecurityNumber, comments, additionalAssistanceInterests,
    isApplicationUrgent, applicationUrgencyTypeId, applicantCommentsChain, clientDetailsDocumentation
  };

  return applicant;
}

const EligibilityApplicationApplicantStaticForm = ({clientId, existingClientConfig, eligibilityAppConfig, rwScoringConfig, disabled}) => {

  const {birthGenders, currentGenderIdentities, phoneTypes, preferredProgramNotificationMethods, 
    maritalStatuses, citizenshipStatuses, eligibilityApplicationUrgencyTypes } = useLists();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [phoneTypeList, setPhoneTypeList] = useState();
  const [birthGenderList, setBirthGenderList] = useState();
  const [currentGenderIdentityList, setCurrentGenderIdentityList] = useState();
  const [preferredProgramNotificationMethodList, setPreferredProgramNotificationMethodList] = useState();
  const [maritalStatusList, setMaritalStatusList] = useState();
  const [citizenshipStatusList, setCitizenshipStatusList] = useState();
  const [applicationUrgencyTypeList, setApplicationUrgencyTypeList] = useState();
  const [additionalAssistanceInterestList, setAdditionalAssistanceInterestList] = useState();
  
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (birthGenders) {
      setBirthGenderList(birthGenders.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (currentGenderIdentities) {
      setCurrentGenderIdentityList(currentGenderIdentities.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (phoneTypes) {
      setPhoneTypeList(phoneTypes.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (preferredProgramNotificationMethods) {
      setPreferredProgramNotificationMethodList(preferredProgramNotificationMethods.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (maritalStatuses) {
      setMaritalStatusList(maritalStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (citizenshipStatuses) {
      setCitizenshipStatusList(citizenshipStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [birthGenders, currentGenderIdentities, phoneTypes, preferredProgramNotificationMethods, maritalStatuses, citizenshipStatuses, eligibilityApplicationUrgencyTypes]);

  useEffect(() => {
    if (existingClientConfig) {
      const interests = existingClientConfig.additionalAssistanceInterests?.filter(c => c.active || (values.additionalAssistanceInterests && values.additionalAssistanceInterests.includes(c.id)))
        .map(c => {return {label: c.name, value: c.id}});
      setAdditionalAssistanceInterestList(interests || []);
    }
  }, [existingClientConfig, values.additionalAssistanceInterests]);

  useEffect(() => {
    if (eligibilityAppConfig && eligibilityApplicationUrgencyTypes) {
      const types = eligibilityApplicationUrgencyTypes.filter(
        t => t.id === values.applicationUrgencyTypeId || (eligibilityAppConfig.selectedEligibilityApplicationUrgencyTypes && eligibilityAppConfig.selectedEligibilityApplicationUrgencyTypes.includes(t.id)))
        .map(t => { return {label: t.itemName, value: t.id}});
      setApplicationUrgencyTypeList(types);
    }
  }, [eligibilityAppConfig, eligibilityApplicationUrgencyTypes, values.applicationUrgencyTypeId]);

  useEffect(() => {
    // recalc the elig in case the settings changed.  doing it here as this should always be seen when editing app
    if (!disabled) {
      let result = determineRwEligibility(values, rwScoringConfig, existingClientConfig);
      setFieldValue("rwIncomeIneligible", !result.incomeEligible);
      setFieldValue("rwResidencyIneligible", !result.residencyEligible);
      setFieldValue("rwHivStatusIneligible", !result.hivStatusEligible);
      setFieldValue("rwEligible", result.rwEligible);
    }
  }, [eligibilityAppConfig, rwScoringConfig]);

  return values && eligibilityAppConfig && (
      <Stack direction="row">
        <LotusForm>
          <LotusFormSection name="Basic Information" disabled={disabled}>
            <LotusFormItems>
              <LotusTextInput
                name="lastName"
                label="Legal Last Name"
                required
                dontCheckRequiredFields={true}
                disabled={true}
                maxLength={35}
              />
              <LotusTextInput
                name="firstName"
                label="Legal First Name"
                required
                dontCheckRequiredFields={true}
                disabled={true}
                maxLength={35}
              />
              <LotusTextInput
                name="middleName"
                label="Legal Middle Name/Initial"
                disabled={true}
                maxLength={35}
              />
              <LotusTextInput
                name="nameSuffix"
                label="Name Suffix"
                disabled={true}
                maxLength={10}
              />
              <LotusDateOfBirth
                name="birthDate"
                label="Date of Birth"
                required
                dontCheckRequiredFields={true}
                disabled={true}
              />
              {eligibilityAppConfig.showPreferredName &&
                <LotusTextInput
                  name="preferredName"
                  label="Preferred Name"
                  disabled={disabled}
                  maxLength={70}
                />
              }
              <SocialSecurityNumberFieldPanel
                hasSocialSecurityNumberFieldName="hasSocialSecurityNumber"
                socialSecurityNumberFieldName="socialSecurityNumber"
                missingSsnReasonIdFieldName="missingSsnReasonId"
                disabled={disabled}
                required
                dontCheckRequiredFields={true}
              />
            </LotusFormItems>
          </LotusFormSection>
          <LotusFormSection name="Gender" disabled={disabled}>
            <LotusFormItems>
              {birthGenderList &&
                <LotusRadioGroup
                  name="birthGenderId"
                  label="Gender Assigned at Birth"
                  items={birthGenderList}
                  disabled={disabled}
                  required
                  dontCheckRequiredFields={true}
                />
              }
              <LotusRadioGroup
                name="currentGenderIdentityId"
                label="Current Gender"
                items={currentGenderIdentityList}
                disabled={disabled}
                required
                dontCheckRequiredFields={true}
              />
              {eligibilityAppConfig.showGenderPronouns &&
                <LotusTextInput
                  name="genderPronouns"
                  label="Gender Pronoun(s)"
                  disabled={disabled}
                  maxLength={35}
                />
              }
            </LotusFormItems>
          </LotusFormSection>
          <LotusFormSection name="Contact Phone & Email" disabled={disabled}>
            <LotusFormItems>
              {phoneTypeList &&
                <LotusSelect
                  name='primaryPhoneTypeId' 
                  label='Primary Phone Type' 
                  items={phoneTypeList}
                  disabled={disabled}
                  autoSort={false}
                  required
                  dontCheckRequiredFields={true}
                />
              }
              <LotusTrueFalseSelect
                name='confidentialityRequested' 
                label='Keep communications confidential?' 
                disabled={disabled}
            />
              <LotusPhoneInput
                name='cellPhone' 
                label='Cell Phone Number' 
                disabled={disabled}
              />
              <LotusTrueFalseSelect
                name='communicationByText' 
                label='Is it okay to send text messages?' 
                disabled={disabled}
              />
              <LotusPhoneInput
                name='homePhone' 
                label='Home Phone Number' 
                disabled={disabled}
              />
              <LotusPhoneInput
                name='workPhone' 
                label='Work Phone Number' 
                disabled={disabled}
              />
              <LotusPhoneInput
                name='otherPhone' 
                label='Other Phone Number' 
                disabled={disabled}
              />
              <LotusTrueFalseSelect
                name='phoneCallConsent' 
                label='Does the client consent to receiving phone calls?' 
                disabled={disabled}
              />
              <LotusTrueFalseSelect
                name='voiceMailConsent' 
                label='Is it okay to leave voicemails?' 
                disabled={disabled}
              />
              <LotusTextInput
                name="emailAddress"
                label="Email Address"
                disabled={disabled}
                maxLength={254}
                required
                dontCheckRequiredFields={true}
              />
              <LotusTextInput
                name="additionalEmailAddresses"
                label="Additional email addresses"
                disabled={disabled}
                maxLength={254}
                multiline
                rows={5}
              />
              <LotusTrueFalseSelect
                name='emailConsent' 
                label='Is client okay with receiving emails?' 
                disabled={disabled}
              />
              {preferredProgramNotificationMethodList &&
              <LotusSelect
                name="prefProgNotifMethodId"
                label="Preferred Method of Receiving Program Notification"
                items={preferredProgramNotificationMethodList}
                disabled={disabled}
                autoSort={false}
                required
                dontCheckRequiredFields={true}
              />
              }
              {eligibilityAppConfig.showCommunicationNotes &&
                <LotusTextInput
                  name="communicationNotes"
                  label="Communication Notes"
                  disabled={disabled}
                  maxLength={500}
                  multiline
                  rows={5}
                />
              }
            </LotusFormItems>
          </LotusFormSection>
          <LotusFormSection name="Race" disabled={disabled}>
            <LotusFormItem>
              <RaceFieldPanel
                raceFieldName="races" 
                asianRaceFieldName="asianRaces" 
                nhpiRaceFieldName="nhpiRaces" 
                showAsianPicklist={true}
                showNhpiPicklist={true}
                disabled={disabled}
                required
                dontCheckRequiredFields={true}
              />
            </LotusFormItem>
          </LotusFormSection>
          <LotusFormSection name="Ethnicity" disabled={disabled}>
            <LotusFormItem>
              <EthnicityFieldPanel
                isHispanicFieldName="isHispanic" 
                hispanicEthnicityFieldName="hispanicEthnicities" 
                agencyHispanicEthnicities={existingClientConfig.selectedHispanicEthnicities}
                showHispanicEthnicities={true}
                disabled={disabled}
                required
                dontCheckRequiredFields={true}
              />
            </LotusFormItem>
          </LotusFormSection>
          <LotusFormSection name="Language" disabled={disabled}>
            <LotusFormItems>
              <AgencyLanguageSelector
                name="primaryLanguageId" 
                label="Primary Language"
                disabled={disabled}
              />
              {eligibilityAppConfig.showSecondaryLanguage &&
                <AgencyLanguageSelector
                  name="secondaryLanguageId" 
                  label="Secondary Language"
                  disabled={disabled}
                />
              }
              {eligibilityAppConfig.showPreferredWrittenCommLanguageId &&
                <AgencyLanguageSelector
                  name="preferredWrittenCommLanguageId" 
                  label="Preferred Written Communications Language"
                  disabled={disabled}
                />
              }
            </LotusFormItems>
          </LotusFormSection>
          <LotusFormSection name="Other Demographics" disabled={disabled}>
            <LotusFormItems>
              {maritalStatusList &&
                <LotusSelect
                  name='maritalStatusId' 
                  label='Marital Status' 
                  items={maritalStatusList}
                  disabled={disabled}
                  autoSort={false}
                  />
              }
              <LotusTrueFalseSelect
                name='isVeteran' 
                label='Veteran Status' 
                disabled={disabled}
              />
              {eligibilityAppConfig.showLawfulResidentDate &&
                <LotusDatePicker
                  name="lawfulResidentDate"
                  label="Date became Lawfully Present Resident"
                  disabled={disabled}
                />
              }
              {citizenshipStatusList && eligibilityAppConfig.showCitizenshipStatus &&
                <LotusSelect
                  name='citizenshipStatusId' 
                  label='Citizenship Status' 
                  items={citizenshipStatusList}
                  disabled={disabled}
                  autoSort={false}
                />
              }
              {eligibilityAppConfig.showIsTobaccoUser &&
                <LotusTrueFalseSelect
                  name='isTobaccoUser' 
                  label='Does Client Use Tobacco?' 
                  disabled={disabled}
                />
              }
            </LotusFormItems>
          </LotusFormSection>
          <LotusFormSection name="Additional Information" disabled={disabled}>
            {existingClientConfig.showAdditionalAssistanceInterests && additionalAssistanceInterestList &&
            <LotusFormItem>
              <LotusMultiSelect
                name="additionalAssistanceInterests"
                label="Additional client assistance?"
                items={additionalAssistanceInterestList}
                disabled={disabled}
                readOnlyRows={2}
              />
            </LotusFormItem>
            }
            <LotusFormItem>
              <LotusTextInput
                name="comments"
                label="Comments"
                disabled={disabled}
                maxLength={500}
                multiline
                rows={5}
              />
            </LotusFormItem>
          </LotusFormSection>
          {eligibilityAppConfig.showIsUrgentApplication &&
            <LotusFormSection name="Urgent Application" disabled={disabled}>
              <LotusFormItem>
                <LotusProgressiveDisclosure
                  name='isApplicationUrgent'
                  label="Is Client's application urgent?"
                  disabled={disabled}
                >
                  <LotusProgressiveDisclosureItem
                    itemValue='true'
                    label='Yes'
                  >
                    {applicationUrgencyTypeList && 
                    <LotusSelect
                      name='applicationUrgencyTypeId' 
                      label='Urgency Type' 
                      items={applicationUrgencyTypeList}
                      disabled={disabled}
                      autoSort={true}
                    />
                    }
                  </LotusProgressiveDisclosureItem>
                  <LotusProgressiveDisclosureItem
                    itemValue='false'
                    label='No'
                  />
                </LotusProgressiveDisclosure>
              </LotusFormItem>
            </LotusFormSection>
          }
          <LotusFormSection name="Documentation" disabled={disabled}>
            <LotusFormItem>
              <LotusSpacedBlock>
                <div>Upload Applicant Documentation</div>
              </LotusSpacedBlock>
              <ClientInfoDocumentation
                documentationListFieldName="clientDetailsDocumentation"
                allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoDetailsDocumentationTypes}
                clientId={clientId}
                disabled={disabled}
              />
            </LotusFormItem>
          </LotusFormSection>
        </LotusForm>
        <div>
          <SectionCommentsChain chainFieldName="applicantCommentsChain"/>
        </div>
      </Stack>

  );
};

export function buildApplicantStaticForm({...props}) {
  return <EligibilityApplicationApplicantStaticForm {...props}/>
}
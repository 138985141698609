import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import { FormGroup, Typography } from '@mui/material';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';

export default function AgencyPermissionsForm({ agencyId, handleSubmit }) {
  const { granteePermissionTemplates, loadGranteePermissionTemplates } = useAgencyManagement();
  const [availablePermissionTemplates, setAvailablePermissionTemplates] = useState();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (!granteePermissionTemplates) {
      loadGranteePermissionTemplates();
    }
  }, []);

  useEffect(() => {
    if (granteePermissionTemplates) {
      setupPermissionTemplates(granteePermissionTemplates);
    }
  }, [granteePermissionTemplates]);

  useEffect(() => {
    let selectedTemplates = [];
    if(availablePermissionTemplates) {
      selectedTemplates = availablePermissionTemplates.filter(p => p.agencies.find(agency => agency.id === agencyId)).map(agency => agency.id) || [];
    }
    setInitialValues({storedTemplateIds: selectedTemplates, selectedPermissionTemplateIds: selectedTemplates, errorIds: []})
  }, [availablePermissionTemplates]);

  const setupPermissionTemplates = (agencyPermissionTemplates) => {

    const sortedTemplates = [...agencyPermissionTemplates].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );

    setAvailablePermissionTemplates(sortedTemplates);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, actions) => {
        if (values?.errorIds?.length <= 0) {
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }
      }}
    >
      {({ handleSubmit, handleChange, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <LotusForm>
              <LotusFormSection name="Permissions for Agency">
                <FormGroup>
                  {availablePermissionTemplates && availablePermissionTemplates.map((node, index) => {
                    const handleCheckboxChange = (e, values, userCount) => {
                      if (!e.target.checked) {
                        if(parseInt(userCount, 10) > 0 && values?.storedTemplateIds?.find((aId) => aId === node.id) !== undefined) {
                          values.errorIds.push(e.target.value);
                        }
                      } else if (values.errorIds.includes(e.target.value)) {
                        values.errorIds.splice(values.errorIds.indexOf(e.target.value), 1);
                      }
                      handleChange(e);
                    }
                    return (
                      <div key={`program-${node.id}`}>
                        <LotusCheckbox
                          name={'selectedPermissionTemplateIds'}
                          label={node.name}
                          value={node.id}
                          checked={
                            values?.selectedPermissionTemplateIds.find((aId) => aId === node.id) !==
                            undefined
                          }
                          onChange={(e) => handleCheckboxChange(e, values, node.agencies.find(a => a.id === agencyId)?.userCount || 0)}
                        />
                        {node.userCount > 0 && values?.errorIds?.find((id) => id === node.id) !== undefined &&
                          values?.storedTemplateIds?.find((aId) => aId === node.id) !== undefined &&
                          <Typography key={node.id} variant="body2" color="error">This Template is assigned to {node.agencies.find(a => a.id === agencyId)?.userCount} agency users. 
                            Please remove this permission from agency users before removing it.
                          </Typography>
                        }
                      </div>
                    );
                  })}
                </FormGroup>
              </LotusFormSection>
              <LotusFormItem>
                <LotusButton color="primary" variant="contained" type="submit">
                  Submit
                </LotusButton>
              </LotusFormItem>
            </LotusForm>
          </Form>
        );
      }}
    </Formik>
  );
}

import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import GenericListLinker from 'components/Linkers/GenericListLinker';
import ClientInfoListSubsetLinker from '../linkers/ClientInfoListSubsetLinker';
import { useLists } from 'contexts/ListsContext';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import LotusFormSubSubSection from 'components/widgets/Forms/LotusFormSubSubSection';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import CustomListLinker from '../linkers/CustomListLinker';

export default function AgencyClientInfoForm({ agency, afterSubmit }) {

  const [initialValues, setInitialValues] = useState();
  const {
    clientRelationships, hispanicEthnicities, 
    incomeDocumentationTypes, hivDocumentationTypes, insuranceDocumentationTypes,
    loadClientInfoPicklists } = useLists();

  const {
    updateAgencyClientInfoConfig, 
    clientInfoCustomLists, loadAgencyClientInfoCustomLists, updateAgencyClientInfoCustomLists } = useAgencyManagement();

  useEffect(() => {
    if (!clientRelationships || !hispanicEthnicities || !incomeDocumentationTypes ||
        !hivDocumentationTypes || !insuranceDocumentationTypes) {
      loadClientInfoPicklists();
    }
    if (!clientInfoCustomLists) {
      loadAgencyClientInfoCustomLists();
    }
  }, []);

  useEffect(() => {
    if (agency && !initialValues) {
      const newInitialValues = {
        registration: {
          showMiddleName: agency.clientInfoConfig.registration.showMiddleName || false,
          showNameSuffix: agency.clientInfoConfig.registration.showNameSuffix || false,
          showPreferredName: agency.clientInfoConfig.registration.showPreferredName || false,
          showBirthGender: agency.clientInfoConfig.registration.showBirthGender || false,
          showGenderPronouns: agency.clientInfoConfig.registration.showGenderPronouns || false,
          showPrimaryAddress: agency.clientInfoConfig.registration.showPrimaryAddress || false,
          showPhoneNumbers: agency.clientInfoConfig.registration.showPhoneNumbers || false,
          showEmailAddress: agency.clientInfoConfig.registration.showEmailAddress || false,
          showPrimaryPhoneType: agency.clientInfoConfig.registration.showPrimaryPhoneType || false,
          showCellPhone: agency.clientInfoConfig.registration.showCellPhone || false,
          showHomePhone: agency.clientInfoConfig.registration.showHomePhone || false,
          showWorkPhone: agency.clientInfoConfig.registration.showWorkPhone || false,
          showOtherPhone: agency.clientInfoConfig.registration.showOtherPhone || false,
        },
        existingClients: {
          // Client Details - Basic Information
          externalSystems: agency.clientInfoConfig.existingClients.externalSystems || [],
          showMiddleName: agency.clientInfoConfig.existingClients.showMiddleName || false,
          showNameSuffix: agency.clientInfoConfig.existingClients.showNameSuffix || false,
          showPreferredName: agency.clientInfoConfig.existingClients.showPreferredName || false,
          showSocialSecurityNumber: agency.clientInfoConfig.existingClients.showSocialSecurityNumber || false,
          showExternalId: agency.clientInfoConfig.existingClients.showExternalId || false,
          showGrandfathered: agency.clientInfoConfig.existingClients.showGrandfathered || false,
          // Client Details - Gender
          showGenderSection: agency.clientInfoConfig.existingClients.showGenderSection || false,
          showBirthGender: agency.clientInfoConfig.existingClients.showBirthGender || false,
          showCurrentGenderIdentity: agency.clientInfoConfig.existingClients.showCurrentGenderIdentity || false,
          showGenderPronouns: agency.clientInfoConfig.existingClients.showGenderPronouns || false,
          // Client Details - Phone and Email
          showClientPhoneAndEmailSection: agency.clientInfoConfig.existingClients.showClientPhoneAndEmailSection || false,
          showPrimaryPhoneType: agency.clientInfoConfig.existingClients.showPrimaryPhoneType || false,
          showConfidentialityRequested: agency.clientInfoConfig.existingClients.showConfidentialityRequested || false,
          showCellPhone: agency.clientInfoConfig.existingClients.showCellPhone || false,
          showCommunicationByText: agency.clientInfoConfig.existingClients.showCommunicationByText || false,
          showHomePhone: agency.clientInfoConfig.existingClients.showHomePhone || false,
          showWorkPhone: agency.clientInfoConfig.existingClients.showWorkPhone || false,
          showOtherPhone: agency.clientInfoConfig.existingClients.showOtherPhone || false,
          showPhoneCallConsent: agency.clientInfoConfig.existingClients.showPhoneCallConsent || false,
          showVoiceMailConsent: agency.clientInfoConfig.existingClients.showVoiceMailConsent || false,
          showEmailAddress: agency.clientInfoConfig.existingClients.showEmailAddress || false,
          showAdditionalEmailAddresses: agency.clientInfoConfig.existingClients.showAdditionalEmailAddresses || false,
          showEmailConsent: agency.clientInfoConfig.existingClients.showEmailConsent || false,
          showPrefProgNotifMethod: agency.clientInfoConfig.existingClients.showPrefProgNotifMethod || false,
          showCommunicationNotes: agency.clientInfoConfig.existingClients.showCommunicationNotes || false,
          // Client Details - Race
          showRaceSection: agency.clientInfoConfig.existingClients.showRaceSection || false,
          showAsianPicklist: agency.clientInfoConfig.existingClients.showAsianPicklist || false,
          showNhpiPicklist: agency.clientInfoConfig.existingClients.showNhpiPicklist || false,
          // Client Details - Ethnicity
          showEthnicitySection: agency.clientInfoConfig.existingClients.showEthnicitySection || false,
          showHispanicEthnicityPicklist: agency.clientInfoConfig.existingClients.showHispanicEthnicityPicklist || false,
          selectedHispanicEthnicities: agency.clientInfoConfig.existingClients.selectedHispanicEthnicities || [],
          // Client Details - Language
          showLanguageSection: agency.clientInfoConfig.existingClients.showLanguageSection || false,
          showSecondaryLanguage: agency.clientInfoConfig.existingClients.showSecondaryLanguage || false,
          showPreferredWrittenCommLanguageId: agency.clientInfoConfig.existingClients.showPreferredWrittenCommLanguageId || false,
          // Client Details - Other Demographics
          showOtherDemographicsSection: agency.clientInfoConfig.existingClients.showOtherDemographicsSection || false,
          showMaritalStatus: agency.clientInfoConfig.existingClients.showMaritalStatus || false,
          showVeteranStatus: agency.clientInfoConfig.existingClients.showVeteranStatus || false,
          showLawfulResidentDate: agency.clientInfoConfig.existingClients.showLawfulResidentDate || false,
          showCitizenshipStatus: agency.clientInfoConfig.existingClients.showCitizenshipStatus || false,
          showIsTobaccoUser: agency.clientInfoConfig.existingClients.showIsTobaccoUser || false,
          // Client Details - Comments
          showClientDetailAdditionalInformation: agency.clientInfoConfig.existingClients.showClientDetailAdditionalInformation || false,
          showAdditionalAssistanceInterests: agency.clientInfoConfig.existingClients.showAdditionalAssistanceInterests || false,
          additionalAssistanceInterests: agency.clientInfoConfig.existingClients.additionalAssistanceInterests || [],
          // Client Details - Deceased
          showDeceasedSection: agency.clientInfoConfig.existingClients.showDeceasedSection || false,
          showDeathDate: agency.clientInfoConfig.existingClients.showDeathDate || false,
          showCauseOfDeath: agency.clientInfoConfig.existingClients.showCauseOfDeath || false,
          // Addresses
          showSecondaryAddress: agency.clientInfoConfig.existingClients.showSecondaryAddress || false,
          selectedClientRelationships: agency.clientInfoConfig.existingClients.selectedClientRelationships || [],
          showLegalGuardian: agency.clientInfoConfig.existingClients.showLegalGuardian || false,
          showCaregiverOfClient: agency.clientInfoConfig.existingClients.showCaregiverOfClient || false,
          showNextOfKin: agency.clientInfoConfig.existingClients.showNextOfKin || false,
          showPowerOfAttorney: agency.clientInfoConfig.existingClients.showPowerOfAttorney || false,
          showAwareOfHivStatus: agency.clientInfoConfig.existingClients.showAwareOfHivStatus || false,
          // Income
          useMagi: agency.clientInfoConfig.existingClients.useMagi || false,
          // Benefits
          showMedicarePolicyHolder: agency.clientInfoConfig.existingClients.showMedicarePolicyHolder || false,
          showMedicaidRx: agency.clientInfoConfig.existingClients.showMedicaidRx || false,
          showMedicareRx: agency.clientInfoConfig.existingClients.showMedicareRx || false,
          showBenefitSubsidies: agency.clientInfoConfig.existingClients.showBenefitSubsidies || false,
          // Health
          showHivStatusSubcategory: agency.clientInfoConfig.existingClients.showHivStatusSubcategory || false,
          // Insurance
          showDentalInsurance: agency.clientInfoConfig.existingClients.showDentalInsurance || false,
          showVisionInsurance: agency.clientInfoConfig.existingClients.showVisionInsurance || false,
          acaUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.acaUsesHealthPlanDatabase || false,
          employerSponsoredUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.employerSponsoredUsesHealthPlanDatabase || false,
          individualMarketplaceUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.individualMarketplaceUsesHealthPlanDatabase || false,
          medicareUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.medicareUsesHealthPlanDatabase || false,
          medicarePartDUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.medicarePartDUsesHealthPlanDatabase || false,
          medicaidUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.medicaidUsesHealthPlanDatabase || false,
          visionUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.visionUsesHealthPlanDatabase || false,
          dentalUsesHealthPlanDatabase: agency.clientInfoConfig.existingClients.dentalUsesHealthPlanDatabase || false,
        },
        // control will set these, being maintained in tables rather than json config
        clientInfoDetailsDocumentationTypes: [], 
        clientInfoAddressDocumentationTypes: [], 
        clientInfoHivDocumentationTypes: [],
        clientInfoIncomeDocumentationTypes: [],
        clientInfoInsuranceDocumentationTypes: [],
        clientInfoIncomeSourceTypes: []
      };
      setInitialValues(newInitialValues);
    }
  }, [agency]);

  return clientInfoCustomLists && (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await updateAgencyClientInfoConfig({
            registration: values.registration, 
            existingClients: values.existingClients
          });
          await updateAgencyClientInfoCustomLists({
            clientInfoDetailsDocumentationTypes: values.clientInfoDetailsDocumentationTypes,
            clientInfoAddressDocumentationTypes: values.clientInfoAddressDocumentationTypes,
            clientInfoHivDocumentationTypes: values.clientInfoHivDocumentationTypes,
            clientInfoIncomeDocumentationTypes: values.clientInfoIncomeDocumentationTypes,
            clientInfoInsuranceDocumentationTypes: values.clientInfoInsuranceDocumentationTypes,
            clientInfoIncomeSourceTypes: values.clientInfoIncomeSourceTypes
          });
          if (afterSubmit) {
            await afterSubmit();
          }
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Client Info Config for Agency'>
                <LotusForm>
                  <LotusFormSection name='Registration'>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showMiddleName"
                        value={values.registration.showMiddleName}
                        label="Show Middle Name"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showNameSuffix"
                        value={values.registration.showNameSuffix}
                        label="Show Name Suffix"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showPreferredName"
                        value={values.registration.showPreferredName}
                        label="Show Preferred Name"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                        <LotusSwitch
                          name="registration.showBirthGender"
                          value={values.registration.showBirthGender}
                          label="Show Birth Gender"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showGenderPronouns"
                        value={values.registration.showGenderPronouns}
                        label="Show Gender Pronouns"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showPrimaryAddress"
                        value={values.registration.showPrimaryAddress}
                        label="Show Primary Address"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showEmailAddress"
                        value={values.registration.showEmailAddress}
                        label="Show Email Address"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showPhoneNumbers"
                        value={values.registration.showPhoneNumbers}
                        label="Show Phone Numbers"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    {values.registration.showPhoneNumbers &&
                    <>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showPrimaryPhoneType"
                        value={values.registration.showPrimaryPhoneType}
                        label="Show Primary Phone Type"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showCellPhone"
                        value={values.registration.showCellPhone}
                        label="Show Cell Phone"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showHomePhone"
                        value={values.registration.showHomePhone}
                        label="Show Home Phone"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showWorkPhone"
                        value={values.registration.showWorkPhone}
                        label="Show Work Phone"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusSwitch
                        name="registration.showOtherPhone"
                        value={values.registration.showOtherPhone}
                        label="Show Other Phone"
                        labelPlacement="start"
                      />
                    </LotusFormItem>
                    </>
                    }
                  </LotusFormSection>
                  <LotusFormSection name='Existing Clients'>
                    <LotusFormSubSection name="Client Details">
                      <LotusFormSubSubSection name='Basic Information'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showMiddleName"
                            value={values.existingClients.showMiddleName}
                            label="Show Middle Name"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showNameSuffix"
                            value={values.existingClients.showNameSuffix}
                            label="Show Name Suffix"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showPreferredName"
                            value={values.existingClients.showPreferredName}
                            label="Show Preferred Name"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showSocialSecurityNumber"
                            value={values.existingClients.showSocialSecurityNumber}
                            label="Show Social Security Number"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showExternalId"
                            value={values.existingClients.showExternalId}
                            label="Show External System IDs"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showGrandfathered"
                            value={values.existingClients.showGrandfathered}
                            label="Show GrandFathered Status"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                        <CustomListLinker
                          fieldName='clientInfoDetailsDocumentationTypes' 
                          allListItems={clientInfoCustomLists.clientInfoDetailsDocumentationTypes}
                          fieldDescription="Client Details Documentation Type"
                          listHeader="Client Details Documentation Types"
                        />
                      </LotusFormItem>
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Gender'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showGenderSection"
                            value={values.existingClients.showGenderSection}
                            label="Show Gender Section"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showGenderSection &&
                          <>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showBirthGender"
                              value={values.existingClients.showBirthGender}
                              label="Show Birth Gender"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showCurrentGenderIdentity"
                              value={values.existingClients.showCurrentGenderIdentity}
                              label="Show Current Gender Identity"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showGenderPronouns"
                              value={values.existingClients.showGenderPronouns}
                              label="Show Gender Pronouns"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          </>
                        }
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Contact Phone & Email'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showClientPhoneAndEmailSection"
                            value={values.existingClients.showClientPhoneAndEmailSection}
                            label="Show Client Phone and Email Section"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showClientPhoneAndEmailSection &&
                          <>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showPrimaryPhoneType"
                              value={values.existingClients.showPrimaryPhoneType}
                              label="Show Primary Phone Type"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showConfidentialityRequested"
                              value={values.existingClients.showConfidentialityRequested}
                              label="Show Confidentiality Requested"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showCellPhone"
                              value={values.existingClients.showCellPhone}
                              label="Show Cell Phone"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showCommunicationByText"
                              value={values.existingClients.showCommunicationByText}
                              label="Show Communication By Text"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showHomePhone"
                              value={values.existingClients.showHomePhone}
                              label="Show Home Phone"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showWorkPhone"
                              value={values.existingClients.showWorkPhone}
                              label="Show Work Phone"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showOtherPhone"
                              value={values.existingClients.showOtherPhone}
                              label="Show Other Phone"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showPhoneCallConsent"
                              value={values.existingClients.showPhoneCallConsent}
                              label="Show Phone Call Consent"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showVoiceMailConsent"
                              value={values.existingClients.showVoiceMailConsent}
                              label="Show Voice Mail Consent"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showEmailAddress"
                              value={values.existingClients.showEmailAddress}
                              label="Show Email Address"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showAdditionalEmailAddresses"
                              value={values.existingClients.showAdditionalEmailAddresses}
                              label="Show Additional Email Addresses"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showEmailConsent"
                              value={values.existingClients.showEmailConsent}
                              label="Show Email Consent"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showPrefProgNotifMethod"
                              value={values.existingClients.showPrefProgNotifMethod}
                              label="Show Preferred Method of Receiving Program Notifications"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showCommunicationNotes"
                              value={values.existingClients.showCommunicationNotes}
                              label="Show Communication Notes"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          </>
                        }
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Race'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showRaceSection"
                            value={values.existingClients.showRaceSection}
                            label="Show Race Section"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showRaceSection &&
                          <>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showAsianPicklist"
                              value={values.existingClients.showAsianPicklist}
                              label="Show Asian Picklist"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showNhpiPicklist"
                              value={values.existingClients.showNhpiPicklist}
                              label="Show Native Hawaiian Pacific Islander Picklist"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          </>
                        }
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Ethnicity'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showEthnicitySection"
                            value={values.existingClients.showEthnicitySection}
                            label="Show Ethnicity Section"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showEthnicitySection &&
                          <>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showHispanicEthnicityPicklist"
                              value={values.existingClients.showHispanicEthnicityPicklist}
                              label="Show Hispanic Ethnicity Picklist"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          {hispanicEthnicities && values.existingClients.showHispanicEthnicityPicklist &&
                          <LotusFormItem>
                            <ClientInfoListSubsetLinker 
                              name='existingClients.selectedHispanicEthnicities' 
                              masterList={hispanicEthnicities}
                              title="Hispanic Ethnicities for Agency"
                            />
                          </LotusFormItem>
                          }
                          </>
                        }
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Language'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showLanguageSection"
                            value={values.existingClients.showLanguageSection}
                            label="Show Language Section"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showLanguageSection &&
                          <>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showSecondaryLanguage"
                              value={values.existingClients.showSecondaryLanguage}
                              label="Show Secondary Language"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showPreferredWrittenCommLanguageId"
                              value={values.existingClients.showPreferredWrittenCommLanguageId}
                              label="Show Preferred Written Communications Language"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          </>
                        }
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Other Demographics'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showOtherDemographicsSection"
                            value={values.existingClients.showOtherDemographicsSection}
                            label="Show Other Demographics Section"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showOtherDemographicsSection &&
                          <>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showMaritalStatus"
                              value={values.existingClients.showMaritalStatus}
                              label="Show Marital Status"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showVeteranStatus"
                              value={values.existingClients.showVeteranStatus}
                              label="Show Veteran Status"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showLawfulResidentDate"
                              value={values.existingClients.showLawfulResidentDate}
                              label="Show Date became Lawfully Present Resident"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showCitizenshipStatus"
                              value={values.existingClients.showCitizenshipStatus}
                              label="Show Citizenship Status"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showIsTobaccoUser"
                              value={values.existingClients.showIsTobaccoUser}
                              label="Show Tobacco Use"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          </>
                        }
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Additional Information'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showClientDetailAdditionalInformation"
                            value={values.existingClients.showClientDetailAdditionalInformation}
                            label="Show Additional Information"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showClientDetailAdditionalInformation && 
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showAdditionalAssistanceInterests"
                              value={values.existingClients.showAdditionalAssistanceInterests}
                              label="Show Additional Assistance Interests"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                        }
                        {values.existingClients.showClientDetailAdditionalInformation && values.existingClients.showAdditionalAssistanceInterests &&
                        <LotusFormItem>
                          <GenericListLinker
                            name="existingClients.additionalAssistanceInterests"
                            title="Additional Assistance Interests"
                            itemName="Assistance Type"
                          />
                        </LotusFormItem>
                        }
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Deceased'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="existingClients.showDeceasedSection"
                            value={values.existingClients.showDeceasedSection}
                            label="Show Deceased Section"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.existingClients.showDeceasedSection &&
                          <>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showDeathDate"
                              value={values.existingClients.showDeathDate}
                              label="Show Death Date"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          <LotusFormItem>
                            <LotusSwitch
                              name="existingClients.showCauseOfDeath"
                              value={values.existingClients.showCauseOfDeath}
                              label="Show Cause of Death"
                              labelPlacement="start"
                            />
                          </LotusFormItem>
                          </>
                        }
                      </LotusFormSubSubSection>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Addresses'>
                      <LotusFormItem>
                      <LotusSwitch
                          name="existingClients.showSecondaryAddress"
                          value={values.existingClients.showSecondaryAddress}
                          label="Show Secondary Address"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <CustomListLinker
                          fieldName='clientInfoAddressDocumentationTypes' 
                          allListItems={clientInfoCustomLists.clientInfoAddressDocumentationTypes}
                          fieldDescription="Address Documentation Type"
                          listHeader="Address Documentation Types"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Personal Contacts'>
                      <LotusFormItem>
                      <LotusSwitch
                          name="existingClients.showLegalGuardian"
                          value={values.existingClients.showLegalGuardian}
                          label="Show Legal Guardian Selection"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                      <LotusSwitch
                          name="existingClients.showCaregiverOfClient"
                          value={values.existingClients.showCaregiverOfClient}
                          label="Show Caregiver of Client Selection"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                      <LotusSwitch
                          name="existingClients.showNextOfKin"
                          value={values.existingClients.showNextOfKin}
                          label="Show Next of Kin Selection"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                      <LotusSwitch
                          name="existingClients.showPowerOfAttorney"
                          value={values.existingClients.showPowerOfAttorney}
                          label="Show Power of Attorney Selection"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                      <LotusSwitch
                          name="existingClients.showAwareOfHivStatus"
                          value={values.existingClients.showAwareOfHivStatus}
                          label="Show Aware of HIV Status Selection"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <ClientInfoListSubsetLinker 
                          name='existingClients.selectedClientRelationships' 
                          masterList={clientRelationships}
                          title="Client Relationships for Agency"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Income'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.useMagi"
                          value={values.existingClients.useMagi}
                          label="Use MAGI"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <CustomListLinker
                          fieldName='clientInfoIncomeSourceTypes' 
                          allListItems={clientInfoCustomLists.clientInfoIncomeSourceTypes}
                          fieldDescription="Income Source Type"
                          listHeader="Income Source Types"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <CustomListLinker
                          fieldName='clientInfoIncomeDocumentationTypes' 
                          allListItems={clientInfoCustomLists.clientInfoIncomeDocumentationTypes}
                          fieldDescription="Income Documentation Type"
                          listHeader="Income Documentation Types"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Health'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.showHivStatusSubcategory"
                          value={values.existingClients.showHivStatusSubcategory}
                          label="Show HIV Status Subcategory"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      {values.existingClients.showHivStatusSubcategory  &&
                        <LotusFormItem>
                          <CustomListLinker
                            fieldName='clientInfoHivDocumentationTypes' 
                            allListItems={clientInfoCustomLists.clientInfoHivDocumentationTypes}
                            fieldDescription="HIV Documentation Type"
                            listHeader="HIV Documentation Types"
                          />
                        </LotusFormItem>
                      }
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Insurance'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.showDentalInsurance"
                          value={values.existingClients.showDentalInsurance}
                          label="Show Dental Insurance"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.showVisionInsurance"
                          value={values.existingClients.showVisionInsurance}
                          label="Show Vision Insurance"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.acaUsesHealthPlanDatabase"
                          value={values.existingClients.acaUsesHealthPlanDatabase}
                          label="Private Insurance - ACA Exchange Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.employerSponsoredUsesHealthPlanDatabase"
                          value={values.existingClients.employerSponsoredUsesHealthPlanDatabase}
                          label="Private Insurance - Employer Sponsored Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.individualMarketplaceUsesHealthPlanDatabase"
                          value={values.existingClients.individualMarketplaceUsesHealthPlanDatabase}
                          label="Private Insurance - Individual Marketplace Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.medicareUsesHealthPlanDatabase"
                          value={values.existingClients.medicareUsesHealthPlanDatabase}
                          label="Medicare Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.medicarePartDUsesHealthPlanDatabase"
                          value={values.existingClients.medicarePartDUsesHealthPlanDatabase}
                          label="Medicare Part D Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.medicaidUsesHealthPlanDatabase"
                          value={values.existingClients.medicaidUsesHealthPlanDatabase}
                          label="Medicaid Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.visionUsesHealthPlanDatabase"
                          value={values.existingClients.visionUsesHealthPlanDatabase}
                          label="Vision Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.dentalUsesHealthPlanDatabase"
                          value={values.existingClients.dentalUsesHealthPlanDatabase}
                          label="Dental Uses Health Plan Database"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <CustomListLinker
                          fieldName='clientInfoInsuranceDocumentationTypes' 
                          allListItems={clientInfoCustomLists.clientInfoInsuranceDocumentationTypes}
                          fieldDescription="Insurance Documentation Type"
                          listHeader="Insurance Documentation Types"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.showMedicaidRx"
                          value={values.existingClients.showMedicaidRx}
                          label="Show Medicaid Rx Fields"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.showMedicarePolicyHolder"
                          value={values.existingClients.showMedicarePolicyHolder}
                          label="Show Medicare Policy Holder Field"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.showMedicareRx"
                          value={values.existingClients.showMedicareRx}
                          label="Show Medicare Rx Fields"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="existingClients.showBenefitSubsidies"
                          value={values.existingClients.showBenefitSubsidies}
                          label="Show Benefit Subsidies"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <GenericListLinker
                          name="existingClients.externalSystems"
                          title="External Systems"
                          itemName="System"
                        />
                      </LotusFormItem>                      
                    </LotusFormSubSection>
                  </LotusFormSection>
                  <LotusFormItem lastOne>
                    <LotusButton type="submit">
                      Submit
                    </LotusButton>
                  </LotusFormItem>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

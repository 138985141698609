import React, { useEffect } from 'react';
import { useState } from 'react';
import { useField } from 'formik';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import { FormGroup } from '@mui/material';

export default function ClientInfoListSubsetLinker({ name, title, masterList }) {
  const [ allMasterItems, setAllMasterItems] = useState();

  const [ configField ] = useField(name);

  useEffect(() => {
    if (masterList) {
      let lst = [...masterList];
      lst = lst.sort((a, b) => {
        return a.itemName > b.itemName ? 1 : -1;
      });
      setAllMasterItems(lst);
    }
  }, [masterList]);

  return (
      <>
      <div>{title}</div>
      <FormGroup>
        {allMasterItems &&
          allMasterItems.map((node) => {
            return (
              <LotusCheckbox
                key={`masteritm-${node.id}`}
                name={configField.name}
                label={node.itemName}
                value={node.id}
                checked={configField.value.find((aId) => aId === node.id) !== undefined}
              />
            );
          })}
      </FormGroup>
      </>
  );
}

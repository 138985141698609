import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAuthState } from 'contexts/AuthProvider';
import PageSection from 'components/widgets/Layout/LotusPageSection';
import LotusPageSubSection from 'components/widgets/Layout/LotusPageSubSection';
import { useEligibilityTimeline } from 'contexts/EligibilityTimelineContext';
import { ELIGIBILITY_APPLICATION_STATUS, TIMELINE_STEP, TIMELINE_COLOR } from 'lib/eligibilityEnums';
import { useUserAgency } from 'contexts/UserAgencyContext';
import EligibiityStepper from './EligibilityStepper';
import { Button, createTheme, Divider, Stack, ThemeProvider, Typography, useTheme } from '@mui/material';
import { useEligibilityApplication } from 'contexts/EligibilityApplicationContext';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LotusShowHideButton from 'components/widgets/Forms/LotusShowHideButton';
import { useLists } from 'contexts/ListsContext';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { Table } from '@lotus/components';
import LotusFileViewer from 'components/widgets/LotusFileViewer';

function EligibilityIndicator({eligible}) {
  const theme = useTheme();

  return (
    <Stack direction="column" justifyContent="flex-start" gap={1} style={{paddingTop: "12px 0"}}>
      <Typography variant="body2">Ryan White Eligibility</Typography>
      {eligible ?
        <div style={{display: 'inline-flex', padding: '2px 4px', background: theme.palette.success.light,  alignItems: 'center', gap: 4, width: 'fit-content' }}>
          <CheckCircleIcon style={{width: '16px', color: theme.palette.success.dark}} />
          <Typography variant="body2">ELIGIBLE</Typography>
        </div>
        :
        <div style={{display: 'inline-flex', padding: '2px 4px', background: theme.palette.error.light,  alignItems: 'center', gap: 4, width: 'fit-content' }}>
          <CancelIcon style={{width: '16px', color: theme.palette.error.dark}} />
          <Typography variant="body2">INELIGIBLE</Typography>
        </div>
      }
    </Stack>
  );
}

function EligibilityDetailsSection({title, children}) {
  const theme = useTheme();

  return (
    <div style={{marginBottom: 20, paddingTop: 12, paddingBottom: 12, borderRadius: "8px", border: "1px solid rgba(0, 0, 0, 0.23)"}}>
      <div style={{paddingLeft: 16}}>
        <Typography variant="subtitle1">{title}</Typography>
      </div>
      <Divider style={{color: theme.palette.other.divider, margin: "12px 0"}}/>
      <div style={{padding: "0 16px 12px 16px"}}>
        {children}
      </div>
    </div>
  );
}

function EligibilityDetailsDocuments({details}) {
  const { clientId } = useParams();
  const [ allDocuments, setAllDocuments ] = useState();
  const { userAgencyClientInfoCustomLists, loadUserAgencyClientInfoCustomLists } = useUserAgency();
  const [ documentToView, setDocumentToView] = useState();

  useEffect(() => {
    if (!userAgencyClientInfoCustomLists) {
      loadUserAgencyClientInfoCustomLists();
    }
  }, []);

  useEffect(() => {
    const allDocs = []; 
    if (details && userAgencyClientInfoCustomLists) {
      if (details.income?.documentation?.length > 0) {
        details.income.documentation.forEach(d => {
          allDocs.push({
            category: 'Income',
            type: userAgencyClientInfoCustomLists.clientInfoIncomeDocumentationTypes.find(dt => dt.id === d.documentationTypeId)?.name,
            dateUploaded: d.dateUploaded,
            files: d.documentationFiles
          });
        });
      }
      if (details.residency?.documentation?.length > 0) {
        details.residency.documentation.forEach(d => {
          allDocs.push({
            category: 'Residency',
            type: userAgencyClientInfoCustomLists.clientInfoAddressDocumentationTypes.find(dt => dt.id === d.documentationTypeId)?.name,
            dateUploaded: d.dateUploaded,
            files: d.documentationFiles
          });
        });
      }
      if (details.hivStatus?.documentation?.length > 0) {
        details.hivStatus.documentation.forEach(d => {
          allDocs.push({
            category: 'HIV Status',
            type: userAgencyClientInfoCustomLists.clientInfoHivDocumentationTypes.find(dt => dt.id === d.documentationTypeId)?.name,
            dateUploaded: d.dateUploaded,
            files: d.documentationFiles
          });
        });
      }
    }
    setAllDocuments(allDocs);
  }, [details, userAgencyClientInfoCustomLists]);

  const docColumns = [
    {
      label: 'Type',
      name: 'type',
      options: {
        customBodyRender: (value, tableMeta, ) => {
          let idx = tableMeta.rowIndex;
          return (
            <div
              onClick={() => viewDocumentForRow(idx)}
              style={{ cursor: 'pointer', textDecoration: 'underline', color: '#006B79', width: 'fit-content'}}
            >
              {value}
            </div>
          );
        },
        setCellHeaderProps: value => {
          return {
            style: {
              width: '50%',
            },
          };
        },
      },
    },
    {
      label: 'Category',
      name: 'category'
    },
    {
      label: 'Date Uploaded',
      name: 'dateUploaded',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let result;
            if (obj1.data === obj2.data) { result = 0; }
            let val1 = moment(obj1.data, 'MM/DD/YYYY');
            let val2 = moment(obj2.data, 'MM/DD/YYYY');
            result = (val1 < val2) ? (order === 'asc' ? -1 : 1) : (order === 'asc' ? 1 : -1);
            return result;
          };
        }
      }
    },
  ];

  const viewDocumentForRow = async (idx) => {
    const doc = allDocuments[idx];
    setDocumentToView(doc);                  
  };

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  return allDocuments && (
    <>
      <Table
        columns={docColumns}
        data={allDocuments}
        options={{
          print: false,
          download: false,
          filter: false,
          viewColumns: false,
          search: false,
          pagination: false,
          elevation: 0,
          textLabels: {
            body: {
              noMatch: "No documents available"
            }
          }
        }}
      />
      <ActionDialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(documentToView)}
        content={
          (documentToView && 
            <LotusFileViewer
              clientId={clientId} 
              subfolderName="documents"
              title={documentToView.type || ''} 
              file={documentToView.files} 
              handleClose={closeDocumentView}
            />
          )
        }
      />
    </>
  );    
}

function EligibilityDetailsField({label, value}) {
  return (
    <Stack direction="column" justifyContent="flex-start" gap={1} style={{paddingTop: "12px 0"}}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Stack>
  );
}

function EligibilityDetails({details}) {
  const { userAgencyClientInfoConfig } = useUserAgency();
  const { hivDiseaseStages, loadClientInfoPicklists } = useLists();
  
  useEffect(() => {
    if (!hivDiseaseStages) {
      loadClientInfoPicklists();
    }
  }, []);

  return (
    <div style={{paddingLeft: 16, paddingRight: 16}}>
      <EligibilityDetailsSection title="Documents">
        <EligibilityDetailsDocuments details={details}/>
      </EligibilityDetailsSection>
      {details.income &&
        <EligibilityDetailsSection title="Income">
          <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
            <EligibilityIndicator eligible={!details.income.rwIneligible}/>
            <EligibilityDetailsField label="FPL" value={(userAgencyClientInfoConfig.existingClients.useMagi ? details.income.magiHouseholdFederalPovertyLevelPct : details.income.householdFederalPovertyLevelPct) || '--'} />
            <EligibilityDetailsField label="Monthly Income" value={userAgencyClientInfoConfig.existingClients.useMagi ? details.income.monthlyHouseholdModifiedAdjustedGrossIncome : details.income.monthlyHouseholdGrossIncome} />
            <EligibilityDetailsField label="Household" value={details.income.totalHouseholdSize} />
          </Stack>
        </EligibilityDetailsSection>
      }
      {details.residency &&
        <EligibilityDetailsSection title="Residency">
          <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
            <EligibilityIndicator eligible={!details.residency.rwIneligible}/>
            <EligibilityDetailsField label="Address" value={details.residency.primaryAddress} />
            <EligibilityDetailsField label="City" value={details.residency.primaryAddressCity} />
            <EligibilityDetailsField label="State" value={details.residency.primaryAddressState} />
            <EligibilityDetailsField label="County" value={details.residency.primaryAddressCounty} />
          </Stack>
        </EligibilityDetailsSection>
      }
      {details.hivStatus &&
        <EligibilityDetailsSection title="HIV Status">
          <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap' }} gap={3}>
            <EligibilityIndicator eligible={!details.hivStatus.rwIneligible}/>
            <EligibilityDetailsField label="Current Disease State" value={hivDiseaseStages && details.hivStatus.hivStatusId ? hivDiseaseStages.find(s => s.id === details.hivStatus.hivStatusId)?.itemName : '--'} />
            <EligibilityDetailsField label="Interviewer Signature" value={details.hivStatus.hivCertificationSignature} />
            <EligibilityDetailsField label="Date Signed" value={details.hivStatus.hivCertificationDate} />
          </Stack>
        </EligibilityDetailsSection>
      }
    </div>
  );
}

function HistoricalEvents({hist, detailRetriever, EventDetailDisplayer}) {
  const theme = useTheme();
  const [eventDetailsToShow, setEventDetailsToShow] = useState();

  function hideAppDetails(appId) {
    let newShowList = (eventDetailsToShow || []).filter(x => x !== appId);
    setEventDetailsToShow(newShowList);
  }

  function showAppDetails(appId) {
    let newShowList = (eventDetailsToShow || []).filter(x => x !== appId);
    newShowList.push(appId);
    setEventDetailsToShow(newShowList);
  }

  return hist && hist.periods && (
    hist.periods.map((period) => (
      <div key={period.startDate}>
        <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 8, paddingBottom: 8, background: 'rgba(0, 0, 0, 0.08)' }}>
          <Typography variant="overline" style={{color: 'rgba(0, 0, 0, 0.68)'}}>Eligibility Period: {period.startDate} - {period.endDate || 'Current'}</Typography>
        </div>
        {period.events && period.events.length > 0 && period.events.map((evt, evtIdx) => {
          return (
            <Stack key={evt.createDate} direction="row">
              <div style={{ width: 116, paddingTop: 18, paddingRight: 4, textAlign: 'right' }}>
                <Typography variant="body1">{evt.date}</Typography>
              </div>
              <div style={{ width: 28, paddingLeft: 4, paddingRight: 4, flexDirection: 'column', justifyContent: 'flex-start', display: 'flex' }}>
                <div style={{ height: 18, justifyContent: 'center', display: 'flex' }}>
                  {(evtIdx !== 0 || period.events.length === 1) &&
                    <div style={{ width: 2, alignSelf: 'stretch', background: theme.palette.other.divider }} />
                  }
                </div>
                <div>
                  {evt.icon === 'ending' && <WarningAmberIcon style={{width: 20, color: theme.palette.warning.main}} />}
                  {evt.icon === 'ended' && <CancelIcon style={{width: 20, color: theme.palette.error.dark}} />}
                  {evt.icon === 'started' && <CheckCircleIcon style={{width: 20, color: theme.palette.success.main}} />}
                  {evt.icon === 'renewed' && <ChangeCircleIcon style={{width: 20, color: theme.palette.success.main}} />}
                  {evt.icon === 'update' && <ChangeHistoryIcon style={{width: 20, color: theme.palette.success.main}} />}
                </div>
                <div style={{ alignSelf: 'stretch', justifyContent: 'center', display: 'flex', flex: '1 1 0' }}>
                  {(evtIdx !== 0 || period.events.length !== 1) &&
                    <div style={{ width: 2, alignSelf: 'stretch', background: theme.palette.other.divider }} />
                  }
                </div>
              </div>
              <div style={{ borderBottom: '1px rgba(0, 0, 0, 0.12) solid', width: '100%'}}>
                <Stack direction="row" justifyContent='space-between' alignItems='center'>
                  <div style={{ paddingTop: 18, paddingBottom: 18, paddingLeft: 4 }}>
                    <Typography variant="body1">{evt.label}</Typography>
                    {evt.subtext && 
                      <Typography variant="body2" style={{color: 'rgba(0, 0, 0, 0.60)'}}>{evt.subtext}</Typography>
                    }
                  </div>
                  {!evt.detailsUnavailable &&
                    <div>
                      <LotusShowHideButton 
                        onShow={() => {showAppDetails(evt.id); detailRetriever(evt.id);}} 
                        onHide={() => hideAppDetails(evt.id)}/>
                    </div>
                  }
                </Stack>
                {Boolean(eventDetailsToShow) && eventDetailsToShow.includes(evt.id) && evt.details && 
                  <div>
                    <EventDetailDisplayer details={evt.details} />
                  </div>
                }
              </div>
            </Stack>
        )})}
      </div>
    ))
  );
}

export default function EligibilityTimeline() {
  const { clientId } = useParams();
  const { verifyPermission } = useAuthState();
  const { userAgencyEligibilityConfig } = useUserAgency();
  const { 
    timeline, applications, eligibilityPeriods, appEligDetails, 
    loadEligibilityTimeline, loadApplicationsForClient, loadEligibilityPeriodsForClient, loadEligibilityDetailsForApplication } = useEligibilityTimeline();
  const [stepperConfig, setStepperConfig] = useState();
  const [reviewDetailConfig, setReviewDetailConfig] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [appId, setAppId] = useState();
  const [showRequestAdap, setShowRequestAdap] = useState();
  const navigate = useNavigate();
  const { createEligibilityApplication } = useEligibilityApplication();
  const [ eligibilityHistory, setEligibilityHistory ] = useState();

  useEffect(() => {
    if (clientId) {
      loadEligibilityTimeline(clientId);
      loadApplicationsForClient(clientId);
      loadEligibilityPeriodsForClient(clientId);
    }
  }, [clientId]);

  useEffect(() => {
    buildStepperConfigs();
    setShowRequestAdap(
      (verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER) || verifyPermission(PERMISSIONS.APPLICATION_COMPLETER)) && 
       timeline && timeline.canRequestAdap);
  }, [timeline]);

  useEffect(() => {
    if (applications && eligibilityPeriods) {

      const getUpdateSubtext = (income, residency, hiv) => {
        const changes = [];
        if (income) { changes.push('Income'); }
        if (residency) { changes.push('Residency'); }
        if (hiv) { changes.push('HIV Status') }
        if (changes.length === 1) {
          return `${changes[0]} Change`;
        }
        if (changes.length === 2) {
          return `${changes[0]} and ${changes[1]} Change`;
        }
        if (changes.length === 3) {
          return `${changes[0]}, ${changes[1]} and ${changes[2]} Change`;
        }
        return '';
      }

      let history = {periods: []};
      let sortedPeriods = [...eligibilityPeriods.sort((a,b) => moment(a.endDate,'MM/DD/YYYY') < moment(b.endDate,'MM/DD/YYYY') ? 1 : -1)];
      sortedPeriods.forEach(p => history.periods.push({...p}));

      let sortedApps = applications.sort((a,b) => moment(a.createDate,'MM/DD/YYYY HH:mm:ss') < moment(b.createDate,'MM/DD/YYYY HH:mm:ss') ? 1 : -1);
      sortedApps.forEach(a => {
        if (a.statusKey === 'completed' || a.statusKey === 'terminated') {
          let period = history.periods.find(p => moment(p.startDate,'MM/DD/YYYY') <= moment(a.statusEffectiveDate, 'MM/DD/YYYY') && moment(p.endDate,'MM/DD/YYYY') >= moment(a.statusEffectiveDate, 'MM/DD/YYYY'));
          if (period) {
            let label = null, subtext = null, icon = null, detailsUnavailable = undefined, overrideDate = null;
            if (a.isDeletingAddressesUpdate ) {
              label = 'Eligibility Data Update';
              icon = 'update';
              subtext = 'Address Update Removed';
              detailsUnavailable = true;
            }
            else if (a.isDeceased) {
              label = 'Eligibility Ended';
              icon = 'ended';
              subtext = 'Client Deceased';
              detailsUnavailable = true;
            }
            else if (a.isInitialApplication && !a.isInterimUpdate && a.rwEligible) {
              label = 'Eligibility Started';
              icon = 'started';
            }
            else if (a.isInitialApplication && !a.isInterimUpdate && !a.rwEligible) {
              label = 'Ryan White Ineligible';
              icon = 'ended';
            }
            else if (a.isRecertificationApplication && !a.isInterimUpdate && a.rwEligible) {
              label = 'Eligibility Renewed';
              icon = 'renewed';
            }
            else if (a.isRecertificationApplication && !a.isInterimUpdate && !a.rwEligible) {
              label = 'Eligibility Ended';
              subtext = 'Client is no longer Ryan White Eligible';
              icon = 'ended';
            }
            else if (a.isInterimUpdate && a.rwEligible && (a.hasIncomeUpdates || a.hasResidencyUpdates || a.hasHealthUpdates)) {
              label = 'Eligibility Data Update';
              icon = 'update';
              subtext = getUpdateSubtext(a.hasIncomeUpdates, a.hasResidencyUpdates, a.hasHealthUpdates);
            }
            else if (a.isInterimUpdate && !a.rwEligible && a.eligibilityEndDate) {
              label = 'Eligibility Ended';
              icon = 'ended';
              subtext = getUpdateSubtext(a.hasIncomeUpdates, a.hasResidencyUpdates, a.hasHealthUpdates);
              overrideDate = a.eligibilityEndDate;
            }

            if (label) {
              if (!period.events) {
                period.events = [];
              }
              period.events.push({
                label,
                icon,
                subtext,
                date: overrideDate || a.statusEffectiveDate,
                createDate: a.createDate,
                id: a.id,
                detailsUnavailable
              });
            }
          }
        }
      });

      // Deal with address changes that cause end of eligibility that then get removed, as ultimately eligibility was not affected
      history.periods.forEach(period => {
        if (!period.events) {
          period.events = [];
        }
        if (period.events.length > 0) {
          let newEvents = []
          for (let i = 0; i < period.events.length; ) {
            if (period.events[i].subtext === 'Address Update Removed' && (i+1) < period.events.length) {
              // Leave this one and the next one out
              i = i + 2;
            } else {
              newEvents.push(period.events[i]);
              i++;
            }
          }
          period.events = newEvents;
        }
      });

      // Deal with address changes that haven't reached the effective date yet
      history.periods.forEach(period => {
        if (period.events.length > 0 && period.events[0].label === 'Eligibility Ended' && moment(period.endDate,'MM/DD/YYYY') > moment().startOf('day')) {
          period.events.shift();
        }
      });

      // For each eligibility period, if it's in the past without an ended event being the last one, add that
      history.periods.forEach(period => {
        if (period.events.length > 0 && period.events[0].label !== 'Eligibility Ended' && moment(period.endDate,'MM/DD/YYYY') < moment().startOf('day')) {
          period.events.unshift({
            label: 'Eligibility Ended',
            icon: 'ended',
            subtext: 'Ryan White Eligibility Expired',
            detailsUnavailable: true,
            date: period.endDate,
            createDate: moment().format('MM/DD/YYYY HH:mm:ss')
          });
        }
      });
      setEligibilityHistory(history);
    }
  }, [applications, eligibilityPeriods]);

  const disableTimelineButtons = (configs) => {
    configs.forEach((cfg) => {
      cfg.buttonEnabled = false;
    });
  }

  const getStatusDate = (statusKey) => {
    if (timeline.statusHistory && timeline.statusHistory.length > 0) {
      let status = timeline.statusHistory.find((statusHistory) => statusHistory.statusKey === statusKey);
      return status?.createdDate;
    }
  };


  const getApplicationStepConfig = (timeline) => {

    // By default, this is the NOT_STARTED config
    let cfg = {
      key: TIMELINE_STEP.APPLICATION_STEP,
      header: 'Application',
      label: '',
      dateVisible: false,
      date: '--/--/--',
      buttonVisible: true,
      buttonEnabled: true,
      buttonText: 'START',
      iconColor: TIMELINE_COLOR.GREY
    };

    if (!timeline) {
      return cfg;
    }

    const getHeaderText = (statusName = "Started") => {
      let lbl = 'Application';
      if (timeline.isDeceased) {
        lbl = 'Deceased';
      } else if (timeline.isAdapOnlyApplication) {
          lbl = 'Recertification'; // not at all confusing
      } else if (timeline.isDeletingAddressesUpdate) {
        lbl = 'Deleting Address';
      } else if (timeline.isInterimUpdate) {
        lbl = "Update";
      } else if (timeline.isRecertificationApplication) {
        lbl = "Recertification";
      }
      return `${lbl}: ${statusName}`;
    }
    
    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING ||
        timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW ||
        timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED) {
      cfg.header = getHeaderText();
      cfg.dateVisible = true;
      cfg.date = getStatusDate(ELIGIBILITY_APPLICATION_STATUS.STARTED);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_STEP;
      cfg.buttonVisible = false;
    }
    else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.STARTED) {
      cfg.header = getHeaderText();
      cfg.buttonText = 'CONTINUE';
      cfg.dateVisible = true;
      cfg.date = getStatusDate(ELIGIBILITY_APPLICATION_STATUS.STARTED);
    }
    else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.CANCELLED || 
             timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.EXPIRED) {
      const expired = (timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) ||
                      (!timeline.expirationDate); // initial app terminated immediately
      cfg.header = getHeaderText(timeline.statusName);
      cfg.buttonText = 'START NEW';
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_RED;
      cfg.buttonVisible = expired;
      cfg.dateVisible = true;
      cfg.date = getStatusDate(timeline.statusKey);
    }
    else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED) {
      const expired = timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
      if (expired) {
        cfg.header = getHeaderText(timeline.statusName);
        cfg.buttonText = 'START NEW';
        cfg.dateVisible = true;
        cfg.iconColor = TIMELINE_COLOR.ACTIVE_STEP;
        cfg.date = getStatusDate(timeline.statusKey);
      } else {
        cfg.buttonVisible = false;
        cfg.iconColor = TIMELINE_COLOR.ACTIVE_STEP;
        cfg.header = getHeaderText(timeline.statusName);
        cfg.dateVisible = true;
        cfg.date = getStatusDate(timeline.statusKey);
      }
      setAppId(null);
    }
    return cfg;
  }

  const getReviewStepConfig = (timeline) => {
    // The cfg we see for the other steps is irrelevant here because we're injecting a different timeline for the content
    let cfg = {
      key: TIMELINE_STEP.REVIEW_STEP,
      header: '',
      iconColor: TIMELINE_COLOR.GREY,
      buttonVisible: false,
      buttonEnabled: false,
      buttonText: 'MAKE UPDATES',
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING) {
      cfg.buttonEnabled = true;
      cfg.buttonVisible = true;
    }
    return cfg;
  }

  const getEligibilityStepConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.ELIGIBILITY_STEP,
      header: 'Eligibility Start Date',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.eligibilityStartDate) {
      cfg.date = timeline.eligibilityStartDate;
    }

    return cfg;
  }

  const getRecertificationStepConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.RECERT_STEP,
      header: 'Recertification',
      label:  '--/--/--',
      dateVisible: false,
      buttonVisible: true,
      buttonEnabled: false,
      buttonText: 'START',
      iconColor: TIMELINE_COLOR.GREY
    };
    
    if (!timeline) {
      return cfg;
    }

    let recertActive = false;

    if (timeline.recertificationDate) {
      cfg.label = 'Available ' + timeline.recertificationDate;
      recertActive = 
          (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED  || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.CANCELLED) && // dont allow recert if an app is in progress
          timeline.recertificationDate && new Date(timeline.recertificationDate).setHours(0,0,0,0) <= new Date().setHours(0,0,0,0) &&
          timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) > new Date().setHours(0,0,0,0);
    }

    if (recertActive) {
      cfg.buttonEnabled = true;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
      cfg.labelColor = TIMELINE_COLOR.BLACK;
    } else if (timeline.recertificationDate) {
      cfg.iconColor = TIMELINE_COLOR.LIGHT_GREY;
    }

    return cfg;
  }

  const getExpirationStepConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.EXPIRE_STEP,
      header: 'Expiration Date',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.expirationDate) {
      cfg.date = timeline.expirationDate;
      const eligibilityValid = timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0);
      if (!eligibilityValid) {
        cfg.iconColor = TIMELINE_COLOR.ACTIVE_RED;
      }
      cfg.headerColor = eligibilityValid ? TIMELINE_COLOR.BLUE_TEXT : TIMELINE_COLOR.ACTIVE_RED;
      cfg.dateColor = eligibilityValid ? TIMELINE_COLOR.BLUE_TEXT : TIMELINE_COLOR.ACTIVE_RED;
      cfg.hideIcon = eligibilityValid;
    } else {
      cfg.hideIcon = true;
    }

    return cfg;
  }

  const getRwReviewDetailConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.RW_REVIEW_STEP,
      header: 'RW Review',
      label: '',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY,
      activeIconStyle: false
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED) {
      cfg.label = 'Completed';
      cfg.date = timeline.rwEligibilityDeterminationDate;
      cfg.activeIconStyle = true;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW) {
      cfg.label = 'Submitted';
      cfg.date = timeline.statusEffectiveDate;
      cfg.activeIconStyle = false;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING) {
      cfg.label = 'Pending';
      cfg.date = getStatusDate(timeline.statusKey);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_ORANGE;
    }

    return cfg;
  }

  const getAdapReviewDetailConfig = (timeline) => {
    let cfg = {
      key: TIMELINE_STEP.ADAP_REVIEW_STEP,
      header: (userAgencyEligibilityConfig?.application?.selectedAssistanceProgramName || 'ADAP') + ' Review',
      label: '',
      dateVisible: true,
      date: '--/--/--',
      iconColor: TIMELINE_COLOR.GREY,
      activeIconStyle: false,
      adapOptedOut: timeline && !timeline.requestedSubprogramsCount
    };

    if (!timeline) {
      return cfg;
    }

    if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED) {
      cfg.label = timeline.requestedSubprogramsCount > 0 ? (timeline.adapAuthorized ? 'Authorized' : 'Denied') : 'Opted Out';
      cfg.dateVisible = timeline.requestedSubprogramsCount > 0;
      cfg.date = timeline.adapAuthorizationDecisionDate;
      cfg.activeIconStyle = true;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW) {
      cfg.label = timeline.requestedSubprogramsCount > 0 ? 'Submitted' : 'Opted Out';
      cfg.dateVisible = timeline.requestedSubprogramsCount > 0;
      cfg.date = timeline.statusEffectiveDate;
      cfg.activeIconStyle = false;
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING) {
      cfg.label = timeline.requestedSubprogramsCount > 0 ? 'Pending' : 'Opted Out';
      cfg.date = timeline.requestedSubprogramsCount > 0 ? getStatusDate(timeline.statusKey) : getStatusDate(ELIGIBILITY_APPLICATION_STATUS.SUBMITTED);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_GREY;
    } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED) {
      cfg.label = 'Submitted';
      cfg.date = getStatusDate(ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED);
      cfg.iconColor = TIMELINE_COLOR.ACTIVE_ORANGE;
    }
    
    return cfg;
  }

  const buildStepperConfigs = () => {

    let applicationStepConfig = getApplicationStepConfig(timeline);
    let reviewStepConfig = getReviewStepConfig(timeline);
    let eligibilityStepConfig = getEligibilityStepConfig(timeline);
    let recertificationStepConfig = getRecertificationStepConfig(timeline);
    let expirationStepConfig = getExpirationStepConfig(timeline);

    let rwReviewDetailConfig = getRwReviewDetailConfig(timeline);
    let adapReviewDetailConfig = getAdapReviewDetailConfig(timeline);
    
    if (timeline) {
      if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.SUBMITTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.IN_REVIEW ||
          timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.RW_REVIEW_COMPLETED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.PENDING ||
          timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.NOT_STARTED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.STARTED) {
        setActiveStep(1);
        setAppId(timeline.id);
      } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.CANCELLED || timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.EXPIRED ||
                 timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED) {
        setAppId(null);
        const canStart = (timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)) ||
                          (!timeline.expirationDate && timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.TERMINATED); // initial app terminated immediately
        if (canStart) {
          setActiveStep(1);
        } else {
          setActiveStep(5);
        }
      } else if (timeline.statusKey === ELIGIBILITY_APPLICATION_STATUS.COMPLETED) {
        const expired = timeline.expirationDate && new Date(timeline.expirationDate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
        if (expired) {
          setActiveStep(1);
        } else {
          setActiveStep(3);
        }
        setAppId(null);
      }
    }

    let stepConfig = [applicationStepConfig, reviewStepConfig, eligibilityStepConfig, recertificationStepConfig, expirationStepConfig];
    let rvwDetailConfig = [rwReviewDetailConfig, adapReviewDetailConfig];

    if (!(verifyPermission(PERMISSIONS.APPLICATION_SUBMITTER) || verifyPermission(PERMISSIONS.APPLICATION_COMPLETER))) {
      disableTimelineButtons(stepConfig);
      disableTimelineButtons(rvwDetailConfig);
    }

    setStepperConfig(stepConfig);
    setReviewDetailConfig(rvwDetailConfig);
  }

  const requestAdap = async () => {
    const id = await createEligibilityApplication(clientId, false, false, true);
    if (id) {
      navigate(`/client/${clientId}/eligibility/application/${id}`);
    }
  }

  // Starting to build up a new theme for use going forward
  // For now, only using it in the eligibility history (and overriding existing theme elements)
  const [theTheme, setTheTheme] = useState();

  useEffect(() => {
    const theme = createTheme({
      typography: {
        h3: {
          fontFamily: 'Inter',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '160%',
          letterSpacing: 0.15
        },
        body1: {
          fontFamily: 'Inter',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '150%',
          letterSpacing: 0.15
        },
        body2: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '143%',
          letterSpacing: 0.17,
          color: 'rgba(0,0,0,0.87)'
        },
        overline: {
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '266%',
          letterSpacing: '1px',
          textTransform: 'uppercase'
        },
        subtitle1: {
          fontFamily: "Inter",
          fontSize: "16px",
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '175%',
          letterSpacing: '0.15px'
        }
      },
      palette: {
        primary: {
          main: '#294055',
          light: '#546B82',
          dark: '#001a2c',
        },
        secondary: {
          main: '#2699A8',
          light: '#E0F1F3',
          dark: '#006b79',
        },
        error: {
          main: '#D32F2F',
          dark: '#B01A1A',
          light: '#FBEAEA'
        },
        background: {
          default: '#fff',
          grey: '#F7F7F7',
        },
        success: {
          main: '#2E7D32',
          light: '#EAF2EA',
          dark: '#1B5E20',
        },
        text: {
          primary: '#000000',
          secondary: '#666666',
          selected: '#B4FFFF',
          completed: 'green',
          tabs: '#7A7A7A',
          outlinedButtonText: '#4A97A6',
        },
        other: {
          divider: 'rgba(0,0,0,0.12)'
        }
      },
      components: {
        MUIDataTableHeadCell: {
          styleOverrides:{
            root: {
              backgroundColor: "rgba(41, 64, 85, 0.04)"
            }
          }
        }
      }
    });

    setTheTheme(theme);
  }, []);

  const retrieveAppDetailsIfNecessary = async (appId) => {
    let found = false;
    eligibilityHistory.periods.forEach(p => {
      if (p.events) {
        let evt = p.events.find(e => e.id === appId);
        if (evt && evt.details) {
          found = true;
        }
      }
    });
    if (!found) {
      await loadEligibilityDetailsForApplication(appId);
    }
  }

  useEffect(() => {
    if (appEligDetails && eligibilityHistory) {
      let newHist = {...eligibilityHistory};
      appEligDetails.forEach(ad => {
        newHist.periods.forEach(p => {
          if (p.events) {
            let evt = p.events.find(e => e.id === ad.appId);
            if (evt) {
              evt.details = ad.details;
            }
          }
        });
      });
      setEligibilityHistory(newHist);
    }
  }, [appEligDetails]);

  return theTheme && clientId && stepperConfig && reviewDetailConfig && (
    <PageSection header="Eligibility">
      <LotusPageSubSection 
        header="Timeline"
        actions={
          <>
          {showRequestAdap &&
            (<Button variant="contained" onClick={() => {requestAdap();}}>
              <Typography variant="h6">Request {(userAgencyEligibilityConfig?.application?.selectedAssistanceProgramName || 'ADAP')}</Typography>
            </Button>)
          }
          </>
        }
      >
        <EligibiityStepper
          activeStep={activeStep}
          clientId={clientId}
          appId={appId}
          stepperConfig={stepperConfig}
          reviewTimelineConfig={reviewDetailConfig}
        />
      </LotusPageSubSection>
      {eligibilityHistory && eligibilityHistory.periods.length > 0 &&
        <LotusPageSubSection header="Ryan White Eligibility History">
          <ThemeProvider theme={theTheme}>
            <HistoricalEvents 
              hist={eligibilityHistory} 
              detailRetriever={retrieveAppDetailsIfNecessary} 
              EventDetailDisplayer={EligibilityDetails}/>
          </ThemeProvider>
        </LotusPageSubSection>
      }
    </PageSection>
  );
};
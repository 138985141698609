import React, { useEffect, useState } from 'react';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { v4 as uuidv4 } from 'uuid';
import { Grid, Stack } from '@mui/material';
import { METRIC_CATEGORIES } from 'lib/metricCategoryEnum'
import AgencyMetricPanel from '../AgencyMetricPanel';
import AgencyMetricForm from './AgencyMetricForm';
import { Form, Formik } from 'formik';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormSubSubSection from 'components/widgets/Forms/LotusFormSubSubSection';
import { useDashboards } from 'contexts/DashboardsContext';


function MetricSection({ agencyId, agencyMetrics, metric }) {
  const { upsertAgencyMetric, deleteAgencyMetric } = useAgencyManagement();
  const [addingAgencyMetric, setAddingAgencyMetric] = useState(false);

  const handleUpsertMetric = async (values) => {
    await upsertAgencyMetric(
      values.id || uuidv4(),
      agencyId,
      metric.id,
      values.title,
      values.data
    );
    setAddingAgencyMetric(false);
  };

  const handleDeleteMetric = async (a, id) => {
    await deleteAgencyMetric(agencyId, id);
    setAddingAgencyMetric(false);
  };

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <LotusFormSubSubSection name={metric.name} />
        </Grid>
        {!addingAgencyMetric && (agencyMetrics.length === 0) && (
          <Grid item xs={6}>
            <Stack direction="row" justifyContent='flex-end'>
              <LotusButton
                plusIcon
                variant={'outlined'}
                color={'secondary'}
                onClick={() => setAddingAgencyMetric(true)}
              >
                ADD
              </LotusButton>
            </Stack>
          </Grid>
        )}
      </Grid>
      {addingAgencyMetric && (
        <AgencyMetricForm
          metric={metric}
          handleSave={handleUpsertMetric}
          handleCancel={() => setAddingAgencyMetric(false)}
        />
      )}
      {agencyMetrics &&
        agencyMetrics
          .sort((a, b) => {
            return a.title > b.title;
          })
          .map((am) => (
            <AgencyMetricPanel
              key={am.id}
              agencyMetric={am}
              agencyId={agencyId}
              metric={metric}
              handleSave={handleUpsertMetric}
              handleDelete={handleDeleteMetric}
            />
          ))}
    </>
  );
}

export default function AgencyDashboardsForm({ agency, afterSubmit }) {
  const { agencyMetrics, loadAgencyMetrics, updateAgencyDashboardConfig } = useAgencyManagement();
  const { metrics, loadMetrics } = useDashboards();
  const [cycleTimeMetrics, setCycleTimeMetrics] = useState();
  const [processorProductivityMetrics, setProcessorProductivityMetrics] = useState();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    loadMetrics([METRIC_CATEGORIES.CYCLE_TIME, METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY]);
  }, []);

  useEffect(() => {
    if (agency) {
      loadAgencyMetrics(agency.id, [METRIC_CATEGORIES.CYCLE_TIME, METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY]);
    }
  }, [agency]);

  useEffect(() => {
    if (agency && !initialValues) {
      const newInitialValues = {
        cycleTimeDashboardName: agency.dashboardConfig.cycleTimeDashboardName || 'Cycle Time Dashboard',
        processorProductivityDashboardName: agency.dashboardConfig.processorProductivityDashboardName || 'Processor Productivity Dashboard',
      };
      setInitialValues(newInitialValues);
    }
  }, [agency]);

  useEffect(() => {
    if (metrics) {
      const ctMetrics = metrics.filter(metric => metric.category === METRIC_CATEGORIES.CYCLE_TIME);
      setCycleTimeMetrics(ctMetrics);
      const ppMetrics = metrics.filter(metric => metric.category === METRIC_CATEGORIES.PROCESSOR_PRODUCTIVITY);
      setProcessorProductivityMetrics(ppMetrics);
    }
  }, [metrics])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, actions) => {
        // Metrics are not being updated with this submit.  Each individual control is doing it for now.
        await updateAgencyDashboardConfig(values);
        if (afterSubmit) {
          await afterSubmit();
        }
        actions.setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return values && (
          <Form onSubmit={handleSubmit}>
            <LotusFormSection name="Cycle Time Dashboard">
              <LotusSpacedBlock/>
              <LotusFormItem>
                <LotusTextInput
                  name="cycleTimeDashboardName"
                  label="Cycle Time Dashboard Name"
                  required
                />
              </LotusFormItem>
              <LotusFormSubSection name="Cycle Time Metrics">
                {cycleTimeMetrics &&
                  agencyMetrics &&
                  cycleTimeMetrics
                    .sort((a, b) => {
                      return a.name > b.name;
                    })
                    .map((metric) => (
                      <MetricSection
                        key={metric.key}
                        metric={metric}
                        agencyId={agency.id}
                        agencyMetrics={agencyMetrics.filter(
                          (am) => am.metricId === metric.id
                        )}
                      />
                    ))}
              </LotusFormSubSection>
            </LotusFormSection>
            <LotusSpacedBlock/>
            <LotusFormSection name="Processor Productivity Dashboard">
              <LotusSpacedBlock/>
              <LotusFormItem>
                <LotusTextInput
                  name="processorProductivityDashboardName"
                  label="Processor Productivity Dashboard Name"
                  required
                />
              </LotusFormItem>
              <LotusFormSubSection name="Processor Productivity Metrics">
                {processorProductivityMetrics &&
                  agencyMetrics &&
                  processorProductivityMetrics
                    .sort((a, b) => {
                      return a.name > b.name;
                    })
                    .map((metric) => (
                      <MetricSection
                        key={metric.key}
                        metric={metric}
                        agencyId={agency.id}
                        agencyMetrics={agencyMetrics.filter(
                          (am) => am.metricId === metric.id
                        )}
                      />
                    ))}
              </LotusFormSubSection>
            </LotusFormSection>
            <LotusFormItem lastOne>
              <LotusButton type="submit">
                Submit
              </LotusButton>
            </LotusFormItem>
          </Form>
        );
      }}
    </Formik>
  );
}

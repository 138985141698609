import React, { useEffect } from 'react';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { TextField } from '@mui/material';

export default function CustomListLinker({ fieldName, fieldDescription, listHeader, allListItems }) {
  const [newItemName, setNewItemName] = useState('');
  const [configField, configFieldMeta, configFieldUtils] = useField(fieldName);
  const {setFieldError} = useFormikContext();

  useEffect(() => {
    if (allListItems) {
      const localValues = allListItems.filter(d => !d.isDeleted).map(d => {
        return {
          id: d.id,
          name: d.name,
          isActive: d.isActive
        }
      });
      configFieldUtils.setValue(localValues);
    }
  }, [allListItems]);

  const addListItem = () => {
    if (newItemName.length > 0) {
      const found = configField.value.find(itm => itm.name.toLowerCase() === newItemName.toLowerCase() );
      if (found) {
        setFieldError(fieldName, `${fieldDescription} already exists.`);
      }
      else {
        const newName = newItemName;
        const newLocalList = [...configField.value, {name: newName, isActive: true}]
        configFieldUtils.setValue(newLocalList);
        setNewItemName('');
      }
    }
  };

  const removeListItem = (itm) => {
    const newListItems = configField.value.filter((localItm) => itm.name !== localItm.name);
    configFieldUtils.setValue(newListItems);
  };

  return configField.value && (
      <LotusForm>
        <LotusFormItem>
          {listHeader}
        </LotusFormItem>
        {configField.value.length > 0 && configField.value.map((itm, index) => {
          return (
            <LotusFormItem key={uuidv4()}>
              <Stack direction="row" spacing={2} alignItems="center">
                <LotusSwitch
                  name={`${fieldName}.${index}.isActive`}
                  label={itm.name}
                  value={itm.isActive}
                  onChange={() => {
                    itm.isActive = !itm.isActive;
                    configFieldUtils.setValue(configField.value);
                  }}
                />
                <LotusButton onClick={() => removeListItem(itm)}>
                  Remove
                </LotusButton>
              </Stack>
            </LotusFormItem>
          );
        })}
        <LotusFormItem>
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              size="small"
              label={`New ${fieldDescription}`}
              value={newItemName}
              onChange={(evt) => setNewItemName(evt.target.value)}
            />
            <LotusButton
              color="primary"
              variant="contained"
              onClick={addListItem}
            >
              Add
            </LotusButton>
          </Stack>
        </LotusFormItem>
        <div style={{color:'#b53f3f'}}>
          <ErrorMessage name={fieldName}/>
        </div>
      </LotusForm>
  );
}

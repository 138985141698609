import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { useDataImport } from 'contexts/DataImportContext';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { Grid, Typography } from '@mui/material';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { DELIMITERS } from './common';

const IMPORT_MODES = [{ name: 'Test', mode: 'test' }, { name: 'Import', mode: 'prod' }];

export default function DataImportForm({
  agencyId,
  handleSubmit,
  afterSubmit,
}) {
  const [initialValues, setInitialValues] = useState();
  const { importTypes, getImportTypes } = useDataImport();

  useEffect(() => {
    setInitialValues({ document: {}, mode: 'test', importType: '', delimiter: '', desc: '', });
  }, []);

  useEffect(() => {
    if (agencyId) {
      getImportTypes(agencyId);
    }
  }, [agencyId]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validate={async (values) => {
        let result = {};
        if (

          Object.keys(values.document).length === 0
        ) {
          result.document =
            'Import file is required.';
        }

        return result;
      }}
      onSubmit={async (values, actions) => {
        await handleSubmit(
          values.document.uniqueName,
          values.importType,
          values.desc,
          values.document.displayName,
          values.delimiter,
          values.mode === 'test'
      );
        actions.setSubmitting(false);
        if (afterSubmit) {
          afterSubmit();
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldTouched,
        setFieldValue,
        values,
        touched,
        errors,
      }) => {
        return values ? (
          <Grid container>
            <Grid item xs={12} alignItems="center" container style={{ border: 20 }}>
              <Grid item xs={6}>
                <Typography variant="h2">Import Data</Typography>
              </Grid>
              
              <Grid item style={{ width: "600px", border: '10px' }}>
                <Form onSubmit={handleSubmit}>

                  <Grid item>
                   <div style={{ padding: "12px 20px", border: "10px" }} />
                    <LotusFormItem>
                      <LotusSelect
                        name="importType"
                        label="Import Type"
                        required={true}
                        items={
                          importTypes &&
                          importTypes.map((item) => {
                            return { label: item.name, value: item.id };
                          })
                        }
                        value={values.importType}
                        onChange={handleChange}
                        error={
                          touched &&
                          touched.importType &&
                          errors &&
                          Boolean(errors.importType)
                        }
                      />
                    </LotusFormItem>
                  </Grid >

                  <Grid item>
                    <LotusFormItem>
                      <LotusTextInput
                        name="desc"
                        label="Description"
                        required
                        maxLength={100}
                      />
                    </LotusFormItem>
                  </Grid >
                  <Grid item>
                    <LotusFormItem>
                      <LotusSelect
                        label="Mode"
                        name="mode"
                        items={IMPORT_MODES.map(mode => { return { label: mode.name, value: mode.mode } })}
                        required
                      />
                    </LotusFormItem>
                  </Grid >
                  <Grid item>
                    <LotusFormItem>
                      <LotusSelect
                        label="Delimiter"
                        name="delimiter"
                        items={DELIMITERS.map(d => { return { label: d.name, value: d.delimiter } })}
                        required
                      />
                    </LotusFormItem>
                  </Grid >

                  <LotusFileUploader
                    name="dataImportUploder"
                    agencyId={agencyId}
                    subfolderName="imports"
                    acceptedFiles={['text/plain', 'text/csv']}
                    maxFiles={1}
                    allowedTypesMsg={'TXT or CSV file types are allowed.'}
                    onFileAdded={async (newFile) => {
                      console.log('Added', newFile);
                      setFieldTouched('documentError');
                      setFieldValue('documentError', '');
                      setFieldTouched('document');
                      setFieldValue('document', newFile);
                    }}
                    onFileDeleted={(newFile) => {
                      console.log('Deleted', newFile);
                      setFieldTouched('document');
                      setFieldValue('document', {});
                    }}

                  />
                  <div style={{ color: '#b53f3f', marginTop: 10 }}>
                    <ErrorMessage name="document" />
                  </div>
                  <div style={{ color: '#b53f3f', marginTop: 10 }}>
                    <ErrorMessage name="documentError" />
                  </div>
                  <Grid container direction="row" justifyContent="right" spacing={1} columns={2}>
                    <Grid item>
                      <LotusButton type="button" variant="outlined" onClick={() => { afterSubmit(); }} >
                        Cancel
                      </LotusButton>
                    </Grid >
                    <Grid item>
                      <LotusFormItem>
                        <LotusButton
                          type="submit"
                        >
                          {(values.mode === 'test') ? 'Start Test' : 'Start Import'}
                        </LotusButton>
                      </LotusFormItem>
                    </Grid>
                  </Grid>
                </Form>
              </Grid></Grid></Grid>
        ) : (
          <div></div>
        );
      }}
    </Formik>

  );
}

import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import LotusDatePicker from "components/widgets/Forms/LotusDatePicker";
import { Button } from "@lotus/components";
import LotusSelect from "components/widgets/Forms/LotusSelect";
import LotusTextInput from "components/widgets/Forms/LotusTextInput";
import LotusFormSection from "components/widgets/Forms/LotusFormSection";
import LotusFormItem from "components/widgets/Forms/LotusFormItem";
import moment from 'moment';
import { useAuthState } from 'contexts/AuthProvider';
import { useLists } from "contexts/ListsContext";
import { CONSTANTS } from "./ClientServicesEnums";
import { useClient } from "contexts/ClientContext";
import ClientServicesDocumentation from "./ClientServicesDocumentation";

export default function ClientPremiumPaymentViewer({
  premiumPaymentId,
  modalType,
  editDisabled,
  entryMode = CONSTANTS.IMPORT_ENTRY_MODE,
  clientId,
  premiumPaymentObj,
  premiumPaymentConfig,
  handleCancel,
  handleSave
}) {
  
  const { clientPremiumPaymentDocuments, loadClientPremiumPaymentDocuments } = useClient();
  const { user: loggedInUser } = useAuthState();
  const disabled = modalType === CONSTANTS.VIEW_MODAL_TYPE && premiumPaymentObj && premiumPaymentObj?.entryMode.toLowerCase() !== CONSTANTS.MANUAL_ENTRY_MODE;
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const [submitBtnState, setSubmitBtnState] = useState();
  const [initialValues, setInitialValues] = useState();
  const [headerLabel, setHeaderLabel] = useState();
  const { clientServicesDataDeletionReasons } = useLists();
  const [deletionReasonList, setDeletionReasonList] = useState();

  useEffect(() => {
    if(premiumPaymentId && entryMode === CONSTANTS.MANUAL_ENTRY_MODE && modalType !== CONSTANTS.ADD_MODAL_TYPE) {
      loadClientPremiumPaymentDocuments(clientId, premiumPaymentId);
    }
  }, [premiumPaymentId]);

  useEffect(() => {
    if (clientServicesDataDeletionReasons) {
      setDeletionReasonList(clientServicesDataDeletionReasons.map(r => {return {value: r.id, label: r.itemName}}));
    }
  }, [clientServicesDataDeletionReasons]);

  useEffect(() => {
    if(modalType === CONSTANTS.ADD_MODAL_TYPE) {
      setHeaderLabel('Add');
    }
    else if(entryMode === CONSTANTS.MANUAL_ENTRY_MODE) {
      setHeaderLabel('Edit');
    } else {
      setHeaderLabel('');
    }
  }, [modalType]);
  
  useEffect(() => {
    if (premiumPaymentObj && premiumPaymentConfig) {
      setInitialValues({
        status: premiumPaymentObj.status || '',
        paidDate: premiumPaymentObj.paidDate || null,
        datePremiumCoverageFrom: premiumPaymentObj.datePremiumCoverageFrom || null,
        datePremiumCoverageTo: premiumPaymentObj.datePremiumCoverageTo || null,
        carrier: premiumPaymentObj.carrier || '',
        premiumAmount: premiumPaymentObj.premiumAmount || '',
        totalAmount: premiumPaymentObj.totalAmount || '',
        adminFeeAmount: premiumPaymentObj.adminFeeAmount || '',
        paymentType: premiumPaymentObj.paymentType || '',
        entryMode: premiumPaymentObj.entryMode ? premiumPaymentObj.entryMode.charAt(0).toUpperCase() + premiumPaymentObj.entryMode.substring(1) : 'Manual',
        adjustmentFlag: premiumPaymentConfig?.enabled && premiumPaymentConfig?.typeCategory?.adjustmentFlag ? premiumPaymentObj.adjustmentFlag || '' : '',
        refunded: premiumPaymentConfig?.enabled && premiumPaymentConfig?.typeCategory?.refunded ? premiumPaymentObj.refunded || '' : '',
        voucherDate: premiumPaymentConfig?.enabled && premiumPaymentConfig?.paymentCategory?.voucherDate ? premiumPaymentObj.voucherDate || '' : '',
        voucherId: premiumPaymentConfig?.enabled && premiumPaymentConfig?.paymentCategory?.voucherId ? premiumPaymentObj.voucherId || '' : '',
        authNum: premiumPaymentConfig?.enabled && premiumPaymentConfig?.paymentCategory?.authNum ? premiumPaymentObj.authNum || '' : '',
        paymentMethod: premiumPaymentConfig?.enabled && premiumPaymentConfig?.paymentCategory?.paymentMethod ? premiumPaymentObj.paymentMethod || '' : '',
        paymentId: premiumPaymentConfig?.enabled && premiumPaymentConfig?.paymentCategory?.paymentId ? premiumPaymentObj.paymentId || '' : '',
        paymentRefundUniqueId: premiumPaymentConfig?.enabled && premiumPaymentConfig?.paymentCategory?.paymentRefundUniqueId ? premiumPaymentObj.paymentRefundUniqueId || '' : '',
        planName: premiumPaymentConfig?.enabled && premiumPaymentConfig?.premiumCoverageCategory?.planName ? premiumPaymentObj.planName || '' : '',
        planMemberId: premiumPaymentConfig?.enabled && premiumPaymentConfig?.premiumCoverageCategory?.planMemberId ? premiumPaymentObj.planMemberId || '' : '',
        planType: premiumPaymentConfig?.enabled && premiumPaymentConfig?.premiumCoverageCategory?.planType ? premiumPaymentObj.planType || '' : '',
        premiumPaymentDocumentation: ((modalType === CONSTANTS.ADD_MODAL_TYPE || entryMode !== CONSTANTS.MANUAL_ENTRY_MODE) ? [] : clientPremiumPaymentDocuments) || [],
        deletionReasonId: '',
        deletionComments: ''
      });
    }
  }, [premiumPaymentObj, premiumPaymentConfig, clientPremiumPaymentDocuments]);

  return (
     initialValues &&
    <Grid container>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={async (newValues, actions) => {
          newValues.totalAmount = (parseFloat(newValues.premiumAmount) + parseFloat(newValues.adminFeeAmount)).toFixed(2);
          await handleSave(newValues, submitBtnState);
          actions.setSubmitting(false);
        }}
      >
        {({values, touched, errors, handleSubmit}) => {
          return (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey"}}>
                  <Grid item xs={6}>
                    <Typography variant="h2">{headerLabel} Premium Payment</Typography>
                    {deleteEnabled && 
                    <>
                      <Typography variant="body">Deletion Date: {moment().format('MMM Do, YYYY')}</Typography>
                      <Typography variant="body" style={{marginLeft: 10, marginRight: 10}}>|</Typography>
                      <Typography variant="body">By: {loggedInUser?.data?.firstName + ' ' + loggedInUser?.data?.lastName }</Typography>
                    </>
                    }
                  </Grid>
                  {!disabled && modalType === CONSTANTS.ADD_MODAL_TYPE && !deleteEnabled &&
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {handleCancel()}} style={{marginRight: 10, border: '1px solid #7A7A7A66'}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Cancel</Typography>
                      </Button>
                      <Button onClick={() => {setSubmitBtnState("add");handleSubmit(values);}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Save</Typography>
                      </Button>
                    </Grid>
                  }
                  {!disabled && modalType === CONSTANTS.VIEW_MODAL_TYPE && entryMode === CONSTANTS.MANUAL_ENTRY_MODE && !deleteEnabled &&
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {handleCancel()}} style={{marginRight: 10, border: '1px solid #7A7A7A66'}}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Cancel</Typography>
                      </Button>
                      <Button variant="text" onClick={() => {setDeleteEnabled(true);setHeaderLabel('Delete')}} style={{marginRight: 10, border: editDisabled ? '1px solid #7A7A7A66' : '1px solid red'}} disabled={editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5, color: editDisabled ? "" :"red"}}>Delete</Typography>
                      </Button>
                      <Button onClick={() => {setSubmitBtnState("edit");handleSubmit(values);}} disabled={editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Save</Typography>
                      </Button>
                    </Grid>
                  }
                  {deleteEnabled &&
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {setDeleteEnabled(false);handleCancel()}} style={{marginRight: 10, border: '1px solid #7A7A7A66'}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5}}>Cancel</Typography>
                      </Button>
                      <Button variant="contained" color="error" onClick={() => {setSubmitBtnState("delete");handleSubmit(values);}} style={{marginRight: 10}} disabled={disabled || editDisabled}>
                        <Typography variant="body" style={{marginLeft: 5, marginRight: 5, color: "#fff"}}>Delete</Typography>
                      </Button>
                    </Grid>
                  }
                </Grid>
                <Grid item xs={12} style={{marginTop: 20, marginBottom: 24}}>
                  <LotusSelect
                      name="status"
                      label="Status"
                      items={CONSTANTS.PAYMENT_STATUS_OPTIONS}
                      required
                      disabled={disabled || editDisabled}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusDatePicker
                      name="paidDate"
                      label={"Paid Date"}
                      maxDate={moment().format('MM/DD/YYYY')}
                      required
                      value={values.paidDate}
                      error={touched.paidDate && Boolean(errors.paidDate)}
                      disabled={disabled || editDisabled}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="premiumAmount"
                      label="Premium Amount"
                      required
                      disabled={disabled || editDisabled}
                      floatingPoint
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="adminFeeAmount"
                      label="Admin Fee Amount"
                      required
                      disabled={disabled || editDisabled}
                      floatingPoint
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="totalAmount"
                      label="Total Amount"
                      value={
                        ((isNaN(parseFloat(values.premiumAmount)) ? 0 : parseFloat(values.premiumAmount))  + 
                        (isNaN(parseFloat(values.adminFeeAmount)) ? 0 : parseFloat(values.adminFeeAmount))).toFixed(2)
                      }
                      disabled={disabled || editDisabled}
                      floatingPoint
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusTextInput
                      name="carrier"
                      label="Carrier"
                      required
                      maxLength={50}
                      disabled={disabled || editDisabled}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusDatePicker
                      name="datePremiumCoverageFrom"
                      label={"Coverage Start Date"}
                      required
                      value={values.datePremiumCoverageFrom}
                      error={touched.datePremiumCoverageFrom && Boolean(errors.datePremiumCoverageFrom)}
                      disabled={disabled || editDisabled}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusDatePicker
                      name="datePremiumCoverageTo"
                      label={"Coverage End Date"}
                      required
                      minDate={values.datePremiumCoverageFrom}
                      value={values.datePremiumCoverageTo}
                      error={touched.datePremiumCoverageTo && Boolean(errors.datePremiumCoverageTo)}
                      disabled={disabled || editDisabled}
                    />
                </Grid>
                <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusSelect
                      name="paymentType"
                      label="Payment Type"
                      items={CONSTANTS.PAYMENT_TYPE_OPTIONS}
                      required
                      disabled={disabled || editDisabled}
                    />
                </Grid>
              </Grid>
              {premiumPaymentConfig && premiumPaymentConfig?.enabled &&
              <Grid container>
                {disabled && premiumPaymentConfig?.typeCategory?.adjustmentFlag &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="adjustmentFlag"
                        label="Adjustment Flag"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && premiumPaymentConfig?.typeCategory?.refunded &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="refunded"
                        label="Refunded"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && premiumPaymentConfig?.paymentCategory?.voucherDate &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="voucherDate"
                        label="Voucher Date"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {premiumPaymentConfig?.paymentCategory?.voucherId &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="voucherId"
                        label="Voucher ID"
                        maxLength={20}
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && premiumPaymentConfig?.paymentCategory?.authNum &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="authNum"
                        label="Authorization Number"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {premiumPaymentConfig?.paymentCategory?.paymentMethod &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusSelect
                        name="paymentMethod"
                        label="Payment Method"
                        items={CONSTANTS.PAYMENT_METHOD_OPTIONS}
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {premiumPaymentConfig?.paymentCategory?.paymentId &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                    <LotusTextInput
                        name="paymentId"
                        label="Payment ID"
                        maxLength={100}
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && premiumPaymentConfig?.paymentCategory?.paymentRefundUniqueId &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="paymentRefundUniqueId"
                        label="Payment Refund Unique ID"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && premiumPaymentConfig?.premiumCoverageCategory?.planName &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="planName"
                        label="Plan Name"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {premiumPaymentConfig?.premiumCoverageCategory?.planMemberId &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="planMemberId"
                        label="Plan Member ID"
                        maxLength={20}
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
                {disabled && premiumPaymentConfig?.premiumCoverageCategory?.planType &&
                  <Grid item xs={12} style={{marginBottom: 24}}>
                      <LotusTextInput
                        name="planType"
                        label="Plan Type"
                        disabled={disabled || editDisabled}
                      />
                  </Grid>
                }
            </Grid>
            }
              <Grid container>
                <Grid item xs={6} style={{marginBottom: 24}}>
                  <LotusTextInput
                    name="entryMode"
                    label="Entry Mode"
                    disabled={true}
                  />
                </Grid>
              </Grid>
              {deleteEnabled && 
                <Grid container>
                  <Grid item xs={12} style={{marginBottom: 24}}>
                    {deletionReasonList &&
                      <LotusSelect
                        name="deletionReasonId"
                        label="Reason for Deletion"
                        items={deletionReasonList}
                        required
                        disabled={disabled || editDisabled}
                      />
                    }
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 24}}>
                    <LotusTextInput
                      name="deletionComments"
                      multiline
                      minRows={5}
                      label="Comments"
                      disabled={disabled || editDisabled}
                    />
                  </Grid>
                </Grid>
              }
              {!disabled && premiumPaymentObj &&
                <Grid container>
                  <Grid item xs={12} style={{marginBottom: 24}}>
                  <LotusFormSection name="Documentation">
                    <LotusFormItem>
                      <ClientServicesDocumentation
                        clientId={clientId}
                        documentationListFieldName="premiumPaymentDocumentation"
                        disabled={disabled || editDisabled}
                      />
                    </LotusFormItem>
                  </LotusFormSection>
                  </Grid>
                </Grid>
              }
            </Grid> 
          );
        }}
      </Formik>
    </Grid>) || <div></div>
}
import React, { useEffect, useState } from 'react';
import { Formik, Form, yupToFormErrors } from 'formik';
import axios from '../../../../axiosInterceptor';
import { debounce } from 'lodash';
import { normalizeData } from 'lib/utils';
import { useAgencyRequests } from 'contexts/AgencyRequestsContext';
import * as yup from 'yup';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusPhoneInput from 'components/widgets/Forms/LotusPhoneInput';
import AddressFieldPanel from 'components/widgets/Composites/AddressFieldPanel';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import Typography from '@mui/material/Typography';
import { useUserAgency } from 'contexts/UserAgencyContext';

const phoneRegExp = /\d{10}/;
let requestSubmitted = false;

const validationSchema = yup.object({
  faxNumber: yup.string().matches(phoneRegExp, {
    message: 'Enter a valid fax number.',
    excludeEmptyString: true,
  }),
  email: yup
    .string('')
    .email("Email Address must be a valid email")
    .required('Email Address is required')
});

export default function AgencyForm({ afterSave }) {

  const { userAgency } = useUserAgency();
  const { createAgencyRequest } = useAgencyRequests();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    setInitialValues({
      name: '',
      shortName: '',
      mailingAddress: {
        address1: '', 
        address2: '', 
        city: '', 
        state: '', 
        zip: '',
        county: ''
      },
      email: '',
      phoneNumber: '',
      faxNumber: '',
      granteeAgencyId: userAgency.isGranteeAgency ? userAgency.granteeAgencyId : null
    });
  }, [userAgency]);
  
  const handleSubmitAgency = async (agencyData) => {
    await createAgencyRequest(agencyData);
    requestSubmitted = true;
    if (afterSave) {
      await afterSave(agencyData);
    }
  };

  const dupChecker = debounce(async (agencyValues) => {
    if (agencyValues && !requestSubmitted) {
      try {
        const { data } = await axios.post(
          '/api/agencies',
          {
            ...agencyValues,
            operationName: 'checkForDuplicates'
          }
        );
        return normalizeData(data).body.duplicates.length === 0;
      } catch (error) {
        return false;
      }
    } else {
      return true;
    }
  }, 500);

  return (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={async (values) => {
            let result = {};
            try {
              validationSchema.validateSync(values, { abortEarly: false });
            } catch (err) {
              result = yupToFormErrors(err);
            }
            if (
              values.name.trim() ||
              values.mailingAddress.address1.trim() ||
              values.mailingAddress.address2.trim() ||
              values.mailingAddress.city.trim() ||
              values.mailingAddress.state.trim() ||
              values.mailingAddress.zip.trim()
            ) {
              const ok = await dupChecker({
                name: values.name,
                address: {
                  address1: values.mailingAddress.address1,
                  address2: values.mailingAddress.address2,
                  city: values.mailingAddress.city,
                  state: values.mailingAddress.state,
                  zip: values.mailingAddress.zip
                },
              });
              if (ok === false) {
                // this seems to be undefined the first time
                return {
                  name: 'Duplicate agency found',
                };
              }
            }
            return result;
          }}
          onSubmit={async (values, actions) => {
            await handleSubmitAgency(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            values,
            setFieldValue,
          }) => {
            return values && (
              <Form onSubmit={handleSubmit}>
                <LotusForm>
                  <LotusFormItem>
                    <LotusTextInput
                      name="name"
                      required
                      label="Agency Name"
                    />
                  </LotusFormItem>

                  <LotusFormItem>
                    <LotusTextInput
                      id="shortName"
                      name="shortName"
                      label="Agency Short Name"
                      value={values.shortName}
                      onChange={handleChange}
                      error={touched.shortName && Boolean(errors.shortName)}
                    />
                  </LotusFormItem>

                  <LotusFormSection name="Mailing Address">
                    <AddressFieldPanel required name='mailingAddress'/>
                  </LotusFormSection>

                  <LotusFormSection name='General Agency Contact Information'>
                    <Typography variant='h6'>Main agency email, phone number, and fax number (if applicable)</Typography>

                    <LotusFormItem>
                      <LotusTextInput
                        autoComplete="off"
                        id="email"
                        name="email"
                        label="Email Address"
                        required
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                      />
                    </LotusFormItem>

                    <LotusFormItem>
                      <LotusPhoneInput
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number"
                        required
                        value={values.phoneNumber}
                        onChange={handleChange}
                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      />
                    </LotusFormItem>

                    <LotusFormItem>
                      <LotusPhoneInput
                        id="faxNumber"
                        name="faxNumber"
                        label="Fax Number"
                        value={values.faxNumber}
                        onChange={handleChange}
                        error={touched.faxNumber && Boolean(errors.faxNumber)}
                      />
                    </LotusFormItem>
                  </LotusFormSection>
                  <LotusFormItem>
                    <LotusButton type="submit">
                      Submit Request
                    </LotusButton>
                  </LotusFormItem>
                </LotusForm>
              </Form>
            );
          }}
        </Formik>
  );
}

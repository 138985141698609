import ActionDialog from 'components/Dialogs/ActionDialog';
import ApproveTemplateRequestForm from '../forms/ApproveTemplateRequestForm';
import React from 'react';
import LotusButton from 'components/widgets/Forms/LotusButton';

export default ({ actionRequest, open, handleClose, handleSubmit }) => {
  return (
    <ActionDialog
      title={`Approve Template Request`}
      open={open}
      handleClose={handleClose}
      content={<ApproveTemplateRequestForm actionRequest={actionRequest} />}
      actions={
        <div>
          <LotusButton onClick={handleClose}>
            Cancel
          </LotusButton>

          <LotusButton
            onClick={() => handleSubmit(actionRequest)}
          >
            Approve Request
          </LotusButton>
        </div>
      }
    />
  );
};

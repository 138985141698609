import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import ProgramLinker from '../linkers/ProgramLinker';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';

export default function AgencyProgramsForm({ agencyId, handleSubmit }) {
  const { programs, loadAgencyPrograms } = useAgencyManagement();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (agencyId && !programs) {
      loadAgencyPrograms(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    const initialVals =
      agencyId && programs
        ? {
            id: agencyId,
            selectedPrograms: programs.map((ap) => ap.id),
          }
        : {
            selectedPrograms: [],
          };
    setInitialValues(initialVals);
  }, [programs]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async (values, actions) => {
        await handleSubmit(values);
        actions.setSubmitting(false);
      }}
    >
      {({ handleSubmit, handleChange, values }) => {
        return values && (
          <Form onSubmit={handleSubmit}>
            <LotusForm>
              <LotusFormSection name="Programs for Agency">
                <ProgramLinker
                  values={values}
                  handleChange={handleChange}
                />
              </LotusFormSection>
              <LotusButton type="submit">
                Submit
              </LotusButton>
            </LotusForm>
          </Form>
        );
      }}
    </Formik>
  );
}

import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import LotusCheckbox from 'components/widgets/Forms/LotusCheckbox';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useUserAgency } from 'contexts/UserAgencyContext';

export default function SelectAgenciesPanel({
  values,
  handleChange,
  hasTemplate
}) {

  const { userAgency } = useUserAgency();
  const { agenciesForGrantee, loadAgenciesForGrantee } = useAgencySearch();

  useEffect(() => {
    if (userAgency && !agenciesForGrantee) {
      loadAgenciesForGrantee(userAgency.granteeAgencyId);
    }
  }, [userAgency]);

  return (
    agenciesForGrantee && values && (
          <FormControl component="fieldset">
            <Typography variant="body1">
              {hasTemplate ? 'Agencies that have this template' : 'Apply template to selected subagencies'}
            </Typography>
            <FormGroup>
              {
                <>
                  {agenciesForGrantee
                    .filter(a => a.id !== userAgency.granteeAgencyId)
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((agency) => {
                      let agencyUserCount = values.userCounts.find(ct => ct.agencyId === agency.id);
                      const userCount = agencyUserCount?.userCount || 0;
                      const handleCheckboxChange = (e, values) => {
                        if (userCount > 0) {
                          if (!e.target.checked) {
                            values.errorIds.push(e.target.value);
                          } else if (values.errorIds.includes(e.target.value)) {
                            values.errorIds.splice(values.errorIds.indexOf(e.target.value), 1);
                          }
                        }
                        handleChange(e);
                      }
                      return (
                        <div key={`agency-${agency.id}`}>
                          <LotusCheckbox
                            name={'selectedSubagencies'}
                            label={agency.name}
                            value={agency.id}
                            onChange={(e) => handleCheckboxChange(e, values)}
                            checked={
                              values.selectedSubagencies.find(
                                (agencyId) => agencyId === agency.id
                              ) !== undefined
                            }
                          />
                          {userCount > 0 && values.errorIds?.find((id) => id === agency.id) !== undefined &&
                            <Typography key={agency.id} variant="body2" color="error">This Template is assigned to {userCount} agency users. 
                              Please remove the permission from agency users before removing it.
                            </Typography>
                          }
                        </div>
                      );
                    })}
                </>
              }
            </FormGroup>
          </FormControl>
      )
  );
}

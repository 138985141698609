import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusForm from 'components/widgets/Forms/LotusForm';
import Typography from '@mui/material/Typography';

const validationSchema = yup.object({
  denyReason: yup
    .string('Reason must be filled in')
    .required('Reason is required'),
});

const initialValues = {
  denyReason: '',
};

function DenyAgencyRequestForm({
  actionRequest,
  inlineTable,
  onSave,
  onCancel,
}) {
  return actionRequest ? (
    <LotusForm>
        <Formik
          initialValues={
            inlineTable
              ? { ...initialValues, denyReason: actionRequest.deniedReason }
              : initialValues
          }
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await onSave(actionRequest, values.denyReason);
            actions.setSubmitting(false);
            if (!inlineTable) {
              actions.resetForm();
            }
          }}
        >
          {({ handleSubmit, handleChange, touched, errors, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {!inlineTable && (
                  <LotusFormItem>
                    <Typography>{'Deny '+ actionRequest.agencyName + '?'}</Typography>
                  </LotusFormItem>
                )}
                <LotusFormItem>
                  <LotusTextInput
                    name="denyReason"
                    label="Denial Reason"
                    rows={4}
                    required
                    multiline={true}
                  />
                </LotusFormItem>
                {inlineTable && (
                  <div style={{ marginTop: 10, color: 'blue', cursor: 'pointer' }}>
                    <Typography
                      onClick={onCancel}
                    >Cancel</Typography>
                    <Typography
                      onClick={handleSubmit}
                    >Update</Typography>
                  </div>
                )}
                {!inlineTable && (
                  <div style={{ float: 'right' }}>
                    <LotusButton onClick={handleSubmit}>
                      Deny Request
                    </LotusButton>
                    <LotusButton
                      onClick={onCancel}
                      style={{ marginLeft: 10 }}
                    >
                      Cancel
                    </LotusButton>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
    </LotusForm>
  ) : (
    ''
  );
}

export default DenyAgencyRequestForm;

import React from 'react';
import TemplatesList from './TemplatesList';
import AddTemplatePanel from './AddTemplatePanel';
import EditTemplatePanel from './EditTemplatePanel';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums'
import LotusButton from 'components/widgets/Forms/LotusButton';
import Stack from '@mui/material/Stack';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function TemplatesPage() {
  const { verifyPermission } = useAuthState();
  const hasTemplatePermission = verifyPermission(PERMISSIONS.REQUEST_NEW_TEMPLATE);
  const navigate = useNavigate();
  const { page } = useParams();

  const addTemplateClick = () => {
    navigate('/templates/create');
  };

  const showTemplateList = () => {
    navigate('/templates/list');
  };

  return (
    <div>
        {page === 'list' && (
          <>
            <LotusSpacedBlock>
              <Stack justifyContent="flex-end" direction='row'>
                <LotusButton
                  style={{ cursor: hasTemplatePermission ? 'pointer': 'default'}}
                  disabled={!hasTemplatePermission}
                  onClick={() => hasTemplatePermission && addTemplateClick()}
                >
                  Request New Template
                </LotusButton>
              </Stack>
            </LotusSpacedBlock>
            <TemplatesList hasTemplateEditPermission={hasTemplatePermission}/>
          </>
        )}

        {page === 'create' && (
            <AddTemplatePanel handleBackClicked={showTemplateList} />
        )}

        {page === 'edit' && (
            <EditTemplatePanel handleBackClicked={showTemplateList} />
        )}
    </div>
  );
}

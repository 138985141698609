import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import LotusForm from 'components/widgets/Forms/LotusForm';
import { ErrorMessage, Form, Formik } from 'formik';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusDatePicker from 'components/widgets/Forms/LotusDatePicker';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnrollments } from 'contexts/EnrollmentsContext';
import { useClient } from 'contexts/ClientContext';
import moment from "moment";
import { PERMISSIONS } from "lib/permissionEnums";
import { useAuthState } from 'contexts/AuthProvider';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusFileViewer from 'components/widgets/LotusFileViewer';
import { Alert, Button, Typography } from '@mui/material';
import ActionDialog from 'components/Dialogs/ActionDialog';
import { Table } from '@lotus/components';
import { useAppStatus } from 'contexts/AppStatusContext';
import LotusFileUploader from 'components/widgets/LotusFileUploader';
import { generateThumbnailsForUrl } from 'lib/documents';
import LotusPageSubSection from 'components/widgets/Layout/LotusPageSubSection';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusButton from 'components/widgets/Forms/LotusButton';

export default function AdapEnrollmentViewer() {
  
  const {clientId, lvl3: programId} = useParams();
  const { client } = useClient();
  const {loadEnrollmentsForClient, adapProgramEnrollments, terminateAdapProgramEnrollment} = useEnrollments();
  const [initialValues, setInitialValues] = useState();
  const [enrollments, setEnrollments] = useState();
  const [showTerminationForm, setShowTerminationForm] = useState(false);
  const [terminationInfo, setTerminationInfo] = useState();
  const [fullTerminationReasonList, setFullTerminationReasonList] = useState();
  const [selectableTerminationReasonList, setSelectableTerminationReasonList] = useState();
  const navigate = useNavigate();
  const { user: loggedInUser, verifyPermission } = useAuthState();
  const isAdapProcessor = verifyPermission(PERMISSIONS.ADAP_PROCESSOR);

  const { 
    adapEnrollmentTerminationReasons, medicationAssistanceLevels, premiumPaymentFrequencies, 
    premiumAssistanceCoverageTypes, privateInsuranceSources, loadClientInfoPicklists } = useLists();

  useEffect(() => {
    if (!adapProgramEnrollments) {
      loadEnrollmentsForClient(clientId, client.agencyId);
    }
  }, []);

  useEffect(() => {
    if (adapEnrollmentTerminationReasons) {
      setFullTerminationReasonList(adapEnrollmentTerminationReasons.map(r => {return {value: r.id, label: r.itemName}}));
      setSelectableTerminationReasonList(adapEnrollmentTerminationReasons.filter(r => r.itemKey !== 'no_recert' && r.itemKey !== 'elig_crit_chg').map(r => {return {value: r.id, label: r.itemName}}));
    }
  }, [adapEnrollmentTerminationReasons]);

  useEffect(() => {
    if (adapProgramEnrollments && programId) {
      // Find current enrollment or most recent one
      let targetEnrollment = null;
      let futures = [];
      let now = moment().startOf('day');
      adapProgramEnrollments?.forEach(e => {
        let startDateMoment = moment(e.startDate,'MM/DD/YYYY');
        if (e.programId === programId && startDateMoment <= now && (!targetEnrollment || startDateMoment > moment(targetEnrollment.startDate,'MM/DD/YYYY'))) {
          targetEnrollment = e;
        }
        if (e.programId === programId && startDateMoment > now) {
          futures.push(e);
        }
      });
      let viewableEnrollments = [];
      if (targetEnrollment) {
        viewableEnrollments.push(targetEnrollment);
      }
      viewableEnrollments.push(...futures);
      setEnrollments(viewableEnrollments);
    }
  }, [programId, adapProgramEnrollments]);

  useEffect(() => {
    if (!medicationAssistanceLevels) {
      loadClientInfoPicklists();
    }
  }, [medicationAssistanceLevels]);

  useEffect(() => {
    if (enrollments && medicationAssistanceLevels && premiumPaymentFrequencies && premiumAssistanceCoverageTypes && privateInsuranceSources) {
      let formEnrollments = enrollments.map(enrollment => {
        return {
          id: enrollment.id,
          clientId: enrollment.clientId,
          programId: enrollment.programId,
          programName: enrollment.programName,
          startDate: enrollment.startDate,
          endDate: enrollment.endDate,
          subprogramNames: enrollment.subprograms.map(s => s.name).join(", "),
          medicationAssistanceEnabled: enrollment.medicationAssistanceEnabled,
          medicationAssistanceLevelText: medicationAssistanceLevels.find(l => enrollment.medicationAssistanceLevel === l.itemKey)?.itemName,
          premiumAssistanceEnabled: enrollment.premiumAssistanceEnabled,
          premiumAssistanceCoverageType: premiumAssistanceCoverageTypes.find(t => enrollment.premiumAssistanceCoverageType === t.itemKey)?.itemName,
          authorizedAdapPremiumAmount: enrollment.authorizedAdapPremiumAmount,
          authorizedAdapPremiumFrequencyName: premiumPaymentFrequencies.find(f => enrollment.authorizedAdapPremiumFrequencyId === f.id)?.itemName,
          authorizedAdapInsuranceStartDate: enrollment.authorizedAdapInsuranceStartDate,
          authorizedAdapInsuranceCarrier: enrollment.authorizedAdapInsuranceCarrier,
          authorizedAdapInsurancePlan: enrollment.authorizedAdapInsurancePlan,
          authorizedAdapInsurancePolicySource: privateInsuranceSources.find(f => enrollment.authorizedAdapInsurancePolicySourceId === f.id)?.itemName,
          terminationReasonId: enrollment.terminationReasonId,
          terminationComments: enrollment.terminationComments,
          terminationAttachments: enrollment.terminationAttachments,
        };
      });
      setInitialValues({enrollmentList: formEnrollments});
    }
  }, [enrollments, medicationAssistanceLevels, premiumPaymentFrequencies, premiumAssistanceCoverageTypes, privateInsuranceSources]);

  const viewTerminateEnrollment = (enr) => {
    setShowTerminationForm(true);
    setTerminationInfo({
      id: enr.id,
      programId: enr.programId,
      programName: enr.programName,
      clientId: enr.clientId,
      clientName: client.name,
      terminationReasonId: '',
      terminationComments: '',
      attachments: []
    });
  }

  const terminateEnrollment = async (values) => {
    await terminateAdapProgramEnrollment(values);
    navigate(`/client/${clientId}/enrollment/list`);
  }

  const isCurrentEnrollment = (enr) => {
    let now = moment().startOf('day');
    return (moment(enr.startDate,'MM/DD/YYYY') <= now && now <= moment(enr.endDate,'MM/DD/YYYY'));
  }

  const isFutureEnrollment = (enr) => {
    let now = moment().startOf('day');
    return (moment(enr.startDate,'MM/DD/YYYY') > now);
  }

  return initialValues && (
    <Formik
      enableReinitialize
      initialValues={initialValues}
    >
      {({values}) => {
        return values && (
          <>
            {values.enrollmentList.map((enr, index) => {
              return (
                <div key={enr.id} style={{backgroundColor: isFutureEnrollment(enr) ? '#E0F1F3' : 'inherit', padding: 10, marginBottom: 30}}>
                  <LotusPageSection 
                    header={`${enr.programName} Enrollment`}
                    actions={
                      <>
                      {isAdapProcessor && isCurrentEnrollment(enr) && !enr.terminationReasonId &&
                        <Button variant="outlined" color="error" onClick={() => {viewTerminateEnrollment(enr);}}>
                          <Typography variant="h6">Terminate Enrollment</Typography>
                        </Button>
                      }
                      {isFutureEnrollment(enr) &&
                        <Alert severity='info' sx={{ bgcolor: '#E0F1F3' }}>EFFECTIVE {enr.startDate}</Alert>
                      }
                      </>
                    }
                  >
                    <LotusForm>
                      <LotusFormItem>
                        <LotusTextInput
                          name={`enrollmentList.${index}.subprogramNames`}
                          label="Sub-Program"
                          disabled={true}
                        />
                      </LotusFormItem>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <LotusFormItem>
                            <LotusDatePicker
                              name={`enrollmentList.${index}.startDate`}
                              label="Enrollment Effective Start Date"
                              disabled={true}
                            />
                          </LotusFormItem>
                        </Grid>
                        <Grid item xs={6}>
                          <LotusFormItem>
                            <LotusDatePicker
                              name={`enrollmentList.${index}.endDate`}
                              label="Enrollment Effective End Date"
                              disabled={true}
                            />
                          </LotusFormItem>
                        </Grid>
                      </Grid>
                      {enr.medicationAssistanceEnabled &&
                        <LotusFormSection name='Medication Assistance'>
                          <LotusFormItem>
                            <LotusTextInput
                              name={`enrollmentList.${index}.medicationAssistanceLevelText`}
                              label="Level"
                              disabled={true}
                            />
                          </LotusFormItem>
                        </LotusFormSection>
                      }
                      {enr.premiumAssistanceEnabled &&
                        <LotusFormSection name='Premium Assistance'>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <LotusFormItems>
                                <LotusDatePicker
                                  name={`enrollmentList.${index}.authorizedAdapInsuranceStartDate`}
                                  label="Insurance Start Date"
                                  disabled={true}
                                /> 
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapPremiumAmount`}
                                  label="Amount"
                                  disabled={true}
                                  maxLength={16}
                                  floatingPoint
                                  adornment="$"
                                />   
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapPremiumFrequencyName`}
                                  label="Frequency"
                                  disabled={true}
                                />
                              </LotusFormItems>
                            </Grid>
                            <Grid item xs={6}>
                              <LotusFormItems>
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapInsurancePolicySource`}
                                  label="Policy Source"
                                  disabled={true}
                                />
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapInsuranceCarrier`}
                                  label="Insurance Carrier"
                                  disabled={true}
                                />   
                                <LotusTextInput
                                  name={`enrollmentList.${index}.authorizedAdapInsurancePlan`}
                                  label="Insurance Plan"
                                  disabled={true}
                                />    
                              </LotusFormItems>
                            </Grid>
                          </Grid>
                        </LotusFormSection>
                      }
                      {enr.terminationReasonId &&
                        <LotusFormSection name='Termination'>
                          <LotusFormItems>
                            {fullTerminationReasonList && 
                              <LotusSelect
                                name={`enrollmentList.${index}.terminationReasonId`}
                                label="Reason for Termination"
                                items={fullTerminationReasonList}
                                disabled
                              />
                            }
                            <LotusTextInput
                              name={`enrollmentList.${index}.terminationComments`}
                              multiline
                              minRows={5}
                              label="Comments"
                              disabled
                            />
                            <EnrollmentTerminationDocumentationList
                              clientId={client.id}
                              documents={enr.terminationAttachments} 
                              disabled={true}
                            />
                          </LotusFormItems>
                        </LotusFormSection>
                      }
                    </LotusForm>
                  </LotusPageSection>
                </div>
              );
            })}
            <ActionDialog
              open={showTerminationForm}
              maxWidth="lg"
              content={
                <Formik
                  initialValues={terminationInfo}
                  enableReinitialize
                  onSubmit={async (values) => {
                    await terminateEnrollment(values);
                    setShowTerminationForm(false);
                  }}
                >
                  {({handleSubmit, values, setFieldValue}) => {
                    const handleCancel = () => {
                      setShowTerminationForm(false);
                    }
                    const addAttachment = (attachment) => {
                      setFieldValue('attachments', [...values.attachments, attachment]);
                    }
                    return values && (
                      <LotusPageSection
                        header={`Terminate ${values.programName} Enrollment`}
                        actions={
                          <>
                          <LotusButton variant="outlined" closeIcon onClick={() => {handleCancel();}}>
                            <Typography variant="h6">Cancel</Typography>
                          </LotusButton>
                          <LotusButton variant="contained" color="error" onClick={() => {handleSubmit(values);}}>
                            <Typography variant="h6">Terminate</Typography>
                          </LotusButton>
                          </>
                        }
                      >
                        <Form onSubmit={handleSubmit}>
                          <LotusForm>
                            <LotusFormItems>
                              <span>Date: {moment().format('MMMM Do, YYYY')} | By: {loggedInUser.firstName + ' ' + loggedInUser.lastName}</span>
                              <span>Client Name: {values.clientName}</span>
                              
                              {selectableTerminationReasonList && 
                                <LotusSelect
                                  name="terminationReasonId"
                                  label="Reason for Termination"
                                  items={selectableTerminationReasonList}
                                  required
                                />
                              }
                              <LotusTextInput
                                name="terminationComments"
                                multiline
                                minRows={5}
                                label="Comments"
                              />
                              <EnrollmentTerminationDocumentationList
                                clientId={client.id}
                                documents={values.attachments} 
                                onDocumentAdded={addAttachment} 
                                disabled={false}
                              />
                            </LotusFormItems>
                          </LotusForm>
                        </Form>
                      </LotusPageSection>
                    );
                  }}
                </Formik>
              }
            />
          </>
        );
      }}
    </Formik>
  );
}

function EnrollmentTerminationDocumentationList ({ clientId, documents, onDocumentAdded, disabled}) {

  const [showAddDocument, setShowAddDocument] = useState(false);
  const [allDocuments, setAllDocuments] = useState();
  const [documentToView, setDocumentToView] = useState();

  useEffect(() => {
    if (documents) {
      documents.sort((a, b) => (a.displayName > b.displayName ? -1 : 1));
      setAllDocuments(documents);
    }
  }, [documents]);

  const columns = [
    {
      label: 'File Name',
      name: 'displayName',
    }
  ];

  const addDocument = async (document) => {
    setShowAddDocument(false);

    if (onDocumentAdded) {
      onDocumentAdded(document);
    }
  };

  const viewDocumentForRow = async (rowData, rowMeta) => {
    const doc = allDocuments[rowMeta.dataIndex];
    setDocumentToView(doc);                 
  };

  const closeDocumentView = () => {
    setDocumentToView(null);
  };

  return (
    <Grid item xs={12}>
      <LotusPageSubSection header="Termination Documentation" >
        <LotusSpacedBlock>
        {allDocuments && (
          <Table
            columns={columns}
            data={allDocuments}
            options={{
              print: false,
              download: false,
              filter: false,
              searchOpen: false,
              search: false,
              viewColumns: false,
              pagination: false
            }}
            handleRowClicked={viewDocumentForRow}
          />
        )}
        </LotusSpacedBlock>
        {!disabled &&
          <LotusButton variant="contained" plusIcon onClick={() => {setShowAddDocument(true);}}>
            Add Attachment
          </LotusButton>
        }
        <ActionDialog
          fullWidth={true}
          maxWidth="lg"
          open={Boolean(documentToView)}
          content={
            <LotusFileViewer 
              clientId={clientId} 
              subfolderName="adapTermDocs"
              title={documentToView?.displayName || ''} 
              file={[documentToView]} 
              handleClose={closeDocumentView}/>}
        />

        <ActionDialog
          maxWidth="sm"
          open={showAddDocument}
          content={
            <TerminationDocumentUploader
              clientId={clientId}
              handleCancel={() => {setShowAddDocument(false);}}
              handleSave={addDocument}
            />
          }
        />
      </LotusPageSubSection>
    </Grid>
  );
};

const TerminationDocumentUploader = ({clientId, handleCancel, handleSave}) => {
  const { addBusyBee, removeBusyBee } = useAppStatus();
  const [ thumbnails, setThumbnails ] = useState([]);
  const [ initialValues, setInitialValues ] = useState();

  useEffect(() => {
    setInitialValues({
      document: {}
    });
  }, []);

  return (
      <Grid container>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={async (values) => {
            let result = {};
            if (values.docErr) {
              result.docErr = values.docErr;
            }
            if (Object.keys(values.document).length === 0) {
              result.document = "Document is required";
            }
            return result;
          }}
          onSubmit={async (newValues, actions) => {
            await handleSave(newValues.document);
            actions.setSubmitting(false);
          }}
        >
          {({ values, setFieldTouched, handleSubmit, setFieldValue }) => {
            return values && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} alignItems="center" container style={{paddingBottom: 10, borderBottom: "1px solid grey", marginBottom: 20}}>
                    <Grid item xs={6}>
                      <Typography variant="h2">Upload Enrollment Termination Document</Typography>
                    </Grid>
                    <Grid item xs={6} container justifyContent='flex-end'>
                      <Button variant="text" onClick={() => {handleCancel();}} style={{marginRight: 10}}>
                        <Typography variant="h6">Cancel</Typography>
                      </Button>
                     <Button onClick={() => {handleSubmit(values);}}>
                        <Typography variant="h6">Upload</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <LotusFileUploader
                      name="terminationDocumentUploader"
                      maxFiles={1}
                      clientId={clientId}
                      subfolderName="adapTermDocs"
                      onFileAdded={async (newFile, base64Str) => {
                        console.log('Added', newFile);
                        try {
                          addBusyBee('generatingThumbnail');
                          const thumbnails = await generateThumbnailsForUrl( base64Str, newFile.type, [100]);
                          setThumbnails(thumbnails);
                          setFieldTouched('docErr');
                          setFieldValue('docErr', '');
                          setFieldTouched('document');
                          setFieldValue('document', newFile);
                        }
                        catch (err) {
                          console.log(err);
                          setFieldTouched('document');
                          setFieldTouched('docErr');
                          setFieldValue('document', {});
                          setFieldValue('docErr', 'An error occurred while generating thumbnail images for file');
                        } finally {
                          removeBusyBee('generatingThumbnail');
                        }
                      }}
                      onFileDeleted={(newFile) => {
                        console.log('Deleted', newFile);
                        setFieldTouched('document');
                        setFieldValue('document', {});
                        setThumbnails(null);
                      }}
                      onError={(err, action, msg) => {
                        console.log(err, msg);
                        setFieldTouched('document');
                        setFieldTouched('docErr');
                        const errMsg = msg || 'An error occurred while processing the document';
                        if (action !== 'preview') {
                          setFieldValue('document', {});
                        }
                        setFieldValue('docErr', errMsg);
                      }}
                    />
                    <div style={{color:'#b53f3f', marginTop: 10}}>
                      <ErrorMessage name="document" />
                    </div>
                    <div style={{color:'#b53f3f', marginTop: 10}}>
                      <ErrorMessage name="docErr" />
                    </div>
                  </Grid>
                </Grid>
                <div style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                  {thumbnails && thumbnails.map((t,idx) => {
                    return (<img key={idx} alt='Thumbnail of documents' src={t} style={{maxWidth: 100, maxHeight: 100, marginRight: 10}}/>);
                  })}
                </div>
              </Grid>
            );
          }}
        </Formik>
      </Grid>
    ) || <div></div>
}
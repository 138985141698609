import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import DashboardIcon from '@mui/icons-material/ViewCompact';
import FileUpload from '@mui/icons-material/FileUpload';

import AgencyIcon from '@mui/icons-material/Business';
import TemplatesIcon from '@mui/icons-material/PlaylistAddCheck';
import MyClientsIcon from '@mui/icons-material/SupervisorAccount';
import UserManagementIcon from '@mui/icons-material/SupervisedUserCircle';
import ApplicationIcon from '@mui/icons-material/ContactPage';
// todo: use warning icon for poc, change later when clean and formalized
import CollapseIcon from '@mui/icons-material/ArrowBackIos';
import ExpandIcon from '@mui/icons-material/ArrowForwardIos';
import classNames from 'classnames';
import { useNavigation } from '../contexts/NavigationContext';
import { useAuthState } from 'contexts/AuthProvider';
import SelectedIcon from '@mui/icons-material/Brightness1';
import FormManagementIcon from '@mui/icons-material/Reorder';
import GroupwareLogo from 'components/icons/GroupwareLogo';
import ProgramsIcon from '@mui/icons-material/Accessibility';
import { PERMISSIONS } from 'lib/permissionEnums'
import { useUserAgency } from 'contexts/UserAgencyContext';

const PREFIX = 'NavBar';

const classes = {
  icon: `${PREFIX}-icon`,
  linkText: `${PREFIX}-linkText`,
  section: `${PREFIX}-section`,
  sectionItem: `${PREFIX}-sectionItem`,
  sectionSubItem: `${PREFIX}-sectionSubItem`,
  sectionDetailWrapper: `${PREFIX}-sectionDetailWrapper`,
  selected: `${PREFIX}-selected`,
  userInfo: `${PREFIX}-userInfo`,
  paper: `${PREFIX}-paper`,
  expandIconContainer: `${PREFIX}-expandIconContainer`,
  collapseIconContainer: `${PREFIX}-collapseIconContainer`,
  collapsed: `${PREFIX}-collapsed`,
  paperCollapsed: `${PREFIX}-paperCollapsed`,
  selectedItem: `${PREFIX}-selectedItem`,
  selectedSubItem: `${PREFIX}-selectedSubItem`,
  footer: `${PREFIX}-footer`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.icon}`]: {
    float: 'left',
    color: '#fff',
    marginRight: 16,
    marginTop: -2,
  },

  [`& .${classes.linkText}`]: {
    color: theme.palette.text.secondary,
  },

  [`& .${classes.section}`]: {
    padding: '16px 0 15px 24px',
    marginBottom: '12px',
  },

  [`& .${classes.sectionItem}`]: {
    padding: '18px 0px 0px 68px',
    position: 'relative',
  },

  [`& .${classes.sectionSubItem}`]: {
    padding: '12px 0 12px 96px',
    position: 'relative',
  },

  [`& .${classes.sectionDetailWrapper}`]: {
    padding: '0px 0 9px 0',
  },

  [`& .${classes.selected}`]: {
    backgroundColor: 'rgba(41 64 85/60%)',
  },

  [`& .${classes.userInfo}`]: {
    borderBottom: '1px solid grey',
    paddingBottom: 32,
    marginBottom: 24,
  },

  [`&.${classes.paper}`]: {
    height: '100%',
    minHeight: 'calc(100vh - 72px)',
    paddingTop: 32,
    paddingBottom: 100,
    background: theme.palette.primary.light,
    color: '#fff',
    borderRadius: '0 0 8px 8px',
    position: 'relative',
  },

  [`& .${classes.expandIconContainer}`]: {
    paddingLeft: 32,
  },

  [`& .${classes.collapseIconContainer}`]: {
    float: 'right',
    paddingRight: 10,
  },

  [`& .${classes.collapsed}`]: {
    maxWidth: '100%',
    paddingRight: 0,
    marginRight: 0,
  },

  [`&.${classes.paperCollapsed}`]: {
    paddingRight: 10,
  },

  [`& .${classes.selectedItem}`]: {
    fontSize: 6,
    position: 'absolute',
    left: 48,
    top: 25,
  },

  [`& .${classes.selectedSubItem}`]: {
    fontSize: 6,
    position: 'absolute',
    left: 76,
    top: 18,
  },

  [`& .${classes.footer}`]: {
    position: 'absolute',
    bottom: 32,
    width: '100%',
    textAlign: 'center',
  },
}));

export default function NavBar({ userAgency, collapsedClicked, collapsed }) {
  const { pathname } = useLocation();
  const { state: navigationState } = useNavigation();
  const { verifyPermission } = useAuthState();
  const { userAgencyOptionalFeatures } = useUserAgency();

  const dashboardSection = {
    selected: pathname.match(/^\/dashboard/) !== null,
    link: '/dashboards',
    icon: <DashboardIcon />,
    linkText: 'Dashboard',
    sectionItems: [],
    permissions: [],
    agencyTypes: ['grantee', 'agency'],
    hide: !userAgencyOptionalFeatures.dashboard
  };

  const importsExportsSection = {
    selected: pathname.match(/^\/imports/) !== null,
    link: '/imports/list/imports',
    icon: <FileUpload />,
    linkText: 'Imports and Exports',
    sectionItems: [],
    permissions: [PERMISSIONS.IMPORT_CLIENT_DATA, PERMISSIONS.IMPORT_SERVICES_DATA, PERMISSIONS.IMPORT_AGENCY_CONFIGURATION_DATA, PERMISSIONS.EXPORT_GRANTEE_DATA,PERMISSIONS.EXPORT_CARRIER_PREMIUMS],
    agencyTypes: ['grantee', 'agency'],
  };


  const clientsSection = {
    selected: pathname.match(/^\/client/) !== null,
    link: '/clients/list/myclients',
    icon: <MyClientsIcon />,
    linkText: 'Client List',
    permissions: [],
    sectionItems: [
      {
        selected:
          pathname.match(/^\/client\//) !== null &&
          navigationState.selectedClient,
        link: pathname,
        linkText: navigationState.selectedClient,
        sectionSubItems: [],
      },
    ],
    agencyTypes: ['grantee', 'agency'],
  };
  
  const applicationsSection = {
    selected: pathname.match(/^\/applications/) !== null,
    link: '/applications/list/allapplications',
    icon: <ApplicationIcon />,
    linkText: 'Applications',
    permissions: [
      PERMISSIONS.APPLICATION_SUBMITTER, 
      PERMISSIONS.APPLICATION_COMPLETER, 
      PERMISSIONS.RW_PROCESSOR_SINGLE_AGENCY, 
      PERMISSIONS.RW_PROCESSOR_MULTIPLE_AGENCY, 
      PERMISSIONS.ADAP_PROCESSOR
    ],
    sectionItems: [
      {
        selected:
          pathname.match(/^\/applications\//) !== null &&
          navigationState.selectedApplication,
        link: pathname,
        linkText: navigationState.selectedApplication,
        sectionSubItems: [],
      },
    ],
    agencyTypes: ['root', 'grantee', 'agency']
  };

  const agenciesSection = {
    selected: pathname.match(/^\/agency/) !== null,
    link: '/agencies/list',
    icon: <AgencyIcon />,
    linkText: 'Agency Management',
    permissions: [PERMISSIONS.REQUEST_SUB_AGENCY, PERMISSIONS.APPROVE_SUB_AGENCY, PERMISSIONS.MODIFY_AGENCY],
    sectionItems: [
      {
        selected: pathname.match(/^\/agencies\/list/) !== null,
        link: '/agencies/list',
        linkText: 'Agency List',
        sectionSubItems: [
          {
            selected:
              pathname.match(/^\/agency\//) !== null &&
              navigationState.selectedAgency,
            link: pathname,
            linkText: navigationState.selectedAgency,
          },
        ],
      },
      {
        selected: pathname.match(/^\/agencies\/requests/) !== null,
        link: '/agencies/requests',
        linkText: 'Agency Requests',
        sectionSubItems: [],
      },
    ],
    agencyTypes: ['root', 'grantee'],
  };

  const permissionsSection = {
    selected: pathname.match(/^\/templates/) !== null,
    link: '/templates/list',
    icon: <TemplatesIcon />,
    linkText: 'Permission Management',
    permissions: [PERMISSIONS.REQUEST_NEW_TEMPLATE, PERMISSIONS.APPROVE_TEMPLATE],
    sectionItems: [
      {
        selected: pathname.match(/^\/templates\/list/) !== null,
        link: '/templates/list',
        linkText: 'Template List',
        sectionSubItems: [
          {
            selected:
              pathname.match(/^\/edit\//) !== null &&
              navigationState.selectedTemplate,
            link: pathname,
            linkText: navigationState.selectedTemplate,
          },
        ],
      },
      {
        selected: pathname.match(/^\/templates\/requests/) !== null,
        link: '/templates/requests',
        linkText: 'Template Requests',
        sectionSubItems: [],
      },
    ],
    agencyTypes: ['grantee'],
  };

  const usersSection = {
    selected: pathname.match(/^(\/users)/) !== null,
    link: '/users/list',
    icon: <UserManagementIcon />,
    linkText: 'User Management',
    permissions: [PERMISSIONS.APPROVE_NEW_ACCOUNT, PERMISSIONS.REQUEST_NEW_ACCOUNT, PERMISSIONS.EDIT_ACCOUNT, PERMISSIONS.APPROVE_ACCOUNT_CHANGE],
    sectionItems: [
      {
        selected: pathname.match(/^\/users\/list/) !== null,
        link: '/users/list',
        linkText: 'User List',
        sectionSubItems: [
          {
            selected:
              pathname.match(/^\/edit/) !== null &&
              navigationState.selectedAccount,
            link: pathname,
            linkText: navigationState.selectedAccount,
          },
        ],
      },
      {
        selected: pathname.match(/^\/users\/requests/) !== null,
        link: '/users/requests',
        linkText: 'User Requests',
        sectionSubItems: [],
      },
    ],
    agencyTypes: ['root', 'grantee', 'agency'],
  };

  const formsSection = {
    selected: pathname.match(/^\/form/) !== null,
    link: '/forms/list',
    icon: <FormManagementIcon />,
    linkText: 'Form Management',
    permissions: [PERMISSIONS.MODIFY_AGENCY],
    sectionItems: [
      {
        selected: pathname.match(/^\/forms/) !== null,
        link: '/forms/list',
        linkText: 'Form List',
        sectionSubItems: [
          {
            selected:
              pathname.match(/^\/form/) !== null && navigationState.selectedForm,
            link: pathname,
            linkText: navigationState.selectedForm,
          },
        ],
      },
    ],
    agencyTypes: ['grantee'],
  };

  const programsSection = {
    selected: pathname.match(/^\/program/) !== null,
    link: '/programs/list',
    icon: <ProgramsIcon />,
    linkText: 'Program Management',
    permissions: [PERMISSIONS.CREATE_PROGRAM],
    sectionItems: [
      {
        selected: pathname.match(/^\/programs\/list/) !== null,
        link: '/programs/list',
        linkText: 'Program List',
        sectionSubItems: [],
      },
    ],
    agencyTypes: ['grantee'],
  };

  const renderSection = (sectionInfo) => {
    if (
      ((sectionInfo.agencyTypes.includes('root') && userAgency.isRoot) ||
       (sectionInfo.agencyTypes.includes('grantee') && userAgency.isGranteeAgency) ||
       (sectionInfo.agencyTypes.includes('agency') && !userAgency.isRoot && !userAgency.isGranteeAgency)) &&
      (sectionInfo.permissions.length === 0 ||
        sectionInfo.permissions.some((p) => verifyPermission(p))) &&
      (!sectionInfo.hide) 
    ) {
      return (
        <Grid
          className={classNames({
            [classes.collapsed]: collapsed,
            [classes.section]: true,
            [classes.selected]: sectionInfo.selected && !collapsed,
          })}
          item
          xs={12}
        >
          <Link to={sectionInfo.link}>
            <div className={classes.icon}>{sectionInfo.icon}</div>
            {!collapsed && (
              <Typography variant="body2" className={classes.linkText}>
                {sectionInfo.linkText}
              </Typography>
            )}
          </Link>
          {sectionInfo.sectionItems.some(
            (si) =>
              si.selected || si.sectionSubItems.some((sub) => sub.selected)
          ) &&
            !collapsed && (
              <div className={classes.sectionDetailWrapper}>
                {sectionInfo.sectionItems.map((si) => (
                  <div key={si.linkText}>
                    <div className={classes.sectionItem}>
                      {si.selected && (
                        <SelectedIcon className={classes.selectedItem} />
                      )}
                      <Link to={si.link}>
                        <Typography variant="body2">{si.linkText}</Typography>
                      </Link>
                    </div>
                    {si.sectionSubItems.map((sub) => {
                      return (
                        sub.selected && (
                          <div
                            key={sub.linkText}
                            className={classes.sectionSubItem}
                          >
                            <SelectedIcon className={classes.selectedSubItem} />
                            <Link to={sub.link}>{sub.linkText}</Link>
                          </div>
                        )
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
        </Grid>
      );
    }
    return null;
  };

  return (
    <StyledPaper
      className={classNames({
        [classes.paper]: true,
        [classes.paperCollapsed]: collapsed,
      })}
      elevation={0}
    >
      <div>
        <Grid className={collapsed ? classes.collapsed : null}>
          <Grid item className={collapsed ? classes.collapsed : null} xs={12}>
            {collapsed && (
              <Grid item xs={4} sm={2} className={classes.expandIconContainer}>
                <a onClick={() => collapsedClicked()}>
                  <ExpandIcon />
                </a>
              </Grid>
            )}
            {!collapsed && (
              <Grid className={collapsed ? null : classes.userInfo}>
                <Grid item xs={12}>
                  <a
                    onClick={() => collapsedClicked()}
                    className={classes.collapseIconContainer}
                  >
                    <CollapseIcon />
                  </a>
                  <Typography
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                    variant="body1"
                    color="textSecondary"
                  >
                    {userAgency?.name}
                  </Typography>
                  {userAgency?.address && userAgency.address.city && userAgency.address.state && (
                    <Typography
                      style={{ textAlign: 'center' }}
                      variant="body2"
                      color="textSecondary"
                    >
                      {userAgency?.address?.city},{' '}
                      {userAgency?.address?.state}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>

          {renderSection(dashboardSection)}
          {renderSection(clientsSection)}
          {renderSection(applicationsSection)}
          {renderSection(agenciesSection)}
          {renderSection(permissionsSection)}
          {renderSection(usersSection)}
          {renderSection(importsExportsSection)}
          {renderSection(formsSection)}
          {renderSection(programsSection)}

          {!collapsed && (
            <Grid item xs={12} className={classes.footer}>
              <GroupwareLogo />
              <Typography variant="body2" color="textSecondary">
                Groupware Technologies
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    </StyledPaper>
  );
}

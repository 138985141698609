import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import React from 'react';

export default function PermissionsPanel({
  permissionsContainer,
  name,
  values,
  categoriesIndex,
  handleChange,
}) {
  return (
      permissionsContainer &&
        permissionsContainer.permissions
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((permission, index) => {
            return (
                <Grid container key={`${name}-${permission.id}`}>
                  <Grid item xs={6}>
                    <Typography variant="body1">{permission.name}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values?.permissions[index]?.enabled || false}
                          onChange={handleChange}
                          name={`${name}.${categoriesIndex}.permissions.${index}.enabled`}
                          color="primary"
                        />
                      }
                      label={values?.permissions[index]?.enabled ? 'On' : 'Off'}
                    />
                  </Grid>
                </Grid>
            );
          })
  );
}

export const PERMISSIONS = Object.freeze({

    // User management
    REQUEST_NEW_ACCOUNT: 'request_new_account',
    APPROVE_NEW_ACCOUNT: 'approve_new_account',
    EDIT_ACCOUNT: 'edit_account',
    APPROVE_ACCOUNT_CHANGE: 'approve_account_change',
    RESET_MFA: 'reset_mfa',

    // Permission management
    REQUEST_NEW_TEMPLATE: 'request_new_template',
    APPROVE_TEMPLATE: 'approve_template',
    
    // Client administration
    EDIT_CARE_MANAGEMENT: 'edit_care_management',
    READ_CARE_MANAGEMENT: 'read_care_management',
    UPDATE_CLIENT_INFORMATION: 'update_client_information',
    SEARCH_ACROSS_GRANTEE: 'search_across_grantee',
    ADD_EDIT_DELETE_LAB_RESULTS: 'add_edit_delete_lab_results',
    ADD_EDIT_DELETE_PREMIUM_PAYMENTS: 'add_edit_delete_premium_payments',
    MANAGE_URN_SUFFIX: 'manage_urn_suffix',
    UPDATE_GRANDFATHERED_CLIENTS: 'update_grandfathered_clients',
    CAN_BE_A_LEAD_CASE_MANAGER: 'can_be_a_lead_case_manager',

    // Program management
    CREATE_PROGRAM: 'create_program',

    // Agency management
    REQUEST_SUB_AGENCY: 'request_sub_agency',
    APPROVE_SUB_AGENCY: 'approve_sub_agency',
    MODIFY_AGENCY: 'modify_agency',
    AGENCY_EDIT_INTEGRATIONS: 'edit_agency_integration_settings',

    IMPORT_CLIENT_DATA: 'import_client_data',
    IMPORT_SERVICES_DATA: 'import_services_data',
    IMPORT_AGENCY_CONFIGURATION_DATA: 'import_agency_configuration_data',
    EXPORT_GRANTEE_DATA: 'export_grantee_data',
    EXPORT_CARRIER_PREMIUMS: 'export_carrier_premiums',

    // Eligibility management
    APPLICATION_SUBMITTER: 'application_submitter', // submit applications
    APPLICATION_COMPLETER: 'application_completer', // submit applications and process RW eligibility
    RW_PROCESSOR_SINGLE_AGENCY: 'rw_processor_single_agency', // process RW for single sub agency
    RW_PROCESSOR_MULTIPLE_AGENCY: 'rw_processor_multi_agency', // process RW for multiple sub agencies
    ADAP_PROCESSOR: 'adap_processor', // process ADAP for multiple sub agencies

    EDIT_CLIENT_PROGRAM_DATA: 'edit_client_program_data',
    READ_CLIENT_PROGRAM_DATA: 'read_client_program_data',
    
    // Dashboard
    VIEW_PROCESSOR_PRODUCTIVITY_DASHBOARD: 'view_processor_productivity_dashboard'
  });
import React, { useState } from 'react';
import TemplateForm from './forms/TemplateForm';
import AddTemplateConfirmPanel from './AddTemplateConfirmPanel';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';

export default function AddTemplatePanel({ handleBackClicked }) {
  const [savedTemplate, setSavedTemplate] = useState();

  const showConfirmPanel = (template) => {
    setSavedTemplate(template);
  };

  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton variant="outlined" onClick={handleBackClicked}>
          Back
        </LotusButton>
      </LotusSpacedBlock>
      {!savedTemplate && (
        <LotusPageSection header='Request New Template'>
          <TemplateForm afterSave={showConfirmPanel} />
        </LotusPageSection>
      )}
      {savedTemplate && (
        <AddTemplateConfirmPanel name={savedTemplate.name} />
      )}
    </LotusPaper>
  );
}

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useLists } from 'contexts/ListsContext';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusFormItems from 'components/widgets/Forms/LotusFormItems';
import { LotusProgressiveDisclosure, LotusProgressiveDisclosureItem } from 'components/widgets/Forms/LotusProgressiveDisclosure';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import IncomeSourcesPanel from 'components/widgets/Composites/IncomeSourcesPanel';
import IncomeAdjustmentsPanel from 'components/widgets/Composites/IncomeAdjustmentsPanel';
import {calculateIncomeFields} from 'lib/incomeCalculator';
import ClientInfoDocumentation from 'components/widgets/Composites/ClientInfoDocumentation';
import moment from 'moment';
import HouseholdMembersPanel from 'components/widgets/Composites/HouseholdMembersPanel';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import { Stack } from '@mui/system';
import SectionCommentsChain from './SectionCommentsChain';
import { determineRwEligibility } from 'lib/rwScorer';
import { useUserAgency } from 'contexts/UserAgencyContext';

export const getIncomeStaticFormSchema = (existingClientConfig) => {
  
  return Yup.object().shape({
    clientHasIncome: Yup.string().required("Does the applicant have personal income? is required"),
    clientIncomeSources: Yup.array().of(Yup.object().shape({
      incomeSourceTypeId: Yup.string().required("Income Source Type is required"),
      incomePeriodId: Yup.string().required("Income Period is required"),
      samples: Yup.array().of(Yup.object().shape({
        amount: Yup.number().required("Amount is required").transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Amount must be a number').min(0, "Amount must be non-negative"),
        sampleDate: Yup.string().required("Date is required").test({
          name: 'validDate',
          skipAbsent: true,
          test(value, ctx) {
            let toValidate = moment(value, 'MM/DD/YYYY');
            if (!toValidate.isValid()) {
              return ctx.createError({ message: 'Date must be a valid date' });
            }
            if (toValidate.year() < 1900) {
              return ctx.createError({ message: 'Date is too old' });
            }
            return true;
          }
        }),
      }))
    })),
    employmentStatusId: Yup.string().required("Employment Status is required"),
    clientFiledTaxes: Yup.string().required("Did the applicant file a tax return for the most recent tax year? is required"),
    taxFilingStatusId: Yup.string().test('taxFilingStatusId', "Tax Filing Status is required", function(value, ctx) {
      return (ctx.parent.clientFiledTaxes !== 'true') || (ctx.parent.clientFiledTaxes === 'true' && Boolean(value));
    }),
    hasOtherHouseholdMembers: Yup.string().required("Does the applicant have other household members? is required"),
    otherHouseholdMembers: Yup.array().of(Yup.object().shape({
      clientRelationshipId: Yup.string().required("Relationship to Client is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    })),
    otherHouseholdMembersHaveIncome: Yup.string().test({
      name: 'validBool',
      test(value, ctx) {
        if (ctx.parent.hasOtherHouseholdMembers === 'true' && !Boolean(value)) {
          return ctx.createError({ message: "Do other members of the applicant's household have income? is required" });
        }
        return true;
      }
    }),
    otherHouseholdIncomeSources: Yup.array().of(Yup.object().shape({
      recipientRelationshipId: Yup.string().required("Income Recipient is required"),
      incomeSourceTypeId: Yup.string().required("Income Source Type is required"),
      incomePeriodId: Yup.string().required("Income Period is required"),
      samples: Yup.array().of(Yup.object().shape({
        amount: Yup.number().required("Amount is required").transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Amount must be a number').min(0, "Amount must be non-negative"),
        sampleDate: Yup.string().required("Date is required").test({
          name: 'validDate',
          skipAbsent: true,
          test(value, ctx) {
            let toValidate = moment(value, 'MM/DD/YYYY');
            if (!toValidate.isValid()) {
              return ctx.createError({ message: 'Date must be a valid date' });
            }
            if (toValidate.year() < 1900) {
              return ctx.createError({ message: 'Date is too old' });
            }
            return true;
          }
        }),
      }))
    })),
    hasIncomeAdjustments: Yup.string().test({
      name: 'validBool',
      test(value, ctx) {
        if (existingClientConfig.useMagi && !Boolean(value)) {
          return ctx.createError({ message: 'Does the applicant or their household have income adjustments? is required' });
        }
        return true;
      }
    }),
    incomeAdjustments: Yup.array().of(Yup.object().shape({
      recipientRelationshipId: Yup.string().required("Adjustment Recipient is required"),
      incomeAdjustmentTypeId: Yup.string().required("Income Adjustment Type is required"),
      incomePeriodId: Yup.string().required("Period is required"),
      samples: Yup.array().of(Yup.object().shape({
        amount: Yup.number().required("Amount is required").transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Amount must be a number').min(0, "Amount must be non-negative"),
        sampleDate: Yup.string().required("Date is required").test({
          name: 'validDate',
          skipAbsent: true,
          test(value, ctx) {
            let toValidate = moment(value, 'MM/DD/YYYY');
            if (!toValidate.isValid()) {
              return ctx.createError({ message: 'Date must be a valid date' });
            }
            if (toValidate.year() < 1900) {
              return ctx.createError({ message: 'Date is too old' });
            }
            return true;
          }
        }),
      }))
    })),
    incomeDocumentation: Yup.array().min(1, 'Proof of Income Documentation is required'),
    incomeCommentsChain: Yup.array().test({
      name: 'unacknowledgedComments',
      test(value, ctx) {
        if (value.length > 0) {
          let last = value[value.length - 1];
          let valType = ctx.options.context.validationType;
          if (valType === 'submit' || valType === 'pend' || valType === 'initial') {
            if (!last.resolverId && last.commenterId !== ctx.options.context.userId) {
              return ctx.createError({ message: 'Comment must be resolved or replied to'});
            }
          }
          if (valType === 'complete') {
            if (!last.resolverId) {
              return ctx.createError({ message: 'Comment must be resolved'});
            }
          }
        }
        return true;
      }
    }),
  })
}

export const getIncomeStaticFormSchemaExcludingRequiredChecks = (eligibilityAppConfig) => {
  return Yup.object().shape({
    clientIncomeSources: Yup.array().of(Yup.object().shape({
      samples: Yup.array().of(Yup.object().shape({
        amount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Amount must be a number').min(0, "Amount must be non-negative"),
        date: Yup.string().nullable().test({
          name: 'validDate',
          skipAbsent: true,
          test(value, ctx) {
            let toValidate = moment(value, 'MM/DD/YYYY');
            if (!toValidate.isValid()) {
              return ctx.createError({ message: 'Date must be a valid date' });
            }
            if (toValidate.year() < 1900) {
              return ctx.createError({ message: 'Date is too old' });
            }
            return true;
          }
        }),
      }))
    })),
    otherHouseholdIncomeSources: Yup.array().of(Yup.object().shape({
      samples: Yup.array().of(Yup.object().shape({
        amount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Amount must be a number').min(0, "Amount must be non-negative"),
        date: Yup.string().nullable().test({
          name: 'validDate',
          skipAbsent: true,
          test(value, ctx) {
            let toValidate = moment(value, 'MM/DD/YYYY');
            if (!toValidate.isValid()) {
              return ctx.createError({ message: 'Date must be a valid date' });
            }
            if (toValidate.year() < 1900) {
              return ctx.createError({ message: 'Date is too old' });
            }
            return true;
          }
        }),
      }))
    })),
    incomeAdjustments: Yup.array().of(Yup.object().shape({
      samples: Yup.array().of(Yup.object().shape({
        amount: Yup.number().nullable().transform((v, o) => (o === '' || Number.isNaN(o) ? null : v)).typeError('Amount must be a number').min(0, "Amount must be non-negative"),
        date: Yup.string().nullable().test({
          name: 'validDate',
          skipAbsent: true,
          test(value, ctx) {
            let toValidate = moment(value, 'MM/DD/YYYY');
            if (!toValidate.isValid()) {
              return ctx.createError({ message: 'Date must be a valid date' });
            }
            if (toValidate.year() < 1900) {
              return ctx.createError({ message: 'Date is too old' });
            }
            return true;
          }
        }),
      }))
    })),
  })
}

export const buildIncomeStaticFormSectionNames = (existingClientConfig) => {
  let lst = [
    "Applicant's Personal Income",
    "Applicant's Household",
  ];
  if (existingClientConfig.useMagi) {
    lst.push("Income Adjustments");
  }
  lst.push(
    "Employment Status",
    "Taxes",
    "Income Totals",
    "Documentation"
  );
  return lst;
}

export const initializeIncomeFormData = (formData) => {
  if (!formData) {
    return formData;
  }
  // React gets pissed about null values bound to controls
  return {
    clientHasIncome: formData.clientHasIncome !== null ? formData.clientHasIncome : '', 
    employmentStatusId: formData.employmentStatusId || '', 
    clientIncomeSources: formData.clientIncomeSources ? formData.clientIncomeSources.map(ais => { return {
      id: ais.id,
      recipientRelationshipId: ais.recipientRelationshipId || '',
      incomeSourceTypeId: ais.incomeSourceTypeId || '',
      incomePeriodId: ais.incomePeriodId || '',
      samples: ais.samples ? ais.samples.map(aiss => { return {
        id: aiss.id,
        amount: aiss.amount || '',
        sampleDate: aiss.sampleDate || null
      }}) : []
    }}) : [],
    hasOtherHouseholdMembers: formData.hasOtherHouseholdMembers !== null ? formData.hasOtherHouseholdMembers : '',
    otherHouseholdMembers: formData.otherHouseholdMembers ? formData.otherHouseholdMembers.map(hm => { return {
      id: hm.id,
      clientRelationshipId: hm.clientRelationshipId || '',
      firstName: hm.firstName || '',
      lastName: hm.lastName || '',
    }}) : [],
    otherHouseholdMembersHaveIncome: formData.otherHouseholdMembersHaveIncome !== null ? formData.otherHouseholdMembersHaveIncome : '',
    otherHouseholdIncomeSources: formData.otherHouseholdIncomeSources ? formData.otherHouseholdIncomeSources.map(ohis => { return {
      id: ohis.id,
      recipientRelationshipId: ohis.recipientRelationshipId || '',
      incomeSourceTypeId: ohis.incomeSourceTypeId || '',
      incomePeriodId: ohis.incomePeriodId || '',
      samples: ohis.samples ? ohis.samples.map(ohis => { return {
        id: ohis.id,
        amount: ohis.amount || '',
        sampleDate: ohis.sampleDate || null
      }}) : []
    }}) : [],
    hasIncomeAdjustments: formData.hasIncomeAdjustments !== null ? formData.hasIncomeAdjustments : '', 
    incomeAdjustments: formData.incomeAdjustments ? formData.incomeAdjustments.map(ohis => { return {
      id: ohis.id,
      recipientRelationshipId: ohis.recipientRelationshipId || (ohis.clientIsRecipient ? 'client' : ''),
      incomeAdjustmentTypeId: ohis.incomeAdjustmentTypeId || '',
      incomePeriodId: ohis.incomePeriodId || '',
      samples: ohis.samples ? ohis.samples.map(ohis => { return {
        id: ohis.id,
        amount: ohis.amount || '',
        sampleDate: ohis.sampleDate || null
      }}) : []
    }}) : [],
    clientFiledTaxes: formData.clientFiledTaxes !== null ? formData.clientFiledTaxes : '', 
    taxFilingStatusId: formData.taxFilingStatusId || '',
    federalPovertyLineBaseAmount: formData.federalPovertyLineBaseAmount, // will populate these if empty
    federalPovertyLineIncrementalAmount: formData.federalPovertyLineIncrementalAmount, // will populate these if empty
    totalHouseholdSize: formData.totalHouseholdSize ? `${formData.totalHouseholdSize}` : '1',
    monthlyHouseholdGrossIncome: formData.monthlyHouseholdGrossIncome || '',
    householdFederalPovertyLevelPct: formData.householdFederalPovertyLevelPct !== null ? `${formData.householdFederalPovertyLevelPct}` : '', 
    monthlyHouseholdAdjustedGrossIncome: formData.monthlyHouseholdAdjustedGrossIncome || '',
    totalIncomeAdjustments: formData.totalIncomeAdjustments || '',
    monthlyHouseholdModifiedAdjustedGrossIncome: formData.monthlyHouseholdModifiedAdjustedGrossIncome || '',
    magiHouseholdFederalPovertyLevelPct: formData.magiHouseholdFederalPovertyLevelPct !== null ? `${formData.magiHouseholdFederalPovertyLevelPct}` :  '',
    incomeDocumentation: formData.incomeDocumentation || [],
    incomeCommentsChain: formData.incomeCommentsChain || []
  };
}

export const adjustIncomeFormDataBeforeSave = (formData) => {
  // fix the adjustment relationships
  formData.incomeAdjustments.forEach(adj => {
    if (adj.recipientRelationshipId === 'client') {
      adj.clientIsRecipient = true;
      adj.recipientRelationshipId = '';
    } else {
      adj.clientIsRecipient = false;
    }
  });
  return formData;
}

export const getIncomeFormData = (formData) => {
    
  // We combined the static/dynamic fields into one form, so now we need to split them back out for saving
  const {
    clientHasIncome, 
    employmentStatusId, 
    clientIncomeSources,
    hasOtherHouseholdMembers,
    otherHouseholdMembers,
    otherHouseholdMembersHaveIncome, 
    otherHouseholdIncomeSources,
    hasIncomeAdjustments, 
    incomeAdjustments,
    clientFiledTaxes, 
    taxFilingStatusId,
    federalPovertyLineBaseAmount,
    federalPovertyLineIncrementalAmount,
    totalHouseholdSize,
    monthlyHouseholdGrossIncome,
    householdFederalPovertyLevelPct, 
    monthlyHouseholdAdjustedGrossIncome,
    totalIncomeAdjustments,
    monthlyHouseholdModifiedAdjustedGrossIncome,
    magiHouseholdFederalPovertyLevelPct,
    incomeDocumentation,
    incomeCommentsChain
  } = formData;

  const income = {
    clientHasIncome, 
    employmentStatusId, 
    clientIncomeSources,
    hasOtherHouseholdMembers,
    otherHouseholdMembers,
    otherHouseholdMembersHaveIncome, 
    otherHouseholdIncomeSources,
    hasIncomeAdjustments, 
    incomeAdjustments,
    clientFiledTaxes, 
    taxFilingStatusId,
    federalPovertyLineBaseAmount,
    federalPovertyLineIncrementalAmount,
    totalHouseholdSize,
    monthlyHouseholdGrossIncome,
    householdFederalPovertyLevelPct, 
    monthlyHouseholdAdjustedGrossIncome,
    totalIncomeAdjustments,
    monthlyHouseholdModifiedAdjustedGrossIncome,
    magiHouseholdFederalPovertyLevelPct,
    incomeDocumentation,
    incomeCommentsChain
  };
  return income;
}

const EligibilityApplicationIncomeStaticForm = ({clientId, existingClientConfig, disabled, rwScoringConfig}) => {

  const { employmentStatuses, taxFilingStatuses, clientRelationships, incomePeriods,
    federalPovertyGuidelines, loadFederalPovertyGuidelines} = useLists();
  const { userAgencyClientInfoCustomLists } = useUserAgency();

  const [employmentStatusList, setEmploymentStatusList] = useState();
  const [taxFilingStatusList, setTaxFilingStatusList] = useState();
  const [clientRelationshipList, setClientRelationshipList] = useState();

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (employmentStatuses) {
      setEmploymentStatusList(employmentStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (taxFilingStatuses) {
      setTaxFilingStatusList(taxFilingStatuses.map(g => { return {label: g.itemName, value: g.id}}));
    }
    if (clientRelationships && existingClientConfig && existingClientConfig.selectedClientRelationships) {
      let rels = clientRelationships.map(g => { return {label: g.itemName, value: g.id, itemKey: g.itemKey}});
      const relationshipList = rels.filter(
        t => existingClientConfig.selectedClientRelationships.includes(t.value));
      setClientRelationshipList(relationshipList);
    }
  }, [employmentStatuses, taxFilingStatuses, clientRelationships, existingClientConfig]);

  useEffect(() => {
    if (!federalPovertyGuidelines && values && !values.federalPovertyLineBaseAmount && !values.federalPovertyLineIncrementalAmount) {
      loadFederalPovertyGuidelines();
    }
  }, [values]);

  useEffect(() => {
    if (values && userAgencyClientInfoCustomLists && incomePeriods) {
      let computedValues = calculateIncomeFields(values, userAgencyClientInfoCustomLists.clientInfoIncomeSourceTypes, incomePeriods);

      if (computedValues.totalHouseholdSize !== values.totalHouseholdSize) {
        setFieldValue("totalHouseholdSize", computedValues.totalHouseholdSize);
      }
      if (computedValues.monthlyHouseholdGrossIncome !== values.monthlyHouseholdGrossIncome) {
        setFieldValue("monthlyHouseholdGrossIncome", computedValues.monthlyHouseholdGrossIncome);
      }
      if (computedValues.householdFederalPovertyLevelPct !== values.householdFederalPovertyLevelPct) {
        setFieldValue("householdFederalPovertyLevelPct", computedValues.householdFederalPovertyLevelPct);
      }
      if (computedValues.totalIncomeAdjustments !== values.totalIncomeAdjustments) {
        setFieldValue("totalIncomeAdjustments", computedValues.totalIncomeAdjustments);
      }
      if (computedValues.monthlyHouseholdAdjustedGrossIncome !== values.monthlyHouseholdAdjustedGrossIncome) {
        setFieldValue("monthlyHouseholdAdjustedGrossIncome", computedValues.monthlyHouseholdAdjustedGrossIncome);
      }
      if (computedValues.monthlyHouseholdModifiedAdjustedGrossIncome !== values.monthlyHouseholdModifiedAdjustedGrossIncome) {
        setFieldValue("monthlyHouseholdModifiedAdjustedGrossIncome", computedValues.monthlyHouseholdModifiedAdjustedGrossIncome);
      }
      if (computedValues.magiHouseholdFederalPovertyLevelPct !== values.magiHouseholdFederalPovertyLevelPct) {
        setFieldValue("magiHouseholdFederalPovertyLevelPct", computedValues.magiHouseholdFederalPovertyLevelPct);
      }
    }
  }, [values, userAgencyClientInfoCustomLists, incomePeriods]);

  useEffect(() => {
    let result = determineRwEligibility(values, rwScoringConfig, existingClientConfig);
    setFieldValue("rwIncomeIneligible", !result.incomeEligible);
    setFieldValue("rwResidencyIneligible", !result.residencyEligible);
    setFieldValue("rwHivStatusIneligible", !result.hivStatusEligible);
    setFieldValue("rwEligible", result.rwEligible);
  }, [values.magiHouseholdFederalPovertyLevelPct, values.monthlyHouseholdModifiedAdjustedGrossIncome, values.householdFederalPovertyLevelPct, values.monthlyHouseholdGrossIncome]);

  useEffect(() => {
    if (federalPovertyGuidelines) {
      // get most recent
      let mostRecent = federalPovertyGuidelines.reduce((prev, curr) => prev.year < curr.year ? curr : prev);
      if (values && !values.federalPovertyLineBaseAmount) {
        setFieldValue("federalPovertyLineBaseAmount", mostRecent.baseAmount);
      }
      if (values && !values.federalPovertyLineIncrementalAmount) {
        setFieldValue("federalPovertyLineIncrementalAmount", mostRecent.incrementalAmount);
      }
    }
  }, [federalPovertyGuidelines, values]);

  return values && existingClientConfig && (
    <Stack direction="row">
      <LotusForm>
        <LotusFormSection name="Applicant's Personal Income" disabled={disabled}>
          <LotusFormItems>
            <LotusProgressiveDisclosure
              name='clientHasIncome'
              label="Does the applicant have personal income?"
              disabled={disabled}
              required
              dontCheckRequiredFields
            >
              <LotusProgressiveDisclosureItem
                itemValue='true'
                label='Yes'
              >
                <IncomeSourcesPanel
                  incomeSourcesFieldName='clientIncomeSources'
                  clientIsRecipient={true}
                  clientRecipientLabel="Applicant"
                  disabled={disabled}
                  required
                  dontCheckRequiredFields
                />
              </LotusProgressiveDisclosureItem>
              <LotusProgressiveDisclosureItem
                itemValue='false'
                label='No'
              />
            </LotusProgressiveDisclosure>
          </LotusFormItems>
        </LotusFormSection>
        <LotusFormSection name="Applicant's Household" disabled={disabled}>
          <LotusFormItems>
            <LotusProgressiveDisclosure
              name='hasOtherHouseholdMembers'
              label="Does the applicant have other household members?"
              disabled={disabled}
              required
              dontCheckRequiredFields
            >
              <LotusProgressiveDisclosureItem
                itemValue='true'
                label='Yes'
              >
                {clientRelationshipList &&
                  <LotusFormItem>
                    <HouseholdMembersPanel
                      otherHouseholdMembersFieldName='otherHouseholdMembers'
                      relationships={clientRelationshipList}
                      disabled={disabled}
                      required
                      dontCheckRequiredFields
                    />
                  </LotusFormItem>
                }
                <LotusProgressiveDisclosure
                  name='otherHouseholdMembersHaveIncome'
                  label="Do other members of the applicant's household have income?"
                  disabled={disabled}
                  required
                  dontCheckRequiredFields
                >
                  <LotusProgressiveDisclosureItem
                    itemValue='true'
                    label='Yes'
                  >
                    {clientRelationshipList &&
                      <IncomeSourcesPanel
                        incomeSourcesFieldName='otherHouseholdIncomeSources'
                        clientIsRecipient={false}
                        relationships={clientRelationshipList}
                        disabled={disabled}
                        required
                        dontCheckRequiredFields
                      />
                    }
                  </LotusProgressiveDisclosureItem>
                  <LotusProgressiveDisclosureItem
                    itemValue='false'
                    label='No'
                  />
                </LotusProgressiveDisclosure>
              </LotusProgressiveDisclosureItem>
              <LotusProgressiveDisclosureItem
                itemValue='false'
                label='No'
              />
            </LotusProgressiveDisclosure>
          </LotusFormItems>
        </LotusFormSection>
        {existingClientConfig.useMagi &&
          <LotusFormSection name="Income Adjustments" disabled={disabled}>
            <LotusFormItems>
              <LotusProgressiveDisclosure
                name='hasIncomeAdjustments'
                label="Does the applicant or their household have income adjustments?"
                disabled={disabled}
                required
                dontCheckRequiredFields
              >
                <LotusProgressiveDisclosureItem
                  itemValue='true'
                  label='Yes'
                >
                  {clientRelationshipList &&
                    <IncomeAdjustmentsPanel
                      incomeAdjustmentsFieldName='incomeAdjustments'
                      relationships={[{value: 'client', label: 'Applicant'}, ...clientRelationshipList]}
                      disabled={disabled}
                      required
                      dontCheckRequiredFields
                    />
                  }
                </LotusProgressiveDisclosureItem>
                <LotusProgressiveDisclosureItem
                  itemValue='false'
                  label='No'
                />
              </LotusProgressiveDisclosure>
            </LotusFormItems>
          </LotusFormSection>
        }
        <LotusFormSection name="Employment Status" disabled={disabled}>
          <LotusFormItems>
            {employmentStatusList &&
              <LotusSelect
                name="employmentStatusId"
                label="Employment Status"
                items={employmentStatusList}
                disabled={disabled}
                autoSort={false}
                required
                dontCheckRequiredFields
              />
            }
          </LotusFormItems>
        </LotusFormSection>
        <LotusFormSection name="Taxes" disabled={disabled}>
          <LotusFormItems>
            <LotusProgressiveDisclosure
              name='clientFiledTaxes'
              label="Did the applicant file a tax return for the most recent tax year?"
              disabled={disabled}
              required
              dontCheckRequiredFields
            >
              <LotusProgressiveDisclosureItem
                itemValue='true'
                label='Yes'
              >
                {taxFilingStatusList &&
                  <LotusRadioGroup
                    name="taxFilingStatusId"
                    label="Tax Filing Status"
                    items={taxFilingStatusList}
                    disabled={disabled}
                    required
                    dontCheckRequiredFields
                  />
                }
              </LotusProgressiveDisclosureItem>
              <LotusProgressiveDisclosureItem
                itemValue='false'
                label='No'
              />
            </LotusProgressiveDisclosure>
          </LotusFormItems>
        </LotusFormSection>
        <LotusFormSection name="Income Totals" disabled={disabled}>
          <LotusFormItem>
            <LotusTextInput
              name="totalHouseholdSize"
              label="Total Household Size"
              disabled={true}
            />
          </LotusFormItem>
          {!existingClientConfig.useMagi &&
            <LotusFormItem>
              <LotusTextInput
                name="monthlyHouseholdGrossIncome"
                label="Monthly Household Gross Income"
                disabled={true}
                adornment="$"
              />
            </LotusFormItem>
          }
          {!existingClientConfig.useMagi &&
            <LotusFormItem>
              <LotusTextInput
                name="householdFederalPovertyLevelPct"
                label="Household Poverty Level %"
                disabled={true}
                adornment="%"
              />
            </LotusFormItem>
          }
          {existingClientConfig.useMagi &&
            <LotusFormItem>
              <LotusTextInput
                name="monthlyHouseholdModifiedAdjustedGrossIncome"
                label="Monthly Household Modified Adjusted Gross Income (MAGI)"
                disabled={true}
                adornment="$"
              />
            </LotusFormItem>
          }
          {existingClientConfig.useMagi &&
            <LotusFormItem>
              <LotusTextInput
                name="magiHouseholdFederalPovertyLevelPct"
                label="MAGI Household Poverty Level %"
                disabled={true}
                adornment="%"
              />
            </LotusFormItem>
          }
        </LotusFormSection>
        <LotusFormSection name="Documentation" disabled={disabled}>
          <LotusFormItem>
            <LotusSpacedBlock>
              <div>Upload Proof of Income Documentation *</div>
            </LotusSpacedBlock>
            <ClientInfoDocumentation 
              documentationListFieldName="incomeDocumentation"
              allDocumentationTypes={userAgencyClientInfoCustomLists?.clientInfoIncomeDocumentationTypes}
              clientId={clientId}
              disabled={disabled}
            />
          </LotusFormItem>
        </LotusFormSection>
      </LotusForm>
      <div>
        <SectionCommentsChain chainFieldName="incomeCommentsChain"/>
      </div>
    </Stack>
  );
};

export function buildIncomeStaticForm({...props}) {
  return <EligibilityApplicationIncomeStaticForm {...props}/>
}
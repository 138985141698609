import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { useDataExport } from 'contexts/DataExportContext';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import { Grid, Typography } from '@mui/material';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { DELIMITERS } from './common';

const descRegex = '^[0-9a-zA-Z\ ]{1,255}$';

const validationSchema = yup.object().shape({
  desc: yup.string().matches(descRegex, {
    message: 'Only letters, numbers and spaces are allowed',
    excludeEmptyString: true,
  }),
});


export default function DataExportForm({
  agencyId,
  handleSubmit,
  afterSubmit,
}) {
  const [initialValues, setInitialValues] = useState();
  const [onDemandTypes, setOnDemandTypes] = useState();
  const [delimiterRequiredList, setDelimiterRequiredList] = useState();
  const { exportTypes, getExportTypes } = useDataExport();

  useEffect(() => {
    setInitialValues({
      exportType: '', delimiter: ''
    });
  }, []);

  useEffect(() => {
    if (agencyId) {
      getExportTypes(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    if (exportTypes) {
      setOnDemandTypes(exportTypes.filter(et => et.onDemandAllowed));
    }
  }, [exportTypes]);

  useEffect(() => {
    if (exportTypes) {
      setDelimiterRequiredList(exportTypes.filter(et => et.delimiterRequired).map(et => { return et.id }));
    }
  }, [exportTypes]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}

      onSubmit={async (values, actions) => {
        const typeObj = exportTypes.find(et => et.id === values.exportType);
        await handleSubmit(
          values,
          typeObj?.key
        );
        actions.setSubmitting(false);
        if (afterSubmit) {
          afterSubmit();
        }
      }}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => {

        return values ? (
          <Grid container>
            <Grid item xs={12} alignItems="center" container style={{ border: 20 }}>
              <Grid item xs={6}>
                <Typography variant="h2">Export Data</Typography>
              </Grid>

              <Grid item style={{ width: "600px", border: '10px' }}>
                <Form onSubmit={handleSubmit}>
                  <div style={{ padding: "12px 20px", border: "10px" }} />
                  {values && (
                    <LotusFormItem>
                      <LotusSelect
                        name="exportType"
                        label="Export Type"
                        required={true}
                        items={
                          onDemandTypes &&
                          onDemandTypes.map((item) => {
                            return { label: item.name, value: item.id };
                          })
                        }
                      />
                    </LotusFormItem>
                  )}
                  {values && (
                    <LotusFormItem>
                      <LotusTextInput
                        name="desc"
                        label="Description"
                        required
                        maxLength={100}
                      />
                    </LotusFormItem>
                  )}
                  {/* {values && values.exportType !== 'ncecho_export' && ( */}
                  {values && (!values.exportType || delimiterRequiredList.includes(values.exportType)) && (
                    <LotusFormItem>
                      <LotusSelect
                        label="Delimiter"
                        name="delimiter"
                        items={DELIMITERS.map(d => { return { label: d.name, value: d.delimiter } })}
                        required
                      />
                    </LotusFormItem>
                  )}

                  <LotusFormItem>
                    <Grid container direction="row" justifyContent="right" spacing={1} columns={2}>
                      <Grid item>
                        <LotusButton type="button" variant="outlined" onClick={() => { afterSubmit(); }} >
                          Cancel
                        </LotusButton>
                      </Grid >
                      <Grid item>
                        <LotusFormItem>
                          <LotusButton
                            type="submit"
                          >
                            {'Start Export'}
                          </LotusButton>
                        </LotusFormItem>
                      </Grid>
                    </Grid>
                  </LotusFormItem>

                </Form>
              </Grid></Grid></Grid >
        ) : (
          <div></div>
        );
      }}
    </Formik >

  );
}

import React from 'react';
import ActionDialog from 'components/Dialogs/ActionDialog';
import DenyAgencyRequestForm from '../forms/DenyAgencyRequestForm';

export default ({ actionRequest, open, handleClose, handleSubmit }) => {
  return (
    <ActionDialog
      title={`Deny Agency Request`}
      open={open}
      handleClose={handleClose}
      content={
        <DenyAgencyRequestForm
          inlineTable={false}
          actionRequest={actionRequest}
          onSave={handleSubmit}
          onCancel={handleClose}
        />
      }
    />
  );
};

import React, { useState, useEffect } from 'react';
import TemplateForm from './forms/TemplateForm';
import EditTemplateConfirmPanel from './EditTemplateConfirmPanel';
import { useParams } from 'react-router-dom';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPaper from 'components/widgets/Layout/LotusPaper';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { usePermissionTemplateManagement } from 'contexts/PermissionTemplateManagementContext';

export default function EditTemplatePanel({ handleBackClicked }) {
  const { id } = useParams();
  const [savedTemplate, setSavedTemplate] = useState();
  const { permissionTemplateToEdit, loadPermissionTemplateToEdit} = usePermissionTemplateManagement();
  const [currentTemplate, setCurrentTemplate] = useState();
  const showConfirmPanel = (template) => {
    setSavedTemplate(template);
  };

  useEffect(() => {
    if (id) {
      loadPermissionTemplateToEdit(id);
    }
  }, [id]);

  useEffect(() => {
    if (!currentTemplate || (permissionTemplateToEdit && permissionTemplateToEdit.id === id)) {
      setCurrentTemplate(permissionTemplateToEdit);
    }
  }, [permissionTemplateToEdit]);

  return (
    <LotusPaper>
      <LotusSpacedBlock>
        <LotusButton variant="outlined" onClick={handleBackClicked}>
          Back
        </LotusButton>
      </LotusSpacedBlock>
      {!savedTemplate && currentTemplate && (
        <LotusPageSection header={`Edit Template: ${currentTemplate.name}`}>
          <TemplateForm
            template={currentTemplate}
            afterSave={showConfirmPanel}
          />
        </LotusPageSection>
      )}
      {savedTemplate && (
        <EditTemplateConfirmPanel name={savedTemplate.name} />
      )}
    </LotusPaper>
  );
}

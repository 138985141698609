import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import ClientInfoListSubsetLinker from '../linkers/ClientInfoListSubsetLinker';
import { useLists } from 'contexts/ListsContext';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';
import LotusFormSubSection from 'components/widgets/Forms/LotusFormSubSection';
import LotusFormSubSubSection from 'components/widgets/Forms/LotusFormSubSubSection';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusRadioGroup from 'components/widgets/Forms/LotusRadioGroup';
import LotusItemList from 'components/widgets/Forms/LotusItemList';
import * as yup from 'yup';
import LotusStateSelector from 'components/widgets/Forms/LotusStateSelector';
import LotusMultiSelect from 'components/widgets/Forms/LotusMultiSelect';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import { useUserAgency } from 'contexts/UserAgencyContext';
import LotusCheckboxList from 'components/widgets/Forms/LotusCheckboxList';

export default function AgencyEligibilityForm({ agency: agencyToEdit, handleSubmit }) {

  const [initialValues, setInitialValues] = useState();
  const [initialEligibilityStartDateList, setInitialEligibilityStartDateList] = useState();
  const [annualCoveragePeriodList, setAnnualCoveragePeriodList] = useState();
  const [eligibilityStartDateDayList, setEligibilityStartDateDayList] = useState();
  const [childAgenciesList, setChildAgenciesList] = useState();
  const [agencyLevelConsents, setAgencyLevelConsents] = useState();

  const { eligibilityApplicationUrgencyTypes, eligibilityStartDateTypes, hivDiseaseStages, loadClientInfoPicklists,
    medicationAssistanceLevels, premiumAssistanceCoverageTypes } = useLists();
  const [hivDiseaseStageList, setHivDiseaseStageList] = useState();
  const [medicationAssistanceLevelList, setMedicationAssistanceLevelList] = useState();
  const [premiumAssistanceCoverageTypeList, setPremiumAssistanceCoverageTypeList] = useState();
  const {agenciesForGrantee, loadAgenciesForGrantee} = useAgencySearch();
  const { agency, consentTypes, loadAgencyConsentTypes } = useAgencyManagement();
  const { loadUserAgencyPrograms } = useUserAgency();

  const extensionOptions = [
    {
      label: 'No Extension',
      value: 'no_extension',
    },
    {
      label: 'Extension (Months)',
      value: 'extension_months',
    }];
  const careApplicationOptions = [
    {
      label: 'All Ryan White Applications',
      value: 'rw_only',
    },
    {
      label: 'Applications grouped by agencies',
      value: 'group_by_agency',
    }];
  const adapApplicationOptions = [
    {
      label: 'All Subprograms',
      value: 'all_subprograms',
    },
    {
      label: 'Group by Subprogram',
      value: 'group_by_subprogram',
    }];

  useEffect(() => {
    if (!eligibilityApplicationUrgencyTypes || !eligibilityStartDateTypes || !hivDiseaseStages) {
      loadClientInfoPicklists();
    }
  }, []);
  
  useEffect(() => {
    if (agency && !consentTypes) {
      loadAgencyConsentTypes();
    }
  }, [agency]);

  useEffect(() => {
    if (consentTypes && agencyToEdit) {
      setAgencyLevelConsents(consentTypes.filter(ct => ct.isAgencyWide || agencyToEdit.eligibilityConfig.application.requiredConsents?.includes(ct.id)).map(ct => { return {value: ct.id, label: ct.name } }));
    }
  }, [consentTypes, agencyToEdit]);

  useEffect(() => {
    if(eligibilityStartDateTypes) {
      setInitialEligibilityStartDateList(eligibilityStartDateTypes.filter(types =>
        types.itemKey === 'date_of_processing' || types.itemKey === 'first_day_of_month'
      ).map(type => {return {label: type.itemName, value: type.id}}));
      setAnnualCoveragePeriodList(eligibilityStartDateTypes.filter(types =>
        types.itemKey !== 'first_day_of_month'
      ).map(type => {return {label: type.itemName, value: type.id}}))
    }
  }, [eligibilityStartDateTypes]);

  useEffect(() => {
    if (hivDiseaseStages) {
      setHivDiseaseStageList(hivDiseaseStages.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [hivDiseaseStages]);

  useEffect(() => {
    if (medicationAssistanceLevels) {
      setMedicationAssistanceLevelList(medicationAssistanceLevels.map(g => { return {label: g.itemName, value: g.itemKey}}));
    }
    if (premiumAssistanceCoverageTypes) {
      setPremiumAssistanceCoverageTypeList(premiumAssistanceCoverageTypes.map(g => { return {label: g.itemName, value: g.itemKey}}));
    }
  }, [medicationAssistanceLevels, premiumAssistanceCoverageTypes]);


  useEffect(() => {
    if (hivDiseaseStages) {
      setHivDiseaseStageList(hivDiseaseStages.map(g => { return {label: g.itemName, value: g.id}}));
    }
  }, [hivDiseaseStages]);

  const validationSchema = yup.object({
    application: yup.object().shape({
      adapSubprograms: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Name is required').test({
            name: 'valid', 
            skipAbsent: true,
            test(value, ctx) {
              if (ctx.from[1].value.adapSubprograms.filter(a => a.name === value).length > 1) {
                return ctx.createError({message: 'Name must be unique across subprograms'});
              }
              let subprogram = ctx.parent;
              if (!(subprogram.supportsMedicationAssistance || subprogram.supportsPremiumAssistance)) {
                return ctx.createError({message: 'Subprogram must support at least one form of assistance'});
              }
              return true;
            }
          }),
          medicationAssistanceLevel: yup.string().nullable().test('medAsstLevelReq','Level of Assistance is required', (value, ctx) => {
            let subprogram = ctx.parent;
            if (subprogram.supportsMedicationAssistance && !value) {
              return false;
            }
            return true;
          }),
          premiumAssistanceCoverageTypes: yup.array().nullable().test('premAsstCovTypReq','Assistance Coverage Type is required', (value, ctx) => {
            let subprogram = ctx.parent;
            if (subprogram.supportsPremiumAssistance && (!value || value.length === 0)) {
              return false;
            }
            return true;
          }),
        })
      ),
      agencyGroups: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Group Name is required').test({
            name: 'valid', 
            skipAbsent: true,
            test(value, ctx) {
              if (ctx.from[1].value.agencyGroups.filter(a => a.name === value).length > 1) {
                return ctx.createError({message: 'Name must be unique across agency groups'});
              }
              let agencyGroups = ctx.parent;
              if (agencyGroups.selectedAgencies.length === 0) {
                return ctx.createError({message: 'At least one agency must be selected'});
              } else {
                const selectedAgencies = ctx.from[1].value.agencyGroups.map(a => a.selectedAgencies);
                let dupAgencies;
                for (let i = 1; i < selectedAgencies.length; i++) {
                  dupAgencies = selectedAgencies[i - 1].filter(val => selectedAgencies[i].indexOf(val) !== -1)
                }
                if(dupAgencies && dupAgencies.length > 0) {
                  return ctx.createError({message: 'Subagencies can only be applied once for all groups'});
                }
              }
              return true;
            }
          })
        })
      )
    })
  });

  useEffect(() => {
    if (agencyToEdit) {
      const newInitialValues = {
        
        application: {
          // Applicant - Basic Information
          showPreferredName: agencyToEdit.eligibilityConfig.application.showPreferredName || false,
          // Applicant - Gender
          showGenderPronouns: agencyToEdit.eligibilityConfig.application.showGenderPronouns || false,
          // Applicant - Phone and Email
          showCommunicationNotes: agencyToEdit.eligibilityConfig.application.showCommunicationNotes || false,
          // Applicant - Language
          showSecondaryLanguage: agencyToEdit.eligibilityConfig.application.showSecondaryLanguage || false,
          showPreferredWrittenCommLanguageId: agencyToEdit.eligibilityConfig.application.showPreferredWrittenCommLanguageId || false,
          // Applicant - Other Demographics
          showLawfulResidentDate: agencyToEdit.eligibilityConfig.application.showLawfulResidentDate || false,
          showCitizenshipStatus: agencyToEdit.eligibilityConfig.application.showCitizenshipStatus || false,
          showIsTobaccoUser: agencyToEdit.eligibilityConfig.application.showIsTobaccoUser || false,
          // Applicant - Urgent Application
          showIsUrgentApplication: agencyToEdit.eligibilityConfig.application.showIsUrgentApplication || false,
          selectedEligibilityApplicationUrgencyTypes: agencyToEdit.eligibilityConfig.application.selectedEligibilityApplicationUrgencyTypes || [],
          // Eligibility Timeline - initial application start date type
          selectedInitialEligibilityStartDateType: agencyToEdit.eligibilityConfig.application.selectedInitialEligibilityStartDateType || '',
          // Eligibility Timeline - annual coverage period
          selectedAnnualCoveragePeriodType: agencyToEdit.eligibilityConfig.application.selectedAnnualCoveragePeriodType || '',
          selectedEligibilityStartDateMonth: agencyToEdit.eligibilityConfig.application.selectedEligibilityStartDateMonth || '',
          selectedEligibilityStartDateDay: agencyToEdit.eligibilityConfig.application.selectedEligibilityStartDateDay || '',
          selectedInitialExtensionType: agencyToEdit.eligibilityConfig.application.selectedInitialExtensionType || '',
          selectedExtensionMonths: agencyToEdit.eligibilityConfig.application.selectedExtensionMonths || '',
          // Recertification - time before recertification (days)
          selectedTimeBeforeRecert: agencyToEdit.eligibilityConfig.application.selectedTimeBeforeRecert || 1,
          expirationDaysForStartedButUnsubmittedApps: agencyToEdit.eligibilityConfig.application.expirationDaysForStartedButUnsubmittedApps || '',
          expirationDaysForPendingApps: agencyToEdit.eligibilityConfig.application.expirationDaysForPendingApps || '',
          selectedAssistanceProgramName: agencyToEdit.eligibilityConfig.application.selectedAssistanceProgramName || 'ADAP',
          // Addresses - Secondary Address
          showSecondaryAddress: agencyToEdit.eligibilityConfig.application.showSecondaryAddress || false,
          // Health
          showGeneralHealthInfo: agencyToEdit.eligibilityConfig.application.showGeneralHealthInfo || false,
          showCurrentArvCount: agencyToEdit.eligibilityConfig.application.showCurrentArvCount || false,
          showCd4AndViralLoads: agencyToEdit.eligibilityConfig.application.showCd4AndViralLoads || false,
          // Insurance
          showPrivateInsurancePremiumOverdueAmount: agencyToEdit.eligibilityConfig.application.showPrivateInsurancePremiumOverdueAmount || false,
          showPrivateInsurancePremiumOverdueMonths: agencyToEdit.eligibilityConfig.application.showPrivateInsurancePremiumOverdueMonths || false,
          showMedicarePremiumOverdueAmount: agencyToEdit.eligibilityConfig.application.showMedicarePremiumOverdueAmount || false,
          showMedicarePremiumOverdueMonths: agencyToEdit.eligibilityConfig.application.showMedicarePremiumOverdueMonths || false,
          
          // ADAP
          allowMultipleAdapSubprogramEnrollments: agencyToEdit.eligibilityConfig.application.allowMultipleAdapSubprogramEnrollments || false,
          adapSubprograms: agencyToEdit.eligibilityConfig.application.adapSubprograms || [],
          // Select Application List - Care
          selectedCareApplicationType: agencyToEdit.eligibilityConfig.application.selectedCareApplicationType || 'rw_only',
          agencyGroups: agencyToEdit.eligibilityConfig.application.agencyGroups || [],
          // Select Application List - ADAP
          selectedADAPApplicationType: agencyToEdit.eligibilityConfig.application.selectedADAPApplicationType || 'all_subprograms',
          requiredConsents: agencyToEdit.eligibilityConfig.application.requiredConsents || [],
          // Additional Information
          showAdditionalAssistanceInterests: agencyToEdit.eligibilityConfig.application.showAdditionalAssistanceInterests || false,
        },
        rwScoring: {
          fplLimit: agencyToEdit.eligibilityConfig.rwScoring.fplLimit || '',
          state: agencyToEdit.eligibilityConfig.rwScoring.state || '',
          diseaseStages: agencyToEdit.eligibilityConfig.rwScoring.diseaseStages || []
        }
      };
      setInitialValues(newInitialValues);
      const days = [];
      for(let i = 1; i <= 31; i++) {
        days.push({label: i, value: i})
      }
      setEligibilityStartDateDayList(days);
      // load child agencies
      if (!agenciesForGrantee) {
        loadAgenciesForGrantee(agencyToEdit.id);
      }
    }
  }, [agencyToEdit]);

  useEffect(() => {
    if(agenciesForGrantee && agenciesForGrantee.length > 0) {
      const childAgencies = agenciesForGrantee.map(c => {return {label: c.name, value: c.id}});
      setChildAgenciesList(childAgencies || []);
    }
  }, [agenciesForGrantee]);


  const emptyAdapSubprogram = {
    name: '',
    supportsMedicationAssistance: false,
    medicationAssistanceLevel: '',
    supportsPremiumAssistance: false,
    premiumAssistanceCoverageTypes: []
  };

  const emptyAgencyGroup = {
    name: '',
    selectedAgencies: []
  };
  
  function buildAdapSubprogramPanel(subprogram, index, disabled) {
    return (
      <div style={{padding: 8}} key={index}>
        <div style={{marginBottom: 16}}>                    
          <LotusTextInput
            disabled={disabled}
            name={`application.adapSubprograms.${index}.name`}
            label="Name"
            required
          />
        </div>
        <div style={{marginBottom: 16}}>                    
          <LotusSwitch
            name={`application.adapSubprograms.${index}.supportsMedicationAssistance`}
            label="Supports Medication Assistance"
            labelPlacement="start"
          />
        </div>
        {medicationAssistanceLevelList && subprogram.supportsMedicationAssistance &&
          <div style={{marginBottom: 16}}>       
            <LotusRadioGroup
              name={`application.adapSubprograms.${index}.medicationAssistanceLevel`}
              label="Level of Assistance"
              items={medicationAssistanceLevelList}
              required
            />
          </div>
        }
        <div style={{marginBottom: 16}}>                    
          <LotusSwitch
            name={`application.adapSubprograms.${index}.supportsPremiumAssistance`}
            label="Supports Premium Assistance"
            labelPlacement="start"
          />
        </div>
        {premiumAssistanceCoverageTypeList && subprogram.supportsPremiumAssistance &&
          <div style={{marginBottom: 16}}>       
            <LotusCheckboxList
              name={`application.adapSubprograms.${index}.premiumAssistanceCoverageTypes`}
              label="Assistance Coverage Type"
              items={premiumAssistanceCoverageTypeList}
              required
            />
          </div>
        }
      </div>
    );
  }

  function buildAgencyGroupPanel(item, index, disabled) {
    return (
      <div style={{padding: 8}} key={index}>
        <div style={{marginBottom: 16}}>                    
          <LotusTextInput
            disabled={disabled}
            name={`application.agencyGroups.${index}.name`}
            label="Group Name"
            required
            defaultValue=''
          />
        </div>
        <div style={{marginBottom: 16}}>                    
          <LotusMultiSelect
            name={`application.agencyGroups.${index}.selectedAgencies`}
            label="Select Agencies"
            items={childAgenciesList}
            value={item.selectedAgencies}
            required
            defaultValue=''
          />
        </div>
      </div>
    );
  }

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          if(values.application.selectedInitialExtensionType !== 'extension_months') {
            values.application.selectedExtensionMonths = '';
          }
          if(annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && (s.label === 'Birth Date')}).length > 0) {
            values.application.selectedEligibilityStartDateMonth = '';
            values.application.selectedEligibilityStartDateDay = '';
          }
          if(!values.application.selectedAnnualCoveragePeriodType || annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && (s.label === 'Date of Processing')}).length > 0) {
            values.application.selectedEligibilityStartDateMonth = '';
            values.application.selectedEligibilityStartDateDay = '';
            values.application.selectedInitialExtensionType = '';
            values.application.selectedExtensionMonths = '';
          }
          await handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
          await loadUserAgencyPrograms();
        }}
      >
        {({ handleSubmit, values }) => {
          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Eligibility Config for Agency'>
                <LotusForm>
                  <LotusFormSection name='Application'>
                    <LotusFormSubSection name='Applicant'>
                      <LotusFormSubSubSection name='Basic Information'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showPreferredName"
                            value={values.application.showPreferredName}
                            label="Show Preferred Name"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Gender'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showGenderPronouns"
                            value={values.application.showGenderPronouns}
                            label="Show Gender Pronouns"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Contact Phone & Email'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showCommunicationNotes"
                            value={values.application.showCommunicationNotes}
                            label="Show Communication Notes"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Language'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showSecondaryLanguage"
                            value={values.application.showSecondaryLanguage}
                            label="Show Secondary Language"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showPreferredWrittenCommLanguageId"
                            value={values.application.showPreferredWrittenCommLanguageId}
                            label="Show Preferred Written Communications Language"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Other Demographics'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showLawfulResidentDate"
                            value={values.application.showLawfulResidentDate}
                            label="Show Date became Lawfully Present Resident"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showCitizenshipStatus"
                            value={values.application.showCitizenshipStatus}
                            label="Show Citizenship Status"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showIsTobaccoUser"
                            value={values.application.showIsTobaccoUser}
                            label="Show Tobacco Use"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                      </LotusFormSubSubSection>
                      <LotusFormSubSubSection name='Urgent Application'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showIsUrgentApplication"
                            value={values.application.showIsUrgentApplication}
                            label="Show Is Urgent Application"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                        {values.application.showIsUrgentApplication &&
                          <LotusFormItem>
                            <ClientInfoListSubsetLinker 
                              name='application.selectedEligibilityApplicationUrgencyTypes' 
                              masterList={eligibilityApplicationUrgencyTypes}
                              title="Urgency Types for Agency"
                            />
                          </LotusFormItem>
                        }
                      </LotusFormSubSubSection>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Addresses'>
                      <LotusFormSubSubSection name='Secondary Address'>
                        <LotusFormItem>
                          <LotusSwitch
                            name="application.showSecondaryAddress"
                            value={values.application.showSecondaryAddress}
                            label="Show Secondary Address"
                            labelPlacement="start"
                          />
                        </LotusFormItem>
                      </LotusFormSubSubSection>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Health'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showGeneralHealthInfo"
                          value={values.application.showGeneralHealthInfo}
                          label="Show General Health Info"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showCurrentArvCount"
                          value={values.application.showCurrentArvCount}
                          label="Show Current ARV"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showCd4AndViralLoads"
                          value={values.application.showCd4AndViralLoads}
                          label="Show CD4 and Viral Loads"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Insurance'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showPrivateInsurancePremiumOverdueAmount"
                          value={values.application.showPrivateInsurancePremiumOverdueAmount}
                          label="Show Private Insurance Overdue Premium Amount"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showPrivateInsurancePremiumOverdueMonths"
                          value={values.application.showPrivateInsurancePremiumOverdueMonths}
                          label="Show Private Insurance Permium Overdue Months"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showMedicarePremiumOverdueAmount"
                          value={values.application.showMedicarePremiumOverdueAmount}
                          label="Show Medicare Overdue Premium Amount"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showMedicarePremiumOverdueMonths"
                          value={values.application.showMedicarePremiumOverdueMonths}
                          label="Show Medicare Permium Overdue Months"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='ADAP Subprograms'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.allowMultipleAdapSubprogramEnrollments"
                          value={values.application.allowMultipleAdapSubprogramEnrollments}
                          label="Allow Multiple ADAP Subprogram Enrollments"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                      <LotusFormItem>
                        <LotusItemList
                          name="application.adapSubprograms"
                          itemName="ADAP Subprogram"
                          buildItemDetail={(item, index, isDisabled) => buildAdapSubprogramPanel(item, index, isDisabled)}
                          buildItemTemplate={() => {return {...emptyAdapSubprogram}}}
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                    <LotusFormSubSection name='Additional Information'>
                      <LotusFormItem>
                        <LotusSwitch
                          name="application.showAdditionalAssistanceInterests"
                          value={values.application.showAdditionalAssistanceInterests}
                          label="Show Additional Assistance Interests"
                          labelPlacement="start"
                        />
                      </LotusFormItem>
                    </LotusFormSubSection>
                  </LotusFormSection>
                  <LotusFormSection name='Eligibility Configuration'>
                  <LotusFormItem>
                      {initialEligibilityStartDateList && 
                      <LotusSelect
                        name="application.selectedInitialEligibilityStartDateType"
                        items={initialEligibilityStartDateList}
                        label="Initial Eligibility Start Date"
                        autoSort={false}
                      />
                      }
                    </LotusFormItem>
                    <LotusFormItem>
                      {annualCoveragePeriodList &&
                      <LotusSelect
                        name="application.selectedAnnualCoveragePeriodType"
                        items={annualCoveragePeriodList}
                        label="Annual Coverage Type"
                        autoSort={false}
                      />
                      }   
                    </LotusFormItem>
                    {annualCoveragePeriodList && annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && s.label === 'Pick a Date'}).length > 0 &&
                        <LotusForm style={{marginBottom: 24}}>
                          <LotusSelect
                            name="application.selectedEligibilityStartDateMonth"
                            autoSort={false}
                            items={[
                              {label: 'January', value: '01'},
                              {label: 'February', value: '02'},
                              {label: 'March', value: '03'},
                              {label: 'April', value: '04'},
                              {label: 'May', value: '05'},
                              {label: 'June', value: '06'},
                              {label: 'July', value: '07'},
                              {label: 'August', value: '08'},
                              {label: 'September', value: '09'},
                              {label: 'October', value: '10'},
                              {label: 'November', value: '11'},
                              {label: 'December', value: '12'}
                            ]}
                            label="Month"
                            style={{width: 200, marginRight: 10}}
                            required
                          />
                          <LotusSelect
                            name="application.selectedEligibilityStartDateDay"
                            autoSort={false}
                            items={eligibilityStartDateDayList}
                            label="Day"
                            style={{width: 200}}
                            required
                          />
                        </LotusForm>
                      }
                      {annualCoveragePeriodList && annualCoveragePeriodList.filter(s => {return s.value === values.application.selectedAnnualCoveragePeriodType && (s.label === 'Pick a Date' || s.label === 'Birth Date')}).length > 0 &&
                        <LotusFormItem>
                          <LotusRadioGroup
                            name="application.selectedInitialExtensionType"
                            label="Initial Coverage Period"
                            items={extensionOptions}
                            required
                        />
                          {values.application.selectedInitialExtensionType === 'extension_months' &&
                              <LotusTextInput
                                name="application.selectedExtensionMonths"
                                label="Number of Months"
                                integer
                                required
                                maxLength={2}
                                style={{marginTop: 10}}
                              />
                          }
                        </LotusFormItem>
                      }
                    <LotusFormItem>
                      <LotusTextInput
                        name="application.selectedTimeBeforeRecert"
                        label="Recertification Renewal Period (Months)"
                        integer
                        maxLength={2}
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusTextInput
                        name="application.expirationDaysForStartedButUnsubmittedApps"
                        label="Automatic Cancellation Period for Started but Unsubmitted Applications (Days)"
                        integer
                        maxLength={3}
                        minValue={1}
                        maxValue={365}
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusTextInput
                        name="application.expirationDaysForPendingApps"
                        label="Automatic Cancellation Period for Pended Applications (Days)"
                        integer
                        maxLength={3}
                        minValue={1}
                        maxValue={365}
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusTextInput
                        name="application.selectedAssistanceProgramName"
                        label="Assistance Program Name"
                      />
                    </LotusFormItem>
                  </LotusFormSection>
                  <LotusFormSection name='Ryan White Scoring'>
                    <LotusFormItem>
                      <LotusTextInput
                        name="rwScoring.fplLimit"
                        label="FPL % Limit"
                        floatingPoint
                      />
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusStateSelector
                        name="rwScoring.state"
                        label='State'
                      />
                    </LotusFormItem>
                    {hivDiseaseStageList &&
                      <LotusFormItem>
                        <LotusMultiSelect
                          name="rwScoring.diseaseStages"
                          label="Allowed Disease Stages"
                          items={hivDiseaseStageList}
                          readOnlyRows={2}
                        />
                      </LotusFormItem>
                    }
                  </LotusFormSection>
                  <LotusFormSection name='Application List'>
                    <LotusFormItem>
                        <LotusRadioGroup
                          name="application.selectedCareApplicationType"
                          label="Care Applications"
                          items={careApplicationOptions}
                          required
                      />
                      {values.application.selectedCareApplicationType === 'group_by_agency' &&
                      <LotusFormItem>
                        <LotusItemList
                          name="application.agencyGroups"
                          itemName="New Group"
                          isSubList={true}
                          label="Subagencies can only be applied once for all groups"
                          buildItemDetail={(item, index, isDisabled) => buildAgencyGroupPanel(item, index, isDisabled)}
                          buildItemTemplate={() => {return {...emptyAgencyGroup}}}
                        />
                      </LotusFormItem>
                      }
                    </LotusFormItem>
                    <LotusFormItem>
                      <LotusRadioGroup
                        name="application.selectedADAPApplicationType"
                        label="ADAP Applications"
                        items={adapApplicationOptions}
                        required
                      />
                    </LotusFormItem>
                  </LotusFormSection>
                  <LotusFormSection name='Consents'>
                    <LotusFormItem>
                      <LotusMultiSelect
                        name={`application.requiredConsents`}
                        label="Required Consents"
                        items={agencyLevelConsents}
                      />
                    </LotusFormItem>
                  </LotusFormSection>
                  <LotusFormItem lastOne>
                    <LotusButton type="submit">
                      Submit
                    </LotusButton>
                  </LotusFormItem>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import LotusSelect from 'components/widgets/Forms/LotusSelect';

const validationSchema = yup.object({
  assessmentTypeId: yup.string().required('Assessment Type is required'),
  expirationReminderDays: yup
    .number('Expiration Reminder must be an integer')
    .required('Expiration Reminder is required'),
  assessmentTimeCycleDays: yup
    .number('Time Cycle must be an integer')
    .required('Time Cycle is required'),
  waitTimeToReassessmentDays: yup
    .number('Wait Time To Reassessment must be an integer')
    .required('Wait Time To Reassessment is required'),
  startedAssessmentExpirationHours: yup
    .number('Started Assessment Expiration must be an integer')
    .required('Started Assessment Expiration is required'),
});

export default function AgencyAssessmentForm({
  agencyAssessment,
  agencyId,
  handleSubmit,
  readOnly,
}) {
  const { assessmentTypes, loadAgencyAssessmentTypes } = useAgencyManagement();

  useEffect(() => {
    if (agencyId && !assessmentTypes) {
      loadAgencyAssessmentTypes(agencyId);
    }
  }, [agencyId]);

  const initialValues =
    agencyAssessment &&
    assessmentTypes
      ? {
          id: agencyAssessment.id,
          name: agencyAssessment.name,
          assessmentTypeId: agencyAssessment.assessmentTypeId,
          expirationReminderDays: agencyAssessment.expirationReminderDays,
          assessmentTimeCycleDays: agencyAssessment.assessmentTimeCycleDays,
          waitTimeToReassessmentDays:
            agencyAssessment.waitTimeToReassessmentDays,
          startedAssessmentExpirationHours:
            agencyAssessment.startedAssessmentExpirationHours,
        }
      : {
          name: '',
          assessmentTypeId: '',
          expirationReminderDays: 0,
          assessmentTimeCycleDays: 0,
          waitTimeToReassessmentDays: 0,
          startedAssessmentExpirationHours: 0,
        };

  return (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await handleSubmit(values);
            actions.setSubmitting(false);
            actions.resetForm();
          }}
        >
          {({ handleSubmit, handleChange, touched, errors, values }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <LotusForm>
                  <LotusFormItem>
                    <LotusTextInput
                      name="name"
                      label="Assessment Name"
                      disabled={readOnly}
                      required
                    />
                  </LotusFormItem>
                  {assessmentTypes && (
                    <LotusFormItem>
                      <LotusSelect
                        label="Assessment Type"
                        name="assessmentTypeId"
                        items={assessmentTypes.map(atype => {return {label: atype.name, value: atype.id}})}
                        disabled={readOnly}
                        required
                      />
                    </LotusFormItem>
                  )}
                  <LotusFormItem>
                    <LotusTextInput
                      id="expirationReminderDays"
                      name="expirationReminderDays"
                      label="Expiration Reminder (days)"
                      disabled={readOnly}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusTextInput
                      id="assessmentTimeCycleDays"
                      name="assessmentTimeCycleDays"
                      label="Time Cycle (days)"
                      disabled={readOnly}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusTextInput
                      id="waitTimeToReassessmentDays"
                      name="waitTimeToReassessmentDays"
                      label="Wait Time To Reassessment (days)"
                      disabled={readOnly}
                    />
                  </LotusFormItem>
                  <LotusFormItem>
                    <LotusTextInput
                      id="startedAssessmentExpirationHours"
                      name="startedAssessmentExpirationHours"
                      label="Started Assessment Expiration (hours)"
                      disabled={readOnly}
                    />
                  </LotusFormItem>
                  {!readOnly && (
                    <LotusFormItem>
                      <LotusButton type="submit">
                        Submit
                      </LotusButton>
                    </LotusFormItem>
                  )}
                </LotusForm>
              </Form>
            );
          }}
        </Formik>
  );
}

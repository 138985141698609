import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table } from '@lotus/components';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useAgencySearch } from 'contexts/AgencySearchContext';
import { useState } from 'react';

export default function AgencyList() {
  const { userAgency } = useUserAgency();
  const { granteeAgencies, loadGranteeAgencies, agenciesForGrantee, loadAgenciesForGrantee } = useAgencySearch();

  const { verifyPermission } = useAuthState();
  const hasModifyAgencyPermission = verifyPermission(PERMISSIONS.MODIFY_AGENCY);
  const [agenciesAvailableToEdit, setAgenciesAvailableToEdit] = useState();

  useEffect(() => {
    if (userAgency.isRoot) {
      loadGranteeAgencies();
    } else {
      loadAgenciesForGrantee(userAgency.granteeAgencyId);
    }
  }, []);

  useEffect(() => {
    if (userAgency.isRoot && granteeAgencies) {
      setAgenciesAvailableToEdit(granteeAgencies);
    }
  }, [userAgency, granteeAgencies]);

  useEffect(() => {
    if (!userAgency.isRoot && agenciesForGrantee) {
      setAgenciesAvailableToEdit([...agenciesForGrantee]);
    }
  }, [userAgency, agenciesForGrantee]);

  const getValueForDisplay = (value, emptyVal = '') => {
    return value || emptyVal;
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
        download: false,
      },
    },
    {
      label: 'Name',
      name: 'name',
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <Link to={hasModifyAgencyPermission ? `/agencies/edit/${id}` : '#'}>
              {value}
            </Link>
          );
        },
      },
    },
    {
      label: 'State',
      name: 'state',
      options: {
        customBodyRender: (value) => {
          return <div>{getValueForDisplay(value, '-')}</div>;
        },
      },
    },
  ];

  return (
    agenciesAvailableToEdit && (
      <Table
        columns={columns}
        data={agenciesAvailableToEdit}
        totalRows={agenciesAvailableToEdit.length}
        options={{
          selectableRows: 'none',
          selectableRowsHideCheckboxes: false,
          selectableRowsHeader: false,
        }}
      />
    )
  );
}

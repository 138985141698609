import React, { useEffect, useState } from 'react';
import AgencyAssessmentPanel from './AgencyAssessmentPanel';
import AgencyAssessmentForm from './forms/AgencyAssessmentForm';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import Stack from '@mui/material/Stack';

export default function AgencyAssessmentsList({ agencyId }) {
  const {
    agency,
    loadAgency,
    assessments,
    loadAgencyAssessments,
    createAgencyAssessment,
    updateAgencyAssessment,
  } = useAgencyManagement();

  const [loadedAgencyAssessments, setLoadedAgencyAssessments] = useState();
  const [addingAssessment, setAddingAssessment] = useState(false);

  useEffect(() => {
    if (agencyId && !agency) {
      loadAgency(agencyId);
    }
  }, [agencyId]);

  useEffect(() => {
    if (agency && !assessments) {
      loadAgencyAssessments();
    }
  }, [agency]);

  useEffect(() => {
    if (assessments) {
      const lst = [...assessments];
      lst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setLoadedAgencyAssessments(lst);
    }
  }, [assessments]);

  const handleUpsertAssessment = async (values) => {
    if (!values.id) {
      await createAgencyAssessment(values);
    } else {
      await updateAgencyAssessment(values);
    }

    setAddingAssessment(false);
  };

  return (
    <LotusPageSection header="Assessments">
      {!addingAssessment && (
        <Stack justifyContent="flex-end" direction="row">
          <LotusButton
            plusIcon
            variant={'outlined'}
            color={'secondary'}
            onClick={() => setAddingAssessment(true)}
          >
            ADD
          </LotusButton>
        </Stack>
      )}
      {addingAssessment && (
        <AgencyAssessmentForm
          agencyId={agencyId}
          handleSubmit={handleUpsertAssessment}
          handleCancel={() => setAddingAssessment(false)}
        />
      )}
      {loadedAgencyAssessments &&
        loadedAgencyAssessments.map((assessment) => (
          <AgencyAssessmentPanel
            key={assessment.id}
            agencyId={agencyId}
            assessment={assessment}
            handleSave={handleUpsertAssessment}
          />
        ))}
    </LotusPageSection>
  );
}

import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AgencyIntegrationForm from './forms/AgencyIntegrationForm';
import { styled } from '@mui/material/styles';
import LotusButton from 'components/widgets/Forms/LotusButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const PREFIX = 'AgencyIntegrationPanel';

const classes = {
  expandIcon: `${PREFIX}-expandIcon`,
  accordion: `${PREFIX}-accordion`,
  borderNoTop: `${PREFIX}-borderNoTop`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.expandIcon}`]: {
    fontSize: '35px',
  },
  [`& .${classes.accordion}`]: {
    border: '1px solid #7A7A7A66',
    borderRadius: '5px',
    borderBottom: 'none',
  },
  [`& .${classes.borderNoTop}`]: {
    border: '1px solid #7A7A7A66',
    borderRadius: '5px',
    borderTop: 'none',
  },
}));

export default function AgencyIntegrationPanel({
  integration,
  agencyId,
  handleSave,
}) {
  const [integrationExpanded, setIntegrationExpanded] = useState(false);
  const [readOnly, setReadOnly] = useState(Boolean(integration));

  const handleSaveIntegrationEvent = async (values) => {
    await handleSave(values);
    setIntegrationExpanded(false);
    setReadOnly(true);
  };

  const EditButtonWrapper = ({ children }) => (
    <div onClick={(e) => e.stopPropagation()}>{children}</div>
  );

  return (
    <StyledGrid item xs={12} sx={{ marginTop: '30px' }}>
      <Accordion style={{ width: '100%', marginBottom: 20 }}
        onChange={(e, expanded) => {
          if (expanded !== integrationExpanded) {
            setIntegrationExpanded(expanded);
          }
          if (!expanded) {
            setReadOnly(true);
          }
        }}
        expanded={integrationExpanded}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          className={classes.accordion}
        >
          <Grid container>
            {readOnly && (
              <Grid item xs={6}>
                <Typography color="primary" variant="body1">{integration.name}</Typography>
              </Grid>
            )}
            {readOnly && (
              <Grid item xs={6} container style={{ justifyContent: 'flex-end' }}>
                <EditButtonWrapper>
                  <LotusButton
                    pencilIcon
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => {
                      if (!integrationExpanded) {
                        setIntegrationExpanded(true);
                      }
                      setReadOnly(false);
                    }}
                  >
                    Edit
                  </LotusButton>
                </EditButtonWrapper>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.borderNoTop}>
          {integrationExpanded && (
            <AgencyIntegrationForm
              agencyIntegration={integration}
              agencyId={agencyId}
              readOnly={readOnly}
              handleSubmit={handleSaveIntegrationEvent}
              handleCancel={() => {
                setIntegrationExpanded(false);
                setReadOnly(true);
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </StyledGrid>
  );
}

import React, { useEffect, useState } from 'react';
import { FieldArray } from 'formik';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PermissionsPanel from './PermissionsPanel';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import LotusFormSection from 'components/widgets/Forms/LotusFormSection';

export default function CategoriesPanel({
  values,
  touched,
  errors,
  handleChange,
}) {
  const { setFieldValue } = useFormikContext();
  const [initialized, setInitialized] = useState(false);
  const [startingTemplateId, setStartingTemplateId] = useState();

  const resetPermissions = () => {
    values.categories.forEach((category) => {
      category.permissions.forEach((permission) => {
        permission.enabled = false;
      });
    });
  };

  useEffect(() => {
    if ((!initialized || values.startingTemplateId !== startingTemplateId) &&
        values.startingTemplate && values.startingTemplate.data &&
        values.categories && values.categories.length > 0
    ) {
      resetPermissions();
      values.startingTemplate.data.forEach((existingPermission) => {
        values.categories.forEach((displayCategory) => {
          displayCategory.permissions.forEach((displayPermission) => {
            if (displayPermission.key === existingPermission.key) {
              displayPermission.enabled = existingPermission.enabled;
            }
          });
        });
      });
      setInitialized(true);
      setStartingTemplateId(values.startingTemplateId);
      // without this doesnt always seem to update the display
      setFieldValue('categories', values.categories);
    }
  }, [values.startingTemplate, values.categories]);

  return (
      values.categories.length > 0 && (
          <LotusFormSection name="Select Permissions">
            <FieldArray name="categories">
              <>
                {values.categories.map((item, index) => {
                  const allPermissionsSelected = item.permissions.filter(
                    (permission) => !permission.enabled
                  );
                  const allSelected = allPermissionsSelected.length === 0;
                  const someSelected =
                    allPermissionsSelected.length > 0 &&
                    allPermissionsSelected.length < item.permissions.length;
                  return (
                    <Accordion key={`catgory-accordion-${item.id}`} style={{width: '100%'}}>
                      <AccordionSummary
                        style={{background: '#3D77D439'}}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${item.id}-header`}
                        id={`${item.id}-header`}
                      >
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="body1">{item.name}</Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={allSelected || someSelected}
                                  color='primary'
                                />
                              }
                              label={
                                allSelected
                                  ? 'All Selected'
                                  : someSelected
                                  ? 'Partially Selected'
                                  : 'None selected'
                              }
                            />
                          </Grid>
                          {errors.categories && errors.categories[index] && (
                            <Grid item xs={12}>
                              <Typography variant="body1" color="error">
                                {errors.categories[index]}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        
                      </AccordionSummary>
                      <AccordionDetails>
                        <PermissionsPanel
                          key={`category-panel-${item.id}`}
                          permissionsContainer={item}
                          name="categories"
                          categoriesIndex={index}
                          handleChange={handleChange}
                          values={
                            values &&
                            values.categories &&
                            values.categories[index]
                          }
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            </FieldArray>
          </LotusFormSection>
      )
  );
}

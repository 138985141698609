import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import { useProperties } from 'contexts/PropertiesContext';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusSelect from 'components/widgets/Forms/LotusSelect';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';

const subDomainRegex = '^[0-9a-zA-Z\-]{2,255}$';

const validationSchema = yup.object({
  idp: yup.string().required('Log In Provider is required'),
  subdomain: yup.string().matches(subDomainRegex, {
    message: 'Only letters, numbers and hyphen (-) are allowed.',
    excludeEmptyString: true,
  }),
});

export default function AgencyOptionalFeaturesForm({ handleSubmit }) {
  const { agency } = useAgencyManagement();
  const [initialValues, setInitialValues] = useState();
  const [logInProviders, setLogInProviders] = useState();
  const { properties } = useProperties();


  useEffect(() => {
    let initialVals = {
      summary: false,
      eligibility: false,
      idp: 'local',
      dashboard: false,
    };

    if (agency && agency.optionalFeatures) {
      initialVals = { ...initialVals, ...agency.optionalFeatures };
    }
    setInitialValues(initialVals);
  }, [agency]);

  useEffect(() => {

    const providers = [];
    providers.push({ value: 'local', label: 'Application Managed' });
    if (properties) {
      const idps = JSON.parse(properties['REACT_APP_EXTERNAL_IDPS']);
      idps.forEach((idp) => {
        console.log(idp);
        providers.push({ value: idp.ProviderName, label: idp.ProviderName + ' (External)' });
      });

    }
    setLogInProviders(providers);

  }, [properties]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={async (values, actions) => {
        await handleSubmit(values);
        actions.setSubmitting(false);
        actions.resetForm();
      }}
    >
      {({ handleSubmit, handleChange, values, errors, touched }) => {
        return values && (
          <Form onSubmit={handleSubmit}>
            <LotusPageSection header='Optional Features for Agency'>
              <LotusForm>
                <LotusFormItem>
                  <LotusSwitch
                    name="summary"
                    value={values.summary}
                    label="Summary Enabled"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="eligibility"
                    value={values.eligibility}
                    label="Eligibility Enabled"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                <LotusFormItem>
                  <LotusSwitch
                    name="dashboard"
                    value={values.dashboard}
                    label="Dashboard Enabled"
                    onChange={handleChange}
                  />
                </LotusFormItem>
                {logInProviders && (
                  <LotusFormItem>
                    <LotusSelect
                      name="idp"
                      label="Log In Provider"
                      items={logInProviders}
                      value={values.idp}
                      onChange={handleChange}
                      error={
                        touched?.idp && Boolean(errors?.idp)
                      }

                    />
                  </LotusFormItem>
                )}
                {agency.isGranteeAgency && (
                  <LotusFormItem>
                    <LotusTextInput
                      name="subdomain"
                      value={values.subdomain}
                      label="Subdomain"
                      onChange={handleChange}
                      error={
                        touched.subdomain && Boolean(errors.subdomain)
                      }
                    />
                  </LotusFormItem>
                )}

                <LotusFormItem>
                  <LotusButton type="submit">
                    Submit
                  </LotusButton>
                </LotusFormItem>
              </LotusForm>
            </LotusPageSection>
          </Form>
        );
      }}
    </Formik>
  );
}

import React, { useEffect, useState } from 'react';
import AgencyConsentTypesList from './AgencyConsentTypesList';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import ActionDialog from 'components/Dialogs/ActionDialog';
import AgencyConsentTypeForm from './forms/AgencyConsentTypeForm';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import Stack from '@mui/material/Stack';
import LotusSpacedBlock from 'components/widgets/Layout/LotusSpacedBlock';

export default function AgencyConsentTypesPanel({ agencyId }) {
  const { agency, consentTypes, loadAgencyConsentTypes } = useAgencyManagement();

  const [loadedAgencyConsentTypes, setLoadedAgencyConsentTypes] = useState();
  const [addingConsentType, setAddingConsentType] = useState(false);

  useEffect(() => {
    if (agency && !consentTypes) {
      loadAgencyConsentTypes();
    }
  }, [agency]);

  useEffect(() => {
    if (consentTypes) {
      let consentTypesLst = consentTypes.map((ct) => {
        let consentType = {
          ...ct,
          statusStr:
            ct.isActive && ct.isAgencyWide
              ? 'Active - Mandatory'
              : ct.isActive
              ? 'Active'
              : 'Discontinued',
          verbalStr: ct.supportsVerbal ? 'Yes' : 'No',
          writtenStr: ct.supportsWritten ? 'Yes' : 'No',
          multilanguageStr:
            ct.languageDocuments.length > 0
              ? ct.languageDocuments.map((doc) => doc.languageName).join(',')
              : 'No',
          fileName: ct.defaultConsentDocumentFileName || '',
          thumbnail: ct.defaultConsentDocumentThumbnail || '',
          lastModified: ct.updatedDate
            ? ct.updatedDate
            : ct.createdDate,
        };
        return consentType;
      });
      consentTypesLst.sort((a, b) => {
        return a.name > b.name ? 1 : -1;
      });
      setLoadedAgencyConsentTypes(consentTypesLst);
    }
  }, [consentTypes]);

  const closeDialog = async () => {
    setAddingConsentType(false);
  };

  return (
    <>
      {loadedAgencyConsentTypes && (
        <LotusPageSection header='Consents'>
          <LotusSpacedBlock>
            <div>Store and manage all consents agency-wide</div>
          </LotusSpacedBlock>
          <LotusSpacedBlock>
            <Stack justifyContent="flex-end" direction="row">
              <LotusButton
                onClick={() => setAddingConsentType(true)}
                plusIcon
              >
                New Consent
              </LotusButton>
            </Stack>
          </LotusSpacedBlock>
          <AgencyConsentTypesList
            agencyId={agencyId}
            consentTypesToShow={loadedAgencyConsentTypes}
          />
        </LotusPageSection>
      )}
      <ActionDialog
        open={addingConsentType}
        handleClose={closeDialog}
        fullWidth={true}
        maxWidth="lg"
        content={
          <AgencyConsentTypeForm
            afterSave={closeDialog}
            readOnly={false}
            handleCancel={closeDialog}
          />
        }
      />
    </>
  );
}

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useAgencyManagement } from 'contexts/AgencyManagementContext';
import LotusForm from 'components/widgets/Forms/LotusForm';
import LotusFormItem from 'components/widgets/Forms/LotusFormItem';
import LotusButton from 'components/widgets/Forms/LotusButton';
import LotusTextInput from 'components/widgets/Forms/LotusTextInput';
import LotusSwitch from 'components/widgets/Forms/LotusSwitch';
import Stack from '@mui/material/Stack';
import { v4 as uuidv4 } from 'uuid';
import Typography from '@mui/material/Typography';
import LotusPageSection from 'components/widgets/Layout/LotusPageSection';
import { useState } from 'react';

export default function AgencyDocumentTypesForm({ handleSubmit }) {
  const { agency, documentTypes, loadAgencyDocumentTypes } = useAgencyManagement();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if (agency && !documentTypes) {
      loadAgencyDocumentTypes();
    }
  }, [agency, documentTypes]);

  useEffect(() => {
    const initialValues = agency && documentTypes
      ? {
          id: agency.id,
          newType: '',
          newDocumentTypes: [],
          agencyDocumentTypes: documentTypes,
        }
      : {
          newType: '',
          newDocumentTypes: [],
          agencyDocumentTypes: [],
        };
    setInitialValues(initialValues);
  }, [documentTypes]);

  return (
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ handleSubmit, setFieldValue, setFieldError, touched, errors, values }) => {
          const addDocumentType = () => {
            if (values.newType.length > 0) {
              const found = [
                ...values.newDocumentTypes,
                ...values.agencyDocumentTypes
              ].find( type => type.name.toLowerCase() === values.newType.toLowerCase() );

              if (found) {
                setFieldError( 'newType', 'Document Type already exists.');
              }
              else {
                setFieldValue('newDocumentTypes', [
                  ...values.newDocumentTypes,
                  {name: values.newType, is_active: true},
                ]);

                setFieldValue('newType', '');
              }
            }
          };

          const removeDocumentType = (doc) => {
            const newValues = values.newDocumentTypes.filter(
              (type) => type.name !== doc.name
            );
            setFieldValue('newDocumentTypes', newValues);
          };

          return values && (
            <Form onSubmit={handleSubmit}>
              <LotusPageSection header='Document Types'>
                <LotusForm>
                  <LotusFormItem>
                    <Stack direction="row" spacing={1}>
                      <LotusTextInput
                        label='New Document Type'
                        name="newType"
                        error={touched.newType && Boolean(errors.newType)}
                      />
                      <LotusButton
                        color="primary"
                        variant="contained"
                        onClick={addDocumentType}
                      >
                        Add
                      </LotusButton>
                    </Stack>
                  </LotusFormItem>

                  {values.agencyDocumentTypes.length > 0 && values.agencyDocumentTypes.map((value, index) => {
                    return (
                      <LotusFormItem key={uuidv4()}>
                        <LotusSwitch
                          name={`agencyDocumentTypes.${index}.is_active`}
                          label={value.name}
                          value={value.is_active}
                          onChange={() => {
                            value.is_active = !value.is_active;

                            setFieldValue('agencyDocumentTypes', [
                              ...values.agencyDocumentTypes,
                            ]);
                          }}
                        />
                      </LotusFormItem>
                    );
                  })}

                  {values.newDocumentTypes?.map((doc) => {
                    return (
                      <LotusFormItem key={uuidv4()}>
                        <Stack direction="row" spacing={1}>
                          <Typography>{doc.name}</Typography>
                            <LotusButton onClick={() => removeDocumentType(doc)}>
                              Remove
                            </LotusButton>
                        </Stack>
                      </LotusFormItem>
                    );
                  })}

                  <LotusFormItem>
                    <LotusButton type="submit">
                      Submit
                    </LotusButton>
                  </LotusFormItem>
                </LotusForm>
              </LotusPageSection>
            </Form>
          );
        }}
      </Formik>
  );
}

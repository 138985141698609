import React, { useEffect, useState } from 'react';
import TabbedMultiformPanel from 'components/Forms/MultiTab/MultiForm/TabbedMultiformPanel';
import { useSubforms } from 'components/Forms/utils/useSubforms';
import { useClientSubforms } from 'components/Forms/utils/useClientSubforms';
import { useLists } from 'contexts/ListsContext';
import { ClientFormContextProvider } from 'contexts/ClientFormContext';
import { ClientConsentsTab } from './ClientConsentsTab';
import { useUserAgency } from 'contexts/UserAgencyContext';
import { DynamicFormProvider } from 'contexts/DynamicFormContext';
import { useNavigate, useParams } from 'react-router-dom';
import ClientDetailsForm from './ClientDetailsForm';
import ClientAddressesForm from './ClientAddressesForm';
import ClientPersonalContactsForm from './ClientPersonalContactsForm';
import ClientIncomeForm from './ClientIncomeForm';
import ClientInsuranceForm from './ClientInsuranceForm';
import ClientCareTeamForm from './ClientCareTeamForm';
import ClientHealthForm from './ClientHealthForm';
import { useAuthState } from 'contexts/AuthProvider';
import { PERMISSIONS } from 'lib/permissionEnums';
import { useClient } from 'contexts/ClientContext';

function ClientInfoEditorPanel({clientId, client}) {
  const formTypeKey = 'client_information';

  const { userAgency, userAgencyClientInfoConfig, userAgencyClientInfoCustomLists, loadUserAgencyClientInfoCustomLists, userAgencyConsentTypes, loadUserAgencyConsentTypes } = useUserAgency();
  const subformDefinitions = useSubforms(formTypeKey);
  const clientForms = useClientSubforms(clientId, formTypeKey);
  const { lvl3: formTab } = useParams();
  const navigate = useNavigate();

  const [existingClientConfig, setExistingClientConfig] = useState();
  const {birthGenders, loadClientInfoPicklists } = useLists();
  const { verifyPermission } = useAuthState();
  const [tabDefinitions, setTabDefinitions] = useState();
  const disabled = !verifyPermission(PERMISSIONS.UPDATE_CLIENT_INFORMATION);
  const [testResultConfig, setTestResultConfig] = useState();
  const [premiumPaymentConfig, setPremiumPaymentConfig] = useState();
  const [dispensesConfig, setDispensesConfig] = useState();

  const { updateRestrictions, loadClientInfoUpdateRestrictions } = useClient();
  
  useEffect(() => {
    if (client) {
      loadClientInfoUpdateRestrictions(client.id);
    }
  }, [client]);

  useEffect(() => {
    if (!formTab) {
      navigate(`/client/${clientId}/clientinformation/General/ClientDetails`);
    }
  }, [formTab]);

  useEffect(() => {
    setExistingClientConfig(userAgencyClientInfoConfig?.existingClients || {});
  }, [userAgencyClientInfoConfig]);

  useEffect(() => {
    if(userAgency) {
      setTestResultConfig(userAgency?.testResultConfig);
      setPremiumPaymentConfig(userAgency?.premiumsPaidConfig);
      setDispensesConfig(userAgency?.medicationDispensesConfig);
    }  
    if (!userAgencyClientInfoCustomLists) {
      loadUserAgencyClientInfoCustomLists(); // Do this here so our child components have access to the lists rather than making their own calls
    }
    if (!userAgencyConsentTypes) {
      loadUserAgencyConsentTypes();
    }
  }, [userAgency]);

  useEffect(() => {
    if (!birthGenders) {
      loadClientInfoPicklists(); // Do this here so our child components have access to the lists rather than making their own calls
    }
  }, []);

  useEffect(() => {
    if (client && subformDefinitions && clientForms && existingClientConfig && updateRestrictions && userAgencyConsentTypes) {
      const allTabs = {
        'Client Details' : {
          component: 
            <ClientDetailsForm
              client={client} 
              disabled={disabled} 
              existingClientConfig={existingClientConfig} 
              dynamicFormExtensionDefinition={subformDefinitions ? subformDefinitions.find(def => {return def.formSubtypeName === 'Client Details' || def.formSubtypeCustomName === 'Client Details'}) : null} 
              dynamicFormExtensionValues={clientForms && (clientForms['Client Details'])}
              updateRestrictions={updateRestrictions} />
        },
        'Addresses' : {
          component: 
            <ClientAddressesForm 
              client={client} 
              disabled={disabled} 
              existingClientConfig={existingClientConfig} 
              dynamicFormExtensionDefinition={subformDefinitions ? subformDefinitions.find(def => {return def.formSubtypeName === 'Addresses' || def.formSubtypeCustomName === 'Addresses'}) : null} 
              dynamicFormExtensionValues={clientForms && (clientForms['Addresses'])} 
              updateRestrictions={updateRestrictions} />
        },
        'Personal Contacts' : { 
          component: 
            <ClientPersonalContactsForm 
              client={client} 
              disabled={disabled} 
              existingClientConfig={existingClientConfig} 
              dynamicFormExtensionDefinition={subformDefinitions ? subformDefinitions.find(def => {return def.formSubtypeName === 'Personal Contacts' || def.formSubtypeCustomName === 'Personal Contacts'}) : null} 
              dynamicFormExtensionValues={clientForms && (clientForms['Personal Contacts'])}
              updateRestrictions={updateRestrictions} />
        },
        'Income' : {
          component: 
            <ClientIncomeForm
              client={client} 
              disabled={disabled} 
              existingClientConfig={existingClientConfig} 
              dynamicFormExtensionDefinition={subformDefinitions ? subformDefinitions.find(def => {return def.formSubtypeName === 'Income' || def.formSubtypeCustomName === 'Income'}) : null} 
              dynamicFormExtensionValues={clientForms && (clientForms['Income'])} 
              updateRestrictions={updateRestrictions} />
        },
        'Health' : { 
          component: 
            <ClientHealthForm 
              client={client} 
              disabled={disabled} 
              existingClientConfig={existingClientConfig} 
              dynamicFormExtensionDefinition={subformDefinitions ? subformDefinitions.find(def => {return def.formSubtypeName === 'Health' || def.formSubtypeCustomName === 'Health'}) : null} 
              dynamicFormExtensionValues={clientForms && (clientForms['Health'])} 
              updateRestrictions={updateRestrictions}
              testResultConfig={testResultConfig}
              dispensesConfig={dispensesConfig} />
        },
        'Insurance' : { 
          component: 
            <ClientInsuranceForm
              client={client} 
              disabled={disabled} 
              existingClientConfig={existingClientConfig} 
              dynamicFormExtensionDefinition={subformDefinitions ? subformDefinitions.find(def => {return def.formSubtypeName === 'Insurance' || def.formSubtypeCustomName === 'Insurance'}) : null} 
              dynamicFormExtensionValues={clientForms && (clientForms['Insurance'])}
              updateRestrictions={updateRestrictions}
              premiumPaymentConfig={premiumPaymentConfig} />
        },
        'Care Team' : { 
          component: 
            <ClientCareTeamForm
            clientId={client?.id} 
            disabled={disabled} 
            userAgency={userAgency}
            />,
          afterDynamicTabs: true
        }
      }
      if (userAgencyConsentTypes.filter(t => t.isActive && t.isAgencyWide).length > 0) {
        allTabs['Consents'] = { 
          component: 
            <ClientConsentsTab 
              client={client}
              disabled={disabled}
              updateRestrictions={updateRestrictions} />,
          afterDynamicTabs: true
        };
      }

      setTabDefinitions(allTabs);
    }
  }, [client, subformDefinitions, clientForms, existingClientConfig, updateRestrictions, userAgencyConsentTypes]);

  return (
    clientForms && tabDefinitions && subformDefinitions && 
      <TabbedMultiformPanel 
        selectedTab={formTab}
        headerText="General"
        tabDefinitions={tabDefinitions}
        dynamicSubformDefinitions={subformDefinitions} 
        clientForms={clientForms} 
        clientId={clientId}
        disabled={disabled}
        linkPathPrefix={`/client/${clientId}/clientinformation/General`} />
  );
}

export default function ClientInfoEditor({ ...props }) {
  return (
    <DynamicFormProvider>
      <ClientFormContextProvider>
          <ClientInfoEditorPanel {...props} />
      </ClientFormContextProvider>
    </DynamicFormProvider>
  );
}